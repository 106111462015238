import stringToRegExp from 'string-to-regexp';
const addSuppressedFlags = (data, path, regId) => {
  // path = [{cloudAccountId:"/r1YbR7EtglMESl5MBEsx/", regId: "/.*/", resourceId: "/amplifytesting-testing-20190508204116-deployment/",signatureId:"/.*/"},{cloudAccountId:"/r1YbR7EtglMESl5MBEsx/", regId: "/.*/", resourceId: "/amplifytesting-testing-20190508204116-deployment/",signatureId:"/AWS:S3-001/"}]
  if (path.length > 0) {
    let eachRegulationData = data.reduce((acc0, eachResource) => {
      suppressResource = true;

      let dataWithSuppressKey = eachResource.risks.reduce((acc, each) => {
        let waitAcc = acc;
        each.suppress = false;
        each.rulesApplied = [];
        for (let i in path) {
          let eachPath = path[i].path;

          if (checkPathByRegix(each.cloudAccountId, eachPath.cloudAccountId)) {
            if (checkPathByRegix(regId, eachPath.regId)) {
              if (checkPathByRegix(each.resourceId, eachPath.resourceId)) {
                let { rulesApplied } = each;
                if (checkPathByRegix(each.signatureName, eachPath.signatureId)) {
                  each.suppress = true;
                  each.rulesApplied = each.rulesApplied
                    ? [...each.rulesApplied, eachPath]
                    : [...eachPath];
                } else {
                  each.rulesApplied = each.rulesApplied
                    ? [...each.rulesApplied, eachPath]
                    : [...eachPath];
                  // each.suppress = false;
                }
              }
            }
          }
        }
        return [...waitAcc, each];
      }, []);
      let suppressResource = false;
      let counter = 0;
      dataWithSuppressKey.forEach(e => {
        if (e.suppress === true) {
          counter++;
        }
      });
      let result = counter > 0 && counter === dataWithSuppressKey.length ? true : false;
      return [...acc0, { ...eachResource, suppress: result, risks: dataWithSuppressKey }];
    }, []);

    return eachRegulationData;
  } else {
    let addSuppressFalse = data?.reduce((acc0, eachResource) => {
      let dataWithSuppressKey = eachResource?.risks?.reduce((acc, each) => {
        let waitAcc = acc;
        return [...waitAcc, { ...each, suppress: false }];
      }, []);
      return [
        ...acc0,
        {
          ...eachResource,
          suppress: false,
          risks: dataWithSuppressKey,
        },
      ];
    }, []);

    return addSuppressFalse;
  }
};

const checkPathByRegix = (currentObjVal, pathValue) => {
  if (!pathValue) {
    return true;
  } else {
    return stringToRegExp(pathValue).test(currentObjVal);
  }
};
export default addSuppressedFlags;
