import { SignatureMetaData, getSignaturesMetaData } from '../services/signaturesMetaData';

export default {
  namespace: 'signatureMetaData',

  state: {
    metadata: [],
    signatures: {},
  },

  effects: {
    *fetchMetaData(_, { call, put }) {
      try {
        let response = yield call(SignatureMetaData);
        let response2 = yield call(getSignaturesMetaData);
        yield put({
          type: 'save',
          payload: {
            metadata: response,
            signatures: response2, // TODO: we should use this as metaData as it is more optimzed, we direct point to obj
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        metadata: action.payload.metadata,
        signatures: action.payload.signatures,
      };
    },
  },
};
