import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { list, create, update, remove, read } from '@/services/firestore';
import { checkUserExistsAndCreate, userExists } from '@/services/user';
import { handleAccessControl } from '@/services/accessControl';

const namespace = 'accessControl';
export default {
  namespace,
  state: {
    loadingList: false,
    loadingUpdate: false,
    loadingCreate: false,
    loadingRemove: false,
    list: [],
  },

  effects: {
    *list({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });
      yield put({
        type: 'save',
        payload: { loadingList: true },
      });
      // create subscription
      const service = yield call(list, {
        module: namespace,
        orderBy: ['createdAt', 'desc'],
        ...payload,
        no404error: true,
      });
      function* push(response) {
        yield put({
          type: 'saveList',
          payload: Array.isArray(response) ? response : [],
        });
      }
      // on every callback from service
      yield takeEvery(service, push);
      // unsubscribe when action type CANCEL_WATCH is dispatched from anywhere
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *create({ payload }, { call, put, select }) {
      yield put({
        type: 'save',
        payload: { loadingCreate: true },
      });
      try {
        const functionParams = {
          orgId: payload.data.orgId,
          orgName: payload.data.orgName,
          email: payload.data.email,
          inviter: payload.data.inviter,
          inviterId: payload.data.inviterId,
          isChecked: payload.data.isChecked,
          host: payload.data.host,
        };

        const response = yield call(handleAccessControl, functionParams);

        if (response.data.status === 200) {
          notification.success({
            message: response.data.message,
          });
        }

        if (response.data.status === 400) {
          notification.error({
            message: response.data.message,
          });
        }
        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
      } catch (error) {
        console.log('error :>> ', error);

        yield put({
          type: 'save',
          payload: { loadingCreate: false },
        });
      }
    },

    *addSelected({ payload }, { call }) {
      yield call(update, {
        module: namespace,
        ...payload,
      });
    },
    *removeSelected({ payload }, { call }) {
      yield call(update, {
        module: namespace,
        ...payload,
      });
    },

    *update({ payload }, { call, put }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingUpdate: true },
        });
        yield call(update, {
          module: namespace,
          ...payload,
        });
        yield put({
          type: 'save',
          payload: { loadingUpdate: false },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingUpdate: false },
        });
      }
    },

    *remove({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const functionParams = {
          orgId: payload.orgId,
          email: payload.email,
          host: window.location.origin,
          status: payload.status,
        };

        const response = yield call(handleAccessControl, functionParams);

        if (response.data.status === 200) {
          notification.success({
            message: 'Removed user from Organization',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        //
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: [],
        loadingList: false,
      };
    },
  },
};
