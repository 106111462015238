export default {
  namespace: 'singleRisk',
  state: {
    riskName: [],
    cloudAccount: null,
    riskTypes: null,
    totalRisks: 0,
    loading: false,
    showResourceScanProgress: false,
  },
  // subscriptions: {
  //   setup({ dispatch, history }) {},
  // },

  effects: {
    *fetch(_, { put }) {
      yield put({ type: 'save' });
    },
    *scanSingleResource(_, { put }) {
      yield put({ type: 'toggleLoading' });
    },
    *changeRiskNames({ payload }, { put }) {
      yield put({ type: 'names', payload });
    },
    *removeRiskNames(_, { put }) {
      yield put({ type: 'removeNames' });
    },
    *changeCloudAccount({ payload }, { put }) {
      yield put({ type: 'account', payload });
    },
    *riskTypeData({ payload }, { put }) {
      yield put({ type: 'riskTypeSet', payload });
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
    toggleLoading(state) {
      return { ...state, loading: !state.loading };
    },
    removeNames(state) {
      state.riskName = [];
      state.totalRisks = 0;
      return { ...state, riskName: [...state.riskName] };
    },
    names(state, action) {
      const check = state.riskName.includes(action.payload.riskName);
      let { riskTypes } = state;
      if (!check) {
        if (riskTypes.hasOwnProperty(action.payload.riskName)) {
          state.totalRisks += riskTypes[action.payload.riskName];
        }
        state.riskName = [...state.riskName, action.payload.riskName];
        return { ...state, riskName: [...state.riskName], totalRisks: state.totalRisks };
      } else {
        if (riskTypes.hasOwnProperty(action.payload.riskName)) {
          state.totalRisks -= riskTypes[action.payload.riskName];
        }
        state.riskName = state.riskName.filter(val => val !== action.payload.riskName);
        return { ...state, riskName: [...state.riskName] };
      }
    },

    account(state, action) {
      if (!state.cloudAccount) {
        state.cloudAccount = action.payload.cloudAccount;
        return { ...state, cloudAccount: state.cloudAccount };
      } else {
        state.cloudAccount = null;
        return { ...state, cloudAccount: state.cloudAccount };
      }
    },
    riskTypeData(state, action) {
      if (!state.riskType) {
        state.riskTypes = action.payload.riskTypes;
        // console.log('state.riskTypes :>> ', state.riskTypes);
        return { ...state, riskTypes: state.riskTypes };
      }
    },
  },
};
