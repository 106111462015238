import { notification } from 'antd';
import { firebase } from '../utils/firebase';

export async function savePathToDB(payload) {
  try {
    const { orgId, suppressionData } = payload;
    let ref = firebase.firestore().doc(`Organizations/${orgId}`);

    // riskSuppress is an array of objects containing suppression rules
    let { riskSuppress } = (await ref.get()).data();

    // newRiskSuppress is an object containing suppression rules
    let newRiskSuppress = { ...suppressionData };

    // check if riskSuppress is an array or not || it also checks if riskSuppress exists or not
    if (Array.isArray(riskSuppress)) {
      // push a new risk suppress rule into the array
      riskSuppress.push(newRiskSuppress);
      // update risk suppress array if array exists
      await ref.update({
        riskSuppress,
      });
    } else {
      // else create a new array and update the doc
      await ref.update({
        riskSuppress: [newRiskSuppress],
      });
    }

    if (payload.type && payload.type === 'add') {
      notification.success({
        message: 'Added into Suppressed',
      });
    }
  } catch (error) {
    notification.error({
      message: 'Something went wrong.',
    });
  }

  return null;
}

export async function deletePathFromDB(payload) {
  try {
    const { suppressionData, orgId } = payload;

    let ref = await firebase.firestore().doc(`Organizations/${orgId}`);

    await Promise.all(
      suppressionData.map(element => {
        return ref.update({
          riskSuppress: firebase.firestore.FieldValue.arrayRemove(element),
        });
      })
    );
    notification.success({
      message: 'Removed from Suppressed',
    });
  } catch (error) {
    notification.error({
      message: 'Something went wrong.',
    });
  }

  return null;
}

export async function editSuppressionRule(payload) {
  try {
    const { suppressionData, orgId, selectedPath } = payload;

    let ref = firebase.firestore().doc(`Organizations/${orgId}`);
    let { riskSuppress } = (await ref.get()).data();

    let newRiskSuppress = riskSuppress.map(rule => {
      if (JSON.stringify(rule) === JSON.stringify(selectedPath)) {
        return suppressionData;
      } else return rule;
    });

    ref
      .update({
        riskSuppress: newRiskSuppress,
      })
      .then(() => {
        notification.success({
          message: 'Updated Suppression Rule',
        });
      });
  } catch (error) {
    notification.error({
      message: 'Something went wrong.',
    });
  }

  return null;
}
