export default {
  firebase: {
    apiKey: 'AIzaSyAZQkfio-HcACB2xXUyFwd5h6E_nfz73X0',
    authDomain: 'console.cloudnosys.com',
    databaseURL: 'https://cloudnosys-prod.firebaseio.com',
    projectId: 'cloudnosys-prod',
    storageBucket: 'cloudnosys-prod.appspot.com',
    messagingSenderId: '960747501403',
    appId: '1:960747501403:web:80c5a823453d6c0d914c7e',
    measurementId: 'G-F2VNCN9FZG',
  },
  api: {
    explorer: 'https://us-central1-cloudnosys-prod.cloudfunctions.net/explorer',
    playbooks: 'https://console.cloudnosys.com/runPlaybook',
  },
  masterOrg: 'cloudnosys-master_s0f8f',
  // cloudnosysVersion: '2.1.3',
};
