import { list, read } from '@/services/firestore';
import hash from 'crypto';

const namespace = 'resources';
export default {
  namespace,
  state: {
    loadingReport: false,
    list: [],
    current: [],
  },
  effects: {
    *list({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: namespace,
        orderBy: ['createdAt', 'desc'],
        ...payload,
      });

      function* push(response) {
        yield put({
          type: 'saveList',
          payload: Array.isArray(response) ? response : [],
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    // payload must have { cloudAccountId, resourceId, time } if no time, then get latest report
    *current({ payload }, { call, put, takeEvery, take }) {
      let { cloudAccountId, orgId, resourceId } = payload;

      resourceId = hash
        .createHash('md5')
        .update(resourceId)
        .digest('hex');
      // clear old before new request

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });
      // create subscription
      const service = yield call(read, {
        module: namespace,
        params: {
          orgId,
          cloudAccountId,
          id: resourceId,
        },
      });

      function* push(response) {
        yield put({
          type: 'saveReport',
          payload: [response],
        });

        yield put({
          type: 'risks/getReport',
          payload: {
            orgId,
            resourceId,
            cloudAccountId,
          },
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`unsubResources/current`);
      service.close();
      yield put({
        type: 'clearCurrent',
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveReport(state, { payload }) {
      return {
        ...state,
        current: payload,
        loadingReport: false,
      };
    },
  },
};
