import { message } from 'antd';
import { eventChannel } from 'dva/saga';
import { regulation } from './risk';
import errorHandler from '@/utils/errorHandler';
import { firestore } from '@/utils/firebase';

export async function uploadRegulation() {
  try {
    await firestore
      .collection('Report')
      .doc(regulation.regulationName)
      .set({});
  } catch (err) {
    console.error('Error in uploadRegulation: ', err);
  }
}

// Add new cloudAccount to firestore
export async function addNewAccount(payload) {
  let { orgId } = payload;
  // let orgId = localStorage.getItem('orgId');

  if (payload.provider === 'AWS') {
    try {
      let res = await firestore
        .collection('Organizations')
        .doc(orgId)
        .collection('cloudAccounts')
        .where('roleArn', '==', payload.credentials.roleArn)
        .get();

      if (
        res.docs.length > 0 &&
        res.docs[0].data().status === 'start-scan' &&
        res.docs[0].data().isActive === true
      ) {
        message.error('CloudAccount already created with this RoleArn');
      } else {
        // let res = await firestore.collection(`Organizations/${orgId}/cloudAccounts`).doc();
        // const { id } = res;
        res.set({
          provider: payload.provider,
          regions: payload.regions,
          accountId: payload.credentials.accountId,
          accountName: payload.credentials.accountName,
          externalId: payload.credentials.externalId,
          policy: payload.credentials.policy,
          roleArn: payload.credentials.roleArn,
          userId: payload.userId,
          createdAt: new Date(),
          status: 'born',
          firstScan: true,
          isActive: true,
        });

        try {
          const accRes = await firestore.collection(`Organizations/${orgId}/cloudAccounts`).doc();
          const { cloudAccId } = accRes;
          let unsubscribe = firestore
            .collection('Organizations')
            .doc(orgId)
            .collection('cloudAccounts')
            .doc(cloudAccId)
            .onSnapshot(async doc => {
              if (doc.exists) {
                let updatedData = await doc.data();

                if (updatedData.status === 'start-scan') {
                  message.success('CloudAccount created successfully');
                  unsubscribe();
                  // return updatedData;
                } else if (updatedData.status === 'fail') {
                  message.error('Invalid credentials');
                  unsubscribe();
                  // return updatedData;
                }
              }
            });
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  } else if (payload.provider === 'AZURE') {
    try {
      let res = await firestore
        .collection('Organizations')
        .doc(orgId)
        .collection('cloudAccounts')
        .where('appId', '==', payload.credentialsAZ.AppId)
        .where('subscriptionId', '==', payload.credentialsAZ.SubscriptionID)
        .where('tenantId', '==', payload.credentialsAZ.TenantID)
        .where('secret', '==', payload.credentialsAZ.secret)
        .get();

      if (res.docs.length > 0 && res.docs[0].data().status === 'start-scan') {
        message.error('CloudAccount already created with these credentials');
      } else {
        await firestore
          .collection(`Organizations/${orgId}/cloudAccounts`)
          .doc()
          .set({
            provider: payload.provider,
            regions: payload.regions,
            accountName: payload.credentialsAZ.accountName,
            appId: payload.credentialsAZ.AppId,
            subscriptionId: payload.credentialsAZ.SubscriptionID,
            tenantId: payload.credentialsAZ.TenantID,
            secret: payload.credentialsAZ.secret,
            userId: payload.userId,
            createdAt: new Date(),
            status: 'born',
            firstScan: true,
            isActive: true,
          });
        try {
          await firestore
            .collection('Organizations')
            .doc(orgId)
            .collection('cloudAccounts')
            .where('appId', '==', payload.credentialsAZ.AppId)
            .where('subscriptionId', '==', payload.credentialsAZ.SubscriptionID)
            .where('tenantId', '==', payload.credentialsAZ.TenantID)
            .where('secret', '==', payload.credentialsAZ.secret)
            .onSnapshot(async doc => {
              let updatedData = await doc.docs[0].data();
              if (updatedData) {
                if (updatedData.status === 'pass') {
                  message.success('CloudAccount created successfully');
                } else if (updatedData.status === 'fail') {
                  message.error('Invalid credentials');
                }
              }
            });
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

//fetch cloudAccounts
export async function fetchCloudAccounts() {
  let orgId2 = localStorage.getItem('orgId');

  return eventChannel(emitter => {
    const unsubscribe = firestore
      .collection('Organizations')
      .doc(orgId2)
      .collection('cloudAccounts')
      .onSnapshot(
        async querySnapshot => {
          let docs = [];
          querySnapshot.forEach(doc => {
            if (doc.exists) {
              let account = doc.data();

              if (account.isActive) {
                docs.push({
                  id: doc.id,
                  ...doc.data(),
                });
              }
            }
          });
          emitter(docs);
          docs = [];
        },
        err => {
          errorHandler({
            status: err.code,
            statusText: err.message,
            statusRaw: err,
          });
          emitter([]);
        }
      );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}
//bab func
//fetch cloudAccounts
export async function fetchCloudAccounts2() {
  let orgId2 = localStorage.getItem('orgId');
  // return eventChannel(emitter => {
  let bab = async () => {
    return new Promise(async (resolve, reject) => {
      const unsubscribe = await firestore
        .collection('Organizations')
        .doc(orgId2)
        .collection('cloudAccounts')
        .onSnapshot(
          async querySnapshot => {
            resolve(querySnapshot.docs);
          },
          err => {
            errorHandler({
              status: err.code,
              statusText: err.message,
              statusRaw: err,
            });
            reject(errorHandler);
            //    emitter([]);
          }
        );
      // The subscriber must return an unsubscribe function
    });
  };
  //

  let cloudAccountsData = await bab();

  let newArr = [];
  if (cloudAccountsData.length > 0) {
    cloudAccountsData.forEach(doc => {
      if (doc.exists) {
        let account = doc.data();
        account.id = doc.id;
        if (account.status === 'pass' && account.isActive) {
          newArr.push(account);
        }
      }
    });
    // newArr = [];
  }

  return newArr; //() => unsubscribe();
}
////////deleteAccount////////////
export async function deleteAccount(payload) {
  let orgId = localStorage.getItem('orgId');
  let result = {
    status: '',
    message: '',
  };
  try {
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('cloudAccounts')
      .doc(payload.userId)
      .update({
        isActive: false,
      });

    result.status = 'success';
    result.message = 'CloudAccount deleted successfully';
  } catch (error) {
    result.status = 'error';
    result.message = 'Error in deleting cloud account';
  }

  return result;
}

//updateCloudAccount

export async function updateCloudAccount(payload) {
  let orgId = localStorage.getItem('orgId');
  if (payload.updateValue.provider === 'AWS') {
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('cloudAccounts')
      .doc(payload.updateValue.cloudAccount)
      .update({
        accountName: payload.updateValue.AWSAccountName,
        regions: payload.updateValue.regions,
        policy: payload.updateValue.policy,
      });
  } else {
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('cloudAccounts')
      .doc(payload.updateValue.cloudAccount)
      .update({
        accountName: payload.updateValue.AzureAccountName,
        regions: payload.updateValue.regions,
      });
  }
}

// updateCloudAccountMode

export async function updateCloudAccountMode(payload) {
  try {
    let orgId = localStorage.getItem('orgId');
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('cloudAccounts')
      .doc(payload.cloudAccountId)
      .update({
        protectionLevel: payload.protectionLevel,
        updatedAt: new Date(),
      });

    return {
      status: 'success',
      message: `Cloud Account Protection Level ${
        payload.protectionLevel === 'advanced' ? 'Upgraded To Advanced' : 'Downgraded to Basic'
      }`,
    };
  } catch (error) {
    return {
      status: 'error',
      message: 'Error in updating cloud account mode',
    };
  }
}

// live monitoring

export async function queryUpdateScheduling(payload) {
  const accountDetail = payload.UpdateDocs;
  let orgId = localStorage.getItem('orgId');
  try {
    let res = await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('cloudAccounts')
      .doc(accountDetail.cloudAccountId)
      .update({
        interval: accountDetail.interval,
        intervalStatus: accountDetail.intervalStatus,
      });
  } catch (err) {
    console.error('Error updating document: ', err);
  }
}
