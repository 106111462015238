import { message } from 'antd';
import {
  checkIntegrationExistence,
  addSamlIntegration,
  update_SAML,
} from '@/services/checkIntegrationExistence';
import {
  addIntegration,
  fetchIntegration,
  updateIntegration,
  deleteIntegration,
  authUserWithSaml,
} from '@/services/integration';
import { list, read } from '@/services/firestore';

const namespace = 'integrations';

export default {
  namespace,

  state: {
    integration: [],
    fetchAuthentication: [],
    key: '',
    loadingList: false,
    loadingCurrent: false,
    loadingRemove: false,
    loadingOperation: false,
    statusOperation: '',
    payloadOperation: null,
    current: null,
    navigateStatus: false,
  },

  effects: {
    *authenticateSamlUser({ payload }, { call }) {
      try {
        yield call(authUserWithSaml, payload);
      } catch (error) {
        console.log(error);
      }
    },

    *addIntegration({ payload }, { call }) {
      try {
        yield call(addIntegration, payload);
      } catch (error) {
        console.error('Error in addIntegration', error);
      }
    },

    *list({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: namespace,
        orderBy: ['createdAt', 'desc'],
        ...payload,
      });
      function* push(response) {
        yield put({
          type: 'saveList',
          payload: Array.isArray(response) ? response : [],
        });
      }

      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *create({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
      });

      // check if current integration exist or not
      let isExist = yield call(checkIntegrationExistence, payload);

      if (isExist.length === 0) {
        // add
        try {
          yield call(addSamlIntegration, payload);
        } catch (error) {
          console.log(error);
        }
      }
      if (isExist.length > 0) {
        // already exist
        message.error('Already exist');
      }

      if (isExist === false) {
        message.error('Something went wrong');
        // something went wrong
      }

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });
    },

    *updateSAML({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
      });

      // check if current integration exist or not
      yield call(update_SAML, payload);

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });
    },

    *updateOneLogin({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
      });

      yield call(update_SAML, payload);
      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });
    },

    *updateOkta({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
      });

      yield call(update_SAML, payload);
      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });
    },

    *clearSelected(_, { put }) {
      yield put({
        type: 'saveSelected',
        payload: [],
      });
    },

    *update({ payload }, { call }) {
      yield call(updateIntegration, payload);
    },

    *current({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/current`,
      });

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      // create subscription
      const service = yield call(read, {
        module: namespace,
        ...payload,
        no404error: true,
      });

      function* push(response) {
        if (!response) {
          yield put({
            type: 'create',
            payload: {
              ...payload,
            },
          });
          yield take(`clear:${namespace}/current`);
        } else {
          yield put({
            type: 'saveCurrent',
            payload: response,
          });
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/current`);
      service.close();
      yield put({
        type: 'clearCurrent',
      });
    },

    *fetchIntegration({ payload }, { call, put, takeEvery }) {
      function* push(response) {
        yield put({
          type: 'fetch',
          payload: response,
        });
      }

      // create subscription
      const service = yield call(fetchIntegration, payload);
      yield takeEvery(service, push);
    },

    *delete({ payload }, { call }) {
      yield call(deleteIntegration, payload);
    },
  },
  reducers: {
    fetch(state, action) {
      return {
        ...state,
        fetchAuthentication: action.payload,
      };
    },
    save(state, action) {
      return {
        ...state,
        key: action?.payload?.integration?.response?.data[0]?.secretKey,
        integration: action?.payload?.integration?.response,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        allCloudAccountsList: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        loadingList: false,
      };
    },

    clearCurrent(state) {
      return {
        ...state,
        current: null,
        loadingCurrent: false,
        payloadOperation: null,
      };
    },
  },
};
