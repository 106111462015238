import { eventChannel } from 'dva/saga';
import { firestore } from '../utils/firebase';
import errorHandler from '../utils/errorHandler';
import { getUser } from './user';

export async function getAccessControls(orgId) {
  const ref = firestore
    .collection('Organizations')
    .doc(orgId)
    .collection('accessControl');

  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      async querySnapshot => {
        let docs = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            docs.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });
        // Get user details for each
        let docsWithUser = [];
        await Promise.all(
          docs.map(async item => {
            const user = await getUser(item.id);
            docsWithUser.push({
              ...item,
              user,
            });
          })
        );

        emitter(docsWithUser);
        docs = [];
      },
      err => {
        errorHandler({
          status: err.code,
          statusText: err.message,
          statusRaw: err,
        });
        emitter([]);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}

export const updateAccessControl = async payload => {
  try {
    await firestore
      .collection('Organizations')
      .doc(payload.orgId)
      .collection('accessControl')
      .doc(payload.id)
      .update({
        ...payload.update,
      });
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
};

export const deleteAccessControl = async ({ orgId, id }) => {
  try {
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('accessControl')
      .doc(id)
      .delete();
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
};

export async function fetchAccessInvites(orgId) {
  const ref = firestore
    .collection('Organizations')
    .doc(orgId)
    .collection('accessInvites');

  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      async querySnapshot => {
        let docs = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            docs.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });

        emitter(docs);
        docs = [];
      },
      err => {
        errorHandler({
          status: err.code,
          statusText: err.message,
          statusRaw: err,
        });
        emitter([]);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}

export const addAccessInvite = async ({ orgId, email, role }) => {
  try {
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('accessInvites')
      .add({
        createdAt: new Date(),
        email,
        role,
      });
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
};

export const deleteAccessInvite = async ({ orgId, id }) => {
  try {
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('accessInvites')
      .doc(id)
      .delete();
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
};

export const updateAccessInvite = async payload => {
  try {
    await firestore
      .collection('Organizations')
      .doc(payload.orgId)
      .collection('accessInvites')
      .doc(payload.id)
      .update({
        ...payload.update,
      });
    return true;
  } catch (err) {
    console.log('err', err);
    return false;
  }
};

/*
 *  Returns default package (Object)
 *  This function gets the default package in Global > Config from firestore.
 *  Every enviornment can have a different default package.
 *
 */
export const getDefaultPackage = async () => {
  try {
    let organisationPackage = {
      type: 'trial',
      start: new Date(),
      end: new Date(Date.now() + 12096e5),
    };

    const config = await firestore
      .collection('Global')
      .doc('Config')
      .get();

    if (!config.exists) {
      return organisationPackage;
    }

    const { defaultPackage } = config.data();

    if (defaultPackage) {
      const currentTime = new Date();
      organisationPackage = {
        type: defaultPackage.type,
        start: new Date(),
        end: new Date(currentTime.setDate(currentTime.getDate() + defaultPackage.durationInDays)),
      };
    }

    return organisationPackage;
  } catch (error) {
    console.log('error', error);

    return false;
  }
};
