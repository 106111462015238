// 1- find path of the key - i.e key =id, value = loop-2
export const getObjectPath = (obj, name, val, currentPath) => {
  currentPath = currentPath || '';

  let matchingPath;

  if (!obj || typeof obj !== 'object') return;

  if (obj[name] === val) return `${currentPath}['${name}']`;

  for (const key of Object.keys(obj)) {
    if (key === name && obj[key] === val) {
      matchingPath = currentPath;
    } else {
      matchingPath = getObjectPath(obj[key], name, val, `${currentPath}['${key}']`);
    }

    if (matchingPath) break;
  }

  // call modifyPath here
  return matchingPath;
};

// 2- get that key value - it may be object | string | boolean etc
export const getObjectValue = (object, objPath) => {
  return objPath.split('.').reduce((o, k) => {
    let c = (o || {})[k];
    return c;
  }, object);
};
// 3- modify that key value
export const updateObjValue = (obj, value, path) => {
  let i;
  path = path.split('.');
  for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];
  obj[path[i]] = value;
};

export const deleteObjValue = (obj, path) => {
  let i;
  path = path.split('.');
  for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];
  delete obj[path[i]];
};

export const modifyPath = idPath => {
  try {
    idPath = idPath.replace(/[[\]']/g, ' ');

    idPath = idPath.split(' ').filter(Boolean);

    idPath.length = idPath.length - 1;
    idPath = idPath.join('.');
    return idPath;
  } catch (error) {
    throw error;
  }
};
