import { savePathToDB, deletePathFromDB, editSuppressionRule } from '@/services/suppression';

let namespace = 'suppression';
export default {
  namespace,
  state: {
    supLoading: false,
  },

  effects: {
    *createSuppress({ payload }, { call, put, select }) {
      // following regex is used to escape special characters
      const sc_reg = /[|\\{}()[\]^$+*?]/g;

      yield put({
        type: 'startLoading',
      });
      const { suppressionData, isCXRRisksExists = false } = payload;
      const orgId = yield select(state => state.organisation.current.id);

      let signatureRegex = '';
      let resourceRegex = '';
      let optimizedSuppressionRule = {};

      // if isCXRRisksExists is true, then we need to create optimized suppression rule
      if (isCXRRisksExists) {
        // ?? here we should have only one signatureId Rule we just need to optimize it by removing & with |
        const signaturesArr = suppressionData.path.signatureId.replace(sc_reg, '').split('&');
        const uniqueSignaturesArr = new Set(signaturesArr);
        signatureRegex = [...uniqueSignaturesArr].join('|');

        const resourcesArr = suppressionData.path.resourceId.replace(sc_reg, '').split('&');
        const uniqueResourcesArr = new Set(resourcesArr);
        resourceRegex = [...uniqueResourcesArr].join('|');

        // populating suppressionData's whole object because all other data in the object remains same
        optimizedSuppressionRule = {
          ...suppressionData,
        };

        // changing resource id on basis of same ids
        optimizedSuppressionRule.path.resourceId = resourceRegex;

        // changing signature id on basis of same ids
        optimizedSuppressionRule.path.signatureId = signatureRegex;
      } else {
        // ?? creating signature id regex separated by pipe "|" for optimized suppression rules
        signatureRegex = suppressionData
          .map(eachRule => eachRule.path.signatureId.slice(1, -1).replace(sc_reg, '\\$&'))
          .join('|');

        // ?? creating resource id regex separated by pipe "|" for optimized suppression rules
        resourceRegex = suppressionData
          .map(eachRule => eachRule.path.resourceId.slice(1, -1).replace(sc_reg, '\\$&'))
          .join('|');

        // check if there exists same resource or signature ids
        let hasSameResourceIds = resourceRegex.split('|').every((val, i, arr) => val === arr[0]);
        let hasSameSignatureIds = signatureRegex.split('|').every((val, i, arr) => val === arr[0]);

        // populating suppressionData's 0th element because all other data in the object remains same
        optimizedSuppressionRule = {
          ...suppressionData[0],
        };

        // changing resource id on basis of same ids
        optimizedSuppressionRule.path.resourceId = hasSameResourceIds
          ? `${suppressionData[0].path.resourceId}`
          : `/${resourceRegex}/`;

        // changing signature id on basis of same ids
        optimizedSuppressionRule.path.signatureId = hasSameSignatureIds
          ? `${suppressionData[0].path.signatureId}`
          : `/${signatureRegex}/`;
      }
      // adding orgId in payload object
      payload.orgId = orgId;

      const objectToSaveInRiskSuppressArray = {
        ...payload,
        suppressionData: optimizedSuppressionRule,
      };
      yield call(savePathToDB, objectToSaveInRiskSuppressArray);

      if (payload?.callback) payload.callback();

      yield put({
        type: 'stopLoading',
      });
    },

    // this effect calls a deletePathFromDB function and deletes the given object from riskSuppress array
    *deleteSuppress({ payload }, { call, select }) {
      const orgId = yield select(state => state.organisation.current.id);
      const newPayload = { ...payload, orgId };
      // delete the object from the riskSuppress array
      yield call(deletePathFromDB, newPayload);
      if (newPayload.callback) newPayload.callback();
    },

    *editSuppress({ payload }, { call, select }) {
      const orgId = yield select(state => state.organisation.current.id);
      const { suppressionData } = payload;
      let cloudAccountRegex = suppressionData
        .map(eachRule =>
          eachRule.path.cloudAccountId.slice(1, -1).replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
        )
        .join('|');

      let newPayload = { ...payload, orgId };

      newPayload = {
        ...newPayload,
        suppressionData: suppressionData[0],
      };

      newPayload.suppressionData.path.cloudAccountId = `/${cloudAccountRegex}/`;
      yield call(editSuppressionRule, newPayload);
    },
  },
  reducers: {
    startLoading(state) {
      return {
        ...state,
        supLoading: true,
      };
    },
    stopLoading(state) {
      return {
        ...state,
        supLoading: false,
      };
    },
  },
};
