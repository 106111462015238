import { notification, message } from 'antd';
import { list, create, read, update, remove } from '@/services/firestore';

let namespace = 'reports';
export default {
  namespace,
  state: {
    loadingList: false,
    loadingCurrent: false,
    loadingRemove: false,
    loadingOperation: false,
    statusOperation: '',
    report: null,
    timeIds: [],
    reportData: {
      report: null,
      updatedAt: '',
      scope: null,
      pdf: null,
      timeId: '',
      status: '',
      type: '',
    },
    payloadOperation: null,
    list: [],
    current: null,
    matricesWidgets: {},
  },

  effects: {
    *list({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: namespace,
        orderBy: ['createdAt', 'desc'],
        ...payload,
      });

      function* push(response) {
        yield put({
          type: 'saveList',
          payload: Array.isArray(response) ? response : [],
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *listHistory({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/list`,
      });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: 'reportsHistory',
        ...payload,
      });

      function* push(response) {
        yield put({
          type: 'saveList',
          payload: Array.isArray(response) ? response : [],
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *currentHistory({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      // create subscription
      const service = yield call(read, {
        module: 'reportsHistory',
        ...payload,
      });

      function* push(response) {
        yield put({
          type: 'saveCurrent',
          payload: response,
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`CANCEL_WATCH_${payload.params.reportId}`);
      //
      service.close();

      yield put({
        type: 'clearCurrent',
      });
    },

    *currentWidget({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/current`,
      });

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      // create subscription
      const service = yield call(read, {
        module: namespace,
        ...payload,
        no404error: true,
      });

      function* push(response) {
        if (!response) {
          yield put({
            type: 'create',
            payload: {
              ...payload,
              data: {
                hashedObj: payload.params.id,
                status: 'born',
                report: null,
                reportId: payload.params.reportId,
                type: payload.type,
                scope: payload.scope,
              },
            },
          });
        } else {
          yield put({
            type: 'saveWidget',
            payload: response,
            id: payload.params.reportId,
          });
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      // currentWidget

      yield take(`CANCEL_WATCH_${payload.params.reportId}`);

      service.close();
      yield put({
        type: 'clearCurrent',
      });
    },
    *getCurrentPdf(_, { put }) {
      try {
        yield put({
          type: 'setLoading',
          payload: {
            loading: true,
          },
        });
        // const response = yield call(getPdf, payload);

        // yield put({
        //   type: 'saveSamlIds',
        //   payload: {
        //     loading: false,
        //     providerIds: response.data,
        //   },
        // });
      } catch (error) {
        // notification.error({
        //   message: error.message,
        //   description: error.details,
        // });
        // yield put({
        //   type: 'setLoading',
        //   payload: {
        //     loading: false,
        //   },
        // });
      }
    },
    *current({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      let orgId = localStorage.getItem('orgId');

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      // create subscription
      const service = yield call(read, {
        module: namespace,
        ...payload,
        no404error: true,
      });

      function* push(response) {
        //
        if (!response) {
          yield put({
            type: 'create',
            payload: {
              ...payload,
              data: {
                hashedObj: payload.params.id,
                status: 'born',
                orgId: orgId,
                report: null,
                reportId: payload.params.reportId,
                type: payload.type,
                scope: payload.scope,
              },
            },
          });
        } else {
          yield put({
            type: 'saveCurrent',
            payload: response,
          });
        }
      }

      // subscribe function to subscription created above
      yield takeEvery(service, push);

      // unsubscribe when asked by creator
      yield take(`CANCEL_WATCH_${payload.params.reportId}`);
      service.close();
      //

      yield put({
        type: 'clearCurrent',
      });
    },

    *create({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
      });

      try {
        const response = yield call(create, {
          module: namespace,
          ...payload,
        });
        if (response) {
          // notification.success({
          //   message: namespace + ' added',
          // });

          yield put({
            type: 'save',
            payload: {
              statusOperation: 'success',
              loadingOperation: false,
              payloadOperation: response,
            },
          });
        } else {
          yield put({
            type: 'save',
            payload: { statusOperation: 'error', loadingOperation: false },
          });
        }
      } catch (error) {
        //
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    *update({ payload }, { call, put }) {
      try {
        if (payload.params.hasOwnProperty('type') && payload.params.type === 'pdf') {
          yield call(update, {
            module: namespace,
            ...payload,
            data: {
              pdf: {
                status: 'born',
                type: payload.params.type,
                pdfType: payload.params.pdfType,
                reportName: null,
              },
            },
          });
          // let a = <div style={{display:'inline-block'}}>Your report will be downloaded shortly <br/>disable ad blocker if enable</div>
          // `
          // Hello n
          // World /n
          // Your report will be downloaded shortly /n
          // remove add blockss /n
          // `
          message.success('Your report will be downloaded shortly');

          // yield put({
          //   type: 'clearCurrent',
          // });
        }

        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
        });
        yield call(update, {
          module: namespace,
          ...payload,
        });
        yield put({
          type: 'f',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    *remove({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(remove, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: `${namespace} deleted`,
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        //
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },

    *unsubscribe({ reportId }, { put }) {
      yield put({ type: `CANCEL_WATCH_${reportId}` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: [],
        loadingList: false,
      };
    },
    saveWidget(state, { payload, id }) {
      return {
        ...state,
        [id]: {
          report: typeof payload.report === 'string' ? JSON.parse(payload.report) : payload.report,
          // report: JSON.parse(payload.report),
          timeId: payload.timeId,
          updatedAt: payload.updatedAt,
          scope: payload.scope,
          status: payload.status,
        },

        loadingCurrent: false,
      };
    },
    saveCurrent(state, { payload }) {
      // let a = JSON.stringify(payload.report);

      payload.reportId = payload && payload.report ? payload.report.regulationId : '';
      return {
        ...state,
        current: payload,
        reportData: {
          report: typeof payload.report === 'string' ? JSON.parse(payload.report) : payload.report,
          // report: JSON.parse(payload.report),
          timeId: payload.timeId,
          pdf: payload.pdf,
          updatedAt: payload.updatedAt,
          scope: payload.scope,
          status: payload.status,
        },
        loadingCurrent: false,
      };
    },

    clearCurrent(state) {
      return {
        ...state,
        current: null,
        loadingCurrent: false,
        payloadOperation: null,
      };
    },
  },
};
