export const regulation = [
    {
        "regulationName": "ISO/IEC 27001",
        "regulationText": "ISO 27001/27002 is a widely-adopted global security standard that sets requirements and best practices for a systematic approach to managing company and customer information that is based on periodic risk assessments appropriate to ever-changing threat scenarios. In order to achieve the certification, a company must show it has a systematic and ongoing approach to managing information security risks that affect the confidentiality, integrity, and availability of company and customer information.\r\n \r\nCloudEye has leveraged NIST Cyber Security Framework special pub 800-53r4 to create cross mappings for ISO 27001/27002 Security Rule Cross Walk combined with Cloud vendors recommended best practices for PCI-DSS to create set of controls that maps closest to the technical security and risk management mandates. These identified and mapped security, audit and risk management controls are intended for help your company meet compliance. The following information is a guidance for compliance does not imply, approve, or provide full assurance for any regulations or laws.\r\n",
        "regulationLogo": "ISO.png",
        "config": {
            "customRules": [{
                "ruleNumber": "A.6.2.2",
                "ruleName": "Mobile Devices and Teleworking",
                "ruleDesc": "A policy and supporting security measures shall be implemented to protect information accessed, processed or stored at teleworking sites.",
                "ruleCategory": "A.6 Organization of Information Security",
                "signatures": [{
                    "signatureName": "AWS:IAM-006"
                },
                {
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }
                ]
            },

            {
                "ruleNumber": "A.8.1.1 ",
                "ruleName": "Inventory of assets",
                "ruleDesc": "Assets associated with information and information processing facilities shall be identified and an inventory of these assets shall be drawn up and maintained.",
                "ruleCategory": "A.8 Asset Management",
                "signatures": [{
                    "signatureName": "AWS:Cloudnosys-004"
                }]
            },

            {
                "ruleNumber": "A.8.2.3",
                "ruleName": "A8.2 - Information classification | Handling of assets",
                "ruleDesc": "Procedures for handling assets shall be developed and implemented in accordance with the information classification scheme adopted by the organization.",
                "ruleCategory": "A.8 Asset Management",
                "signatures": [{
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }
                ]
            },

            {
                "ruleNumber": "A.9.1.1 | A9.1.2",
                "ruleName": "Access Control Policies and Access to Networks",
                "ruleDesc": "A9.1.1 & A9.1.2 An access control policy shall be established, documented and reviewed based on business and information security requirements. Users shall only be provided with access to the network and network services that they have been specifically authorized to use.",
                "ruleCategory": "A.9 Access Control",
                "signatures": [{
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }
                ]
            },

            {
                "ruleNumber": "A.9.2.1 | A.9.2.2 | A.9.2.3 | A.9.2.4 | A.9.3.1 | A9.4.2",
                "ruleName": "A.9 Access Control | User Registrations and Privilege Controls | System and application access control",
                "ruleDesc": "A formal user registration and de-registration process shall be implemented to enable assignment of access rights., The allocation of secret authentication information shall be controlled through a formal management process.  The allocation and use of privileged access rights shall be restricted and controlled.",
                "ruleCategory": "A.9 Access Control",
                "signatures": [{
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }
                ]
            },

            {
                "ruleNumber": "A.10.1.1 | A.10.1.2",
                "ruleName": "Cryptographic controls & Key Management",
                "ruleDesc": "A policy on the use of cryptographic controls for protection of information shall be developed and implemented. A policy on the use, protection and lifetime of cryptographic keys shall be developed and implemented through their whole lifecycle.",
                "ruleCategory": "A.10 Cryptography",
                "signatures": [{
                    "signatureName": "AWS:CLT-003"
                },
                {
                    "signatureName": "AWS:KMS-005"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }
                ]
            },

            {
                "ruleNumber": "A.12.1.2",
                "ruleName": "A.12.1 Operational procedures and responsibilities | Change Management",
                "ruleDesc": "Changes to the organization, business processes, information processing facilities and systems that affect information security shall be controlled.",
                "ruleCategory": "A.10 Cryptography",
                "signatures": [{
                    "signatureName": "AWS:CONFIG-003"
                }]
            },

            {
                "ruleNumber": "A.12.3.1",
                "ruleName": "Information backup",
                "ruleDesc": "Backup copies of information, software and system images shall be taken and tested regularly in accordance with an agreed backup policy.",
                "ruleCategory": "A.10 Cryptography",
                "signatures": [{
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AWS:RDS-010"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }
                ]
            },

            {
                "ruleNumber": "A.12.4.1 | A.12.4.2 | A.12.4.3 | A.12.4.4 | A.12.7.1",
                "ruleName": "A.12.4 Logging and monitoring | Event Logging | Audit Controls",
                "ruleDesc": "Event logs recording user activities, exceptions, faults and information security events shall be produced, kept and regularly reviewed. Logging facilities and log information shall be protected against tampering and unauthorized access.",
                "ruleCategory": "A.10 Cryptography",
                "signatures": [{
                    "signatureName": "AWS:CLT-007"
                },

                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CWATCH-002"
                },
                {
                    "signatureName": "AWS:CWATCH-003"
                },
                {
                    "signatureName": "AWS:CWATCH-004"
                },
                {
                    "signatureName": "AWS:CWATCH-005"
                },
                {
                    "signatureName": "AWS:CWATCH-006"
                },
                {
                    "signatureName": "AWS:CWATCH-011"
                },
                {
                    "signatureName": "AWS:CWATCH-014"
                },
                {
                    "signatureName": "AWS:VPC-009"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }


                ]
            },

            {
                "ruleNumber": "A.13.1 | A.13.1.1  | A.13.1.2 | A.13.1.3 | A.13.2.1",
                "ruleName": "A.13 Communications security | Network security and segregation management",
                "ruleDesc": "Networks shall be managed and controlled to protect information in systems and applications. Groups of information services, users and information systems shall be segregated on networks. Security of network services.",
                "ruleCategory": "A.10 Cryptography",
                "signatures": [{
                    "signatureName": "AWS:S3-012"
                },
                {
                    "signatureName": "AWS:REDSHIFT-010"
                },
                {
                    "signatureName": "AWS:ELB-014"
                },
                {
                    "signatureName": "AWS:SQS-001"
                },

                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-005"
                }
                ]
            },

            {
                "ruleNumber": "A.13.2.3",
                "ruleName": "A.13 Communications security | Electronic messaging",
                "ruleDesc": "Information involved in electronic messaging shall be appropriately protected.",
                "ruleCategory": "A.10 Cryptography",
                "signatures": [{
                    "signatureName": "AWS:SNS-005"
                },
                {
                    "signatureName": "AWS:SQS-001"
                }
                ]
            },

            {
                "ruleNumber": "A.14.1.2",
                "ruleName": "Securing application services on public networks",
                "ruleDesc": "Information involved in application services passing over public networks shall be protected from fraudulent activity, contract dispute and unauthorized disclosure and modification.",
                "ruleCategory": "A.14 System acquisition, development and maintenance",
                "signatures": [{
                    "signatureName": "AWS:ELB-014"
                },

                {
                    "signatureName": "AWS:SQS-001"
                }
                ]
            },

            {
                "ruleNumber": "A.14.1.3",
                "ruleName": "Protecting application services transactions Screen reader support enabled.",
                "ruleDesc": "Information involved in application service transactions shall be protected to prevent incomplete transmission, mis-routing, unauthorized message alteration, unauthorized disclosure, unauthorized message duplication or replay.",
                "ruleCategory": "A.14 System acquisition, development and maintenance",
                "signatures": [{
                    "signatureName": "AWS:S3-012"
                },

                {
                    "signatureName": "AWS:SQS-001"
                },
                {
                    "signatureName": "AWS:REDSHIFT-010"
                },
                {
                    "signatureName": "AWS:ELB-014"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-005"
                }
                ]
            },

            {
                "ruleNumber": "A.14.2.4",
                "ruleName": "Restrictions on changes to software packages",
                "ruleDesc": "Modifications to software packages shall be discouraged, limited to necessary changes and all changes shall be strictly controlled.",
                "ruleCategory": "A.14.2 Security in development and support processes",
                "signatures": [{
                    "signatureName": "AWS:CLT-007"
                },
                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CWATCH-002"
                },
                {
                    "signatureName": "AWS:CFM-004"
                },

                {
                    "signatureName": "AWS:CONFIG-003"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }
                ]
            },

            {
                "ruleNumber": "A.14.2.5",
                "ruleName": "Secure system engineering principles",
                "ruleDesc": "Principles for engineering secure systems shall be established, documented, maintained and applied to any information system implementation efforts.",
                "ruleCategory": "A.14.2 Security in development and support processes",
                "signatures": [{
                    "signatureName": "AWS:CFM-004"
                }]
            },

            {
                "ruleNumber": "A.16.1.2",
                "ruleName": "Reporting information security events",
                "ruleDesc": "Information security events shall be reported through appropriate management channels as quickly as possible.",
                "ruleCategory": "A.16 Information security incident management",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-001"
                },

                {
                    "signatureName": "AWS:SNS-005"
                },

                {
                    "signatureName": "AWS:IAM-021"
                },

                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }
                ]
            },

            {
                "ruleNumber": "A.16.1.4",
                "ruleName": "Assessment of and decision on information security events",
                "ruleDesc": "Information security events shall be assessed and it shall be decided if they are to be classified as information security incidents.",
                "ruleCategory": "A.16 Information security incident management",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-001"
                }]
            },

            {
                "ruleNumber": "A.16.1.7",
                "ruleName": "Collection of evidence",
                "ruleDesc": "The organization shall define and apply procedures for the identification, collection, acquisition and preservation of information, which can serve as evidence.",
                "ruleCategory": "A.16 Information security incident management",
                "signatures": [{
                    "signatureName": "AWS:S3-014"
                },

                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AWS:CWATCH-001"
                },

                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },

                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                }
                ]
            },

            {
                "ruleNumber": "A.17.1.3 |A.17.2 |  A.17.2.1",
                "ruleName": "Verify, review and evaluate information security continuity",
                "ruleDesc": "The organization shall verify the established and implemented information security continuity controls at regular intervals. Redundancy - Information processing facilities shall be implemented with redundancy sufficient to meet availability requirements.",
                "ruleCategory": "A.17 Information security aspects of business management",
                "signatures": [{
                    "signatureName": "AWS:S3-016"
                },

                {
                    "signatureName": "AWS:RDS-010"
                },
                {
                    "signatureName": "AWS:DDB-001"
                },

                {
                    "signatureName": "AWS:EC2-017"
                },
                {
                    "signatureName": "AWS:RDS-009"
                },

                {
                    "signatureName": "AWS:S3-015"
                },

                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                },
                {
                    "signatureName": "AZU:SQLDB-004"
                }
                ]
            },

            {
                "ruleNumber": "A.18.1.3",
                "ruleName": "Protection of records",
                "ruleDesc": "Records shall be protected from loss, destruction, falsification, unauthorized access and unauthorized release, in accordance with legislatory, regulatory, contractual and business requirements.",
                "ruleCategory": "A.18 Compliance",
                "signatures": [{
                    "signatureName": "AWS:S3-013"
                },

                {
                    "signatureName": "AWS:S3-001"
                },

                {
                    "signatureName": "AWS:RDS-004"
                },

                {
                    "signatureName": "AWS:RDS-008"
                },

                {
                    "signatureName": "AWS:RDS-002"
                },

                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                },
                {
                    "signatureName": "AZU:SQLDB-004"
                },
                {
                    "signatureName": "AZU:SQLDB-005"
                }

                ]
            },

            {
                "ruleNumber": "A.18.1.4",
                "ruleName": "Privacy and protection of personally identifiable information",
                "ruleDesc": "Privacy and protection of personally identifiable information shall be ensured as required in relevant legislation and regulation where applicable.",
                "ruleCategory": "A.18 Compliance",
                "signatures": [{
                    "signatureName": "AWS:S3-001"
                },

                {
                    "signatureName": "AWS:S3-011"
                },

                {
                    "signatureName": "AWS:REDSHIFT-008"
                },

                {
                    "signatureName": "AWS:EC2-010"
                },

                {
                    "signatureName": "AWS:RDS-002"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                },
                {
                    "signatureName": "AZU:SQLDB-004"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                }
                ]
            },

            {
                "ruleNumber": "A.18.1.5",
                "ruleName": "Regulation of cryptographic controls",
                "ruleDesc": "Cryptographic controls shall be used in compliance with all relevant agreements, legislation and regulations.",
                "ruleCategory": "A.18 Compliance",
                "signatures": [{
                    "signatureName": "AWS:S3-012"
                },
                {
                    "signatureName": "AWS:CLT-004"
                },

                {
                    "signatureName": "AWS:EC2-006"
                },

                {
                    "signatureName": "AWS:SQS-001"
                },

                {
                    "signatureName": "AWS:ELB-012"
                },

                {
                    "signatureName": "AWS:RDS-002"
                },
                {
                    "signatureName": "AWS:REDSHIFT-008"
                },

                {
                    "signatureName": "AZU:SQLDB-003"
                },
                {
                    "signatureName": "AZU:SQLDB-004"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                }
                ]
            }
            ]
        }
    },
    {
        "regulationName": "NIST 800-53 Rev 4 - FedRAMP / High",
        "regulationText": "NIST 800-53 Rev4 is a publication that recommends security controls for federal information systems and organizations and documents security controls for all federal information systems, except those designed for national security. NIST 800-53 Rev4 is published by the National Institute of Standards and Technology (NIST), which creates and promotes the standards used by federal agencies to implement the Federal Information Security Management Act (FISMA) and manage other programs designed to protect information and promote information security. This controls set also complies with FISMA; FIPS 140-2; FIPS199; FIPS 200 and FedRAMP. CloudEye has reviewed Cloud vendors recommended best practices for NIST-800 Rev4 FedRAMP to create set of controls that maps closest to the technical security and risk management mandates.  These identified and mapped security, audit and risk management controls are intended for help your company meet compliance.  The following information is a guidance for compliance does not imply, approve, or provide full assurance for any regulations or laws.\r\n",
        "regulationLogo": "nist.png",
        "config": {
            "customRules": [{
                "ruleNumber": "AC-2(1) | AC-2(a) | AC-2(6) | AC-2 (7)(a) | AC-3 (7) |AC-3 (8) | AC-6 (4)",
                "ruleName": "Account Management | Automated System Account Management",
                "ruleDesc": "Identifies and selects the following types of information system accounts to support organizational missions/business functions: [Assignment: organization-defined information system account types]",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AZU:AD-001"

                },
                {
                    "signatureName": "AZU:AD-002"

                }

                ]
            },
            {
                "ruleNumber": "AC-2(g) | AC-2(4) | AC-2 (7)(b) | AC-2 (7)(c) | V | AC-3 (3)(b)(5) | AC-3 (3)(b)(2) | AC-3 (3)(b)(3) | AC-3 (3)(b)(4) | AC-3 (3)(b)(5) | AC-3 (7) | AC-3 (8) | AC-6 (10) | AU-10",
                "ruleName": "Account Management | Automated Audit Actions| Access Enforcement | Role-Based Access Control | Non-Repudiation of Logs",
                "ruleDesc": "The information system automatically audits account creation, modification, enabling, disabling, and removal actions, and notifies [Assignment: organization-defined personnel or roles]. | The information system enforces approved authorizations for logical access to information and system resources in accordance with applicable access control policies.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CONFIG-003"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:ALA-004"
                },
                {
                    "signatureName": "AZU:AD-002"
                }


                ]
            },
            {
                "ruleNumber": "AC-3 | AC-3 (4) | AC-3 (4)(a) | AC-3 (4)(b) | AC-3 (4)(c) | AC-3 (4)(d) | AC-3 (4)(e)",
                "ruleName": "Access Enforcement | Discretionary Access Control",
                "ruleDesc": "The information system enforces approved authorizations for logical access to information and system resources in accordance with applicable access control policies. | The information system enforces [Assignment: organization-defined discretionary access control policy] over defined subjects and objects where the policy specifies that a subject that has been granted access to information can do one or more of the following.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CLT-002"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:ALA-003"
                },
                {
                    "signatureName": "AZU:ALA-004"
                }


                ]
            },
            {
                "ruleNumber": "AC-4",
                "ruleName": "Information Flow Enforcement",
                "ruleDesc": "The information system enforces approved authorizations for controlling the flow of information within the system and between interconnected systems based on [Assignment: organization-defined information flow control policies].",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:CF-001"
                },
                {
                    "signatureName": "AWS:EC2-011"
                },
                {
                    "signatureName": "AWS:EC2-016"
                },
                {
                    "signatureName": "AWS:S3-012"
                },
                {
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AWS:WAF-001"
                },
                {
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AWS:VPC-003"
                },
                {
                    "signatureName": "AWS:S3-005"
                },
                {
                    "signatureName": "AWS:EC2-005"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }

                ]
            },
            {
                "ruleNumber": "AC-6 (9)",
                "ruleName": "Auditing Use of Privileged Functions",
                "ruleDesc": "The information system audits the execution of privileged functions.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-003"
                }
                ]
            },
            {
                "ruleNumber": "AC-17 (1))",
                "ruleName": "Automated Monitoring / Control",
                "ruleDesc": "The information system monitors and controls remote access methods.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:RDS-009"
                },
                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:SA-001"
                }
                ]
            },
            {
                "ruleNumber": "AC-17 (2) | AC-17 (3)",
                "ruleName": "Protection of Confidentiality / Integrity Using Encryption / Managed Access Control Points",
                "ruleDesc": "The information system implements cryptographic mechanisms to protect the confidentiality and integrity of remote access sessions. | The information system routes all remote accesses through [Assignment: organization-defined number] managed network access control points.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AWS:ELB-014"
                },
                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:VM-001"
                }
                ]
            },
            {
                "ruleNumber": "AU-2 | AU-2(a-d) | AU-2(3) | AU-3 | AU-8 | AU-8a | AU-8b | AU-8(1) |AU-8(1)a | AU-8(1)b |AU-8(2) | AU-12 | AU-12(a) | AU-12(b) | AU-12(c) | AU-12 (1) | AU-12 (2)",
                "ruleName": "Audit Events | Synchronization with Authoritative Time Source | System-Wide / Time-Correlated Audit Trail",
                "ruleDesc": "Determines that the information system is capable of auditing the following events: [Assignment: organization-defined auditable events]; Determines that the following events are to be audited within the information system: [Assignment: organization-defined audited events (the subset of the auditable events defined in AU-2 a.) along with the frequency of (or situation requiring) auditing for each identified event].",
                "ruleCategory": "Audit and Accountability (AU)",
                "signatures": [{
                    "signatureName": "AWS:CLT-007"
                },
                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CF-002"
                },
                {
                    "signatureName": "AWS:VPC-009"
                },
                {
                    "signatureName": "AWS:S3-006"
                },
                {
                    "signatureName": "AWS:ELB-014"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                }

                ]
            },
            {
                "ruleNumber": "AU-5 |AU-5a | AU-5(1)| AU-5(2)",
                "ruleName": "Response to Audit Processing Failures | Cryptographic Protection | | Audit Record Retention",
                "ruleDesc": "The information system provides a warning to [Assignment: organization-defined personnel, roles, and/or locations] within [Assignment: organization-defined time period] when allocated audit record storage volume reaches [Assignment: organization-defined percentage] of repository maximum audit record storage capacity. | The information system provides an alert in [Assignment: organization-defined real-time period] to [Assignment: organization-defined personnel, roles, and/or locations] when the following audit failure events occur: [Assignment: organization-defined audit failure events requiring real-time alerts].",
                "ruleCategory": "Audit and Accountability (AU)",
                "signatures": [{
                    "signatureName": "AWS:CLT-007"
                },
                {
                    "signatureName": "AWS:CLT-002"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:ALA-003"
                }
                ]
            },
            {
                "ruleNumber": "AU-9 | AU-9(2) | AU-9(3)",
                "ruleName": "Protection of Audit Information",
                "ruleDesc": "The information system protects audit information and audit tools from unauthorized access, modification, and deletion.",
                "ruleCategory": "Audit and Accountability (AU)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },


                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:ALA-004"
                }
                ]
            },
            {
                "ruleNumber": "AU-11",
                "ruleName": "Audit Record Retention",
                "ruleDesc": "The organization retains audit records for [Assignment: organization-defined time period consistent with records retention policy] to provide support for after-the-fact investigations of security incidents and to meet regulatory and organizational information retention requirements.",
                "ruleCategory": "Audit and Accountability (AU)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-015"
                },
                {
                    "signatureName": "AWS:S3-014"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }

                ]
            },
            {
                "ruleNumber": "CA-3 (5)",
                "ruleName": "Restrictions on External System Connections",
                "ruleDesc": "The organization employs [Selection: allow-all, deny-by-exception; deny-all, permit-by-exception] policy for allowing [Assignment: organization-defined information systems] to connect to external information systems.",
                "ruleCategory": "Security Assessment And Authorization (CA)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-005"
                }]
            },
            {
                "ruleNumber": "CM-2",
                "ruleName": "Baseline Configuration",
                "ruleDesc": "The organization develops, documents, and maintains under configuration control, a current baseline configuration of the information system.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CFM-001"
                }]
            },
            {
                "ruleNumber": "CM-2 (2) | CM-5 | CM-5 (1) | CM-5 (2)",
                "ruleName": "Automation Support for Accuracy / Currency | Access Restrictions for Change",
                "ruleDesc": "The organization employs automated mechanisms to maintain an up-to-date, complete, accurate, and readily available baseline configuration of the information system. | The organization reviews information system changes [Assignment: organization-defined frequency] and [Assignment: organization-defined circumstances] to determine whether unauthorized changes have occurred.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CLT-001"
                },
                {
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:CONFIG-003"
                },

                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }

                ]
            },
            {
                "ruleNumber": "CM-3(6)",
                "ruleName": "Configuration Change Control | Cryptography Management",
                "ruleDesc": "The organization ensures that cryptographic mechanisms used to provide [Assignment: organization-defined security safeguards] are under configuration management.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-005"
                },
                {
                    "signatureName": "AWS:CWATCH-007"
                },
                {
                    "signatureName": "AZU:ALA-003"
                },
                {
                    "signatureName": "AZU:ALA-004"
                }
                ]
            },
            {
                "ruleNumber": "CM-6 (1) | CM-6 (2)",
                "ruleName": "Automated Central Management / Application / Verification",
                "ruleDesc": "The organization employs automated mechanisms to centrally manage, apply, and verify configuration settings for [Assignment: organization-defined information system components]. | The organization employs [Assignment: organization-defined security safeguards] to respond to unauthorized changes to [Assignment: organization-defined configuration settings].",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-006"
                },
                {
                    "signatureName": "AWS:CLT-001"
                },
                {
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:CONFIG-003"
                },
                {
                    "signatureName": "AWS:CWATCH-014"
                },
                {
                    "signatureName": "AWS:EC2-011"
                },

                {
                    "signatureName": "AZU:ALA-003"
                },
                {
                    "signatureName": "AZU:ALA-004"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                }
                ]
            },
            {
                "ruleNumber": "CM-7a | CM-7b",
                "ruleName": "Least Functionality",
                "ruleDesc": "Configures the information system to provide only essential capabilities; and Disables [Assignment: organization-defined functions, ports, protocols, and services within the information system deemed to be unnecessary and/or nonsecure].",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:IAM-017"
                },
                {
                    "signatureName": "AWS:VPC-002"
                },
                {
                    "signatureName": "AWS:IAM-018"
                },
                {
                    "signatureName": "AWS:S3-010"
                },
                {
                    "signatureName": "AWS:S3-007"
                },
                {
                    "signatureName": "AWS:S3-005"
                },
                {
                    "signatureName": "AWS:CWATCH-004"
                },
                {
                    "signatureName": "AWS:EC2-013"
                },
                {
                    "signatureName": "AWS:S3-009"
                },

                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:ALA-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }
                ]
            },
            {
                "ruleNumber": "CM-7 (1)(a) | CM-7 (1)(b)",
                "ruleName": "Periodic Review",
                "ruleDesc": "Disables [Assignment: organization-defined functions, ports, protocols, and services within the information system deemed to be unnecessary and/or nonsecure].| Requirement: The service provider shall use the Center for Internet Security guidelines (Level 1) to establish list of prohibited or restricted functions, ports, protocols, and/or services or establishes its own list of prohibited or restricted functions, ports, protocols, and/or services if USGCB is not available.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:IAM-006"
                },
                {
                    "signatureName": "AWS:VPC-003"
                },
                {
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AWS:VPC-009"
                },
                {
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:NSG-003"
                },
                {
                    "signatureName": "AZU:NSG-004"
                }
                ]
            },
            {
                "ruleNumber": "CM-8a | CM-8a.1 | CM-8a.2 | CM-8a.3 | CM-8a.4 | CM-8b | CM-8 (1) | CM-8 (2) | CM-8 (4) | CM-8 (5) | CM-8 (7) | CM-8(8)",
                "ruleName": "Information System Component Inventory",
                "ruleDesc": "Develops and documents an inventory of information system components that: INFORMATION SYSTEM COMPONENT INVENTORY, UPDATES DURING INSTALLATIONS / REMOVALS, AUTOMATED MAINTENANCE, NO DUPLICATE ACCOUNTING OF COMPONENTS, CENTRALIZED REPOSITORY, AUTOMATED LOCATION TRACKING.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-004"
                }]
            },
            {
                "ruleNumber": "CP-2 (6) | CP-6 | CP-6a-6b | CP-6 (1) to CP-6 (3) | CP-7 | CP-7a to 7c | CP-7 (1) to CP-7 (4) | CP-9 | CP-9(a) to CP-9(d) | CP-9(3) to CP-9(6)| CP-10(6) |",
                "ruleName": "Alternate Processing Site | Separation From Primary Site | Accessibility | Information System Backup | Separate Storage For Critical Information",
                "ruleDesc": "Establishes an alternate processing site including necessary agreements to permit the transfer and resumption of [Assignment: organization-defined information system operations] for essential missions/business functions within [Assignment: organization-defined time period consistent with recovery time and recovery point objectives] when the primary processing capabilities are unavailable;",
                "ruleCategory": "CONTINGENCY PLANNING (CP)",
                "signatures": [{
                    "signatureName": "AWS:DDB-001"
                },
                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AWS:EC2-017"
                },
                {
                    "signatureName": "AWS:EC2-018"
                },
                {
                    "signatureName": "AWS:EC2-020"
                },
                {
                    "signatureName": "AWS:RDS-006"
                },
                {
                    "signatureName": "AWS:RDS-009"
                },
                {
                    "signatureName": "AWS:S3-015"
                },



                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                }
                ]
            },
            {
                "ruleNumber": "IA-2 | IA-2 (11)",
                "ruleName": "Identification And Authentication (Organizational Users), Remote Access  - Separate Device",
                "ruleDesc": "The information system uniquely identifies and authenticates organizational users (or processes acting on behalf of organizational users). | The information system implements multifactor authentication for remote access to privileged and non-privileged accounts such that one of the factors is provided by a device separate from the system gaining access and the device meets [Assignment: organization-defined strength of mechanism requirements].",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:IAM-018"
                },
                {
                    "signatureName": "AWS:CWATCH-002"
                },
                {
                    "signatureName": "AWS:IAM-007"
                },

                {
                    "signatureName": "AWS:IAM-018"
                },
                {
                    "signatureName": "AWS:CWATCH-002"
                },
                {
                    "signatureName": "AWS:IAM-007"
                },


                {
                    "signatureName": "AZU:AD-002"
                },

                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:ALA-001"
                }

                ]
            },
            {
                "ruleNumber": "IA-5 (1) | IA-5(1)(a)(b)(c)(d)(e)(f) | IA-6 | IA-7 | IA-8",
                "ruleName": "Password-based Authentication",
                "ruleDesc": "The information system, for password-based authentication:",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:IAM-016"
                },
                {
                    "signatureName": "AWS:IAM-015"
                },
                {
                    "signatureName": "AWS:IAM-014"
                },
                {
                    "signatureName": "AWS:IAM-011"
                },
                {
                    "signatureName": "AWS:IAM-012"
                },
                {
                    "signatureName": "AWS:IAM-009"
                },

                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }
                ]
            },
            {
                "ruleNumber": "IR-5(1)",
                "ruleName": "Incident Monitoring | Automated Tracking / Data Collection / Analysis",
                "ruleDesc": "The organization employs automated mechanisms to assist in the tracking of security incidents and in the collection and analysis of incident information.Supplemental Guidance: Automated mechanisms for tracking security incidents and collecting/analyzing incident information include, for example, the Einstein network monitoring device and monitoring online Computer Incident Response Centers (CIRCs) or other electronic databases of incidents.",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:CONFIG-003"
                },
                {
                    "signatureName": "AWS:CWATCH-005"
                },
                {
                    "signatureName": "AWS:CWATCH-011"
                },
                {
                    "signatureName": "AWS:CWATCH-012"
                },
                {
                    "signatureName": "AWS:CWATCH-013"
                },
                {
                    "signatureName": "AWS:CWATCH-014"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:ALA-003"
                },
                {
                    "signatureName": "AZU:ALA-004"
                }
                ]
            },
            {
                "ruleNumber": "IR-6(1) | IR-6(2)",
                "ruleName": "Incident Reporting | Automated Reporting",
                "ruleDesc": "No Description",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CWATCH-009"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }
                ]
            },
            {
                "ruleNumber": "SI-2(2) | SI-4(1) | SI-4(2) | SI-4(3) |SI-4(4) | SI-4(5) | SI-4(7) | SI-4(9) | SI-4(10) | SI-4(11) | SI-4(12) | SI-4(13) | SI-4(20) ",
                "ruleName": "Flaw Remediation | Automated Flaw Remediation Status, Monitoring, Alerts, Anomalies, Automation ",
                "ruleDesc": "The organization employs automated mechanisms [Assignment: organization-defined frequency] to determine the state of information system components with regard to flaw remediation.",
                "ruleCategory": "SYSTEM AND INFORMATION INTEGRITY (SI)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-001"
                }]
            },
            {
                "ruleNumber": "SI-5(1)",
                "ruleName": "Security Alerts, Advisories, And Directives | Automated Alerts And Advisories",
                "ruleDesc": "The organization employs automated mechanisms to make security alert and advisory information available throughout the organization.Supplemental Guidance: The significant number of changes to organizational information systems and the environments in which those systems operate requires the dissemination of security-related information to a variety of organizational entities that have a direct interest in the success of organizational missions and business functions. Based on the information provided by the security alerts and advisories, changes may be required at one or more of the three tiers related to the management of information security risk including the governance level, mission/business process/enterprise architecture level, and the information system level.",
                "ruleCategory": "SYSTEM AND INFORMATION INTEGRITY (SI)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-001"
                }]
            },
            {
                "ruleNumber": "SC-2 | SC-3 | SC-4",
                "ruleName": "Application Partitioning | Security Function Isolation | Information In Shared Resources",
                "ruleDesc": "The information system separates user functionality (including user interface services) from information system management functionality. | The information system prevents unauthorized and unintended information transfer via shared system resources.",
                "ruleCategory": "SYSTEM AND COMMUNICATIONS PROTECTION (SC)",
                "signatures": [{
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }
                ]
            },
            {
                "ruleNumber": "SC-5",
                "ruleName": "Denial of Service Protection",
                "ruleDesc": "The information system protects against or limits the effects of the following types of denial of service attacks: [Assignment: organization-defined types of denial of service attacks or references to sources for such information] by employing [Assignment: organization-defined security safeguards].",
                "ruleCategory": "SYSTEM AND COMMUNICATIONS PROTECTION (SC)",
                "signatures": [{
                    "signatureName": "AWS:ELB-001"
                },
                {
                    "signatureName": "AWS:EC2-018"
                },
                {
                    "signatureName": "AZU:NSG-002"
                }

                ]
            },
            {
                "ruleNumber": "SC-7 |SC-7a |SC-7b | SC-7c | SC-7 (5) | SC-7 (5)",
                "ruleName": "Boundary Protection | Deny By Default / Allow By Exception | Transmission Confidentiality And Integrity",
                "ruleDesc": "Monitors and controls communications at the external boundary of the system and at key internal boundaries within the system",
                "ruleCategory": "SYSTEM AND COMMUNICATIONS PROTECTION (SC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-017"
                },
                {
                    "signatureName": "AWS:IAM-018"
                },
                {
                    "signatureName": "AWS:VPC-002"
                },
                {
                    "signatureName": "AWS:VPC-010"
                },

                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                }
                ]
            },
            {
                "ruleNumber": "SC-8 | SC-8 (1) | SC-8 (12) | SC-8 (13) | SC-8 (1)| SC-8 (23) | SC-8 (24) | SC-8 (39) |",
                "ruleName": "Cryptographic or Alternate Physical Protection | Session Authenticity",
                "ruleDesc": "The information system implements cryptographic mechanisms to [Selection (one or more): prevent unauthorized disclosure of information; detect changes to information] during transmission unless otherwise protected by [Assignment: organization-defined alternative physical safeguards].",
                "ruleCategory": "SYSTEM AND COMMUNICATIONS PROTECTION (SC)",
                "signatures": [{
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },

                {
                    "signatureName": "AZU:NSG-002"
                }
                ]
            },
            {
                "ruleNumber": "SC-28",
                "ruleName": "Protection of Information At Rest",
                "ruleDesc": "The information system protects the [Selection (one or more): confidentiality; integrity] of [Assignment: organization-defined information at rest].",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [{
                    "signatureName": "AWS-S3-013"
                },
                {
                    "signatureName": "AWS:S3-011"
                },
                {
                    "signatureName": "AWS:S3-006"
                },
                {
                    "signatureName": "AWS:EC2-006"
                },
                {
                    "signatureName": "AWS:RDS-004"
                },
                {
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AWS:RDS-012"
                },
                {
                    "signatureName": "AWS:EC2-010"
                },
                {
                    "signatureName": "AWS:RDS-002"
                },
                {
                    "signatureName": "AWS:RDS-008"
                },
                {
                    "signatureName": "AWS:CF-001"
                },
                {
                    "signatureName": "AWS:CF-003"
                },



                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                }
                ]
            },
            {
                "ruleNumber": "SA-4(8)",
                "ruleName": "Acquisition Process | Continuous Monitoring Plan",
                "ruleDesc": "The organization requires the developer of the information system, system component, or information system service to produce a plan for the continuous monitoring of security control effectiveness that contains [Assignment: organization-defined level of detail].",
                "ruleCategory": "System And Services Acquisition Control Family (SA)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-001"
                }]
            }
            ]
        }
    },
    {
        "regulationName": "PCI Data Security Standard (PCI DSS 3.2)",
        "regulationText": "The PCI Data Security Standard (PCI DSS 3.2) applies to all entities that store, process, and/or transmit cardholder data. It covers technical and operational practices for system components included in or connected to environments with cardholder data. The Security Rule requires appropriate administrative, physical and technical safeguards to ensure the confidentiality, integrity, and security of card holder data information.  Please review: https://www.pcisecuritystandards.org/pci_security/ for more details.\r\n",
        "regulationLogo": "PCI.png",
        "config": {
            "customRules": [{
                "ruleNumber": "1.1.4",
                "ruleName": "Firewall",
                "ruleDesc": "1.1.4 Requirements for a firewall at each Internet connection and between any demilitarized zone (DMZ) and the Internal network zone.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:IAM-006"
                }, {
                    "signatureName": "AZU:NSG-001"
                }, {
                    "signatureName": "AZU:NSG-002"
                }, {
                    "signatureName": "AZU:NSG-003"
                }]
            },

            {
                "ruleNumber": "1.2.1",
                "ruleName": "Restrict Traffic",
                "ruleDesc": "1.2.1 Restrict inbound and outbound traffic to that which is necessary for the cardholder data environment, and specifically deny all other traffic.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:NSG-004"
                }
                ]
            },



            {
                "ruleNumber": "1.3.1",
                "ruleName": "DMZ",
                "ruleDesc": "1.3.1 Implement a DMZ to limit inbound traffic to only system components that provide authorized publicly accessible services, protocols, and ports.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:VPC-003"
                }, {
                    "signatureName": "AZU:NSG-001"
                }, {
                    "signatureName": "AZU:NSG-005"
                }]
            },

            {
                "ruleNumber": "1.3.2",
                "ruleName": "Restrict Traffic within DMZ",
                "ruleDesc": "1.3.2 Limit inbound Internet traffic to IP addresses within the DMZ.accessible services, protocols, and ports.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:ALA-004"
                },
                {
                    "signatureName": "AZU:AD-002"
                }

                ]
            },

            {
                "ruleNumber": "1.3.4",
                "ruleName": "Access to Systems",
                "ruleDesc": "1.3.4 Do not allow unauthorized outbound traffic from the cardholder data environment to the Internet.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:ALA-003"
                },
                {
                    "signatureName": "AZU:ALA-004"
                }
                ]
            },

            {
                "ruleNumber": "1.3.5",
                "ruleName": "Authentication to Networks",
                "ruleDesc": "1.3.5 Permit only “established” connections into the network.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:IAM-020"
                }]
            },

            {
                "ruleNumber": "1.3.6",
                "ruleName": "Database location ",
                "ruleDesc": "1.3.6 Place system components that store cardholder data (such as a database) in an internal network zone, segregated from the DMZ and other untrusted networks.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:RDS-004"
                },
                {
                    "signatureName": "AWS:S3-005"
                },
                {
                    "signatureName": "AWS:S3-013"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }

                ]
            },

            {
                "ruleNumber": "1.3.7",
                "ruleName": "Protect private IP addresses and Routing information",
                "ruleDesc": "1.3.7 Do not disclose private IP addresses and routing information to unauthorized parties.  Note: Methods to obscure IP addressing may include, but are not limited to: • Network Address Translation (NAT) • Placing servers containing cardholder data behind proxy servers/firewalls or content caches, • Removal or filtering of route advertisements for private networks that employ registered addressing, • Internal use of RFC1918 address space instead of registered addresses.",
                "ruleCategory": "Requirement 1: Install and maintain a firewall configuration to protect cardholder data",
                "signatures": [{
                    "signatureName": "AWS:RDS-004"
                },
                {
                    "signatureName": "AWS:S3-005"
                },
                {
                    "signatureName": "AWS:S3-013"
                }

                ]
            },
            {
                "ruleNumber": "2.1",
                "ruleName": "Remove default settings",
                "ruleDesc": "Always change vendor-supplied defaults and remove or disable unnecessary default accounts before installing a system on the network.",
                "ruleCategory": "Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters",
                "signatures": [{
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AWS:RDS-004"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-003"
                }
                ]
            },
            {
                "ruleNumber": "2.2.1",
                "ruleName": "One function per server",
                "ruleDesc": "2.2.1  Implement only one primary function per server to prevent functions that require different security levels from co-existing on the same server. (For example, web servers, database servers, and DNS should be implemented on separate servers.)  Note: Where virtualization technologies are in use, implement only one primary function per virtual system component.",
                "ruleCategory": "Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters",
                "signatures": [{}]
            },
            {
                "ruleNumber": "2.2.3",
                "ruleName": "Protocols",
                "ruleDesc": "2.2.3 Implement additional security features for any required services, protocols, or daemons that are considered to be insecure.  Note: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed.",
                "ruleCategory": "Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters",
                "signatures": [{
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:SA-001"
                }
                ]
            },
            {
                "ruleNumber": "2.2.4",
                "ruleName": "Prevent miss-use",
                "ruleDesc": "2.2.4 Configure system security parameters to prevent misuse.",
                "ruleCategory": "Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters",
                "signatures": [{
                    "signatureName": "AWS:EC2-005"
                },
                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:SA-001"
                }
                ]
            },

            {
                "ruleNumber": "2.3",
                "ruleName": "Strong Cryptography",
                "ruleDesc": "2.3 Encrypt all non-console administrative access using strong cryptography.  Note: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed.",
                "ruleCategory": "Requirement 2: Do not use vendor-supplied defaults for system passwords and other security parameters",
                "signatures": [{
                    "signatureName": "AWS:S3-006"
                },
                {
                    "signatureName": "AWS:EC2-006"
                },
                {
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AWS:RDS-004"
                },
                {
                    "signatureName": "AWS:RDS-002"
                }

                ]
            },
            {
                "ruleNumber": "3.4.1",
                "ruleName": "Storage Encryption ",
                "ruleDesc": "3.4.1 If disk encryption is used (rather than file- or column-level database encryption), logical access must be managed separately and independently of native operating system authentication and access control mechanisms (for example, by not using local user account databases or general network login credentials). Decryption keys must not be associated with user accounts.  Note: This requirement applies in addition to all other PCI DSS encryption and key-management requirements.",
                "ruleCategory": "Requirement 3: Protect stored cardholder data",
                "signatures": [{
                    "signatureName": "AWS:S3-006"
                },
                {
                    "signatureName": "AWS:S3-001"
                },
                {
                    "signatureName": "AWS:EC2-006"
                },
                {
                    "signatureName": "AWS:RDS-004"
                },
                {
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AWS:RDS-002"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                }

                ]
            },


            {
                "ruleNumber": "3.5",
                "ruleName": "Key Protection",
                "ruleDesc": "3.5 Document and implement procedures to protect keys used to secure stored cardholder data against disclosure and misuse: Note: This requirement applies to keys used to encrypt stored cardholder data, and also applies to key-encrypting keys used to protect data-encrypting keys—such key-encrypting keys must be at least as strong as the data-encrypting key.",
                "ruleCategory": "Requirement 3: Protect stored cardholder data",
                "signatures": [{
                    "signatureName": "AWS:CLT-003"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:ALA-003"
                }

                ]
            },
            {
                "ruleNumber": "3.6",
                "ruleName": "Key Management process ",
                "ruleDesc": "3.6 Fully document and implement all key-management processes and procedures for cryptographic keys used for encryption of cardholder data, including the following:  Note: Numerous industry standards for key management are available from various resources including NIST, which can be found at http://csrc.nist.gov",
                "ruleCategory": "Requirement 3: Protect stored cardholder data",
                "signatures": [

                ]
            },
            {
                "ruleNumber": "3.6.4",
                "ruleName": "Key Rotation",
                "ruleDesc": "3.6.4 Cryptographic key changes for keys that have reached the end of their crypto period (for example, after a defined period of time has passed and/or after a certain amount of cipher-text has been produced by a given key), as defined by the associated application vendor or key owner, and based on industry best practices and guidelines (for example, NIST Special Publication 800-57).",
                "ruleCategory": "Requirement 3: Protect stored cardholder data",
                "signatures": [

                ]
            },

            {
                "ruleNumber": "4.1",
                "ruleName": "Cryptography in Transmission",
                "ruleDesc": "4.1 Use strong cryptography and security protocols to safeguard sensitive cardholder data during transmission over open, public networks, including the following: • Only trusted keys and certificates are accepted. • The protocol in use only supports secure versions or configurations. • The encryption strength is appropriate for the encryption methodology in use.   Note: Where SSL/early TLS is used, the requirements in Appendix A2 must be completed.",
                "ruleCategory": "Requirement 4: Encrypt transmission of cardholder data across open, public networks",
                "signatures": [{
                    "signatureName": "AWS:ELB-013"
                },
                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:ALA-004"
                }

                ]
            },
            {
                "ruleNumber": "5.1.2",
                "ruleName": "Maintain a Vulnerability Management Program",
                "ruleDesc": "For systems considered to be not commonly affected by malicious software, perform periodic evaluations to identify and evaluate evolving malware threats in order to confirm whether such systems continue to not require anti-virus software.",


                "ruleCategory": "Requirement 5: Protect all sysytems against malware and regularly update anti-virus software and programs",
                "signatures": [{
                    "signatureName": "AWS:ELB-013"
                }

                ]
            },

            {
                "ruleNumber": "6.5.1 | 6.5.7 | 6.5.9",
                "ruleName": "XSS and SQL injection protection",
                "ruleDesc": "Injection flaws, particularly SQL injection. Also consider OS Command Injection, LDAP and XPath injection flaws as well as other injection flaws.  Protect for Cross-site scripting (XSS), and  Cross-site request forgery (CSRF) ",
                "ruleCategory": "Requirement 6: Develop and maintain secure systems and applications",
                "signatures": [

                ]
            },
            {
                "ruleNumber": "6.5.3",
                "ruleName": "Insecure storage",
                "ruleDesc": "6.5.3 Insecure cryptographic storage",
                "ruleCategory": "Requirement 6: Develop and maintain secure systems and applications",
                "signatures": [{
                    "signatureName": "AWS:CLT-003"
                },
                {
                    "signatureName": "AWS:S3-006"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }

                ]
            },

            {
                "ruleNumber": "7.1.1",
                "ruleName": "Roles and Users",
                "ruleDesc": "7.1.1 Define access needs for each role, including: • System components and data resources that each role needs to access for their job function • Level of privilege required (for example, user, administrator, etc.) for accessing resources.",
                "ruleCategory": "Requirement 7: Restrict access to cardholder data by business need to know",
                "signatures": [{
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }

                ]
            },

            {
                "ruleNumber": "7.1.2",
                "ruleName": "Access",
                "ruleDesc": "7.1.2 Restrict access to privileged user IDs to least privileges necessary to perform job responsibilities.",
                "ruleCategory": "Requirement 7: Restrict access to cardholder data by business need to know",
                "signatures": [{
                    "signatureName": "AWS:IAM-006"
                }

                ]
            },

            {
                "ruleNumber": "7.2.1",
                "ruleName": "Access for all components",
                "ruleDesc": "7.2.1 Coverage of all system components.",
                "ruleCategory": "Requirement 7: Restrict access to cardholder data by business need to know",
                "signatures": [{
                    "signatureName": "AWS:IAM-006"
                },

                {
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }

                ]
            },
            {
                "ruleNumber": "8.0 | 8.1.4",
                "ruleName": "Inactive Users",
                "ruleDesc": "8.1.4 Remove/disable inactive user accounts within 90 days.",
                "ruleCategory": "Requirement 8: Assign a unique ID to each person with computer access",
                "signatures": [{
                    "signatureName": "AWS:IAM-008"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }

                ]
            },


            {
                "ruleNumber": "8.1.5",
                "ruleName": "Remote Access by third parties",
                "ruleDesc": "8.1.5 Manage IDs used by third parties to access, support, or maintain system components via remote access.",
                "ruleCategory": "Requirement 8: Assign a unique ID to each person with computer access",
                "signatures": [{
                    "signatureName": "AWS:CLT-007"
                },
                {
                    "signatureName": "AWS:CLT-002"
                },
                {
                    "signatureName": "AZU:ALA-004"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                }

                ]
            },

            {
                "ruleNumber": "8.2.3",
                "ruleName": "Password Complexity",
                "ruleDesc": "8.2.3 Passwords/phrases must meet the following: • Require a minimum length of at least seven characters. • Contain both numeric and alphabetic characters.  Alternatively, the passwords/phrases must have complexity and strength at least equivalent to the parameters specified above.",
                "ruleCategory": "Requirement 8: Assign a unique ID to each person with computer access",
                "signatures": [{
                    "signatureName": "AWS:IAM-010"
                },
                {
                    "signatureName": "AWS:IAM-011"
                },
                {
                    "signatureName": "AWS:IAM-012"
                },
                {
                    "signatureName": "AWS:IAM-013"
                }


                ]
            },


            {
                "ruleNumber": "8.2.4",
                "ruleName": "Password Rotation",
                "ruleDesc": "8.2.4 Change user passwords/passphrases at least every 90 days.",
                "ruleCategory": "Requirement 8: Assign a unique ID to each person with computer access",
                "signatures": [{
                    "signatureName": "AWS:IAM-016"
                }

                ]
            },

            {
                "ruleNumber": "8.2.5",
                "ruleName": "Password Reuse",
                "ruleDesc": "8.2.5 Do not allow an individual to submit a new password/phrase that is the same as any of the last four passwords/phrases he or she has used.",
                "ruleCategory": "Requirement 8: Assign a unique ID to each person with computer access",
                "signatures": [{
                    "signatureName": "AWS:IAM-015"
                }

                ]
            },
            {
                "ruleNumber": "8.3 | 8.3.1 | 8.3.2",
                "ruleName": "Enable Multi Factor Authentication",
                "ruleDesc": "8.3 Secure all individual non-console administrative access and all remote access to the CDE using multi-factor authentication.   multi-factor authentication for all remote network access (both user and administrator, and including third-party access for support or maintenance) originating from outside the entity’s network.",
                "ruleCategory": "Requirement 8: Assign a unique ID to each person with computer access",
                "signatures": [{
                    "signatureName": "AWS:IAM-007"
                },
                {
                    "signatureName": "AWS:IAM-018"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:ALA-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }

                ]
            },

            {
                "ruleNumber": "10.1 | 10.2.1 | 10.2.2 | 10.2.3 | 10.2.4 | 10.2.5 |10.2.7 | 10.3.1 | 10.3.2 | 10.3.3 | 10.3.4 | 10.3.5 | 10.3.6",
                "ruleName": "Audit logs",
                "ruleDesc": "10.1 Implement audit trails to link all access to system components to each individual user. 10.2.1 All individual user accesses to cardholder data.  10.2.2 All actions taken by any individual with root or administrative privileges. 10.2.3 Access to all audit trails. 10.2.4 Invalid logical access attempts. 10.2 5 Use of and changes to identification and authentication mechanisms—including but not limited to creation of new accounts and elevation of privileges—and all changes, additions, or deletions to accounts with root or administrative privileges. 10.2.6 Initialization, stopping, or pausing of the audit logs. 10.3.1 User identification. 10.3.2 Type of event. 10.3.3 Date and time. 10.3.4 Success or failure indication. 10.3.5 Origination of event. 10.3.6 Identity or name of affected data, system component, or resource.",
                "ruleCategory": "Requirement 10: Regularly Monitor and Test Networks",
                "signatures": [

                    {
                        "signatureName": "AWS:CWATCH-001"
                    },

                    {
                        "signatureName": "AWS:CWATCH-002"
                    },
                    {
                        "signatureName": "AWS:VPC-009"
                    },
                    {
                        "signatureName": "AWS:CLT-007"
                    },
                    {
                        "signatureName": "AWS:CLT-005"
                    },
                    {
                        "signatureName": "AZU:NSG-001"
                    },
                    {
                        "signatureName": "AZU:NSG-002"
                    },
                    {
                        "signatureName": "AZU:NSG-003"
                    },
                    {
                        "signatureName": "AZU:NSG-004"
                    }



                ]
            },
            {
                "ruleNumber": "10.2.7",
                "ruleName": "Change Management",
                "ruleDesc": "10.2.7 Creation and deletion of system level objects.",
                "ruleCategory": "Requirement 10: Regularly Monitor and Test Networks",
                "signatures": [

                ]
            },

            {
                "ruleNumber": "10.5.2 | 10.5.3",
                "ruleName": "Log Backup",
                "ruleDesc": "10.5.2 Protect audit trail files from unauthorized modifications. 10.5.3 Promptly back up audit trail files to a centralized log server or media that is difficult to alter.",
                "ruleCategory": "Requirement 10: Regularly Monitor and Test Networks",
                "signatures": [{
                    "signatureName": "AWS:S3-014"
                },

                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AWS:VPC-009"
                }


                ]
            },

            {
                "ruleNumber": "10.5.4",
                "ruleName": "Log creation for external facing services",
                "ruleDesc": "10.5.4 Write logs for external-facing technologies onto a secure, centralized, internal log server or media device.",
                "ruleCategory": "Requirement 10: Regularly Monitor and Test Networks",
                "signatures": [{
                    "signatureName": "AWS:S3-016"
                }

                ]
            },
            {
                "ruleNumber": "10.5.5",
                "ruleName": "File Integrity Management",
                "ruleDesc": "10.5.5 Use file integrity monitoring or change-detection software on logs to ensure that existing log data cannot be changed without generating alerts (although new data being added should not cause an alert)..",
                "ruleCategory": "Requirement 10: Regularly Monitor and Test Networks",
                "signatures": [{
                    "signatureName": "AWS:CLT-001"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                }

                ]
            },


            {
                "ruleNumber": "10.7",
                "ruleName": "Log Retention one year",
                "ruleDesc": "10.7 Retain audit trail history for at least one year, with a minimum of three months immediately available for analysis.",
                "ruleCategory": "Requirement 10: Regularly Monitor and Test Networks",
                "signatures": [{
                    "signatureName": "AWS:S3-016"
                }]
            }

            ]
        }
    },
    {
        "regulationName": "SOC-2 Services Organization Control",
        "regulationText": "The following report is built using the American Institute of CPA (AICPA.org) SOC 2 Controls (2017). AICPA guide on Reporting on Controls as noted below at a Service Organization Relevant to Security, Availability, Processing Integrity, Confidentiality, or Privacy specifies the components of a SOC 2. AWS Customer should request a copy of the most recent SOC-2 Audit of AWS services via the artifacts section in your AWS Account Console.  The report below is capturing all the requirements of SOC-2 for the application services used within AWS, and scans are showing the pass/fail and other risk ratings. This is a guide to help you remediate and mitigate all risks and vulnerabilities identfied in order to meet SOC -2 requirements. CloudEye has leveraged a process called “Cross Walk” of NIST 800-53 R4 Cyber Security Framework to SOC 2 Security Rule Cross Walk combined with Cloud vendors recommended best practices for SOC 2 to create set of controls that maps closest to the technical security and risk management mandates.  These identified and mapped security, audit and risk management controls are intended to help your company meet SOC 2 compliance.  The following information is a guidance for compliance and does not imply, approve, or provide full assurance for any regulations or laws.\r\n",
        "regulationLogo": "SOC-2.png",
        "config": {
            "customRules":

                [{
                    "ruleNumber": "CC2.1",
                    "ruleName": "COSO Principle 13: The entity obtains or generates and uses relevant, quality information to support the functioning of internal control.",
                    "ruleDesc": "Captures Internal and External Sources of Data—Information systems capture internal and external sources of data. | Maintains Quality Throughout Processing—Information systems produce information that is timely, current, accurate, complete, accessible, protected, verifiable, and retained. Information is reviewed to assess its relevance in supporting the internal control components.",
                    "ruleCategory": "COMMUNICATION AND INFORMATION",
                    "signatures": [{
                        "signatureName": "AWS:S3-009"
                    },
                    {
                        "signatureName": "AZU:SA-001"
                    },
                    {
                        "signatureName": "AZU:SA-002"
                    },
                    {
                        "signatureName": "AZU:SA-003"
                    }


                    ]
                },

                {
                    "ruleNumber": "CC2.3",
                    "ruleName": "COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.",
                    "ruleDesc": "Identifies and Assesses Criticality of Information Assets and Identifies Threats and Vulnerabilities—The entity's risk identification and assessment process includes (1) identifying information assets, including physical devices and systems, virtual devices, software, data and data flows, external information systems, and organizational roles; (2) assessing the criticality of those information assets; (3) identifying the threats to the assets from intentional (including malicious) and unintentional acts and environmental events; and (4) identifying the vulnerabilities of the identified assets.",
                    "ruleCategory": "TRUST SERVICES CRITERIA AND POINTS OF FOCUS",
                    "signatures": [{
                        "signatureName": "AWS:IAM-021"
                    },
                    {
                        "signatureName": "AZU:AD-002"
                    }

                    ]
                },

                {
                    "ruleNumber": "CC3.2",
                    "ruleName": "COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.",
                    "ruleDesc": "The information system enforces approved authorizations for logical access to information and system resources in accordance with applicable access control policies. | The information system enforces [Assignment: organization-defined discretionary access control policy] over defined subjects and objects where the policy specifies that a subject that has been granted access to information can do one or more of the following.",
                    "ruleCategory": "RISK ASSESSMENT",
                    "signatures": [


                        {
                            "signatureName": "AWS:IAM-017"
                        },
                        {
                            "signatureName": "AWS:IAM-018"
                        },

                        {
                            "signatureName": "AWS:VPC-010"
                        },

                        {
                            "signatureName": "AWS:VPC-002"
                        },

                        {
                            "signatureName": "AWS:CONFIG-003"
                        },
                        {
                            "signatureName": "AZU:AD-002"
                        },
                        {
                            "signatureName": "AZU:NSG-001"
                        },

                        {
                            "signatureName": "AZU:NSG-002"
                        }

                    ]
                },



                {
                    "ruleNumber": "CC4.1",
                    "ruleName": "COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.",
                    "ruleDesc": "Establishes Baseline Understanding—The design and current state of an internal control system are used to establish a baseline for ongoing and separate evaluations.",
                    "ruleCategory": "MONITORING ACTIVITIES",
                    "signatures": [{
                        "signatureName": "AWS:Cloudnosys007"
                    }

                    ]
                },

                {
                    "ruleNumber": "CC4.2",
                    "ruleName": "COSO Principle 17: The entity evaluates and communicates internal control deficiencies in a timely manner to those parties responsible for taking corrective action, including senior management and the board of directors, as appropriate.",
                    "ruleDesc": "Communicates Deficiencies—Deficiencies are communicated to parties responsible for taking corrective action and to senior management and the board of directors, as appropriate.",
                    "ruleCategory": "MONITORING ACTIVITIES",
                    "signatures": [

                        {
                            "signatureName": "AWS:IAM-021"
                        },

                        {
                            "signatureName": "AWS:CFM-004"
                        },

                        {
                            "signatureName": "AWS:SNS-001"
                        },

                        {
                            "signatureName": "AWS:SNS-005"
                        },

                        {
                            "signatureName": "AWS:SQS-004"
                        },

                        {
                            "signatureName": "AZU:AD-002"
                        },
                        {
                            "signatureName": "AZU:NSG-001"
                        },
                        {
                            "signatureName": "AZU:NSG-002"
                        }


                    ]
                },

                {
                    "ruleNumber": "CC5.1",
                    "ruleName": "COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.",
                    "ruleDesc": "Evaluates a Mix of Control Activity Types—Control activities include a range and variety of controls and may include a balance of approaches to mitigate risks, considering both manual and automated controls, and preventive and detective controls. | Addresses Segregation of Duties—Management segregates incompatible duties, and where such segregation is not practical, management selects and develops alternative control activities. | Addresses Segregation of Duties—Management segregates incompatible duties, and where such segregation is not practical, management selects and develops alternative control activities.",
                    "ruleCategory": "CONTROL ACTIVITIES",
                    "signatures": [

                        {
                            "signatureName": "AWS:VPC-002"
                        },

                        {
                            "signatureName": "AWS:S3-001"
                        },

                        {
                            "signatureName": "AWS:S3-006"
                        },
                        {
                            "signatureName": "AWS:ELB-012"
                        },

                        {
                            "signatureName": "AWS:IAM-021"
                        },

                        {
                            "signatureName": "AWS:EC2-010"
                        },

                        {
                            "signatureName": "AWS:ELB-001"
                        },

                        {
                            "signatureName": "AWS:CF-001"
                        },

                        {
                            "signatureName": "AWS:CF-002"
                        },


                        {
                            "signatureName": "AWS:EC2-011"
                        },




                        {
                            "signatureName": "AWS:VPC-001"
                        },


                        {
                            "signatureName": "AWS:EC2-005"
                        },
                        {
                            "signatureName": "AZU:AD-001"
                        },
                        {
                            "signatureName": "AZU:ALA-002"
                        },
                        {
                            "signatureName": "AZU:ALA-004"
                        },
                        {
                            "signatureName": "AZU:AD-002"
                        }






                    ]
                },

                {
                    "ruleNumber": "CC5.2",
                    "ruleName": "COSO Principle 11: The entity also selects and develops general control activities over technology to support the achievement of objectives.",
                    "ruleDesc": "Establishes Relevant Technology Infrastructure Control Activities—Management selects and develops control activities over the technology infrastructure, which are designed and implemented to help ensure the completeness, accuracy, and availability of technology processing.",
                    "ruleCategory": "CONTROL ACTIVITIES",
                    "signatures": [

                        {
                            "signatureName": "AWS:IAM-006"

                        },

                        {
                            "signatureName": "AWS:IAM-007"
                        },
                        {
                            "signatureName": "AWS:IAM-017"

                        },

                        {
                            "signatureName": "AWS:IAM-020"
                        },

                        {
                            "signatureName": "AZU:NSG-001"
                        },
                        {
                            "signatureName": "AZU:NSG-002"
                        },
                        {
                            "signatureName": "AZU:VM-001"
                        },
                        {
                            "signatureName": "AZU:VM-002"
                        },
                        {
                            "signatureName": "AZU:SA-001"
                        },
                        {
                            "signatureName": "AZU:SA-002"
                        }



                    ]
                },

                {
                    "ruleNumber": "CC5.3",
                    "ruleName": "COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.",
                    "ruleDesc": "Establishes Responsibility and Accountability for Executing  Policies and ProceduresManagement establishes responsibility and accountability for control activities with management (or other designated personnel) of the business unit or function in which the relevant risks reside. |  Performs Using Competent Personnel—Competent personnel  with sufficient authority perform control activities with diligence and continuing focus. | Reassesses Policies and Procedures Management periodically reviews control activities to determine their continued relevance and refreshes them when necessary",
                    "ruleCategory": "CONTROL ACTIVITIES",
                    "signatures": [

                        {
                            "signatureName": "AWS:IAM-005"
                        },
                        {
                            "signatureName": "AWS:IAM-006"
                        },

                        {
                            "signatureName": "AWS:IAM-007"
                        },

                        {
                            "signatureName": "AWS:IAM-015"
                        },
                        {
                            "signatureName": "AWS:IAM-020"
                        },
                        {
                            "signatureName": "AWS:IAM-009"
                        },
                        {
                            "signatureName": "AZU:SA-001"
                        }



                    ]


                },


                {
                    "ruleNumber": "CC6.1",
                    "ruleName": "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity’s objectives.",
                    "ruleDesc": "Identifies and Manages the Inventory of Information AssetsThe entity identifies, inventories, classifies, and manages information assets. |  Restricts Logical Access—Logical access to information assets, including hardware, data (at-rest, during processing, or in transmission), software, administrative authorities, mobile devices, output, and offline system components is restricted through the use of access control software and rule sets. | Identifies and Authenticates Users—Persons, infrastructure and software are identified and authenticated prior to accessing information assets, whether locally or remotely. | Restricts Access to Information Assets—Combinations of data classification, separate data structures, port restrictions, access protocol restrictions, user identification, and digital certificates are used to establish access control rules for information assets. | Manages Identification and Authentication—Identification and authentication requirements are established, documented, and managed for individuals and systems accessing entity information, infrastructure and software | Uses Encryption to Protect Data—The entity uses encryption to supplement other measures used to protect data-at-rest, when such protections are deemed appropriate based on assessed risk.  | Protects Encryption Keys—Processes are in place to protect encryption keys during generation, storage, use, and destruction.",
                    "ruleCategory": "LOGICAL AND PHYSICAL ACCESS CONTROLS",
                    "signatures": [

                        {
                            "signatureName": "AWS:S3-012"
                        },
                        {
                            "signatureName": "AWS:S3-016"
                        },
                        {
                            "signatureName": "AWS:IAM-006"
                        },

                        {
                            "signatureName": "AWS:IAM-007"
                        },
                        {
                            "signatureName": "AWS:S3-001"
                        },
                        {
                            "signatureName": "AWS:S3-011"
                        },
                        {
                            "signatureName": "AWS:EC2-006"
                        },

                        {
                            "signatureName": "AWS:RDS-009"
                        },
                        {
                            "signatureName": "AWS:RDS-012"
                        },
                        {
                            "signatureName": "AWS:REDSHIFT-008"
                        },
                        {
                            "signatureName": "AWS:EC2-015"
                        },



                        {
                            "signatureName": "AWS:IAM-005"
                        },


                        {
                            "signatureName": "AWS:IAM-008"
                        },

                        {
                            "signatureName": "AWS:ELB-012"
                        },

                        {
                            "signatureName": "AWS:ELB-013"
                        },
                        {
                            "signatureName": "AWS:IAM-020"
                        },

                        {
                            "signatureName": "AWS:EC2-008"
                        },
                        {
                            "signatureName": "AZU:SA-001"
                        },
                        {
                            "signatureName": "AZU:SQLDB-001"
                        },
                        {
                            "signatureName": "AZU:VM-001"
                        }





                    ]
                },
                {
                    "ruleNumber": "CC6.2",
                    "ruleName": "Prior to issuing system credentials and granting system access, the entity registers and authorizes new internal and external users whose access is administered by the entity. For those users whose access is administered by the entity, user system credentials are removed when user access is no longer authorized.",
                    "ruleDesc": "Controls Access Credentials to Protected Assets—Information asset access credentials are created based on an authorization from the system's asset owner or authorized custodian | Controls Access Credentials to Protected Assets—Information asset access credentials are created based on an authorization from the system's asset owner or authorized custodian",
                    "ruleCategory": "LOGICAL AND PHYSICAL ACCESS CONTROLS",
                    "signatures": [

                        {
                            "signatureName": "AWS:IAM-006"
                        },

                        {
                            "signatureName": "AWS:IAM-021"
                        },

                        {
                            "signatureName": "AWS:IAM-020"
                        },

                        {
                            "signatureName": "AWS:IAM-005"
                        }



                    ]
                },
                {
                    "ruleNumber": "CC7.1",
                    "ruleName": "To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.",
                    "ruleDesc": "Monitors Infrastructure and Software—The entity monitors infrastructure and software for noncompliance with the cstandards, which could threaten the achievement of the entity's objectives. |  Implements Change-Detection Mechanisms—The IT systemc includes a change-detection mechanism (for example, file integrity monitoring tools) to alert personnel to unauthorized modifications of critical system files, configuration files, or content files.   | Detects Unknown or Unauthorized Components—Procedures are in place to detect the introduction of unknown or unauthorized components  |  Conducts Vulnerability Scans—The entity conducts vulnerability scans designed to identify potential vulnerabilities or misconfigurations on a periodic basis and after any significant change in the environment and takes action to remediate identified deficiencies on a timely basis",
                    "ruleCategory": "SYSTEM OPERATIONS",
                    "signatures": [

                        {
                            "signatureName": "AWS:CWATCH-001"
                        },
                        {
                            "signatureName": "AWS:CWATCH-002"
                        },

                        {
                            "signatureName": "AWS:CLT-001"
                        },
                        {
                            "signatureName": "AWS:CLT-002"
                        },
                        {
                            "signatureName": "AZU:NSG-001"
                        },
                        {
                            "signatureName": "AZU:NSG-002"
                        },
                        {
                            "signatureName": "AZU:SA-003"
                        },
                        {
                            "signatureName": "AZU:SA-002"
                        },
                        {
                            "signatureName": "AZU:SA-001"
                        }


                    ]


                },

                {
                    "ruleNumber": "CC7.4",
                    "ruleName": "The entity responds to identified security incidents by executing a defined incident response program to understand, contain, remediate, and communicate security incidents, as appropriate.",
                    "ruleDesc": "Assigns Roles and Responsibilities—Roles and responsibilities for the design, implementation, maintenance, and execution of the incident response program are assigned, including the use of external resources when necessary  |   Mitigates Ongoing Security Incidents—Procedures are in place to mitigate the effects of ongoing security incidents   |   Ends Threats Posed by Security Incidents—Procedures are in place to end the threats posed by security incidents through closure of the vulnerability, removal of unauthorized access, and other remediation actions",
                    "ruleCategory": "SYSTEM OPERATIONS",
                    "signatures": [

                        {
                            "signatureName": "AWS:S3-012"
                        },

                        {
                            "signatureName": "AWS:S3-016"
                        },

                        {
                            "signatureName": "AWS:IAM-005"
                        },

                        {
                            "signatureName": "AWS:IAM-007"
                        },


                        {
                            "signatureName": "AWS:S3-001"
                        },

                        {
                            "signatureName": "AWS:S3-006"
                        },

                        {
                            "signatureName": "AWS:VPC-001"
                        },

                        {
                            "signatureName": "AWS:VPC-002"
                        },

                        {
                            "signatureName": "AWS:S3-011"
                        },
                        {
                            "signatureName": "AWS:IAM-020"
                        }



                    ]
                },
                {
                    "ruleNumber": "CC8.1",
                    "ruleName": "The entity identifies, selects, and develops risk mitigation  activities for risks arising from potential business disruptions.",
                    "ruleDesc": "Manages Changes Throughout the System Lifecycle A process for managing system changes throughout the lifecycle of the system and its components (infrastructure, data, software and procedures) is used to support system availability and processing integrity.  |  Documents Changes—A process is in place to document system changes to support ongoing maintenance of the system and to support system users in performing their responsibilities.   | Tracks System Changes   |Configures Software   | Identifies and Evaluates System Changes  |  Identifies Changes in Infrastructure, Data, Software, and Procedures Required to Remediate Incidents  |  Creates Baseline Configuration of IT Technology  |  Protects Personal Information",
                    "ruleCategory": "CHANGE MANAGEMENT",
                    "signatures": [

                        {
                            "signatureName": "AWS:IAM-003"
                        },

                        {
                            "signatureName": "AWS:CONFIG-003"
                        },


                        {
                            "signatureName": "AWS:CLT-007"
                        },

                        {
                            "signatureName": "AWS:CWATCH-009"
                        },
                        {
                            "signatureName": "AWS:CLT-001"
                        },
                        {
                            "signatureName": "AZU:ALA-001"
                        },
                        {
                            "signatureName": "AZU:ALA-002"
                        },
                        {
                            "signatureName": "AZU:ALA-003"
                        }



                    ]
                },

                {
                    "ruleNumber": "CC9.1",
                    "ruleName": "The entity identifies, selects, and develops risk mitigation activities for risks arising from potential business disruptions.",
                    "ruleDesc": "Considers Mitigation of Risks of Business Disruption—Risk  mitigation activities include the development of planned policies,  procedures, communications, and alternative processing solutions  to respond to, mitigate, and recover from security events that disrupt business operations. Those policies and procedures include monitoring processes and information and communications to meet the entity's objectives during response, mitigation, and recovery efforts.",
                    "ruleCategory": "RISK MITIGATION",
                    "signatures": [


                        {
                            "signatureName": "AWS:IAM-003"
                        },
                        {
                            "signatureName": "AWS:CLOUDNOSYS-001"
                        },
                        {
                            "signatureName": "AWS:CLT-001"
                        },

                        {
                            "signatureName": "AWS:CWATCH-007"
                        }



                    ]
                },



                {
                    "ruleNumber": "A1.1",
                    "ruleName": "The entity maintains, monitors, and evaluates current  processing capacity and use of system components  (infrastructure, data, and software) to manage capacity demand and to enable the implementation of additional capacity to help meet its objectives.",
                    "ruleDesc": "Measures Current Usage—The use of the system components is measured to establish a baseline for capacity management and to use when evaluating the risk of impaired availability due to capacity constraints. | Makes Changes Based on Forecasts—The system change management process is initiated when forecasted usage exceeds capacity tolerances.",
                    "ruleCategory": "AVAILABILITY",
                    "signatures": [

                        {
                            "signatureName": "AWS:VPC-006"
                        },
                        {
                            "signatureName": "AWS:VPC-005"
                        },

                        {
                            "signatureName": "AWS:VPC-004"
                        },
                        {
                            "signatureName": "AZU:AD-002"
                        },
                        {
                            "signatureName": "AZU:ALA-002"
                        },
                        {
                            "signatureName": "AZU:SA-003"
                        },
                        {
                            "signatureName": "AZU:ALA-004"
                        }


                    ]
                },

                {
                    "ruleNumber": "A1.2",
                    "ruleName": "The entity authorizes, designs, develops or acquires,  implements, operates, approves, maintains, and monitors environmental protections, software, data back-up processes, and recovery infrastructure to meet its objectives.",
                    "ruleDesc": "Identifies Environmental Threats |Designs Detection Measures  |  Implements and Maintains Environmental Protection Mechanisms  |  Implements Alerts to Analyze Anomalies   |  Responds to Environmental Threat Events  |  Determines Data Requiring Backup  |  Performs Data Backup |   Addresses Offsite Storage  |  Implements Alternate Processing Infrastructure",
                    "ruleCategory": "AVAILABILITY",
                    "signatures": [

                        {
                            "signatureName": "AWS:S3-016"
                        },
                        {
                            "signatureName": "AWS:RDS-010"
                        },
                        {
                            "signatureName": "AWS:DDB-001"
                        },
                        {
                            "signatureName": "AWS:EC2-017"
                        },
                        {
                            "signatureName": "AWS:RDS-009"
                        },
                        {
                            "signatureName": "AWS:S3-015"
                        },

                        {
                            "signatureName": "AWS:RDS-006"
                        },
                        {
                            "signatureName": "AWS:EC2-020"
                        }

                    ]
                },


                {
                    "ruleNumber": "A1.3",
                    "ruleName": "The entity tests recovery plan procedures supporting system recovery to meet its objectives.",
                    "ruleDesc": "Implements Business Continuity Plan Testing—Business continuity plan testing is performed on a periodic basis. The testing includes (1) development of testing scenarios based on threat likelihood and magnitude; (2) consideration of system components from across the entity that can impair the availability; (3) scenarios that consider the potential for the lack of availability of key personnel; and (4) revision of continuity plans and systems based on test results.   |   Tests Integrity and Completeness of Back-Up Data—The integrity and completeness of back-up information is tested on a periodic basis",
                    "ruleCategory": "AVAILABILITY",
                    "signatures": [

                        {
                            "signatureName": "AWS:S3-016"
                        },
                        {
                            "signatureName": "AWS:RDS-010"
                        },
                        {
                            "signatureName": "AWS:DDB-001"
                        },

                        {
                            "signatureName": "AWS:EC2-017"
                        },
                        {
                            "signatureName": "AWS:RDS-009"
                        },
                        {
                            "signatureName": "AWS:S3-015"
                        },

                        {
                            "signatureName": "AWS:RDS-011"
                        },
                        {
                            "signatureName": "AWS:DDB-001"
                        },
                        {
                            "signatureName": "AWS:RDS-006"
                        },
                        {
                            "signatureName": "AWS:EC2-020"
                        },
                        {
                            "signatureName": "AZU:ALA-001"
                        },
                        {
                            "signatureName": "AZU:AD-002"
                        }


                    ]
                },


                {
                    "ruleNumber": "PI1.5",
                    "ruleName": "The entity implements policies and procedures to store inputs, items in processing, and outputs completely, accurately, and timely in accordance with system specifications to meet the entity’s objectives",
                    "ruleDesc": "Protects Stored Items | Archives and Protects System Records | Creates and Maintains Records of System Storage ActivitiesRecords of system storage activities are created and maintained completely and accurately in a timely manner.",
                    "ruleCategory": "PROCESSING INTEGRITY",
                    "signatures": [{
                        "signatureName": "AWS:S3-006"
                    },
                    {
                        "signatureName": "AWS:RDS-011"
                    },
                    {
                        "signatureName": "AWS:RDS-004"
                    },

                    {
                        "signatureName": "AWS:DDB-001"
                    },
                    {
                        "signatureName": "AWS:EC2-020"
                    },
                    {
                        "signatureName": "AZU:ALA-003"
                    },
                    {
                        "signatureName": "AZU:ALA-004"
                    },
                    {
                        "signatureName": "AZU:AD-001"
                    },
                    {
                        "signatureName": "AZU:AD-002"
                    },
                    {
                        "signatureName": "AZU:VM-001"
                    },
                    {
                        "signatureName": "AZU:VM-002"
                    }



                    ]
                }





                ]

        }

    },
    {
        "regulationName": "General Data Protection Regulation (EU) 2016/679 (GDPR)",
        "regulationText": "The General Data Protection Regulation (EU) 2016/679 (GDPR) is a regulation in EU law on data protection and privacy for all individuals within the European Union (EU). Superseding the Data Protection Directive 95/46/EC, the regulation contains provisions and requirements pertaining to the processing of personal data of individuals (formally called data subjects in the GDPR) —regardless of its location and the data subjects' citizenship—that is processing the personal information of data subjects inside the EEA. The Security Rule requires appropriate administrative, physical and technical safeguards to ensure the confidentiality, integrity, and security of electronic protected health information. CloudEye has leveraged a process called “Cross Walk” of NIST Cyber Security Framework to GDPR Security Rule Cross Walk combined with Cloud vendors recommended best practices for GDPR to create set of comprehensive controls that maps closest to the technical security, data protection and risk management mandates. These identified and mapped security, audit and risk management controls are intended to help your company meet compliance. The following information is a guidance for compliance and does not imply, approve, or provide full assurance for any regulations or laws.",
        "regulationLogo": "GDPR.png",
        "config": {
            "customRules": [
                {
                    "ruleNumber": "Article 25 1.0, 2.0",
                    "ruleName": "1.0 - Taking into account the state of the art, the cost of implementation and the nature, scope, context and purposes of processing as well as the risks of varying likelihood and severity for rights and freedoms of natural persons posed by the processing, the controller shall, both at the time of the determination of the means for processing and at the time of the processing itself, implement appropriate technical and organisational measures, such as pseudonymisation, which are designed to implement data-protection principles, such as data minimisation, in an effective manner and to integrate the necessary safeguards into the processing in order to meet the requirements of this Regulation and protect the rights of data subjects.",
                    "ruleDesc": "2.0 - The controller shall implement appropriate technical and organisational measures for ensuring that, by default, only personal data which are necessary for each specific purpose of the processing are processed. That obligation applies to the amount of personal data collected, the extent of their processing, the period of their storage and their accessibility. In particular, such measures shall ensure that by default personal data are not made accessible without the individual's intervention to an indefinite number of natural persons.",
                    "ruleCategory": "Article 25 - Data protection by design and by default",
                    "signatures": [
                        {
                            "signatureName": "AWS:CF-001"
                        },
                        {
                            "signatureName": "AWS:CF-003"
                        },
                        {
                            "signatureName": "AWS:CLT-004"
                        },
                        {
                            "signatureName": "AWS:ELB-012"
                        },
                        {
                            "signatureName": "AWS:ELB-013"
                        },
                        {
                            "signatureName": "AWS:ELB-014"
                        },
                        {
                            "signatureName": "AWS:SQS-001"
                        },
                        {
                            "signatureName": "AWS:ELB-008"
                        },
                        {
                            "signatureName": "AZU-SA-001"
                        },
                        {
                            "signatureName": "AZU-SA-002"
                        },
                        {
                            "signatureName": "AZU-SA-003"
                        },
                        {
                            "signatureName": "AZU-SA-004"
                        },
                        {
                            "signatureName": "AZU-VM-001"
                        },
                        {
                            "signatureName": "AZU-VM-002"
                        },
                        {
                            "signatureName": "AZU:SQLDB-001"
                        },
                        {
                            "signatureName": "AZU:SQLDB-002"
                        },
                        {
                            "signatureName": "AZU:SQLDB-003"
                        },
                        {
                            "signatureName": "AZU:SQLDB-004"
                        },
                        {
                            "signatureName": "AZU:SQLDB-005"
                        }
                    ]
                },
                {
                    "ruleNumber": "Article 30",
                    "ruleName": "Each controller and, where applicable, the controller's representative, shall maintain a record of processing activities under its responsibility. That record shall contain all of the following information: (a)the name and contact details of the controller and, where applicable, the joint controller, the controller's representative and the data protection officer",
                    "ruleDesc": "Each controller and, where applicable, the controller's representative, shall maintain a record of processing activities under its responsibility. That record shall contain all of the following information: (a)the name and contact details of the controller and, where applicable, the joint controller, the controller's representative and the data protection officer",
                    "ruleCategory": "Article 30 - Records of processing activities",
                    "signatures": [
                        {
                            "signatureName": "AWS:CLT-007"
                        },
                        {
                            "signatureName": "AWS:CWATCH-001"
                        },
                        {
                            "signatureName": "AWS:S3-006"
                        },
                        {
                            "signatureName": "AWS:CLT-002"
                        },
                        {
                            "signatureName": "AWS:CLT-005"
                        },
                        {
                            "signatureName": "AWS:ELB-001"
                        },
                        {
                            "signatureName": "AWS:CF-002"
                        },
                        {
                            "signatureName": "AZU-ALA-001"
                        },
                        {
                            "signatureName": "AZU-ALA-002"
                        },
                        {
                            "signatureName": "AZU-SA-003"
                        },
                        {
                            "signatureName": "AZU:SQLDB-002"
                        },
                        {
                            "signatureName": "AZU:SQLSERVER-001"
                        },
                        {
                            "signatureName": "AZU:SQLSERVER-002"
                        }
                    ]
                },
                {
                    "ruleNumber": "Article 32 - (a)",
                    "ruleName": " (a) the pseudonymisation and encryption of personal data",
                    "ruleDesc": "Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, the controller and the processor shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriate",
                    "ruleCategory": "Article 32 - Security of processing",
                    "signatures": [
                        {
                            "signatureName": "AWS:EC2-006"
                        },
                        {
                            "signatureName": "AWS:S3-012"
                        },
                        {
                            "signatureName": "AWS:S3-011"
                        },
                        {
                            "signatureName": "AWS:S3-001"
                        },
                        {
                            "signatureName": "AWS:EC2-010"
                        },
                        {
                            "signatureName": "AWS:RDS-012"
                        },
                        {
                            "signatureName": "AWS:REDSHIFT-008"
                        },
                        {
                            "signatureName": "AWS:REDSHIFT-009"
                        },
                        {
                            "signatureName": "AWS:REDSHIFT-010"
                        },
                        {
                            "signatureName": "AWS:RDS-002"
                        },
                        {
                            "signatureName": "AZU-VM-001"
                        },
                        {
                            "signatureName": "AZU-VM-002"
                        },
                        {
                            "signatureName": "AZU-SA-001"
                        },
                        {
                            "signatureName": "AZU-SA-002"
                        },
                        {
                            "signatureName": "AZU:SQLSERVER-001"
                        },
                        {
                            "signatureName": "AZU:SQLSERVER-002"
                        },
                        {
                            "signatureName": "AZU:SQLSERVER-004"
                        },
                        {
                            "signatureName": "AZU:SQLSERVER-005"
                        }


                    ]
                },
                {
                    "ruleNumber": "Article 32 - (b)",
                    "ruleName": "(b) the ability to ensure the ongoing confidentiality, integrity, availability and resilience of processing systems and services",
                    "ruleDesc": "Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, the controller and the processor shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriate",
                    "ruleCategory": "Article 32 - Security of processing",
                    "signatures": [
                        {
                            "signatureName": "AWS:IAM-006"
                        },
                        {
                            "signatureName": "AWS:IAM-007"
                        },
                        {
                            "signatureName": "AWS:VPC-003"
                        },
                        {
                            "signatureName": "AWS:IAM-018"
                        },
                        {
                            "signatureName": "AWS:VPC-011"
                        },
                        {
                            "signatureName": "AWS:IAM-020"
                        },
                        {
                            "signatureName": "AWS:EC2-005"
                        },
                        {
                            "signatureName": "AWS:VPC-010"
                        },
                        {
                            "signatureName": "AWS:CLT-001"
                        },
                        {
                            "signatureName": "AWS:EC2-015"
                        },
                        {
                            "signatureName": "AWS:IAM-001"
                        },
                        {
                            "signatureName": "AWS:IAM-005"
                        },
                        {
                            "signatureName": "AWS:IAM-017"
                        },
                        {
                            "signatureName": "AWS:WAF-001"
                        },
                        {
                            "signatureName": "AZU-AD-001"
                        },
                        {
                            "signatureName": "AZU-AD-002"
                        },
                        {
                            "signatureName": "AZU-VM-001"
                        },
                        {
                            "signatureName": "AZU-VM-002"
                        },
                        {
                            "signatureName": "AZU-NSG-001"
                        },
                        {
                            "signatureName": "AZU-NSG-002"
                        },
                        {
                            "signatureName": "AZU-NSG-003"
                        },
                        {
                            "signatureName": "AZU-NSG-004"
                        },
                        {
                            "signatureName": "AZU-NSG-005"
                        }

                    ]
                },
                {
                    "ruleNumber": "Article 32 - (c)",
                    "ruleName": "(c) the ability to restore the availability and access to personal data in a timely manner in the event of a physical or technical incident",
                    "ruleDesc": "Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, the controller and the processor shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriate",
                    "ruleCategory": "Article 32 - Security of processing",
                    "signatures": [
                        {
                            "signatureName": "AWS:DDB-001"
                        },
                        {
                            "signatureName": "AWS:EC2-020"
                        },
                        {
                            "signatureName": "AWS:RDS-001"
                        },
                        {
                            "signatureName": "AWS:RDS-006"
                        },
                        {
                            "signatureName": "AWS:RDS-009"
                        },
                        {
                            "signatureName": "AWS:RDS-010"
                        },
                        {
                            "signatureName": "AWS:S3-015"
                        },
                        {
                            "signatureName": "AWS:S3-016"
                        },
                        {
                            "signatureName": "AZU-AD-001"
                        },
                        {
                            "signatureName": "AZU-AD-002"
                        },
                        {
                            "signatureName": "AZU-VM-001"
                        },
                        {
                            "signatureName": "AZU-VM-002"
                        },
                        {
                            "signatureName": "AZU-NSG-001"
                        },
                        {
                            "signatureName": "AZU-NSG-002"
                        },
                        {
                            "signatureName": "AZU-NSG-003"
                        },
                        {
                            "signatureName": "AZU-NSG-004"
                        },
                        {
                            "signatureName": "AZU-ALA-001"
                        },
                        {
                            "signatureName": "AZU-ALA-002"
                        }
                    ]
                },
                {
                    "ruleNumber": "Article 32 - (d)",
                    "ruleName": "(d) a process for regularly testing, assessing and evaluating the effectiveness of technical and organisational measures for ensuring the security of the processing.",
                    "ruleDesc": "Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, the controller and the processor shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriates",
                    "ruleCategory": "Article 32 - Security of processing",
                    "signatures": [
                        {
                            "signatureName": "AWS:CONFIG-003"
                        },
                        {
                            "signatureName": "AWS:CWATCH-004"
                        },
                        {
                            "signatureName": "AWS:CWATCH-006"
                        },
                        {
                            "signatureName": "AWS:CWATCH-009"
                        },
                        {
                            "signatureName": "AWS:CWATCH-010"
                        },
                        {
                            "signatureName": "AWS:CWATCH-011"
                        },
                        {
                            "signatureName": "AWS:CWATCH-012"
                        },
                        {
                            "signatureName": "AWS:CWATCH-013"
                        },
                        {
                            "signatureName": "AWS:CWATCH-014"
                        },
                        {
                            "signatureName": "AWS:CWATCH-015"
                        },
                        {
                            "signatureName": "AZU-ALA-001"
                        },
                        {
                            "signatureName": "AZU-ALA-002"
                        },
                        {
                            "signatureName": "AZU-ALA-003"
                        },
                        {
                            "signatureName": "AZU-ALA-004"
                        }
                    ]
                },
                {
                    "ruleNumber": "Article 32 - 2.0",
                    "ruleName": "2.In assessing the appropriate level of security account shall be taken in particular of the risks that are presented by processing, in particular from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to personal data transmitted, stored or otherwise processed.",
                    "ruleDesc": "Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, the controller and the processor shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriate:",
                    "ruleCategory": "Article 32 - Security of processing",
                    "signatures": [
                        {
                            "signatureName": "AWS:AWS-001"
                        },
                        {
                            "signatureName": "AWS:CLOUDNOSYS-001"
                        }
                    ]
                }
            ]
        }
    },
    {
        "regulationName": "HIPAA Regulatory Citation (45 C.F.R. § xxx.xxx)",
        "regulationText": "The Health Insurance Portability and Accountability Act of 1996 (HIPAA), Public Law 104-191, was enacted on August 21, 1996. Sections 261 through 264 of HIPAA require the Secretary of HHS to publicize standards for the electronic exchange, privacy and security of health information.\r\n",
        "config": {
            "customRules": [{
                "ruleNumber": "§164.308(a)(1)(ii)(D)",
                "ruleName": "Security Management Process –Information System Activity Review",
                "ruleDesc": "Implement procedures to regularly review records of information system activity, such as audit logs, access reports, and security incident tracking reports.",
                "ruleCategory": "§ 164.308 Administrative safeguards.",
                "signatures": [{
                    "signatureName": "AWS:S3-006"
                },

                {
                    "signatureName": "AWS:CLT-005"
                }

                ]
            },

            {
                "ruleNumber": "§164.308(a)(3)(i)",
                "ruleName": "Workforce Security",
                "ruleDesc": "Implement policies and procedures to ensure that all members of its workforce have appropriate access to electronic protected health information, as provided under paragraph (a)(4) of this section, and to prevent those workforce members who do not have access under paragraph (a)(4) of this section from obtaining access to electronic protected health information.",
                "ruleCategory": "§ 164.308 Administrative safeguards.",
                "signatures": [{
                    "signatureName": "AWS:S3-006"
                },


                {
                    "signatureName": "AWS:S3-014"
                },

                {
                    "signatureName": "AWS:S3-016"
                },

                {
                    "signatureName": "AWS:RDS-002"
                },

                {
                    "signatureName": "AWS:RDS-004"
                },

                {
                    "signatureName": "AWS:RDS-009"
                },

                {
                    "signatureName": "AWS:RDS-010"
                },

                {
                    "signatureName": "AWS:EC2-006"
                }

                ]
            },

            {
                "ruleNumber": "§164.308(a)(7)(ii)(A) |§164.308(a)(7)(ii)(B) | §164.308(a)(7)(i) | §164.308(a)(7)(ii)(C)",
                "ruleName": "Contingency Plan –Disaster Recovery and Data Backup Plan",
                "ruleDesc": "Establish (and implement as needed) procedures to restore any loss of data.",
                "ruleCategory": "§ 164.308 Administrative safeguards.",
                "signatures": [


                    {
                        "signatureName": "AWS:S3-016"
                    },

                    {
                        "signatureName": "AWS:RDS-009"
                    },

                    {
                        "signatureName": "AWS:RDS-010"
                    }

                ]
            },

            {
                "ruleNumber": "§164.308(a)(5)(ii)(C)",
                "ruleName": "Security Awareness, Training, and Tools — Log-in Monitoring",
                "ruleDesc": "Procedures for monitoring log-in attempts and reporting discrepancies.",
                "ruleCategory": "§ 164.308 Administrative safeguards.",
                "signatures": [{
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AWS:CWATCH-001"
                },

                {
                    "signatureName": "AWS:CWATCH-002"
                },
                {
                    "signatureName": "AZU-AD-001"

                },
                {
                    "signatureName": "AZU-AD-002"

                }


                ]
            },

            {
                "ruleNumber": "§164.308(a)(6)(i) | §164.308(a)(6)(ii)",
                "ruleName": "Security Incident Procedures and Response and Reporting",
                "ruleDesc": "Implement policies and procedures to address security incidents. Identify and respond to suspected or known security incidents; mitigate, to the extent practicable, harmful effects of security incidents that are known to the covered entity or business associate; and document security incidents and their outcomes.",
                "ruleCategory": "§ 164.308 Administrative safeguards.",
                "signatures": [

                    {
                        "signatureName": "AWS:CLT-005"
                    },

                    {
                        "signatureName": "AWS:CWATCH-001"
                    },

                    {
                        "signatureName": "AWS:CWATCH-002"
                    },
                    {
                        "signatureName": "AZU-AD-001"
                    },
                    {
                        "signatureName": "AZU-ALA-002"
                    },
                    {
                        "signatureName": "AZU:ALA-004"
                    },
                    {
                        "signatureName": "AZU-AD-002"
                    }


                ]
            },

            {
                "ruleNumber": "§164.310(d)(2)(iv)",
                "ruleName": "Device and Media Controls — Data Backup and Storage Procedures",
                "ruleDesc": "Create a retrievable, exact copy of electronic protected health information, when needed, before movement of equipment.",
                "ruleCategory": "§ 164.310 Physical safeguards.",
                "signatures": [

                    {
                        "signatureName": "AWS:RDS-009"
                    },

                    {
                        "signatureName": "AWS:S3-016"
                    },
                    {
                        "signatureName": "AZU-ALA-001"
                    },
                    {
                        "signatureName": "AZU-ALA-002"
                    },
                    {
                        "signatureName": "AZU:ALA-003"
                    },
                    {
                        "signatureName": "AZU:ALA-004"
                    }

                ]
            },

            {
                "ruleNumber": "§164.312(a)(1)",
                "ruleName": "Access Control",
                "ruleDesc": "Implement technical policies and procedures for electronic information systems that maintain electronic protected health information to allow access only to those persons or software programs that have been granted access rights as specified in §164.308(a)(4).",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [

                    {
                        "signatureName": "AWS:IAM-006"
                    },

                    {
                        "signatureName": "AWS:IAM-020"
                    },
                    {
                        "signatureName": "AZU-NSG-001"
                    },
                    {
                        "signatureName": "AZU-NSG-002"
                    },
                    {
                        "signatureName": "AZU:VM-001"
                    },
                    {
                        "signatureName": "AZU:VM-002"
                    },
                    {
                        "signatureName": "AZU-SA-001"
                    },
                    {
                        "signatureName": "AZU-SA-002"
                    }

                ]
            },

            {
                "ruleNumber": "§164.312(a)(2)(i)",
                "ruleName": "Access Control — Unique User Identification",
                "ruleDesc": "Assign a unique name and/or number for identifying and tracking user identity.",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [{
                    "signatureName": "AWS:IAM-020"
                }]
            },

            {
                "ruleNumber": "§164.312(a)(2)(ii)",
                "ruleName": "Access Control — Emergency Access Procedure",
                "ruleDesc": "Establish (and implement as needed) procedures for obtaining necessary electronic protected health information during an emergency.",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [

                    {
                        "signatureName": "AWS:S3-006"
                    },

                    {
                        "signatureName": "AWS-RDS-009"
                    },

                    {
                        "signatureName": "AWS-RDS-010"
                    }

                ]
            },
            {
                "ruleNumber": "§164.312(a)(2)(iv)",
                "ruleName": "Access Control — Encryption and Decryption",
                "ruleDesc": "Implement a mechanism to encrypt and decrypt electronic protected health information.",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [

                    {
                        "signatureName": "AWS:RDS-002"
                    },

                    {
                        "signatureName": "AWS:RDS-004"
                    },

                    {
                        "signatureName": "AWS:S3-006"
                    },

                    {
                        "signatureName": "AWS:ELB-012"
                    },

                    {
                        "signatureName": "AWS:CLT-005 "
                    },
                    {
                        "signatureName": "AZU-ALA-001"
                    },
                    {
                        "signatureName": "AZU:ALA-003"
                    }


                ]
            },
            {
                "ruleNumber": "§164.312(c)(1) | §164.312(c)(1)",
                "ruleName": "Integrity - Mechanism to Authenticate ePHI",
                "ruleDesc": "Implement policies and procedures to protect electronic protected health information from improper alteration or destruction.",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [{
                    "signatureName": "AWS:CLT-005 "
                },
                {
                    "signatureName": "AWS:S3-016"
                }
                ]
            },

            {
                "ruleNumber": "§164.312(d)",
                "ruleName": "Person or entity authentication",
                "ruleDesc": "Implement procedures to verify that a person or entity seeking access to electronic protected health information is the one claimed.",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [

                    {
                        "signatureName": "AWS:IAM-006"
                    },

                    {
                        "signatureName": "AWS:IAM-020"
                    },
                    {
                        "signatureName": "AZU-AD-001"

                    },
                    {
                        "signatureName": "AZU-AD-002"

                    }

                ]
            },
            {
                "ruleNumber": "§164.312(e)(2)(ii)",
                "ruleName": "Transmission Security –Encryption",
                "ruleDesc": "Implement a mechanism to encrypt electronic protected health information whenever deemed appropriate.",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [{
                    "signatureName": "AWS:S3-012"
                },
                {
                    "signatureName": "AWS:ELB-012"
                },

                {
                    "signatureName": "AZU-SA-002"
                },
                {
                    "signatureName": "AZU-SA-001"
                }

                ]
            },


            {
                "ruleNumber": "§164.312(e)(2)(i)",
                "ruleName": "Transmission Security — Integrity Controls",
                "ruleDesc": "Implement security measures to ensure that electronically transmitted electronic protected health information is not improperly modified without detection until disposed of.",
                "ruleCategory": "§ 164.312 Technical safeguards.",
                "signatures": [{
                    "signatureName": "AWS:S3-014"
                },

                {
                    "signatureName": "AZU-SA-002"
                },
                {
                    "signatureName": "AZU-SA-001"
                }

                ]
            }




            ]
        }
    },
    {
        "regulationName": "Cloudnosys.Inc Custom Compliance Report",
        "regulationText": "This is a custom compliance report by Cloudnosys.Inc which evaluates different security parameters such as Access Logging, Data Protection, Cryptography, Audit Log",
        "regulationLogo": "logo.png",
        "config": {
            "customRules": [{
                "ruleNumber": "A.1.1",
                "ruleName": "ACCOUNT MANAGEMENT | AUTOMATED SYSTEM ACCOUNT MANAGEMENT",
                "ruleDesc": "Identifies and selects the following types of information system accounts to support organizational missions/business functions: [Assignment: organization-defined information system account types]",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                }

                ]
            },

            {
                "ruleNumber": "A.1.2",
                "ruleName": "ACCOUNT MANAGEMENT | AUTOMATED AUDIT ACTIONS| ACCESS ENFORCEMENT | ROLE-BASED ACCESS CONTROL | NON-REPUDIATION of LOGS",
                "ruleDesc": "The information system automatically audits account creation, modification, enabling, disabling, and removal actions, and notifies [Assignment: organization-defined personnel or roles]. | The information system enforces approved authorizations for logical access to information and system resources in accordance with applicable access control policies.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },

                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AWS:CONFIG-003"
                },

                {
                    "signatureName": "AZU:SQLDB-002"
                },

                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },


                {
                    "signatureName": "AWS:CWATCH-001"
                }
                ]
            },

            {
                "ruleNumber": "A.1.3",
                "ruleName": "ACCESS ENFORCEMENT | DISCRETIONARY ACCESS CONTROL",
                "ruleDesc": "The information system enforces approved authorizations for logical access to information and system resources in accordance with applicable access control policies. | The information system enforces [Assignment: organization-defined discretionary access control policy] over defined subjects and objects where the policy specifies that a subject that has been granted access to information can do one or more of the following.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },

                {
                    "signatureName": "AWS:CLT-002"
                },

                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AWS:CWATCH-001"
                },

                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:NSG-003"
                },
                {
                    "signatureName": "AZU:NSG-004"
                },

                {
                    "signatureName": "AZU:NSG-005"
                }

                ]
            },

            {
                "ruleNumber": "A.1.4",
                "ruleName": "INFORMATION FLOW ENFORCEMENT",
                "ruleDesc": "The information system enforces approved authorizations for controlling the flow of information within the system and between interconnected systems based on [Assignment: organization-defined information flow control policies].",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:CF-001"
                },
                {
                    "signatureName": "AWS:VPC-003"
                },
                {
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AWS:S3-012"
                },
                {
                    "signatureName": "AWS:S3-005"
                },

                {
                    "signatureName": "AWS:EC2-005"
                },

                {
                    "signatureName": "AWS:EC2-011"
                },

                {
                    "signatureName": "AWS:EC2-016"
                },

                {
                    "signatureName": "AWS:WAF-001"
                },

                {
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AWS:VPC-010"
                },

                {
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },

                {
                    "signatureName": "AZU:NSG-003"
                }





                ]
            },

            {
                "ruleNumber": "A.1.5",
                "ruleName": "AUDITING USE OF PRIVILEGED FUNCTIONS",
                "ruleDesc": "The information system audits the execution of privileged functions.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },

                {
                    "signatureName": "AZU:ALA-002"
                }




                ]
            },

            {
                "ruleNumber": "A.1.6",
                "ruleName": "AUTOMATED MONITORING / CONTROL",
                "ruleDesc": "The information system monitors and controls remote access methods.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:RDS-009"
                },

                {
                    "signatureName": "AWS:S3-016"
                },

                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },

                {
                    "signatureName": "AZU:SQLDB-003"
                }


                ]
            },

            {
                "ruleNumber": "A.1.7",
                "ruleName": "PROTECTION OF CONFIDENTIALITY / INTEGRITY USING ENCRYPTION / MANAGED ACCESS CONTROL POINTS",
                "ruleDesc": "The information system implements cryptographic mechanisms to protect the confidentiality and integrity of remote access sessions. | The information system routes all remote accesses through [Assignment: organization-defined number] managed network access control points.",
                "ruleCategory": "Access Control (AC)",
                "signatures": [{
                    "signatureName": "AWS:ELB-012"
                },

                {
                    "signatureName": "AWS:ELB-014"
                },

                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }





                ]
            },

            {
                "ruleNumber": "A.2.1",
                "ruleName": "AUDIT EVENTS | SYNCHRONIZATION WITH AUTHORITATIVE TIME SOURCE | SYSTEM-WIDE / TIME-CORRELATED AUDIT TRAIL",
                "ruleDesc": "Determines that the information system is capable of auditing the following events: [Assignment: organization-defined auditable events]; Determines that the following events are to be audited within the information system: [Assignment: organization-defined audited events (the subset of the auditable events defined in AU-2 a.) along with the frequency of (or situation requiring) auditing for each identified event].",
                "ruleCategory": "AUDIT AND ACCOUNTABILITY (AU)",
                "signatures": [{
                    "signatureName": "AWS:ELB-014"
                },
                {
                    "signatureName": "AWS:CLT-007"
                },

                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CF-002"
                },

                {
                    "signatureName": "AWS:VPC-009"
                },
                {
                    "signatureName": "AWS:S3-006"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },

                {
                    "signatureName": "AZU:ALA-002"
                }

                ]
            },

            {
                "ruleNumber": "A.2.2",
                "ruleName": "RESPONSE TO AUDIT PROCESSING FAILURES | CRYPTOGRAPHIC PROTECTION | | AUDIT RECORD RETENTION",
                "ruleDesc": "The information system provides a warning to [Assignment: organization-defined personnel, roles, and/or locations] within [Assignment: organization-defined time period] when allocated audit record storage volume reaches [Assignment: organization-defined percentage] of repository maximum audit record storage capacity. | The information system provides an alert in [Assignment: organization-defined real-time period] to [Assignment: organization-defined personnel, roles, and/or locations] when the following audit failure events occur: [Assignment: organization-defined audit failure events requiring real-time alerts].",
                "ruleCategory": "AUDIT AND ACCOUNTABILITY (AU)",
                "signatures": [{
                    "signatureName": "AWS:CLT-002"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },
                {
                    "signatureName": "AWS:CLT-007"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },

                {
                    "signatureName": "AZU:ALA-002"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },

                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:SA-002"
                }


                ]
            },
            {
                "ruleNumber": "A.2.3",
                "ruleName": "PROTECTION OF AUDIT INFORMATION",
                "ruleDesc": "The information system protects audit information and audit tools from unauthorized access, modification, and deletion.",
                "ruleCategory": "AUDIT AND ACCOUNTABILITY (AU)",
                "signatures": [{
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AWS:CLT-005"
                },

                {
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },
                {
                    "signatureName": "AZU:SQLDB-004"
                },
                {
                    "signatureName": "AZU:SQLDB-005"
                }


                ]
            },
            {
                "ruleNumber": "A.2.4",
                "ruleName": "AUDIT RECORD RETENTION",
                "ruleDesc": "The organization retains audit records for [Assignment: organization-defined time period consistent with records retention policy] to provide support for after-the-fact investigations of security incidents and to meet regulatory and organizational information retention requirements.",
                "ruleCategory": "AUDIT AND ACCOUNTABILITY (AU)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-015"
                },
                {
                    "signatureName": "AWS:S3-014"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },
                {
                    "signatureName": "AZU:SQLDB-004"
                },
                {
                    "signatureName": "AZU:SQLDB-005"
                }

                ]
            },

            {
                "ruleNumber": "A.3.1",
                "ruleName": "RESTRICTIONS ON EXTERNAL SYSTEM CONNECTIONS",
                "ruleDesc": "The organization employs [Selection: allow-all, deny-by-exception; deny-all, permit-by-exception] policy for allowing [Assignment: organization-defined information systems] to connect to external information systems.",
                "ruleCategory": "SECURITY ASSESSMENT AND AUTHORIZATION (CA)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-005"
                }]
            },
            {
                "ruleNumber": "A.4.1",
                "ruleName": "BASELINE CONFIGURATION",
                "ruleDesc": "The organization develops, documents, and maintains under configuration control, a current baseline configuration of the information system.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CFM-001"
                }]
            },

            {
                "ruleNumber": "A.4.2",
                "ruleName": "AUTOMATION SUPPORT FOR ACCURACY / CURRENCY | ACCESS RESTRICTIONS FOR CHANGE",
                "ruleDesc": "The organization employs automated mechanisms to maintain an up-to-date, complete, accurate, and readily available baseline configuration of the information system. | The organization reviews information system changes [Assignment: organization-defined frequency] and [Assignment: organization-defined circumstances] to determine whether unauthorized changes have occurred.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CLT-001"
                },
                {
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:CONFIG-003"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }

                ]
            },

            {
                "ruleNumber": "A.4.3",
                "ruleName": "CONFIGURATION CHANGE CONTROL | CRYPTOGRAPHY MANAGEMENT",
                "ruleDesc": "The organization ensures that cryptographic mechanisms used to provide [Assignment: organization-defined security safeguards] are under configuration management.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-005"
                },
                {
                    "signatureName": "AWS:CWATCH-007"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }

                ]
            },

            {
                "ruleNumber": "A.4.4",
                "ruleName": "AUTOMATED CENTRAL MANAGEMENT / APPLICATION / VERIFICATION",
                "ruleDesc": "The organization employs automated mechanisms to centrally manage, apply, and verify configuration settings for [Assignment: organization-defined information system components]. | The organization employs [Assignment: organization-defined security safeguards] to respond to unauthorized changes to [Assignment: organization-defined configuration settings].",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-006"
                },
                {
                    "signatureName": "AWS:CLT-001"
                },
                {
                    "signatureName": "AWS:IAM-005"
                },
                {
                    "signatureName": "AWS:CONFIG-003"
                },
                {
                    "signatureName": "AWS:CWATCH-014"
                },
                {
                    "signatureName": "AWS:EC2-011"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-005"
                }
                ]
            },

            {
                "ruleNumber": "A.4.5",
                "ruleName": "LEAST FUNCTIONALITY",
                "ruleDesc": "Configures the information system to provide only essential capabilities; and Disables [Assignment: organization-defined functions, ports, protocols, and services within the information system deemed to be unnecessary and/or nonsecure].",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:IAM-017"
                },
                {
                    "signatureName": "AWS:VPC-002"
                },

                {
                    "signatureName": "AWS:S3-005"
                },
                {
                    "signatureName": "AWS:S3-010"
                },
                {
                    "signatureName": "AWS:IAM-018"
                },
                {
                    "signatureName": "AWS:CWATCH-004"
                },

                {
                    "signatureName": "AWS:EC2-013"
                },

                {
                    "signatureName": "AWS:S3-007"
                },
                {
                    "signatureName": "AWS:S3-009"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },
                {
                    "signatureName": "AZU:NSG-003"
                },
                {
                    "signatureName": "AZU:AD-001"
                },

                {
                    "signatureName": "AZU:AD-002"
                }



                ]
            },

            {
                "ruleNumber": "A.4.6",
                "ruleName": "PERIODIC REVIEW",
                "ruleDesc": "Disables [Assignment: organization-defined functions, ports, protocols, and services within the information system deemed to be unnecessary and/or nonsecure].| Requirement: The service provider shall use the Center for Internet Security guidelines (Level 1) to establish list of prohibited or restricted functions, ports, protocols, and/or services or establishes its own list of prohibited or restricted functions, ports, protocols, and/or services if USGCB is not available.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:IAM-006"
                },
                {
                    "signatureName": "AWS:VPC-003"
                },
                {
                    "signatureName": "AWS:VPC-010"
                },

                {
                    "signatureName": "AWS:VPC-009"
                },
                {
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AZU:NSG-003"
                },
                {
                    "signatureName": "AZU:AD-001"
                },

                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },

                {
                    "signatureName": "AZU:AD-003"
                }


                ]
            },

            {
                "ruleNumber": "A.4.7",
                "ruleName": "INFORMATION SYSTEM COMPONENT INVENTORY",
                "ruleDesc": "Develops and documents an inventory of information system components that: INFORMATION SYSTEM COMPONENT INVENTORY, UPDATES DURING INSTALLATIONS / REMOVALS, AUTOMATED MAINTENANCE, NO DUPLICATE ACCOUNTING OF COMPONENTS, CENTRALIZED REPOSITORY, AUTOMATED LOCATION TRACKING.",
                "ruleCategory": "Configuration Management Control Family (CM)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-004"
                }]
            },

            {
                "ruleNumber": "B.1.1",
                "ruleName": "ALTERNATE PROCESSING SITE | SEPARATION FROM PRIMARY SITE |ACCESSIBILITY | INFORMATION SYSTEM BACKUP | SEPARATE STORAGE FOR CRITICAL INFORMATION",
                "ruleDesc": "Establishes an alternate processing site including necessary agreements to permit the transfer and resumption of [Assignment: organization-defined information system operations] for essential missions/business functions within [Assignment: organization-defined time period consistent with recovery time and recovery point objectives] when the primary processing capabilities are unavailable;",
                "ruleCategory": "CONTINGENCY PLANNING (CP)",
                "signatures": [{
                    "signatureName": "AWS:DDB-001"
                },
                {
                    "signatureName": "AWS:EC2-017"
                },
                {
                    "signatureName": "AWS:EC2-018"
                },

                {
                    "signatureName": "AWS:EC2-020"
                },
                {
                    "signatureName": "AWS:RDS-006"
                },
                {
                    "signatureName": "AWS:RDS-009"
                },

                {
                    "signatureName": "AWS:S3-015"
                },

                {
                    "signatureName": "AWS:S3-016"
                },
                {
                    "signatureName": "AZU:SQLDB-002"
                },
                {
                    "signatureName": "AZU:SQLDB-004"
                },
                {
                    "signatureName": "AZU:SQLDB-005"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                }



                ]
            },

            {
                "ruleNumber": "B.2.1",
                "ruleName": "IDENTIFICATION AND AUTHENTICATION (ORGANIZATIONAL USERS), REMOTE ACCESS  - SEPARATE DEVICE",
                "ruleDesc": "The information system uniquely identifies and authenticates organizational users (or processes acting on behalf of organizational users). | The information system implements multifactor authentication for remote access to privileged and non-privileged accounts such that one of the factors is provided by a device separate from the system gaining access and the device meets [Assignment: organization-defined strength of mechanism requirements].",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-002"
                },
                {
                    "signatureName": "AWS:IAM-007"
                },
                {
                    "signatureName": "AWS:IAM-018"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }


                ]
            },

            {
                "ruleNumber": "B.2.2",
                "ruleName": "PASSWORD-BASED AUTHENTICATION",
                "ruleDesc": "The information system, for password-based authentication:",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:IAM-015"
                },

                {
                    "signatureName": "AWS:IAM-014"
                },
                {
                    "signatureName": "AWS:IAM-016"
                },
                {
                    "signatureName": "AWS:IAM-011"
                },

                {
                    "signatureName": "AWS:IAM-012"
                },
                {
                    "signatureName": "AWS:IAM-009"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:AD-003"
                },
                {
                    "signatureName": "AZU:AD-004"
                }



                ]
            },

            {
                "ruleNumber": "B.2.3",
                "ruleName": "INCIDENT MONITORING | AUTOMATED TRACKING / DATA COLLECTION / ANALYSIS",
                "ruleDesc": "The organization employs automated mechanisms to assist in the tracking of security incidents and in the collection and analysis of incident information.Supplemental Guidance: Automated mechanisms for tracking security incidents and collecting/analyzing incident information include, for example, the Einstein network monitoring device and monitoring online Computer Incident Response Centers (CIRCs) or other electronic databases of incidents.",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:CONFIG-003"
                },
                {
                    "signatureName": "AWS:CWATCH-005"
                },
                {
                    "signatureName": "AWS:CWATCH-011"
                },
                {
                    "signatureName": "AWS:CWATCH-012"
                },

                {
                    "signatureName": "AWS:CWATCH-013"
                },
                {
                    "signatureName": "AWS:CWATCH-014"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }

                ]
            },

            {
                "ruleNumber": "B.2.4",
                "ruleName": "INCIDENT REPORTING | AUTOMATED REPORTING",
                "ruleDesc": "No Description",
                "ruleCategory": "IDENTIFICATION AND AUTHENTICATION (IA)",
                "signatures": [{
                    "signatureName": "AWS:CWATCH-001"
                },
                {
                    "signatureName": "AWS:CWATCH-009"
                },
                {
                    "signatureName": "AZU:ALA-001"
                },
                {
                    "signatureName": "AZU:ALA-002"
                }


                ]
            },

            {
                "ruleNumber": "B.3.1",
                "ruleName": "FLAW REMEDIATION | AUTOMATED FLAW REMEDIATION STATUS, MNITORING, ALERTS, ANAMALIES, AUTOMATION ",
                "ruleDesc": "The organization employs automated mechanisms [Assignment: organization-defined frequency] to determine the state of information system components with regard to flaw remediation.",
                "ruleCategory": "SYSTEM AND INFORMATION INTEGRITY (SI)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-001"
                }]
            },

            {
                "ruleNumber": "B.3.2",
                "ruleName": "SECURITY ALERTS, ADVISORIES, AND DIRECTIVES | AUTOMATED ALERTS AND ADVISORIES",
                "ruleDesc": "The organization employs automated mechanisms to make security alert and advisory information available throughout the organization.Supplemental Guidance: The significant number of changes to organizational information systems and the environments in which those systems operate requires the dissemination of security-related information to a variety of organizational entities that have a direct interest in the success of organizational missions and business functions. Based on the information provided by the security alerts and advisories, changes may be required at one or more of the three tiers related to the management of information security risk including the governance level, mission/business process/enterprise architecture level, and the information system level.",
                "ruleCategory": "SYSTEM AND INFORMATION INTEGRITY (SI)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-001"
                }]
            },

            {
                "ruleNumber": "B.4.1",
                "ruleName": "APPLICATION PARTITIONING | SECURITY FUNCTION ISOLATION | INFORMATION IN SHARED RESOURCES",
                "ruleDesc": "The information system separates user functionality (including user interface services) from information system management functionality. | The information system prevents unauthorized and unintended information transfer via shared system resources.",
                "ruleCategory": "SYSTEM AND COMMUNICATIONS PROTECTION (SC)",
                "signatures": [{
                    "signatureName": "AWS:VPC-011"
                },
                {
                    "signatureName": "AWS:IAM-020"
                },
                {
                    "signatureName": "AZU:AD-002"
                },
                {
                    "signatureName": "AZU:NSG-001"
                }


                ]
            },

            {
                "ruleNumber": "B.4.2",
                "ruleName": "DENIAL OF SERVICE PROTECTION",
                "ruleDesc": "The information system protects against or limits the effects of the following types of denial of service attacks: [Assignment: organization-defined types of denial of service attacks or references to sources for such information] by employing [Assignment: organization-defined security safeguards].",
                "ruleCategory": "SYSTEM AND COMMUNICATIONS PROTECTION (SC)",
                "signatures": [{
                    "signatureName": "AWS:ELB-001"
                },
                {
                    "signatureName": "AWS:EC2-018"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },

                {
                    "signatureName": "AZU:AD-003"
                }

                ]
            },

            {
                "ruleNumber": "B.4.3",
                "ruleName": "BOUNDARY PROTECTION | DENY BY DEFAULT / ALLOW BY EXCEPTION | TRANSMISSION CONFIDENTIALITY AND INTEGRITY",
                "ruleDesc": "Monitors and controls communications at the external boundary of the system and at key internal boundaries within the system",
                "ruleCategory": "SYSTEM AND COMMUNICATIONS PROTECTION (SC)",
                "signatures": [{
                    "signatureName": "AWS:IAM-017"
                },
                {
                    "signatureName": "AWS:IAM-018"
                },

                {
                    "signatureName": "AWS:VPC-002"
                },
                {
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AZU:NSG-001"
                },
                {
                    "signatureName": "AZU:NSG-002"
                },

                {
                    "signatureName": "AZU:NSG-003"
                },
                {
                    "signatureName": "AZU:AD-001"
                },
                {
                    "signatureName": "AZU:AD-002"
                },

                {
                    "signatureName": "AZU:AD-003"
                }

                ]
            },

            {
                "ruleNumber": "B.5.1",
                "ruleName": "CRYPTOGRAPHIC OR ALTERNATE PHYSICAL PROTECTION | SESSION AUTHENTICITY",
                "ruleDesc": "The information system implements cryptographic mechanisms to [Selection (one or more): prevent unauthorized disclosure of information; detect changes to information] during transmission unless otherwise protected by [Assignment: organization-defined alternative physical safeguards].",
                "ruleCategory": "Technical safeguards.",
                "signatures": [{
                    "signatureName": "AWS:VPC-010"
                },
                {
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AWS:ELB-001"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },

                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                }

                ]
            },

            {
                "ruleNumber": "B.5.2",
                "ruleName": "PROTECTION OF INFORMATION AT REST",
                "ruleDesc": "The information system protects the [Selection (one or more): confidentiality; integrity] of [Assignment: organization-defined information at rest].",
                "ruleCategory": "Technical safeguards.",
                "signatures": [{
                    "signatureName": "AWS-S3-013"
                },
                {
                    "signatureName": "AWS:S3-011"
                },
                {
                    "signatureName": "AWS:S3-006"
                },

                {
                    "signatureName": "AWS:EC2-006"
                },

                {
                    "signatureName": "AWS:EC2-010"
                },
                {
                    "signatureName": "AWS:RDS-002"
                },
                {
                    "signatureName": "AWS:RDS-004"
                },

                {
                    "signatureName": "AWS:RDS-008"
                },

                {
                    "signatureName": "AWS:CF-001"
                },

                {
                    "signatureName": "AWS:CF-003"
                },

                {
                    "signatureName": "AWS:RDS-012"
                },
                {
                    "signatureName": "AWS:ELB-012"
                },
                {
                    "signatureName": "AZU:SQLDB-001"
                },

                {
                    "signatureName": "AZU:SA-001"
                },
                {
                    "signatureName": "AZU:VM-001"
                },
                {
                    "signatureName": "AZU:VM-002"
                },
                {
                    "signatureName": "AZU:SA-002"
                },
                {
                    "signatureName": "AZU:SA-003"
                },
                {
                    "signatureName": "AZU:SQLDB-003"
                }



                ]
            },

            {
                "ruleNumber": "C.1.1",
                "ruleName": "ACQUISITION PROCESS | CONTINUOUS MONITORING PLAN",
                "ruleDesc": "The organization requires the developer of the information system, system component, or information system service to produce a plan for the continuous monitoring of security control effectiveness that contains [Assignment: organization-defined level of detail].",
                "ruleCategory": "System And Services Acquisition Control Family (SA)",
                "signatures": [{
                    "signatureName": "AWS:CLOUDNOSYS-001"
                }]
            }
            ]
        }
    }




]