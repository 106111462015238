import axios from 'axios';
import { functions } from '../utils/firebase';
const port = 5002;
const projectId = 'temp-cloudnosys';
const isDevenv = process.env.NODE_ENV === 'development';
const isLocal = window.location.hostname === 'localhost';
const PLAYBOOK_FUNC_NAMES = {
  getPlaybooksNodesConfig: 'Playbook-getPlaybookNodesConfig',
  runPlaybook: 'Playbook-runPlaybook',
  getPlaybookDataFromURL: 'Playbook-getPlaybookDataFromURL',
};

// ?? Turn true and false if you want to runPBLocally, Note (Your server should be running in order to run locally)
const wannaRunPBLocal = false;

export const runPlaybook = async playbookData => {
  try {
    if (isDevenv && wannaRunPBLocal) {
      // ~ Development mode call functions locally
      const res = await axios.post(
        `http://localhost:${port}/${projectId}/us-central1/${PLAYBOOK_FUNC_NAMES.runPlaybook}`,
        {
          data: playbookData,
        }
      );
      return { ...res, data: res.data.result };
    }

    // ~ Live mode call functions from firebase
    return await functions.httpsCallable(PLAYBOOK_FUNC_NAMES.runPlaybook)(playbookData);
  } catch (error) {
    throw error;
  }
};
export const getPlaybookDataFromURL = async historyData => {
  try {
    // if (isDevenv && wannaRunPBLocal) {
    //   console.log('calling getPlaybookDataFromURL locally');
    //   // ~ Development mode call functions locally

    //   const endpoint = `http://localhost:${port}/${projectId}/us-central1/${PLAYBOOK_FUNC_NAMES.getPlaybookDataFromURL}`;
    //   const res = await axios.post(insertDataAsQuery(historyData, endpoint));
    //   return { ...res, data: res.data };
    // }

    // ~ Live mode call functions from firebase
    const result = await functions.httpsCallable(
      insertDataAsQuery(historyData, PLAYBOOK_FUNC_NAMES.getPlaybookDataFromURL)
    )();
    console.log('result :>> ', result);
    return result;
  } catch (error) {
    console.log('error getPlaybookDataFromURL:>> ', error);
    throw error;
  }
};

//call httpscallable function to get playbooks nodes config
export const fetchNodesConfig = async nodeData => {
  try {
    if (isDevenv && wannaRunPBLocal) {
      // ~ Development mode call functions locally
      const res = await axios.post(
        `http://localhost:${port}/${projectId}/us-central1/${PLAYBOOK_FUNC_NAMES.getPlaybooksNodesConfig}`,
        {
          data: nodeData,
        }
      );
      return { ...res, data: res.data.result };
    }

    // ~ Live mode call functions from firebase
    return await functions.httpsCallable(PLAYBOOK_FUNC_NAMES.getPlaybooksNodesConfig)({
      orgId: localStorage.getItem('orgId'),
    });
  } catch (error) {
    throw error;
  }
};

const insertDataAsQuery = (inputData, endpoint) => {
  // Constructing the query parameters
  const queryParams = Object.keys(inputData)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(inputData[key]))
    .join('&');

  // Final URL with query parameters
  const finalURL = `${endpoint}?${queryParams}`;

  return finalURL;
};
