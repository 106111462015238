import { notification } from 'antd';
import { firebase } from '../utils/firebase';

export async function savePreset(payload) {
  try {
    let { uid, filterPreset, orgId, fromInventory = false } = payload;

    let userRef = firebase.firestore().doc(`Users/${uid}`);
    let doc = await userRef.get();
    let docData = doc.data();

    const updateData = {
      updatedAt: new Date(),
      updatedBy: docData.email,
    };

    const masterOrgId = orgId === 'master-cloudnosys_5aj5e' || orgId === 'cloudnosys-master_s0f8f';

    if (masterOrgId) {
      const db = firebase.firestore();
      let globalRef = db.collection('Global').doc('filterPresets');
      let doc = await globalRef.get();

      if (!doc.exists) {
        console.error('Doc does not exist');
        notification.error({
          message: 'Doc does not exist',
        });
        return false;
      }
      let { _riskFilterPresets, _inventoryFilterPresets } = doc.data();

      if (fromInventory) {
        if (_inventoryFilterPresets && Array.isArray(_inventoryFilterPresets)) {
          _inventoryFilterPresets.push(filterPreset);
          updateData._inventoryFilterPresets = _inventoryFilterPresets;
        } else {
          updateData._inventoryFilterPresets = [filterPreset];
        }
      } else {
        if (_riskFilterPresets && Array.isArray(_riskFilterPresets)) {
          _riskFilterPresets.push(filterPreset);
          updateData._riskFilterPresets = _riskFilterPresets;
        } else {
          updateData._riskFilterPresets = [filterPreset];
        }
      }
      await globalRef.update(updateData);
      notification.success({
        message: 'Filter Preset saved successfully',
      });
      return true;
    }

    if (!masterOrgId) {
      if (!doc.exists) {
        console.error('Doc does not exist');
        notification.error({
          message: 'Doc does not exist',
        });
        return false;
      }

      let { riskFilterPresets, inventoryFilterPresets } = doc.data();
      if (fromInventory) {
        if (inventoryFilterPresets && Array.isArray(inventoryFilterPresets)) {
          inventoryFilterPresets.push(filterPreset);
          updateData.inventoryFilterPresets = inventoryFilterPresets;
        } else {
          updateData.inventoryFilterPresets = [filterPreset];
        }
      } else {
        if (riskFilterPresets && Array.isArray(riskFilterPresets)) {
          riskFilterPresets.push(filterPreset);
          updateData.riskFilterPresets = riskFilterPresets;
        } else {
          updateData.riskFilterPresets = [filterPreset];
        }
      }
      await userRef.update(updateData);
      notification.success({
        message: 'Filter Preset saved successfully',
      });
      return true;
    }
  } catch (error) {
    console.error('Error while saving filter preset:', error);
    notification.error({
      message: 'Error while Saving Filter Preset',
      description: error.message,
    });
    return false;
  }
}

export async function deletePreset(payload) {
  try {
    const { uid, name, preset, fromInventory = false } = payload;

    const db = firebase.firestore();
    const docId = `Users/${uid}`;

    if (fromInventory) {
      const fieldName = 'inventoryFilterPresets';
      db.doc(docId).update({
        [fieldName]: firebase.firestore.FieldValue.arrayRemove(preset),
      });
    } else {
      const fieldName = 'riskFilterPresets';
      db.doc(docId).update({
        [fieldName]: firebase.firestore.FieldValue.arrayRemove(preset),
      });
    }

    notification.success({
      message: 'Filter Preset deleted successfully',
    });
    return true;
  } catch (error) {
    console.error('Error while deleting filter preset:', error);
    notification.error({
      message: 'Error while Deleting Filter Preset',
      description: error.message,
    });
    return false;
  }
}
