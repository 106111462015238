import { notification } from 'antd';

const errorHandler = error => {
  let { status } = error;

  switch (status) {
    case 'permission-denied':
      status = 403;
      break;
    default:
      break;
  }
  if (status === 401) {
    notification.error({
      message: 'Not logged in or login has expired, please log in again.',
    });
    // @HACK
    /* eslint-disable no-underscore-dangle */
    window.g_app._store.dispatch({
      type: 'login/logout',
    });
    return;
  }
  // notification.error({
  //   message: `Request error ${status}`,
  //   description: errortext,
  // });
  // environment should not be used
  if (status === 403) {
    // notification.error({
    //   message: statusText
    // });
    // router.push('/exception/403');
    return;
  }
  if (status <= 504 && status >= 500) {
    // error message related to the above code 500
    // router.push('/exception/500');
    notification.error({
      message: 'Not logged in or login has expired, please log in again.',
    });
    return;
  }
  if (status >= 404 && status < 422) {
    // error message related to the above code 404
    notification.error({
      message: 'Not logged in or login has expired, please log in again.',
    });
    // router.push('/exception/404');
  }
};

export default errorHandler;
