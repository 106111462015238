export default {
  firebase: {
    apiKey: 'AIzaSyAb8HtNt5JpjQLRkori-kcOQ4EnJaHyTpc',
    authDomain: 'dev.cloudnosys.com',
    databaseURL: 'https://cloudnosys-1562854966815.firebaseio.com',
    projectId: 'cloudnosys-1562854966815',
    storageBucket: 'cloudnosys-1562854966815.appspot.com',
    messagingSenderId: '1009960507482',
    appId: '1:1009960507482:web:569bbe7432d4a762',
  },
};
