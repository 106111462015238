import { read, remove, update } from '@/services/firestore';
import { createOrganisation, fetchOrganisationsList } from '@/services/user';
import { notification } from 'antd';

let sum = 0;

const namespace = 'organisation';

export default {
  namespace,
  state: {
    current: null,
    list: [],
    role: 'user',
    spRisks: [],
    loading: true,
    loadingCurrent: true,
    loadingOperation: false,
    statusOperation: '',
    packageExpiry: '',
    currentData: null,
    currentPackageLimitations: null,
  },

  effects: {
    *setById({ payload }, { select, put, takeEvery, take, call }) {
      const list = yield select(state => state.organisation.list);
      const newCurrent = list.filter(item => item.id === payload);

      yield put({
        type: 'save',
        payload: { current: newCurrent[0], loadingCurrent: false },
      });

      if (newCurrent.length < 1) {
        yield put({
          type: 'save',
          payload: {
            current: null,
            loadingCurrent: false,
          },
        });
        return;
      }

      // == SAVE TO LOCAL STORAGE TO SAVE SELECTIO

      localStorage.setItem('orgId', payload);

      // == SET ACCESS CONTROL ROLE
      const userId = yield select(state => state.user.currentUser.id); // from user model

      // create subscription
      const service = yield call(read, {
        module: 'accessControl',
        stream: true,
        params: {
          orgId: newCurrent[0].id,
          id: userId,
        },
      });
      function* push(response) {
        // update access control
        if (response) {
          if (response.isActive === false) {
            // show error and logout
            if (sum === 0) {
              notification.error({
                message: 'Your account is disabled. Contact your administrator.',
              });
              sum = sum + 1;
            }

            response.role = 'user';
            yield put({
              type: 'user/setAccessControl',
              payload: {
                accessControl: response,
              },
            });
            // yield put({
            //   type: 'login/logout',
            // });
          } else {
            // if isActive === false

            // else

            yield put({
              type: 'user/setAccessControl',
              payload: {
                accessControl: response,
              },
            });
          }
        }
      }

      // subscribe function to subscription created above
      yield takeEvery(service, push);

      // unsubscribe when asked by creator
      yield take('CANCEL_WATCH_read/accessControl');

      service.close();

      yield put({
        type: 'save',
        payload: { current: newCurrent[0], loadingCurrent: false },
      });

      yield put({
        type: 'saveSPRisks',
        payload: [],
      });
      yield put({
        type: 'fetchSPRisks',
      });
    },

    *remove({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(remove, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: `${namespace} deleted`,
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        //
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },

    *fetchList({ payload }, { call, put, takeEvery, take }) {
      yield put({
        type: 'save',
        payload: { loading: true },
      });

      // create subscription
      const service = yield call(fetchOrganisationsList, payload);
      function* push(response) {
        const list = Array.isArray(response)
          ? response.filter(item => item.type !== 'deleted')
          : [];

        yield put({
          type: 'saveList',
          payload: list,
        });
        yield put({
          type: 'setCurrent',
          payload: list.length > 0 ? list[0].id : null,
        });

        yield put({
          type: 'save',
          payload: { loading: false },
        });
      }

      // subscribe function to subscription created above
      yield takeEvery(service, push);

      // unsubscribe when asked by creator
      yield take('CANCEL_WATCH_list/organisation');

      service.close();
    },

    *current({ payload }, { call, put, takeEvery, take, select }) {
      // create subscription
      const service = yield call(read, {
        module: namespace,
        ...payload,
      });

      let packageLimitations = yield select(state => state.login.packageLimitations);
      function* push(response) {
        if (response) {
          let packageTrial = '';
          const oneDay = 24 * 60 * 60 * 1000;
          const currentDate = new Date();
          const endDate = response.package.end.toDate();
          let packageType = response?.package?.type || 'paid';
          let diffDays = Math.round(Math.abs((currentDate - endDate) / oneDay));
          let currentPackageLimitations = packageLimitations[packageType];
          if (currentDate > endDate && diffDays !== 0) {
            packageTrial = 'expired';
          } else {
            packageTrial = 'remaining';
          }

          if (response?.marketPlace?.marketPlaceStatus === 'deactivate') {
            packageTrial = 'expired';
          }

          yield put({
            type: 'savePackage',
            payload: {
              packageTrial,
              currentPackageLimitations,
              response,
            },
          });
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *fetchSPRisks(_, { call, put, takeEvery, take }) {
      yield put({
        type: 'save',
        payload: { loading: true },
      });

      const orgId = localStorage.getItem('orgId');
      const service = yield call(read, {
        module: namespace,
        params: {
          id: orgId,
        },
      });

      function* push(response) {
        yield put({
          type: 'saveSPRisks',
          payload: response.riskSuppress ? response.riskSuppress : [],
        });

        yield put({
          type: 'save',
          payload: { loading: false },
        });
      }

      // subscribe function to subscription created above
      yield takeEvery(service, push);

      // unsubscribe when asked by creator
      yield take('CANCEL_WATCH_SP_RISKS/organisation');

      service.close();
    },
    *savePathToDB({ payload }, { call, put }) {
      yield put({
        type: 'saveLoading',
        payload: true,
      });

      try {
        const response = yield call(createOrganisation, payload);
        if (response) {
          notification.success({
            message: 'Organization created',
          });
          localStorage.setItem('orgId', response);

          yield put({
            type: `setById`,
            payload: response, // id
          });
        }
        yield put({
          type: 'saveLoading',
          payload: false,
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'saveLoading',
          payload: false,
        });
      }
    },
    // set current by selecting first getting from localstorage,
    // if not avaible then from the state list, but dont overwrite
    *setCurrent({ payload }, { put, select }) {
      const cachedCurrent = localStorage.getItem('orgId');

      if (cachedCurrent) {
        const list = yield select(state => state.organisation.list);
        if (list.filter(item => item.id === cachedCurrent).length > 0) {
          yield put({
            type: 'setById',
            payload: cachedCurrent,
          });

          return;
        }
      }

      const current = yield select(state => state.organisation.current);
      if (!current) {
        // dont override
        yield put({
          type: 'setById',
          payload,
        });
      }
    },

    *create({ payload }, { call, put }) {
      yield put({
        type: 'saveLoading',
        payload: true,
      });

      try {
        const response = yield call(createOrganisation, payload);

        if (response) {
          notification.success({
            message: 'Organization created',
          });
          localStorage.setItem('orgId', response);

          yield put({
            type: `setById`,
            payload: response, // id
          });
        }
        yield put({
          type: 'saveLoading',
          payload: false,
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'saveLoading',
          payload: false,
        });
      }
    },

    *update({ payload }, { call, put }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '' },
        });

        yield call(update, {
          module: namespace,
          ...payload,
        });

        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    *unsubscribe(_, { put }) {
      yield put({ type: 'CANCEL_WATCH' });
      yield put({ type: `clearList` });
      yield put({ type: `clear` });
    },
  },

  reducers: {
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
    saveSPRisks(state, { payload }) {
      return {
        ...state,
        spRisks: payload,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: [],
        packageExpiry: '',
      };
    },
    savePackage(state, { payload }) {
      return {
        ...state,
        currentPackageLimitations: payload.currentPackageLimitations,
        packageExpiry: payload.packageTrial,
        currentData: payload.response,
        loadingList: false,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear(state) {
      return {
        ...state,
        current: null,
        loading: false,
      };
    },
  },
};
