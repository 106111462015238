import { firebase } from '@/utils/firebase';
import axios from 'axios';

let { projectId } = firebase.apps[0].options;
const isValidProjectId =
  projectId === 'cloudnosysbeta' ||
  projectId === 'temp-cloudnosys' ||
  projectId === 'cloudnosys-prod';
const cloudFunctionsRegion = isValidProjectId ? 'us-central1' : '';

// change the name of the function afterwards
export const cloudViewCall = async ({
  rawQuery = false,
  query = '',
  cloudAccounts = [],
  URI,
  type,
  cloudAccountDocId,
  orgId,
}) => {
  // cloud account ids will have account ids from the provider, not from the firebase doc
  try {
    // let url = `http://localhost:5001/temp-cloudnosys/us-central1/CloudViz-cloudVizProxy/${URI}`; // for local
    let url = `https://${cloudFunctionsRegion}-${projectId}.cloudfunctions.net/CloudViz-cloudVizProxy/${URI}`;
    const context = type === 'pii-instances' ? 'piiInstance' : 'exposedInstances';
    let config = {
      cloudAccounts,
      cloudAccountDocId,
      orgId,
    };
    if (rawQuery) {
      config = {
        ...config,
        inputBox: query,
      };
    } else {
      url = `${url}?context=${context}`;
    }

    const res = await axios.post(url, config);
    return res.data;
  } catch (error) {
    console.log('Error from Cloud View Call: ', error.message);
    throw error;
  }
};

export const getCloudViewQuery = async ({ cloudAccounts = [], URI, type }) => {
  // cloud account ids will have account ids from the provider, not the firebase doc ids
  try {
    // let url = `http://localhost:5001/temp-cloudnosys/us-central1/CloudViz-cloudVizProxy/${URI}`; // local
    let url = `https://${cloudFunctionsRegion}-${projectId}.cloudfunctions.net/CloudViz-cloudVizProxy/${URI}`;
    const context = type === 'pii-instances' ? 'piiInstance' : 'exposedInstances';
    url = `${url}?context=${context}`;
    const res = await axios.post(url, {
      cloudAccounts,
    });
    return res.data;
  } catch (error) {
    console.log('Error from Get Cloud View Query: ', error);
    throw error;
  }
};
