import { firestore } from '../utils/firebase';

export const checkIntegrationExistence = async payload => {
  const { params, data } = payload;
  const { orgId, id } = params;
  const { name } = data;

  try {
    let res = await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc(id)
      .get();

    let resData = res.data();

    if (resData && resData.integrations && resData.integrations.length > 0) {
      let { integrations } = resData;
      let isExist = integrations.filter(v => v.name === name && v.isAuthenticated === true);

      return isExist;
    } else {
      return [];
    }
  } catch (error) {
    return false;
  }
};

export const addSamlIntegration = async payload => {
  const { params, data } = payload;

  const { orgId, id } = params;
  const { name } = data;

  try {
    let ref = await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc(id);

    let res = await ref.get();
    let resData = res.data();

    if (resData === undefined || Object.keys(resData).length === 0) {
      ref.set({
        integrations: [payload.data],
      });
    } else {
      let { integrations } = resData;

      let newIntegrations = integrations.filter(v => v.name !== name);

      newIntegrations.push(payload.data);
      ref.set({
        integrations: newIntegrations,
      });
    }
  } catch (error) {
    return false;
  }
};

export const update_SAML = async payload => {
  const { params, data } = payload;
  const { orgId, id } = params;
  const { type } = data;

  try {
    let ref = await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc(id);

    let res = await ref.get();
    let resData = res.data();

    let { integrations } = resData;

    let newIntegrations = integrations.filter(v => v.type !== type);

    newIntegrations.push(data);

    ref.set({
      integrations: newIntegrations,
    });
  } catch (error) {
    return false;
  }
};
