import { notification } from 'antd';
import { firebase } from '../utils/firebase';

export async function saveCloudViewPreset(payload) {
  try {
    let { uid, cloudViewPreset, orgId } = payload;

    let userRef = firebase.firestore().doc(`Users/${uid}`);
    let doc = await userRef.get();
    let docData = doc.data();

    const updateData = {
      updatedAt: new Date(),
      updatedBy: docData.email,
    };

    const masterOrgId = orgId === 'master-cloudnosys_5aj5e' || orgId === 'cloudnosys-master_s0f8f';

    if (masterOrgId) {
      const db = firebase.firestore();
      let globalRef = db.collection('Global').doc('filterPresets');
      let doc = await globalRef.get();

      if (!doc.exists) {
        console.error('Doc does not exist');
        notification.error({
          message: 'Doc does not exist',
        });
        return false;
      }
      let { _cloudViewPresets } = doc.data();

      if (_cloudViewPresets && Array.isArray(_cloudViewPresets)) {
        _cloudViewPresets.push(cloudViewPreset);
        updateData._cloudViewPresets = _cloudViewPresets;
      } else {
        updateData._cloudViewPresets = [cloudViewPreset];
      }

      await globalRef.update(updateData);
      notification.success({
        message: 'Filter Preset saved successfully',
      });
      return true;
    }

    if (!masterOrgId) {
      if (!doc.exists) {
        console.error('Doc does not exist');
        notification.error({
          message: 'Doc does not exist',
        });
        return false;
      }

      let { cloudViewPresets } = doc.data();
      if (cloudViewPresets && Array.isArray(cloudViewPresets)) {
        cloudViewPresets.push(cloudViewPreset);
        updateData.cloudViewPresets = cloudViewPresets;
      } else {
        updateData.cloudViewPresets = [cloudViewPreset];
      }
      await userRef.update(updateData);
      notification.success({
        message: 'Filter Preset saved successfully',
      });
      return true;
    }
  } catch (error) {
    console.error('Error while saving filter preset:', error);
    notification.error({
      message: 'Error while Saving Filter Preset',
      description: error.message,
    });
    return false;
  }
}

export async function deleteCloudViewPreset(payload) {
  try {
    const { uid, name, preset, fromInventory = false } = payload;

    const db = firebase.firestore();
    const docId = `Users/${uid}`;

    const fieldName = 'cloudViewPresets';
    db.doc(docId).update({
      [fieldName]: firebase.firestore.FieldValue.arrayRemove(preset),
    });

    notification.success({
      message: 'Filter Preset deleted successfully',
    });
    return true;
  } catch (error) {
    console.error('Error while deleting filter preset:', error);
    notification.error({
      message: 'Error while Deleting Filter Preset',
      description: error.message,
    });
    return false;
  }
}
