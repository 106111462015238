import { notification } from 'antd';
// import { OwnerPerms, AdministratorPerms, AuditorPerms, DevSecOpsPerms } from './rbacConfig';

let authorityState = ['guest'];
let roles = {
  // Owner: OwnerPerms,
  // Administrator: AdministratorPerms,
  // Auditor: AuditorPerms,
  // DevSecOps: DevSecOpsPerms,
  user: ['view'],
  guest: ['none'],
};

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority(str) {
  // return localStorage.getItem('web-authority') || ['admin', 'user'];
  // typeof str === 'undefined' ? localStorage.getItem('web-authority') : str;
  const authorityString = typeof str === 'undefined' ? authorityState : str;

  // authorityString could be admin, "admin", ["admin"]
  let authority;
  // try {
  // authority = JSON.parse(authorityString);
  // } catch (e) {
  authority = authorityString;

  // }
  if (typeof authority === 'string') {
    return [authority];
  }
  // // preview.pro.ant.design only do not use in your production ; preview.pro.ant.design 专用环境变量，请不要在你的项目中使用它。
  // if (!authority && APP_ENV === 'site') {
  //   return ['admin'];
  // }

  return authority;
}

export function setRoles(rbacRoles) {
  rbacRoles.map(role => (roles[role.roleName] = role.capabilities));
}

export function setAuthority(authority) {
  const proAuthority = typeof authority === 'string' ? [authority] : authority;
  authorityState = proAuthority;
  return localStorage.setItem('web-authority', JSON.stringify(proAuthority));
}

export function isSuperAdmin() {
  return getAuthority().includes('superAdmin');
}

export function isSuperAdminByEmail(email) {
  // currently true for all

  return email.match(/\@steelink\.com\.pk/i) ? true : false;
  // return email.match(/\@atompoint\.com/i) ? true : false;
}

export function userCan(action, executor) {
  let role = getAuthority()[0];

  if (!roles[role]) return false;

  let isAllowed = roles[role].includes(action);

  if (!executor) {
    // component
    return isAllowed;
  } else {
    if (isAllowed) {
      // executor function
      return executor;
    } else {
      // show error
      return () =>
        notification.error({
          message: `You don't have permission to ${getMessage(action)}, contact your admin`,
        });
    }
  }
}

function getMessage(action) {
  return action.replace(/-/g, ' ');
}
