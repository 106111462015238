import React, { PureComponent } from 'react';
import { connect } from 'dva';
import InfiniteScroll from 'react-infinite-scroller';
import { Popover, Input, List, Checkbox, Tooltip, Tag, Spin } from 'antd';
import { CheckSquareOutlined, DownOutlined } from '@ant-design/icons';
import { truncateString } from '@/utils/utils';
import CloudProviderIcon from '@/components/CloudProviderIcon';
import styles from './MultiAccountSelector.less';

const { Search } = Input;
@connect(({ accessControl, user }) => ({
  accessControl: accessControl.list,
  currentUser: user.currentUser,
}))
class MultiAccountSelector extends PureComponent {
  state = {
    popVisible: false,
    searchList: [],
  };

  handlePopOver = v => {
    this.setState({
      popVisible: v,
    });
  };

  // this function will add or remove cloudAccounts
  onChangeBox = (e, item) => {
    const { checked } = e.target;

    if (checked) {
      let { dispatch, accessControlRecord, organizationId, accessibleAccounts } = this.props;
      let addCloudAccount = {
        id: item.id,
        accountName: item.accountName,
      };

      accessibleAccounts.push(addCloudAccount);
      //this will add cloud accounts in access control
      dispatch({
        type: 'accessControl/addSelected',
        payload: {
          params: {
            id: accessControlRecord.id,
            orgId: organizationId,
          },
          data: {
            cloudAccounts: accessibleAccounts,
          },
        },
      });
    } else {
      let { dispatch, accessControlRecord, organizationId, accessibleAccounts } = this.props;

      accessibleAccounts = accessibleAccounts.filter(cloudAccount => cloudAccount.id !== item.id);
      //this will remove cloud accounts from access control
      dispatch({
        type: 'accessControl/removeSelected',
        payload: {
          params: {
            id: accessControlRecord.id,
            orgId: organizationId,
          },
          data: {
            cloudAccounts: accessibleAccounts,
          },
        },
      });
    }
  };

  selectAll = () => {
    const {
      accessibleAccounts,
      allCloudAccountsList,
      accessControlRecord,
      dispatch,
      organizationId,
    } = this.props;
    if (accessibleAccounts.length === allCloudAccountsList.length) {
      dispatch({
        type: 'accessControl/removeSelected',
        payload: {
          params: {
            id: accessControlRecord.id,
            orgId: organizationId,
          },
          data: {
            cloudAccounts: [],
          },
        },
      });
    } else {
      let allSelectedAccounts = allCloudAccountsList.map(eachAccount => {
        return {
          id: eachAccount.id,
          accountName: eachAccount.accountName,
        };
      });
      dispatch({
        type: 'accessControl/addSelected',
        payload: {
          params: {
            id: accessControlRecord.id,
            orgId: organizationId,
          },
          data: {
            cloudAccounts: allSelectedAccounts,
          },
        },
      });
    }
  };

  emptySearchBar(query) {
    const { allCloudAccountsList } = this.props;
    if (allCloudAccountsList === null) {
      return <Spin />;
    }
    if (query.target.value === '') {
      this.setState({
        searchList: [],
      });
      return;
    }
    const filteredCloudAccount = allCloudAccountsList.filter(val =>
      val.accountName.toLowerCase().includes(query.target.value.toLowerCase())
    );
    if (filteredCloudAccount.length === 0) {
      return;
    }
    this.setState({
      searchList: filteredCloudAccount,
    });
  }

  renderListItem = item => {
    let { accessibleAccounts, accessControlRecord, currentUser, accessControl } = this.props;
    let filteredCurrentUser = accessControl.filter(eachRecord => eachRecord.id === currentUser.id);
    let currentUserRole = filteredCurrentUser[0].role;
    return (
      <List.Item key={item.id} style={{ marginBottom: '23px' }}>
        <List.Item.Meta
          title={
            <Checkbox
              disabled={
                currentUserRole !== 'Owner' ||
                (currentUserRole === 'Owner' && accessControlRecord.role === 'Owner')
              }
              onChange={e => this.onChangeBox(e, item)}
              id="AllCloudAccount"
              value={item.accountName}
              key={item.accountName}
              checked={accessibleAccounts.filter(v => v.id === item.id).length > 0 ? true : false}
            >
              {truncateString(item.accountName, 20)}
              <CloudProviderIcon provider={item.provider} />
            </Checkbox>
          }
        />
      </List.Item>
    );
  };
  render() {
    let {
      accessibleAccounts,
      accessControlRecord,
      currentUser,
      accessControl,
      allCloudAccountsList,
    } = this.props;
    let filteredCurrentUser = accessControl.filter(eachRecord => eachRecord.id === currentUser.id);
    let currentUserRole = filteredCurrentUser[0].role;

    const content = (
      <div className={styles.content}>
        <div>
          <CheckSquareOutlined
            style={{
              fontSize: '22px',
              marginTop: '6px',
              marginBottom: '17px',
              color:
                allCloudAccountsList.length === accessibleAccounts.length ? '#1890FF' : '#d9d9d9',
            }}
            onClick={() => {
              if (
                currentUserRole !== 'Owner' ||
                (currentUserRole === 'Owner' && accessControlRecord.role === 'Owner')
              ) {
              } else {
                this.selectAll();
              }
            }}
          />
          <Search
            placeholder="Search for cloud accounts.."
            onChange={value => this.emptySearchBar(value)}
            allowClear
            style={{ width: 230, float: 'right' }}
          />

          <div className={styles.demoInfiniteContainer}>
            <InfiniteScroll
              loadMore={this.loadMore}
              initialLoad={false}
              pageStart={0}
              style={{ padding: '2px' }}
            >
              <List
                dataSource={
                  this.state.searchList.length > 0
                    ? this.state.searchList
                    : allCloudAccountsList && allCloudAccountsList
                }
                renderItem={this.renderListItem}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
    return (
      <div>
        {' '}
        <Popover
          placement="bottom"
          onVisibleChange={this.handlePopOver}
          content={content}
          trigger="click"
          visible={this.state.popVisible}
        >
          <div id="MultiCloudAccountSelector" className={styles.multiAccountSelector}>
            {accessibleAccounts.length === 0 ? (
              <div className={styles.noAccountSelected}>
                <Tooltip placement="top" title="No Cloud Account Selected">
                  <Tag className={styles.tagStyle}>None Selected</Tag>
                </Tooltip>
              </div>
            ) : (
              <div className={styles.selectedCloudAccounts}>
                {accessibleAccounts.map((v, i) => {
                  if (i >= 2) {
                    return;
                  }
                  return (
                    <Tooltip key={v.accountName} placement="top" title={v.accountName}>
                      <Tag className={styles.tagStyle}>
                        {v.accountName.length > 6
                          ? v.accountName.substring(0, 6).concat('...')
                          : v.accountName}
                      </Tag>
                    </Tooltip>
                  );
                })}
              </div>
            )}

            {<DownOutlined className={styles.iconStyle} />}
          </div>
        </Popover>
      </div>
    );
  }
}

export default MultiAccountSelector;
