import { notification } from 'antd';
import { list, create, read, update, remove } from '@/services/firestore';
import { addCustomSignature, testCustomSignature } from '@/services/customSignature';
import router from 'umi/router';
import { PATH_DASHBOARD } from '@/utils/routes/path';

const namespace = 'customSignatures';

export default {
  namespace,

  state: {
    loadingList: false,
    loadingCurrent: false,
    loadingRemove: false,
    loadingOperation: false,
    testingCS: false,
    statusOperation: '',
    payloadOperation: null,
    current: null,
    passedResources: 0,
    list: null,
    testResList: null,
    customSigId: '',
  },

  effects: {
    *addCustomSignature({ payload }, { call }) {
      yield call(addCustomSignature, payload);
    },
    *testCustomSig({ payload }, { call, put }) {
      try {
        yield put({
          type: 'resetAndStartTestingCustomSignature',
        });
        const { data } = yield call(testCustomSignature, payload);
        let response = data;

        if (response === undefined) {
          throw new Error('No response from server');
        }

        if (response.hasOwnProperty('statusCode') && response.statusCode !== 200) {
          yield put({
            type: 'saveResourcesError',
            payload: { signatures: [], error: response.message },
          });
        } else {
          yield put({
            type: 'saveResources',
            payload: {
              signatures: response.riskResponse[0].sigs,
              status: response.riskResponse[1].signatureStatus,
              logs: response.logs,
            },
          });
        }
      } catch (error) {
        if (typeof error === 'string') {
          notification.error({
            message: error,
          });
        } else {
          notification.error({
            message: error.message,
          });
        }
        yield put({
          type: 'save',
          payload: {
            testingCS: false,
          },
        });
      }
    },
    *list({ _ }, { call, put, takeEvery, take }) {
      try {
        const orgId = localStorage.getItem('orgId');
        const payload = {
          params: {
            orgId,
          },
        };

        // clear old before new request
        yield put({
          type: `clear:${namespace}/list`,
        });

        yield put({
          type: 'save',
          payload: { loadingList: true },
        });

        // create subscription
        const service = yield call(list, {
          module: namespace,
          orderBy: ['createdAt', 'desc'],
          ...payload,
        });
        function* push(response) {
          yield put({
            type: 'saveList',
            payload: Array.isArray(response) ? response : [],
          });
        }
        // on every callback from service
        yield takeEvery(service, push);

        // unsubscribe & clear when this action fired
        yield take(`clear:${namespace}/list`);
        service.close();
        yield put({
          type: 'clearList',
        });
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    *clearResourceCount(_, { put }) {
      yield put({
        type: 'clear',
      });
    },
    *unMountCurrent(_, { put }) {
      // clear old before new request
      yield put({
        type: 'clearCurrent',
      });
    },
    *current({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/current`,
      });
      yield put({
        type: 'clearCurrent',
      });
      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      // create subscription
      const service = yield call(read, {
        module: namespace,
        ...payload,
        no404error: true,
      });

      function* push(response) {
        if (!response) {
          yield put({
            type: 'create',
            payload: {
              ...payload,
            },
          });
          yield take(`clear:${namespace}/current`);
        } else {
          yield put({
            type: 'saveCurrent',
            payload: response,
          });
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/current`);
      service.close();
      yield put({
        type: 'clearCurrent',
      });
    },
    *create({ payload }, { call, put, select }) {
      try {
        const organisation = yield select(state => state.organisation.current);
        const orgId = organisation.id;
        const { showAdminSwitch, adminMode } = payload;
        payload = modifySignature(showAdminSwitch, adminMode, payload);
        if (payload.params.type === 'add') {
          if (showAdminSwitch) {
            // check if sigId exist in signatures or customSigs collection
            // 1 - for custom signature in organization
            const customSigRes = yield call(read, {
              module: namespace,
              params: {
                orgId: payload.params.orgId,
                id: payload.params.id,
              },
              stream: false,
            });
            // 1 - for signatures in Signatures collection
            const masterSigRes = yield call(read, {
              module: 'Signatures',
              params: {
                id: payload.params.id,
              },
              stream: false,
            });
            // responses of custom and master signatures
            if (customSigRes || masterSigRes) {
              // show error message
              notification.error({
                message: 'Signature already exist with this name',
              });
              return;
            }
          }
          const response = yield call(create, {
            module: namespace,
            ...payload,
          });

          if (response) {
            notification.success({
              message: `Custom Signature added`,
            });
            // router.push(`/custom-signatures/edit?sigId=${payload.params.id}`);

            let url = `/monitoring/risks?org=${orgId}&risksFilters=[
          {%22type%22:%22cloudProvider%22,%22value%22:[]},{%22type%22:%22severity%22,%22value%22:[]},{%22type%22:%22standards%22,%22value%22:[]},{%22type%22:%22service%22,%22value%22:[]},{%22type%22:%22categories%22,%22value%22:[]},{%22type%22:%22dataSource%22,%22value%22:[]},{%22type%22:%22TimeDiscoveredTab%22,%22value%22:[]}]&dType=`;
            router.push(url);
            // ? don't navigate to risks screen simply we need to make sure that the custom signature tab is also selected
            yield put({
              type: 'Risks/saveFilterToState',
              payload: {
                tabName: 'Custom Signatures',
                pushParamsToUrl: false,
              },
            });

            yield put({
              type: 'save',
              payload: {
                statusOperation: 'success',
                loadingOperation: false,
                payloadOperation: response,
              },
            });
          } else {
            yield put({
              type: 'save',
              payload: { statusOperation: 'error', loadingOperation: false },
            });
          }
        }
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },
    *update({ payload }, { call, put }) {
      try {
        const { showAdminSwitch, adminMode } = payload;
        payload = modifySignature(showAdminSwitch, adminMode, payload);

        yield put({
          type: 'save',
          payload: {
            loadingOperation: true,
            statusOperation: '',
            payloadOperation: null,
          },
        });
        if (payload.params.hasOwnProperty('type') && payload.params.type === 'edit') {
          const response = yield call(update, {
            module: namespace,
            ...payload,
            data: payload.data,
          });
          if (response) {
            notification.success({
              message: `Custom Signature updated successfully`,
            });
            router.push(PATH_DASHBOARD.monitoring.risks.root);
            // ? don't navigate to risks screen simply we need to make sure that the custom signature tab is also selected
            yield put({
              type: 'Risks/saveFilterToState',
              payload: {
                tabName: 'Custom Signatures',
                pushParamsToUrl: false,
              },
            });
          }
        }
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },
    *remove({ payload }, { call, put, select }) {
      const organisation = yield select(state => state.organisation.current);
      const orgId = organisation.id;
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(remove, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: `Custom Signature deleted`,
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
        let url = `/monitoring/risks?org=${orgId}&risksFilters=[
          {%22type%22:%22cloudProvider%22,%22value%22:[]},{%22type%22:%22severity%22,%22value%22:[]},{%22type%22:%22standards%22,%22value%22:[]},{%22type%22:%22service%22,%22value%22:[]},{%22type%22:%22categories%22,%22value%22:[]},{%22type%22:%22dataSource%22,%22value%22:[]},{%22type%22:%22TimeDiscoveredTab%22,%22value%22:[]}]&dType=`;
        router.push(url);
        // and call applyFilters to apply the filters on the data
        yield put({
          type: 'list',
          payload: {
            params: {
              orgId,
            },
          },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: `clear:${namespace}/list` });
      yield put({ type: `clear:${namespace}/current` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    saveResources(state, { payload }) {
      const passedResources = payload.signatures.filter(sig => sig.status);
      return {
        ...state,
        testResList: payload.signatures,
        signatureStatus: payload.status,
        logs: payload.logs,
        passedResources: passedResources.length > 0 ? passedResources.length : '0',
        testingCS: false,

        errorMessage: '',
      };
    },
    resetAndStartTestingCustomSignature(state) {
      return {
        ...state,
        testResList: [],
        signatureStatus: null,
        passedResources: 0,
        logs: [],
        testingCS: true,
        errorMessage: '',
      };
    },
    clear(state) {
      return {
        ...state,
        passedResources: 0,
        errorMessage: '',
      };
    },
    saveResourcesError(state, { payload }) {
      return {
        ...state,
        logs: [],
        testingCS: false,
        errorMessage: payload.error,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        testResList: null,
        signatureStatus: null,
        logs: null,
        loadingList: false,
      };
    },
    saveCurrent(state, { payload }) {
      return {
        ...state,
        current: payload,
        loadingCurrent: false,
      };
    },
    clearCurrent(state) {
      return {
        ...state,
        current: null,
        loadingCurrent: false,
        payloadOperation: null,
      };
    },
  },
};

function modifySignature(showAdminSwitch, adminMode, payload) {
  if (!payload.data) return payload;
  let newPayload = { ...payload };

  // normal user
  if (!showAdminSwitch && !adminMode) {
    newPayload.data.mode = 'test';
    newPayload.data.name = payload.params.id;
    newPayload.data.id = payload.params.id;
    // newPayload.params.id = payload.data.name;
  }

  // admin as a user
  if (showAdminSwitch || !adminMode) {
    newPayload.data.mode = 'test';
    newPayload.data.name = payload.data.name;
    newPayload.data.id = payload.data.name;
    newPayload.params.id = payload.data.name;
  }

  // admin with admin mode ON
  if (showAdminSwitch && adminMode) {
    newPayload.data.mode = 'prod';
    newPayload.data.name = payload.data.name;
    newPayload.data.id = payload.data.name;
    newPayload.params.id = payload.data.name;
  }

  // if (!adminMode) return newPayload;

  // newPayload.data.id = newPayload.data.name;
  // newPayload.params.id = newPayload.data.name;
  // delete newPayload.data.userCustomSignature;
  // delete newPayload.data.updatedAt;
  // delete newPayload.data.createdAt;
  return newPayload;
}
