// * ____________________________________________________________________________

function path(root, sublink) {
  return `${root}${sublink}`;
}
const ROOTS_DASHBOARD = '/';
const ROOTS_SETTING = '/settings';
const ROOTS_MONITORING = '/monitoring';
const ROOTS_REGULATIONS = '/regulations';
const ROOTS_USER = '/user';
const ROOTS_PLAYBOOKS = '/playbooks';
const ROOTS_ACCOUNT_SETTINGS = '/account/settings';
const ROOTS_SETUP = '/setup';

// * ____________________________________________________________________________

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    login: path(ROOTS_USER, '/login'),
    samlLogin: path(ROOTS_USER, '/saml-login'),
    forgetPassword: path(ROOTS_USER, '/forgot-password'),
    resetPassword: path(ROOTS_USER, '/reset-password'),
  },
  monitoring: {
    root: path(ROOTS_DASHBOARD, '/monitoring'),
    securityDashboard: path(ROOTS_MONITORING, '/security-dashboard'),
    complianceDashboard: path(ROOTS_MONITORING, '/compliance-dashboard'),
    risks: {
      root: path(ROOTS_MONITORING, '/risks'),
      customSignatures: {
        root: path(ROOTS_MONITORING, '/risks/custom-signatures'),
        add: path(ROOTS_MONITORING, '/risks/custom-signatures/add'),
        edit: path(ROOTS_MONITORING, '/risks/custom-signatures/edit'),
      },
    },
    inventory: path(ROOTS_MONITORING, '/inventory'),
  },
  regulations: {
    root: path(ROOTS_DASHBOARD, '/regulations'),
    compliance: path(ROOTS_REGULATIONS, '/compliance'),
    customRegulations: {
      root: path(ROOTS_REGULATIONS, '/custom-regulations'),
      add: path(ROOTS_REGULATIONS, '/custom-regulations/add'),
      edit: path(ROOTS_REGULATIONS, '/custom-regulations/edit'), // when using this note to concate the id to the end of the path
    },
  },
  playbooks: {
    root: path(ROOTS_DASHBOARD, '/playbooks'),
    add: path(ROOTS_PLAYBOOKS, '/add'),
    editor: path(ROOTS_PLAYBOOKS, '/editor'),
  },
  setup: {
    root: path(ROOTS_DASHBOARD, '/setup'),
    cloudAccounts: path(ROOTS_SETUP, '/cloud-accounts'),
    cloudAccountsAdd: path(ROOTS_SETUP, '/cloud-accounts/add'),
    cloudAccountsEdit: path(ROOTS_SETUP, '/cloud-accounts/edit'),
    cloudAccountsRealTimeAlerts: path(ROOTS_SETUP, '/cloud-accounts/real-time-alerts'),
    settings: {
      root: path(ROOTS_SETUP, '/settings'),
      integration: {
        root: path(ROOTS_SETUP, '/settings/integration'),
        authorize: path(ROOTS_SETUP, '/settings/integration/authorize'),
      },
      accessControl: path(ROOTS_SETUP, '/settings/access-control'),
      roles: path(ROOTS_SETUP, '/settings/roles'),
      suppressionRules: path(ROOTS_SETUP, '/settings/suppression-roles'),
      billing: path(ROOTS_SETUP, '/settings/billing'),
    },
  },
  account: {
    settings: {
      root: path(ROOTS_ACCOUNT_SETTINGS, '/'),
      basic: path(ROOTS_ACCOUNT_SETTINGS, '/basic'),
      security: path(ROOTS_ACCOUNT_SETTINGS, '/security'),
      apiConfig: path(ROOTS_ACCOUNT_SETTINGS, '/api-config'),
    },
  },
};
