import { notification, message } from 'antd';
import hash from 'crypto';
import { list, read, update, remove } from '@/services/firestore';
import { firebase } from '@/utils/firebase';
import {
  removeAccountFromAccessControl,
  addCloudAccount,
  testCloudAccountApis,
  generateUrl,
  saveTestApis,
  checkRolesExist,
  updateBillingBeforeDeletion,
  initiateForceCleanup,
} from '@/services/cloudAccount';
import { removeUserOrg } from '@/services/user';
import { router } from 'umi';
import { PATH_DASHBOARD } from '@/utils/routes/path';
import { createRoles } from '@/utils/createRoles';
import { dynamicallyCreateAnchorAndNavigate, encryptAES } from '@/utils/utils';
import { SCAN_STATUS, SCAN_TYPE } from '@/utils/constant';

const namespace = 'cloudAccount';

export default {
  namespace,
  state: {
    invokeURL: '',
    disableRunScanButton: true,
    loadingRunScanButton: false,
    isAccountAddedSuccessfully: false,
    viewListButton: true,
    cloudAccountIdRef: '',
    authKey: '',
    receiverKey: '',
    deployStackButtonLoading: false,
    deployStackCheckboxDisabled: true,
    deployCftButtonLoading: false,
    loadingList: false,
    loadingCurrent: false,
    loadingRemove: false,
    allCloudAccountsListLoaded: false,
    updating: false,
    loadingOperation: false,
    statusOperation: '',
    errorMessage: '',
    payloadOperation: null,
    eagleEyeUrl: '',
    allCloudAccountsList: [], // this array will have all accessible cloud accounts
    activeCloudAccountsList: [],
    selectedCloudAccounts: [],
    eagleEyeStatus: '',
    current: null,
    finalProgress: 0,
    currentAccId: '',
    navigateStatus: false,
    haveScanArray: [],
    willScanArray: [],
    haveScan: 0,
    willScan: 0,
    currentStatus: 'completed-scan',
    currentProgress: 0,
    progress: {
      current: 0,
      log: {
        // CA1: {willScan: [], haveScan: []}
      },
    },
    testConnectionTypes: {
      authentication: { loading: true },
      resourcesAccess: { loading: true },
      misconfigurationDetection: { loading: true },
      cloudXray: { loading: true },
      eagleEye: { loading: true },
    },
    testingPageTitle: 'Connecting...',
    scanRunningInstances: true,
    forceCleanupInProgress: false,
    scanProgressWrtCloudAccounts: {}, //Wrt = with respect to
    appName: '',
    principalId: '',
    secretExpiryDates: '',
  },

  effects: {
    *list({ payload }, { call, put, takeEvery, take, select }) {
      try {
        // clear old before new request
        yield put({
          type: `clear:${namespace}/list`,
        });
        // create subscription
        const service = yield call(list, {
          module: namespace,
          orderBy: ['createdAt', 'desc'],
          ...payload,
          no404error: true,
        });
        function* push(response) {
          try {
            // all cloudAccounts of the organization
            let allCloudAccounts = response;

            // extract only user acccesable accounts list from accessControl
            const accessControlList = yield select(state => state.accessControl.list);
            const loadingList = yield select(state => state.accessControl.loadingList);
            if (!loadingList) {
              const currentUserId = yield select(state => state.user.currentUser.id); // from user model
              const currentUserRole = yield select(state => state.user.currentUser.role); // from user model
              const filteredAccessControl = accessControlList.filter(
                accessControl => accessControl.id === currentUserId
              );

              // if access control do not have cloudAccounts which isin case of owner and support only
              const accessCloudAccountList =
                filteredAccessControl.length > 0 &&
                filteredAccessControl[0].hasOwnProperty('cloudAccounts') &&
                filteredAccessControl[0].cloudAccounts.length > 0
                  ? response.filter(each =>
                      filteredAccessControl[0].cloudAccounts.some(e => e.id === each.id)
                    )
                  : response;

              const activeCloudAccountsList = accessCloudAccountList.filter(
                acc => acc.isActive === true
              );

              yield put({
                type: 'saveList',
                payload: {
                  cloudAccountList: Array.isArray(accessCloudAccountList)
                    ? accessCloudAccountList
                    : [],
                  activeCloudAccountsList: Array.isArray(activeCloudAccountsList)
                    ? activeCloudAccountsList
                    : [],
                },
              });

              let currentStatus = '';
              let statusOfAccounts =
                activeCloudAccountsList && activeCloudAccountsList.map(item => item.status);

              if (statusOfAccounts.includes('start-scan')) {
                currentStatus = 'start-scan';
              } else if (statusOfAccounts.includes('running-scan')) {
                currentStatus = 'running-scan';
              } else {
                currentStatus = 'completed-scan';
              }

              // console.log('Updatin currentStatus from list', currentStatus);

              yield put({
                type: 'saveStatus',
                payload: currentStatus,
              });

              if (currentStatus === 'completed-scan') {
                yield put({
                  type: 'save',
                  payload: {
                    willScan: 0,
                  },
                });
              }

              // Retrieve selected cloud accounts from Redux state
              let selectedCloudAccounts = yield select(
                state => state.cloudAccount.selectedCloudAccounts
              );
              // Retrieve and parse cloud account IDs from local storage
              let localStorageSelectedCloudAccountIds = localStorage.getItem(
                'selectedCloudAccountsIds'
              );

              let selectedCloudAccountIdsInStorage = localStorageSelectedCloudAccountIds
                ? JSON.parse(localStorageSelectedCloudAccountIds)
                : [];

              // Use a local variable for handling selected accounts
              let updatedSelectedAccounts = [];

              // Check if there are selected cloud account IDs in local storage
              if (
                !selectedCloudAccountIdsInStorage ||
                selectedCloudAccountIdsInStorage.length === 0
              ) {
                // If local storage is empty and there are active cloud accounts, select the first one
                if (activeCloudAccountsList.length > 0) {
                  updatedSelectedAccounts = [activeCloudAccountsList[0]];
                  const accountIds = updatedSelectedAccounts.map(account => account.id);
                }
              } else {
                // Filter the active cloud accounts based on the IDs from local storage
                updatedSelectedAccounts = activeCloudAccountsList.filter(cloudAccount =>
                  selectedCloudAccountIdsInStorage.includes(cloudAccount.id)
                );
              }

              // Update local storage with the filtered list's IDs
              const newSelectedAccountIds = updatedSelectedAccounts.map(account => account.id);
              localStorage.setItem(
                'selectedCloudAccountsIds',
                JSON.stringify(newSelectedAccountIds)
              );

              // Dispatch the saveSelected action with the new list of accounts
              yield put({
                type: 'saveSelected',
                payload: updatedSelectedAccounts,
              });
            }
          } catch (err) {
            console.log(err);
          }
        }

        yield takeEvery(service, push);

        // unsubscribe & clear when this action fired
        yield take('CANCEL_WATCH/cloudAccount');
        service.close();
        yield put({
          type: 'clearList',
        });
      } catch (error) {
        console.log(error);
      }
    },

    *resetScanningProgress(_, { put }) {
      yield put({
        type: 'clearScanProgress',
      });
    },
    // ! Throtling
    // *scanProgress({ payload }, { call, take, put, takeEvery, select }) {
    //   console.log('payload.params.id :>> ', payload.params.id);
    //   // create subscription
    //   const service = yield call(list, {
    //     module: 'scanProgress',
    //     ...payload,
    //   });
    //   console.log('Scan progress effect');

    //   const { allCloudAccountsList } = yield select(state => state.cloudAccount);

    //   let cloudAccountIds = allCloudAccountsList.map(each => each.id);

    //   // response has the updated data from stream
    //   const throttledPush = throttle(function*(response) {
    //     // console.log('throtle called response :>> ', response);
    //     console.log(new Date(), ':💂‍♂️🕵️‍♀️ ', response);
    //     // for multiple cloud accounts
    //     const prev = yield select(state => state.cloudAccount.scanProgressWrtCloudAccounts);
    //     const updated = {
    //       ...prev,
    //       [payload.params.id]: response.reduce((obj, item) => {
    //         obj[item.id] = item.resources;
    //         return obj;
    //       }, {}),
    //     };
    //     Object.keys(updated).forEach(eachKey => {
    //       if (!cloudAccountIds.includes(eachKey)) {
    //         delete updated[eachKey];
    //       }
    //     });

    //     const anyResources = response[0].resources === 0;

    //     if (!anyResources) {
    //       // * select from state, current cloud accounts willScan
    //       const cloudAccountsProgress = yield select(state => state.cloudAccount.progress);

    //       const newLog = {
    //         ...cloudAccountsProgress.log,
    //         [payload.params.id]: {
    //           willScan: response[1].resources,
    //           haveScan: response[0].resources,
    //         },
    //         // CA1: {willScan: [], haveScan: []}
    //       };

    //       const currentProgress = Object.keys(newLog).reduce((acc, cloudAccountId) => {
    //         const { haveScan, willScan } = newLog[cloudAccountId];

    //         // const test = newLog[cloudAccountId];
    //         const correctWillScan = willScan < 1 ? 100 : willScan;

    //         let progress = (haveScan / correctWillScan) * 90;

    //         progress = Math.round(progress + 10);

    //         return acc + progress;
    //       }, 0);

    //       yield put({
    //         type: 'saveProgress',
    //         payload: {
    //           finalProgress: currentProgress / Object.keys(newLog).length,
    //         },
    //       });

    //       yield put({
    //         type: 'save',
    //         payload: {
    //           scanProgressWrtCloudAccounts: updated,
    //         },
    //       });
    //     }
    //   }, 3000);

    //   yield takeEvery(service, throttledPush);

    //   // unsubscribe & clear when this action fired
    //   yield take(`CANCEL_WATCH/cloudAccount_${payload.params.id}`);
    //   service.close();
    // },
    *scanProgress({ payload }, { call, take, put, takeEvery, select }) {
      // create subscription
      const service = yield call(list, {
        module: 'scanProgress',
        ...payload,
      });

      // console.log('Scan progress effect');

      const { allCloudAccountsList } = yield select(state => state.cloudAccount);

      let cloudAccountIds = allCloudAccountsList.map(each => each.id);

      function* push(response) {
        // console.log('🍎 Resp from scan progress:: ', response);
        // for multiple cloud accounts
        const prev = yield select(state => state.cloudAccount.scanProgressWrtCloudAccounts);
        const updated = {
          ...prev,
          [payload.params.id]: response.reduce((obj, item) => {
            obj[item.id] = item.resources;
            return obj;
          }, {}),
        };
        Object.keys(updated).forEach(eachKey => {
          if (!cloudAccountIds.includes(eachKey)) {
            delete updated[eachKey];
          }
        });

        const anyResources = response[0].resources === 0;

        if (!anyResources) {
          // * select from state, current cloud accounts willScan
          const cloudAccountsProgress = yield select(state => state.cloudAccount.progress);

          const newLog = {
            ...cloudAccountsProgress.log,
            [payload.params.id]: {
              willScan: response[1].resources,
              haveScan: response[0].resources,
            },
            // CA1: {willScan: [], haveScan: []}
          };

          const currentProgress = Object.keys(newLog).reduce((acc, cloudAccountId) => {
            const { haveScan, willScan } = newLog[cloudAccountId];

            // const test = newLog[cloudAccountId];
            const correctWillScan = willScan < 1 ? 100 : willScan;

            let progress = (haveScan / correctWillScan) * 90;

            progress = Math.round(progress + 10);

            return acc + progress;
          }, 0);

          yield put({
            type: 'saveProgress',
            payload: {
              finalProgress: currentProgress / Object.keys(newLog).length,
            },
          });

          yield put({
            type: 'save',
            payload: {
              scanProgressWrtCloudAccounts: updated,
            },
          });
        }
      }

      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`CANCEL_WATCH/cloudAccount_${payload.params.id}`);
      service.close();
    },
    *selectRemove(payload, { put }) {
      yield put({ type: 'selectedRemove', payload });
    },
    *addSelected({ payload }, { select, put }) {
      let selected = yield select(state => state.cloudAccount.selectedCloudAccounts);

      yield put({
        type: 'saveSelected',
        payload: [...selected, payload],
      });
    },
    *removeSelected({ payload }, { select, put }) {
      let selected = yield select(state => state.cloudAccount.selectedCloudAccounts);
      let filteredArr = selected.filter(v => v.id !== payload.id);
      yield put({
        type: 'saveSelected',
        payload: filteredArr,
      });
    },

    *current({ payload }, { call, put, takeEvery, take }) {
      // const userId = yield select(state => state.user.currentUser.id); // from user model

      // clear old before new request
      yield put({
        type: `clear:${namespace}/current`,
      });

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      // create subscription
      const service = yield call(read, {
        module: namespace,
        ...payload,
        no404error: true,
      });

      function* push(response) {
        if (response.eagleEye.hasOwnProperty('url')) {
          const eagleEyeUrl = response.eagleEye.url;
          yield put({
            type: 'saveUrl',
            payload: eagleEyeUrl,
          });
        }
        if (!response) {
          yield put({
            type: 'create',
            payload: {
              ...payload,
            },
          });
          yield take(`clear:${namespace}/current`);
        } else {
          yield put({
            type: 'saveCurrent',
            payload: response,
          });
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/current`);
      service.close();
      yield put({
        type: 'clearCurrent',
      });
    },

    *create({ payload }, { call, put, select }) {
      let {
        cloudAccountIdRef,
        authKey,
        receiverKey,
        invokeURL,
        testConnectionTypes,
        scanRunningInstances,
        appName,
        principalId,
        secretExpiryDates,
      } = yield select(state => state.cloudAccount);
      yield put({
        type: 'save',
        payload: {
          testingPageTitle: 'Adding Cloud Account Now',
          loadingOperation: true,
          statusOperation: '',
          errorMessage: '',
          payloadOperation: null,
          loadingRunScanButton: true,
        },
      });
      try {
        let response;
        if (payload.data.provider === 'AWS') {
          const roles = createRoles(payload.data.credentials.roleArn?.trim());
          response = yield call(addCloudAccount, {
            testConnectionTypes,
            orgId: payload.params.orgId,
            provider: payload.data.provider,
            regions: [
              'us-east-1',
              'us-east-2',
              'us-west-1',
              'us-west-2',
              'ap-south-1',
              'ap-northeast-3',
              'ap-northeast-2',
              'ap-southeast-1',
              'ap-southeast-2',
              'ap-northeast-1',
              'ca-central-1',
              'eu-central-1',
              'eu-west-1',
              'eu-west-2',
              'eu-west-3',
              'eu-north-1',
              'sa-east-1',
            ],
            accountId: payload.data.credentials.accountId,
            accountName: payload.data.accountName,
            protectionLevel: payload.data.protectionLevel,
            externalId: payload.params.orgId,
            policy: payload.data.credentials.policy,
            roleArn: payload.data.credentials.roleArn?.trim(),
            adminRoleArn: roles.adminRoleARN?.trim(),
            execRoleArn: roles.execRoleARN?.trim(),
            userId: payload.data.userId,
            createdBy: payload.data.createdBy,
            updatedBy: payload.data.createdBy,
            createdAt: new Date(),
            updatedAt: new Date(),
            status: SCAN_STATUS.START.status,
            firstScan: true,
            isActive: true,
            cxrayInstanceFailureCount: 0,
            id: cloudAccountIdRef, // to create a new document of given id
            cloudXrayConfig: {
              cxrRegion: payload.data.credentials.region, // selected region for cloudxray
              authKey, // sender auth key for cloud xray
              receiverKey, // rec auth key for cloud xray
              invokeURL,
              scanRunningInstances,
              scanType: SCAN_TYPE.FULL_SCAN,
            },
          });
        }

        if (payload.data.provider === 'AZURE')
          response = yield call(addCloudAccount, {
            orgId: payload.params.orgId,
            provider: payload.data.provider,
            regions: [],
            accountName: payload.data.accountName,
            protectionLevel: payload.data.protectionLevel,
            appId: payload.data.credentialsAZ.AppId,
            subscriptionId: payload.data.credentialsAZ.SubscriptionID,
            tenantId: payload.data.credentialsAZ.TenantID,
            secret: payload.data.credentialsAZ.secret,
            userId: payload.data.userId,
            createdBy: payload.data.createdBy,
            updatedBy: payload.data.createdBy,
            createdAt: new Date(),
            updatedAt: new Date(),
            status: SCAN_STATUS.START.status,
            firstScan: true,
            isActive: true,
            cloudXrayConfig: {
              authKey, // sender auth key for cloud xray
              receiverKey, // rec auth key for cloud xray
              invokeURL: '',
              scanRunningInstances,
              scanType: SCAN_TYPE.FULL_SCAN,
            },
            appName,
            principalId,
            testConnectionTypes,
            secretExpiryDates,
          });

        if (payload.data.provider === 'GCP')
          response = yield call(addCloudAccount, {
            orgId: payload.params.orgId,
            accountName: payload.data.accountName,
            protectionLevel: payload.data.protectionLevel,
            provider: payload.data.provider,
            // regions: [],
            userId: payload.data.userId,
            serviceAccount: payload.data.credentialsGCP.service_account,
            createdBy: payload.data.createdBy,
            updatedBy: payload.data.createdBy,
            createdAt: new Date(),
            updatedAt: new Date(),
            status: SCAN_STATUS.START.status,
            firstScan: true,
            isActive: true,
            id: cloudAccountIdRef,
            testConnectionTypes,
            cloudXrayConfig: {
              authKey, // sender auth key for cloud xray
              receiverKey, // rec auth key for cloud xray
              invokeURL: '',
              scanRunningInstances,
              scanType: SCAN_TYPE.FULL_SCAN,
            },
          });

        if (response.data && response.data.status === 200) {
          yield put({
            type: 'save',
            payload: {
              statusOperation: 'success',
              loadingOperation: false,
              errorMessage: '',
              loadingRunScanButton: false,
              viewListButton: false,
              testingPageTitle: 'Cloud Account Added Successfully',
              disableRunScanButton: true, // disable run scan button after adding cloud account successfully
              isAccountAddedSuccessfully: true, // account is added successfully
            },
          });
        } else {
          yield put({
            type: 'save',
            payload: {
              statusOperation: 'error',
              loadingOperation: false,
              loadingRunScanButton: false,
              errorMessage: response?.data?.error,
              testingPageTitle: response?.data?.error,
              viewListButton: false,
              isAccountAddedSuccessfully: false, // account is not added successfully
            },
          });
        }
        router.push(PATH_DASHBOARD.monitoring.securityDashboard);
      } catch (error) {
        console.log('error.message: ', error.message);
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: {
            testingPageTitle: `Unexpected Error Occurred: ${error.message}`,
            loadingOperation: false,
            statusOperation: 'error',
            viewListButton: false,
            isAccountAddedSuccessfully: false, // account is not added successfully
          },
        });
      }
    },
    *update({ payload }, { call, put, select }) {
      let { email: updatedByEmail } = yield select(state => state.user.currentUser);
      // TODO: Refactor this code (PART OF BACKLOG)
      // TODO: following conditions are not readable, need to refactor it.
      if (payload.params.hasOwnProperty('type') && payload.params.type === 'interval') {
        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            interval: payload.data.interval,
            intervalStatus: payload.data.intervalStatus,
          },
        });
      }
      if (
        payload.params.hasOwnProperty('type') &&
        payload.params.type === 'edit' &&
        payload.params.provider === 'AWS'
      ) {
        yield put({
          type: 'saveUpdate',
          payload: true,
        });

        let res = yield call(update, {
          module: namespace,
          ...payload,
          data: {
            ...payload.data,
          },
        });

        if (res) {
          message.success('Cloud Account updated successfully');
        } else {
          message.error('Missing or insufficient permissions.');
        }

        yield put({
          type: 'saveUpdate',
          payload: false,
        });
      }
      if (
        payload.params.hasOwnProperty('type') &&
        payload.params.type === 'edit' &&
        payload.params.provider === 'AZURE'
      ) {
        yield put({
          type: 'saveUpdate',
          payload: true,
        });
        let res = yield call(update, {
          module: namespace,
          ...payload,
          data: payload.data,
        });

        if (res) {
          message.success('Cloud Account updated successfully');
        } else {
          message.error('Missing or insufficient permissions.');
        }

        yield put({
          type: 'saveUpdate',
          payload: false,
        });
      }
      if (
        payload.params.hasOwnProperty('type') &&
        payload.params.type === 'edit' &&
        payload.params.provider === 'GCP'
      ) {
        yield put({
          type: 'saveUpdate',
          payload: true,
        });

        let res = yield call(update, {
          module: namespace,
          ...payload,
          data: payload.data,
        });

        if (res) {
          message.success('Cloud Account updated successfully');
        } else {
          message.error('Missing or insufficient permissions.');
        }

        yield put({
          type: 'saveUpdate',
          payload: false,
        });
      }
      if (payload.params.hasOwnProperty('type') && payload.params.type === 'updateIsActiveKey') {
        yield put({
          type: 'saveUpdate',
          payload: true,
        });

        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            ...payload.data,
            updatedBy: updatedByEmail,
            updatedAt: new Date(),
          },
        });

        yield put({
          type: 'saveUpdate',
          payload: false,
        });
      }

      if (payload.params.hasOwnProperty('type') && payload.params.type === 'delete') {
        yield call(removeAccountFromAccessControl, payload);
        yield call(updateBillingBeforeDeletion, {
          orgId: payload.params.orgId,
          cloudAccountId: payload.params.id,
          isDeletingCloudAccount: true,
        });
        yield call(remove, {
          module: namespace,
          ...payload,
          data: {
            isActive: false,
          },
        });
        // check if user is on edit clout account screen then navigate user to cloud account list
        const url = new URL(window.location.href);
        const cloudAccount = url.searchParams.get('cloudaccount');
        if (cloudAccount) {
          router.push(PATH_DASHBOARD.setup.cloudAccounts);
        }
      }
      if (payload.params.hasOwnProperty('type') && payload.params.type === 'template') {
        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            eagleEye: {
              time: payload.params.time,
              status: 'born',
            },
          },
        });
      }
      if (payload.params.hasOwnProperty('type') && payload.params.type === 'eagleEye') {
        const tempUrl = yield select(state => state.cloudAccount.eagleEyeUrl);
        yield put({
          type: 'eagleEyeStatus',
          payload: 'done',
        });
        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            eagleEye: {
              status: 'pending',
              url: tempUrl,
            },
          },
        });
      }
      if (payload.params.hasOwnProperty('type') && payload.params.type === 'revive-temporary') {
        try {
          yield call(update, {
            module: namespace,
            ...payload,
            data: {
              eagleEye: {
                status: 'completed',
              },
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
      if (payload.params.hasOwnProperty('type') && payload.params.type === 'disable-eagleEye') {
        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            eagleEye: {
              status: 'disabled',
            },
          },
        });
      }
      if (
        payload.params.hasOwnProperty('type') &&
        payload.params.type === 'disable-eagleEye-temporary'
      ) {
        const tempUrl = yield select(state => state.cloudAccount.eagleEyeUrl);
        //
        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            eagleEye: {
              status: 'temporary',
              url: tempUrl || '',
            },
          },
        });
      }

      if (payload.params.hasOwnProperty('type') && payload.params.type === 'enable-eagleEye') {
        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            eagleEye: {
              status: 'verifying',
            },
          },
        });
      }
      try {
        yield put({
          type: 'save',
          payload: {
            loadingOperation: true,
            statusOperation: '',
            payloadOperation: null,
          },
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },
    *removeEagleEyeConfig({ payload }, { call }) {
      try {
        yield call(update, {
          module: namespace,
          ...payload,
          data: {
            eagleEye: firebase.firestore.FieldValue.delete(),
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    *clearSelected({ payload }, { put, select }) {
      // Unselect only current provider accounts
      if (payload && payload.tabType) {
        const selectedCloudAccounts = yield select(
          state => state.cloudAccount.selectedCloudAccounts
        );
        const fileteredAccounts = selectedCloudAccounts.filter(
          val => val.provider !== payload.tabType
        );
        yield put({
          type: 'saveSelected',
          payload: fileteredAccounts,
        });
        return;
      }

      yield put({
        type: 'saveSelected',
        payload: [],
      });
    },
    *clearAccountId(_, { put }) {
      yield put({
        type: 'clearAccountId',
      });
    },

    *selectAll({ payload }, { select, put }) {
      const allCloudAccountsList = yield select(state => state.cloudAccount.allCloudAccountsList);
      const selectedCloudAccounts = yield select(state => state.cloudAccount.selectedCloudAccounts);
      if (payload.tabType === 'aws') {
        const filterAWS = allCloudAccountsList.filter(
          val =>
            val.provider === 'AWS' &&
            (selectedCloudAccounts.length === 0 ||
              !selectedCloudAccounts.some(cloudAccount => cloudAccount.id === val.id))
        );
        yield put({
          type: 'saveSelected',
          payload: [...filterAWS, ...selectedCloudAccounts],
        });
      } else if (payload.tabType === 'azure') {
        const filterAzure = allCloudAccountsList.filter(
          val =>
            val.provider === 'AZURE' &&
            (selectedCloudAccounts.length === 0 ||
              !selectedCloudAccounts.some(cloudAccount => cloudAccount.id === val.id))
        );

        yield put({
          type: 'saveSelected',
          payload: [...filterAzure, ...selectedCloudAccounts],
        });
      } else if (payload.tabType === 'gcp') {
        const filterGcp = allCloudAccountsList.filter(
          val =>
            val.provider === 'GCP' &&
            (selectedCloudAccounts.length === 0 ||
              !selectedCloudAccounts.some(cloudAccount => cloudAccount.id === val.id))
        );

        yield put({
          type: 'saveSelected',
          payload: [...filterGcp, ...selectedCloudAccounts],
        });
      } else if (payload.tabType === 'all') {
        yield put({
          type: 'saveSelected',
          payload: allCloudAccountsList,
        });
      }
    },
    *remove({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(remove, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: `${namespace} deleted`,
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        //
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: `clear:${namespace}/list` });
      yield put({ type: `clear:${namespace}/current` });
    },
    *createCloudAccountIdAndAuthKeys({ payload }, { put }) {
      try {
        // generate random cloud account doc id
        let uid = `${Math.random()
          .toString(36)
          .substr(2, 5)}id${new Date().getTime()}`;
        // make it of length 20
        uid = uid.slice(0, 21);

        const authKey = hash
          .createHash('md5')
          .update(`${uid}${new Date().getTime()}`)
          .digest('hex');

        const receiverKey = hash
          .createHash('md5')
          .update(
            `${uid}${Math.random()
              .toString(36)
              .substr(2, 5)}${uid}${new Date().getTime()}`
          )
          .digest('hex');

        yield put({
          type: 'save',
          payload: {
            cloudAccountIdRef: uid,
            authKey,
            receiverKey,
          },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
    *testApis({ payload }, { all, call, put, select }) {
      try {
        const {
          roleArn,
          testAndSave, // flag to test and save into DB if refresh button is clicked
          protectionLevel,
          externalId,
          cxrRegion = '',
          cloudAccountId,
          isEditCloudAccount,
          provider = '',
          serviceAccount = '',
          credentials = {},
        } = payload;

        const basicConnectionsLoading = {
          authentication: { loading: true },
          resourcesAccess: { loading: true },
          misconfigurationDetection: { loading: true },
        };
        const advancedConnectionsLoading = {
          authentication: { loading: true },
          resourcesAccess: { loading: true },
          misconfigurationDetection: { loading: true },
          cloudXray: { loading: true },
          eagleEye: { loading: true },
        };
        const GCPAdvancedConnectionsLoading = {
          authentication: { loading: true },
          resourcesAccess: { loading: true },
          misconfigurationDetection: { loading: true },
          cloudXray: { loading: true },
          // eagleEye: { loading: true },
        };
        const azureAdvancedConnectionsLoading = {
          authentication: { loading: true },
          resourcesAccess: { loading: true },
          misconfigurationDetection: { loading: true },
          cloudXray: { loading: true },
          // eagleEye: { loading: true },
        };

        yield put({
          type: 'save',
          payload: {
            testConnectionTypes:
              protectionLevel === 'basic'
                ? basicConnectionsLoading
                : provider === 'gcp'
                ? GCPAdvancedConnectionsLoading
                : provider === 'azure'
                ? azureAdvancedConnectionsLoading
                : advancedConnectionsLoading,
          },
        });

        let { cloudAccountIdRef, authKey } = yield select(state => state.cloudAccount);
        let { current } = yield select(state => state.organisation);
        let { id } = current;
        let typeArray = [
          'authentication',
          'resourcesAccess',
          'misconfigurationDetection',
          'cloudXray',
          'eagleEye',
          'vpcTest',
        ];
        const params = {
          cloudAccountId: isEditCloudAccount ? cloudAccountId : cloudAccountIdRef,
          orgId: id,
          externalId,
          roleArn,
          cxrRegion,
          authorizerSecretKey: authKey ? authKey : payload?.authKey,
          isEditCloudAccount,
          serviceAccount,
          provider,
          protectionLevel,
          credentials,
        };
        let res;

        if (provider === 'gcp' || provider === 'azure') {
          if (protectionLevel.toLowerCase() === 'basic') {
            res = yield all([
              call(testCloudAccountApis, { ...params, type: typeArray[0] }),
              call(testCloudAccountApis, { ...params, type: typeArray[1] }),
              call(testCloudAccountApis, { ...params, type: typeArray[2] }),
            ]);
          } else {
            res = yield all([
              call(testCloudAccountApis, { ...params, type: typeArray[0] }),
              call(testCloudAccountApis, { ...params, type: typeArray[1] }),
              call(testCloudAccountApis, { ...params, type: typeArray[2] }),
              call(testCloudAccountApis, { ...params, type: typeArray[3] }),
              // call(testCloudAccountApis, { ...params, type: typeArray[4] }),
              // call(testCloudAccountApis, { ...params, type: typeArray[5] }),
            ]);
          }
        }

        if (provider === 'aws') {
          if (protectionLevel.toLowerCase() === 'basic') {
            res = yield all([
              call(testCloudAccountApis, { ...params, type: typeArray[0] }),
              call(testCloudAccountApis, { ...params, type: typeArray[1] }),
              call(testCloudAccountApis, { ...params, type: typeArray[2] }),
            ]);
          } else {
            res = yield all([
              call(testCloudAccountApis, { ...params, type: typeArray[0] }),
              call(testCloudAccountApis, { ...params, type: typeArray[1] }),
              call(testCloudAccountApis, { ...params, type: typeArray[2] }),
              call(testCloudAccountApis, { ...params, type: typeArray[3] }),
              call(testCloudAccountApis, { ...params, type: typeArray[4] }),
              call(testCloudAccountApis, { ...params, type: typeArray[5] }),
            ]);
          }
        }

        let obj = {};
        if (res && res.length > 0) {
          // making an object to update the loading state on testing screen
          res.forEach((eachResponse, i) => {
            obj[typeArray[i]] = {
              status: eachResponse?.data?.status || 500,
              message: eachResponse?.data?.message || 'Failed',
              loading: false,
            };
          });
        }

        let invokeURL =
          protectionLevel.toLowerCase() === 'advanced' && res[3] && res[3]?.data?.invokeURL
            ? res[3].data.invokeURL
            : 'N/A';

        // checking response
        const apiResponseStatus = Object.values(obj)
          .map(each => {
            return each.status;
          })
          .every(status => status === 200);

        // checking if first three responses are successful or not
        let shouldAddCloudAccount = res
          .slice(0, 3)
          .every(each => (each?.data === null ? false : each.data.status === 200));

        // creating title on basis of response
        const title = !shouldAddCloudAccount
          ? 'Cannot Add Cloud Account. Testing Failed'
          : shouldAddCloudAccount && !apiResponseStatus
          ? 'Errors Detected while Testing Connection'
          : 'Tested Connection Successfully';

        let saveTestApisResponse;
        // condition for refresh button
        if (testAndSave) {
          let saveApisObject = { orgId: id };
          // TODO: save the response object to database
          saveTestApisResponse = yield call(saveTestApis, {
            saveApisObject,
          });
        }
        // saving title and response object
        yield put({
          type: 'save',
          payload: {
            testConnectionTypes: obj,
            testingPageTitle: title,
            disableRunScanButton: !shouldAddCloudAccount,
            viewListButton: false,
            invokeURL,
            ...(provider === 'azure' &&
              protectionLevel.toLowerCase() === 'advanced' && {
                appName: encryptAES(res[3].data.appName),
                principalId: encryptAES(res[3].data.principalId),
              }),
            ...(provider === 'azure' && {
              secretExpiryDates: res[0]?.data?.secretExpiryDates?.[0]?.endDateTime,
            }),
          },
        });
      } catch (error) {
        console.log('error in testApis :>> ', error);
      }
    },
    *generateUrl({ payload }, { call, put, select }) {
      try {
        let paramsForGenerateUrl;
        const { type, isEditCloudAccount = false, actionStatus } = payload || {};
        const actionType = type === 'deployCft' ? 'rolesDeployment' : 'eeAndXrayDeployment';

        yield put({
          type: 'save',
          payload: {
            [`${type}ButtonLoading`]: true,
          },
        });
        // 1 . New cloud account creation
        if (actionStatus === 'creating') {
          let { id: externalId } = yield select(state => state.organisation.current);
          if (actionType === 'rolesDeployment') {
            paramsForGenerateUrl = {
              type: actionType,
              externalId,
            };
          }
          if (actionType === 'eeAndXrayDeployment') {
            let { cloudAccountIdRef, authKey, receiverKey } = yield select(
              state => state.cloudAccount
            );

            const awsAccountId = type !== 'deployCft' ? payload.roleArn.split(':')[4] : null;

            // Not encrypting the roleArn
            paramsForGenerateUrl = {
              type: actionType,
              cxrRegion: payload.region,
              roleARN: payload.roleArn.trim(),
              orgId: payload.organisationId,
              awsAccountId,
              authKey,
              receiverKey,
              externalId,
              cloudAccountId: cloudAccountIdRef,
            };
          }
        }

        // 3 . User wants to reconnect its all roles and stacks after that its CNS cloud account will be updated
        if (actionStatus === 'reconnecting') {
          if (actionType === 'rolesDeployment') {
            paramsForGenerateUrl = {
              type: actionType,
              externalId: payload.externalId,
              cxrRegion: payload.cxrRegion,
            };
          }
          if (actionType === 'eeAndXrayDeployment') {
            const awsAccountId = type !== 'deployCft' ? payload.roleArn.split(':')[4] : null;
            paramsForGenerateUrl = {
              type: actionType,
              externalId: payload.externalId,
              orgId: payload.orgId,
              cloudAccountId: payload.cloudAccountId,
              cxrRegion: payload.cxrRegion,
              roleARN: payload.roleArn,
              authKey: payload.authKey,
              receiverKey: payload.receiverKey,
              awsAccountId,
            };
          }
        }

        // 2 . moving from basic to advanced and deploying cft that is stack

        if (isEditCloudAccount) {
          paramsForGenerateUrl = {
            type: actionType,
            cloudAccountId: payload.cloudAccountId,
            externalId: payload.externalId,
            roleARN: payload.roleArn,
            cxrRegion: payload.region,
            orgId: payload.organisationId,
            authKey: payload.cloudXrayConfig.authKey,
            receiverKey: payload.cloudXrayConfig.receiverKey,
            isEditCloudAccount,
          };
        }

        let response = yield call(generateUrl, paramsForGenerateUrl);
        if (response) {
          const { buttonName, url } = response;
          openInNewTab(url);

          yield put({
            type: 'save',
            payload: {
              [`${buttonName}ButtonLoading`]: false,
              [`${buttonName}CheckboxDisabled`]: false,
            },
          });
        } else {
          yield put({
            type: 'save',
            payload: {
              [`${type}ButtonLoading`]: false,
            },
          });
        }
      } catch (e) {
        const { type } = payload;
        yield put({
          type: 'save',
          payload: {
            [`${type}ButtonLoading`]: false,
          },
        });
      }
    },

    *checkRolesExist({ payload }, { call, put }) {
      try {
        const response = yield call(checkRolesExist, payload);
        return response;
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    *runForceCleanup({ payload }, { call, put, select }) {
      try {
        const { cloudAccountData } = payload;
        if (!cloudAccountData) {
          notification.error({
            message: 'Ops! Something went Wrong',
          });
          return;
        }
        const { id, orgId, XRayScan, cloudXrayConfig, provider } = cloudAccountData;
        const { receiverKey } = cloudXrayConfig;
        const { scanId } = XRayScan;

        yield put({
          type: 'save',
          payload: {
            forceCleanupInProgress: true,
          },
        });

        const params = {
          orgId,
          cloudAccountId: id,
          scanId,
          receiverKey,
          type: 'cxray_context',
          scanStatus: 'run-force-cleanup',
          provider,
        };

        let response = yield call(initiateForceCleanup, params);
        if (response) {
          yield put({
            type: 'save',
            payload: {
              forceCleanupInProgress: false,
            },
          });
        }
      } catch (e) {
        yield put({
          type: 'save',
          payload: {
            forceCleanupInProgress: false,
          },
        });
      }
    },
  },

  reducers: {
    selectedRemove(selectedCloudAccounts) {
      selectedCloudAccounts.selectedCloudAccounts.map(number =>
        selectedCloudAccounts.selectedCloudAccounts.splice(number, 1)
      );

      return {
        ...selectedCloudAccounts,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveProgress(state, { payload }) {
      return {
        ...state,
        finalProgress: Math.round(payload.finalProgress),
      };
    },

    saveStatus(state, { payload }) {
      return {
        ...state,
        currentStatus: payload,
      };
    },
    saveSelected(state, { payload }) {
      const accountIds = payload.map(each => each.id);
      localStorage.setItem('selectedCloudAccountsIds', JSON.stringify(accountIds));
      return {
        ...state,
        selectedCloudAccounts: payload,
      };
    },
    saveScanProgress(state) {
      let { allCloudAccountsList } = state;
      let allCloudAccountsState = { ...state.progress.log };

      let willScanCount = 0;
      let haveScanCount = 0;

      // Calculate and update haveScan and willScan,
      // if there exists cloud accounts with status 'running-scan'
      // and scan progress has been fetched for all accounts.
      if (
        state.allCloudAccountsList.filter(val => val.status === 'running-scan').length &&
        Object.keys(allCloudAccountsState).length >=
          allCloudAccountsList.filter(val => val.status === 'running-scan').length
      ) {
        // Loop through each cloud account
        Object.keys(allCloudAccountsState).forEach(cloudAccountId => {
          let cloudAccount = allCloudAccountsState[cloudAccountId];

          // Check if any cloud account willScan is zero,
          // then set willScanCount = 0 and break the loop.
          if (parseInt(cloudAccount.willScan) === 0) {
            willScanCount = 0;
            haveScanCount = 0;
            return true;
          }

          // calculate haveScan and willScan
          haveScanCount = haveScanCount + cloudAccount.haveScan;
          willScanCount = willScanCount + cloudAccount.willScan;
        });
      }

      return {
        ...state,
        willScan: willScanCount,
        haveScan: haveScanCount,
      };
    },
    clearScanProgress(state) {
      return {
        ...state,
        currentProgress: 0,
        willScanArray: [],
        haveScanArray: [],
        currentStatus: 'completed-scan',
        finalProgress: 0,
        willScan: 0,
        haveScan: 0,
        progress: {
          log: {},
        },
      };
    },
    saveCurrentId(state, { payload }) {
      return {
        ...state,
        currentAccId: payload,
      };
    },
    saveAllAccounts(state, { payload }) {
      return {
        ...state,
        allCloudAccountsList: payload,
        loadingList: false,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        allCloudAccountsList: payload.cloudAccountList,
        activeCloudAccountsList: payload.activeCloudAccountsList,
        loadingList: false,
        allCloudAccountsListLoaded: true,
      };
    },
    clearList(state) {
      return {
        ...state,
        allCloudAccountsListLoaded: false,
        loadingList: false,
        allCloudAccountsList: [],
        selectedCloudAccounts: [],
      };
    },
    saveUrl(state, { payload }) {
      return {
        ...state,
        eagleEyeUrl: payload,
        loadingCurrent: false,
      };
    },
    eagleEyeStatus(state, { payload }) {
      return {
        ...state,
        eagleEyeStatus: payload,
        loadingCurrent: false,
      };
    },

    saveUpdate(state, { payload }) {
      return {
        ...state,
        updating: payload,
      };
    },
    clearCurrent(state) {
      return {
        ...state,
        current: null,
        loadingCurrent: false,
        payloadOperation: null,
      };
    },
    saveNavigateStatus(state) {
      return {
        ...state,
        navigateStatus: true,
      };
    },
    clearAccountId(state) {
      return {
        ...state,
        navigateStatus: false,
        currentAccId: '',
      };
    },
  },
};

function openInNewTab(url) {
  dynamicallyCreateAnchorAndNavigate(url);
}
