import { firestore } from '../utils/firebase';
import config from '../../config';

export const adminOrg = config.masterOrg;

export const SignatureMetaData = async () => {
  try {
    const orgId = localStorage.getItem('orgId');

    if (!orgId) return;

    // default signatures for all users
    let ref = await firestore.collection('Signatures').get();
    let { docs } = ref;
    let allSignatures = docs.map(report => report.data());

    // custom signatures from my custom org
    // let customSignaturesRef = await firestore
    //   .collection('Organizations')
    //   .doc(orgId)
    //   .collection('customSignatures')
    //   .get();

    // let customSignatures = customSignaturesRef;

    // if (customSignatures.length > 0) {
    //   customSignatures = customSignatures.map(sig => {
    //     return {
    //       ...sig.data(),
    //       id: sig.id,
    //     };
    //   });
    //   allSignatures = allSignatures.concat(customSignatures);
    // }

    if (adminOrg !== orgId) {
      let masterSignaturesRef = await firestore
        .collection('Organizations')
        .doc(adminOrg)
        .collection('customSignatures')
        .where('mode', '==', 'prod')
        .get();

      // master signatures from master org
      let masterSignatures = masterSignaturesRef.docs;
      if (masterSignatures.length > 0) {
        masterSignatures = masterSignatures.map(sig => {
          return {
            ...sig.data(),
            id: sig.id,
          };
        });
        allSignatures = allSignatures.concat(masterSignatures);
      }
    }

    return allSignatures;
  } catch (error) {
    return [];
  }
};

export const getSignaturesMetaData = async () => {
  try {
    const orgId = localStorage.getItem('orgId');

    if (!orgId) return;

    const sigRef = firestore.collection('Signatures');

    const masterSignaturesRef = firestore
      .collection('Organizations')
      .doc(adminOrg)
      .collection('customSignatures')
      .where('mode', '==', 'prod');

    const result = await fetchData(sigRef, masterSignaturesRef);
    return result;
  } catch (error) {
    return {};
  }
};

async function fetchData(sigRef, masterSignaturesRef) {
  try {
    // Execute both queries in parallel using Promise.all()
    const [sigQuerySnapshot, masterSignaturesQuerySnapshot] = await Promise.all([
      sigRef.get(),
      masterSignaturesRef.get(),
    ]);

    // Convert query snapshots into objects with key-value pairs
    const sigData = {};
    sigQuerySnapshot.forEach(doc => {
      sigData[doc.id] = doc.data();
    });

    const masterSignaturesData = {};
    masterSignaturesQuerySnapshot.forEach(doc => {
      masterSignaturesData[doc.id] = doc.data();
    });

    // Merge data from both queries into one object
    const mergedData = {
      ...sigData,
      ...masterSignaturesData,
    };

    // Now return, mergedData contains the merged data from both queries as an object with key-value pairs
    return mergedData;
  } catch (error) {
    console.error(error.message); // Handle any errors that occur during the fetch operation
    throw error;
  }
}
