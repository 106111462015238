import { getAllRegulationsService } from '@/services/regulations';
import _ from 'lodash';

const namespace = 'regulations';
export default {
  namespace,
  state: {
    allRegulations: [],
    securityRegulation: [],
    complianceRegulation: [],
    customRegulations: [],
  },

  effects: {
    *getAllRegulations({ payload }, { call, put }) {
      const res = yield call(getAllRegulationsService, payload);

      yield put({
        type: 'save',
        payload: res,
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      // remove duplicate by key - lodash
      payload = _.uniqBy(payload, 'reportId');
      return {
        ...state,
        allRegulations: payload,
        securityRegulations: payload.filter(v => v.type === 'security'),
        complianceRegulations: payload.filter(v => v.type === 'compliance'),
        customRegulations: payload.filter(v => v.type === 'customRegulation'),
      };
    },
  },
};
