export default {
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',
  'component.miniProgress.tooltipDefault': 'Target value',
  'component.Tag.Storage': 'Storage Account',
  'component.Tag.SA': 'Azure Storage Account',
  'component.Tag.Network Security Group': 'Network Security Group',
  'component.Tag.CF': 'CloudFront',
  'component.Tag.Monitor (Alerts)': 'Monitor (Alerts)',
  'component.Tag.KV': 'Key Vault',
  'component.Tag.Elasticsearch': 'AWS Elastic search',
  'component.Tag.SQLDB': 'SQL Database',
  'component.Tag.SQL Server': 'SQL Server',
  'component.Tag.SQLSVR': 'SQL Server',
  'component.Tag.Project': 'Project',
  'component.Tag.ServiceAccountKey': 'ServiceAccountKey',
  'component.Tag.Address': 'Address',
  'component.Tag.Disk': 'Disk',
  'component.Tag.Subnetwork': 'Subnetwork',
  'component.Tag.InstanceTemplate': 'Instance Template',
  'component.Tag.Firewall': 'Firewall',
  'component.Tag.Route': 'Route',
  'component.Tag.undefined': 'N/A',
  'component.Tag.Virtual Machine': 'Virtual Machine',
  // 'component.Tag.VM': 'Virtual Machine',
  'component.Tag.CLT': 'Cloud Trail',
  'component.Tag.CW': 'Cloud Watch',
  'component.Tag.CloudFunction': 'Cloud Function',
  'component.Tag.VPC': 'Vitual Private Cloud',
  'component.Tag.CWEVENTS': 'CloudWatch Events',
  'component.Tag.DDB': 'DynamoDB',
  'component.Tag.WEBAPPS': 'WEBAPPS',
  'component.Tag.EBS': 'Elastic Block Store',
  'component.Tag.EC2': 'AWS Elastic Compute Cloud (EC2)',
  'component.Tag.ELB': 'Elastic Load Balancing',
  'component.Tag.IAM': 'Identity Access Management',
  'component.Tag.KMS': 'Key Management Service',
  'component.Tag.RDS': 'Relational Database Service',
  'component.Tag.ALA': 'Monitor Alerts (ALA)',
  'component.Tag.S3': 'Simple Storage Service',
  'component.Tag.PLNK': 'Private Link',
  'component.Tag.Private Link': 'Private Link',
  'component.Tag.SBNET': 'Subnets',
  'component.Tag.RedShift': 'AWS RedShift',
  'component.Tag.logProfiles': 'Azure Log profiles',
  'component.Tag.LogBucket': 'Audit - Log',
  'component.Tag.Cloud Function': 'Cloud Function',
  // 'component.Tag.VPC': 'VPC',
  'component.Tag.CloudFunction': 'Cloud Function',
  'component.Tag.Firestore': 'Firestore',
  'component.Tag.Networking ': 'Networking',
  'component.Tag.Virtual Machines': 'Virtual Machines',
  'component.Tag.Storage': 'Storage',
  'component.Tag.CWALM': 'Cloud Watch Alarm',
  'component.Tag.CWEVENTS': 'Cloud Watch Events',
  'component.Tag.CloudWatch': 'Cloud Watch Alarm',
  'component.Tag.Active directory': 'Active directory',
  'component.Tag.AD': 'Active directory',
  'component.Tag.SQL Database': 'SQL Database',
  'component.Tag.LAMBDA': 'LAMBDA',
  'component.Tag.WEBAPPS': 'WEBAPPS',
  'component.Tag.Key Vault': 'Key Vault',
  'component.Tag.NSG': 'Network Security Group',
  'component.Tag.DynamoDB': 'DynamoDB',
  'component.Tag.DDB': 'DynamoDB',
  'component.Tag.EBS': 'Volumes',
  'component.Tag.EIP': 'Elastic IPs',
  'component.Tag.EC2:RI': 'EC2:Reserve Instance',
  'component.Tag.EC2:Volume': 'Volumes',
  'component.Tag.EC2:ElasticIP': 'Elastic IPs',
  'component.Tag.EC2:Instance': 'Instances',
  'component.Tag.EC2:SnapShot': 'SnapShot',
  'component.Tag.EC2:SecurityGroups': 'Security Groups',
  'component.Tag.ServiceAccount': 'Service Account',
  'component.Tag.SQL': 'SQL',
  'component.Tag.storage': 'Storage',
  'component.Tag.Network': 'Network',
  'component.Tag.Networking': 'Network',
  'component.Tag.Instance': 'GCP VM',
  'component.Tag.sql': 'SQL',
  'component.Tag.SQL': 'GCP SQL',
  'component.Tag.VM': 'Azure Virtual Machine',
  'component.Tag.cloudkms': 'Cloud Key Management Service',
  'component.Tag.ECR': 'AWS ECR',
  'component.Tag.ECS': 'AWS ECS',
  'component.Tag.EKS': 'AWS EKS',
  'component.Tag.AG': 'AWS API Gateway',
  'component.Tag.GLUE': 'AWS Glue',
};
