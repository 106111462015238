export const dashboardConfigs = {
  security: {
    // SecurityPosture: {
    //   widgetId: 'SecurityPosture',
    //   widgetName: 'Security Posture',
    //   widgetDescription: 'Security Posture',
    // },
    Inventory: {
      widgetId: 'Inventory',
      widgetName: 'Inventory',
      widgetDescription: 'Inventory',
    },
    Network: {
      widgetId: 'Network',
      widgetName: 'Network',
      widgetDescription: 'Network',
    },
    Compute: {
      widgetId: 'Compute',
      widgetName: 'Compute',
      widgetDescription: 'Compute',
    },
    Storage: {
      widgetId: 'Storage',
      widgetName: 'Storage',
      widgetDescription: 'Storage',
    },
    IAM: {
      widgetId: 'IAM',
      widgetName: 'IAM',
      widgetDescription: 'IAM',
    },
    InventorySummary: {
      widgetId: 'InventorySummary',
      widgetName: 'Inventory Summary',
      widgetDescription: 'Inventory Summary',
    },
    RisksSummary: {
      widgetId: 'RisksSummary',
      widgetName: 'Risks Summary',
      widgetDescription: 'Risks Summary',
    },
    CriticalRisksV2: {
      widgetId: 'CriticalRisksV2',
      widgetName: 'Critical Risks',
      widgetDescription: 'Critical Risks',
    },
    RisksByRegion: {
      widgetId: 'RisksByRegion',
      widgetName: 'Risks by Regions',
      widgetDescription: 'Risks by Region',
    },
    PublicallyExposedResources: {
      widgetId: 'PublicallyExposedResources',
      widgetName: 'Publicly Accessible Resources',
      widgetDescription: 'List of Cloud Resources which are open to the Public',
    },
    MostCriticalResources: {
      widgetId: 'MostCriticalResources',
      widgetName: 'Most Critical Resources',
      widgetDescription: 'Most Critical Resources',
    },
    RisksByGroup: {
      widgetId: 'RisksByGroup',
      widgetName: 'Risks by Groups',
      widgetDescription: 'Risks by Group',
    },
  },
  compliance: {
    ComplianceOverview: {
      widgetId: 'ComplianceOverview',
      widgetName: 'Compliance Overview',
      widgetDescription: 'Compliance Overview',
    },
    SecurityGroups: {
      widgetId: 'SecurityGroups',
      widgetName: 'Security Groups',
      widgetDescription: 'Security Groups',
    },
    Regulations: {
      widgetId: 'Regulations',
      widgetName: 'Regulations',
      widgetDescription: 'Regulations',
    },
    ComplianceStandards: {
      widgetId: 'ComplianceStandards',
      widgetName: 'Compliance Standards',
      widgetDescription: 'Compliance Standards',
    },
  },
  health: {
    RisksBySeverity: {
      widgetId: 'RisksBySeverity',
      widgetName: 'Risks by Severity',
      widgetDescription: 'Risks by Severity',
      type: 'health',
    },
    RisksByCloudAccount: {
      widgetId: 'RisksByCloudAccount',
      widgetName: 'Risks by Cloud Account',
      widgetDescription: 'Risks by Cloud Account',
      type: 'health',
    },
    RisksByCategory: {
      widgetId: 'RisksByCategory',
      widgetName: 'Risks by Category',
      widgetDescription: 'Risks by Category',
      type: 'health',
    },
  },
};

export const getWidgetType = widgetId => {
  let widgetType = '';
  let healthIds = ['RisksBySeverity', 'RisksByCloudAccount', 'RisksByCategory'];
  if (healthIds.includes(widgetId)) {
    widgetType = 'trends';
  }
  return widgetType;
};
// 1- Widget should have tooltip containing description of current widget.
// 2- Widget should handle three states.
//     a- Loading state
//     b- Empty state
//     c- Actual Data
// 3- Each widget should show its history documents (dropdown)
// 4- Each widget should maintain its own state document and history document.
// 5- Adding new widget should be as simple as possible. (for any other developer).
// 6- widget should show relevant widget status (i.e updating)
// 7- Widgets List:
//     i - Security :
//         a- Security Posture
//         b- Inventory
//         c- Network
//         d- Compute
//         e- Storage
//         f- IAM
//         g- Risks by Regions
//         h- Most Critical Resources
//         i- Risks by Groups

//     ii- Compliance :
//         a- Compliance Overview
//         b- Security Groups
//         c- Regulations
//         d- Compliance Standards

//     iii- Health :
//         a- Risks by Severity
//         b- Risks by CloudAccount
//         c- Risks by Category

// Wiidgets should be draggable
// Widgets should have a legend
// Widgets should have description / tooltips
// Should have the following widgets
// Risks By Regions
// Risks by Categories
// Risks by Service
// Most Critical Resources

// Widget should not take longer than 10 seconds to generate
// Should allow easy creation of new widgets for developers (boilerplate, modular etc)
