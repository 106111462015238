import moment from 'moment';
import hash from 'crypto';
import filterResources from '@/components/UniversalDrawer/helpers/filterResources';
import { DateOfDays } from '@/components/UniversalDrawer/components/Resource/Utils/DateOfDays';
// @services
import { list, read } from '@/services/firestore';
import { getExplorerHistory } from '@/services/universalDrawer';
// @utils
import { truncateString } from '@/utils/utils';
import addSuppressedFlags from '@/utils/addSuppressedFlags';
import _ from 'lodash';
import { firestore } from '@/utils/firebase';
let namespace = 'universalDrawer';
const CNS_DEFINED_SIG = ['AWS', 'AZU', 'GCP', 'FBS', 'AZ'];
export const CNS_CATEGORIES = {
  CVE: 'osvulnerabilities',
  MALWARE: 'malware',
  THIRD_PARTY_RISK: 'thirdPartyRisks',
};
const sampleSignaruteMetaData = {
  RemediationSteps: '',
  category: '',
  cloud: '',
  description: '',
  function: '',
  group: '',
  messageFail: '',
  messagePass: '',
  name: '',
  pageDetail: '',
  risk: '',
  service: '',
  signatureName: '',
};

export default {
  namespace,
  state: {
    drawerVisible: false,
    drawerType: null, // remove this out of here, make other arrangements for sig and res drawer
    loading: false,
    healthLoading: false,
    selectedDate: null,
    currentSignatureMetaData: sampleSignaruteMetaData,
    appliedFilters: [],
    filteredResources: null, // for resources explorer
    filteredRisks: [], // for single resource drawer,
    filteredResourcesOfSingleRisk: null, // resources for signature drawer
    cveInstanceDetails: null,
    riskByDate: [],
    counts: {
      totalResources: 0,
      totalRisks: 0,
      totalSuppressedRisks: 0,
    },
    alertCloudAcc: [],
    notificationResourcesData: {},
    currentRiskData: {},
    currentResourceData: {},
    dataMalformed: false,
  },

  effects: {
    *newFilters({ payload }, { put, select }) {
      const { filters, drawerType, orgId } = payload;

      if (filters.length === 0) {
        yield put({
          type: 'save',
          payload: {
            appliedFilters: filters,
            drawerType,
            loading: true,
          },
        });

        return;
      }
      const filterType = filters[0].type;

      let resourceId;
      if (filterType === 'resourceId') {
        resourceId = filters[0].value[0];
      }
      // get and assign required data to variables from models
      const prevFilters = yield select(state => state.universalDrawer.appliedFilters);

      let cloudAccounts = filters.find(v => v.type === 'cloudAccounts');

      if (filterType === 'resourceId') {
        cloudAccounts = cloudAccounts && cloudAccounts.value[0];
      } else {
        cloudAccounts = cloudAccounts && cloudAccounts.value;
      }
      let prevCloudAccounts = prevFilters.find(v => v.type === 'cloudAccounts');

      prevCloudAccounts = prevCloudAccounts && prevCloudAccounts.value;

      yield put({
        type: 'save',
        payload: {
          appliedFilters: filters,
          drawerType,
          loading: true,
        },
      });

      let exit = false;
      // proceed only if cloud Accounts have changed in new filter
      if (prevCloudAccounts && cloudAccounts && prevCloudAccounts.length === cloudAccounts.length) {
        exit = true;
      }

      if (JSON.stringify(prevFilters) !== JSON.stringify(filters)) {
        exit = false;
      }

      if (exit) {
        return;
      }
      // Get data according to type of drawer

      switch (drawerType?.toLowerCase()) {
        // we need resources for table in resourcedrawer & signaturedrawer
        case 'resourcedrawer':
          break;
        case 'signaturedrawer':
          break;
        // we only need risks for table in single resource drawer (showing only its own risks)
        case 'singleresource':
          yield put({
            type: 'universalDrawer/getResourcesOfSingleRisk',
            payload: {
              orgId,
              cloudAccountId: cloudAccounts,
              resourceId,
            },
          });
          break;
        default:
          break;
      }
    },

    // universal drawer
    *getFilteredResources({ payload }, { call, put, select }) {
      console.log('getFilteredResources called from universal:>> ');
      const { showSuppress } = payload;

      yield put({
        type: 'save',
        payload: {
          loading: true,
          filteredResources: null,
        },
      });
      try {
        // get current data from other models

        const organisation = yield select(state => state.organisation.current);
        const orgId = organisation.id;

        const signaturesMetadata = yield select(state => state.signatureMetaData.metadata);

        const filters = yield select(state => state.universalDrawer.appliedFilters);

        const path = yield select(state => state.organisation.spRisks); // Get suppresion path
        let resourcesReport = yield* getResourcesData(filters, orgId, call, select);

        //IMP

        // attach sigature metadata to all risks inside resource - babar

        // let filters = yield select(state => state.universalDrawer.appliedFilters);
        let filtersForCloudAccount = filters.filter(v => v.type === 'cloudAccounts');
        let currentCloudAccount = filtersForCloudAccount[0].value;

        resourcesReport = resourcesReport.filter(
          item => currentCloudAccount.includes(item.cloudAccountId) // === 'iqYEgPmriw5ntrCFbknx'
        );

        //IMP

        let resourcesWithRisks = resourcesReport.map(eachResource => {
          // add other risk obj in sig obj
          // let collectRisk = {
          //   ...(eachResource.signatures
          //     ? { ...eachResource.signatures }
          //     : { ...eachResource.risks }),
          // };

          let riskArray = [];

          Object.keys(eachResource.signatures).map(v => {
            let currentSig = eachResource.signatures[v];
            riskArray.push({ ...currentSig, sigName: v, signatureName: v });
          });

          //
          if (eachResource.hasOwnProperty('thirdPartyRisks')) {
            let { thirdPartyRisks } = eachResource;
            Object.keys(thirdPartyRisks).map(k => {
              let currentType = thirdPartyRisks[k];

              Object.keys(currentType).map(sigName => {
                let currentSig = currentType[sigName];
                // yahn pe sara data add krna hai for risk helper
                let obj = {
                  ...currentSig,
                  riskType: 'thirdPartyRisks',
                  sigName: sigName,
                  signatureName: sigName,
                };
                // collectRisk[sigName] = obj;
                riskArray.push(obj);
              });
            });
          }

          const riskFromArray = riskArray.map(currentSig => {
            let metaData = signaturesMetadata.filter(v =>
              v.hasOwnProperty('id')
                ? v.id === currentSig.signatureName
                : v.name === currentSig.signatureName
            );
            metaData = metaData[0];
            //

            if (!metaData && !currentSig.hasOwnProperty('riskType')) {
              return;
            } else {
            }
            //

            let currentObj = {
              ...currentSig,
              cloudAccountId: eachResource.cloudAccountId,
              resourceName: eachResource.name,
              resourceId: eachResource.id,
              // cloudXRayRisks: eachResource?.cloudXRayRisks,
              // signatureName: signatureId,
            };
            if (metaData) {
              return { ...currentObj, ...metaData };
            }
            return currentObj;
          });

          return {
            ...eachResource,
            risks: riskFromArray.filter(Boolean), // risksWithMetaData, //
          };
        });
        // Filter resources with the given filters in payload.filters

        // get resource status
        resourcesWithRisks = resourcesWithRisks.map(v => {
          let resourceStatus = 'pass';
          const { risks } = v;
          risks.map(r => {
            if (r.status === 'fail') {
              resourceStatus = 'fail';
            }
          });
          let progressObj = {
            high: risks.filter(
              v => v.status?.toLowerCase() === 'fail' && v.risk?.toLowerCase() === 'high'
            ).length,
            low: risks.filter(
              v => v.status?.toLowerCase() === 'fail' && v.risk?.toLowerCase() === 'low'
            ).length,
            medium: risks.filter(
              v => v.status?.toLowerCase() === 'fail' && v.risk?.toLowerCase() === 'medium'
            ).length,
            passed: risks.filter(v => v.status?.toLowerCase() === 'pass').length,
            total: risks.length,
          };
          v.status = resourceStatus;
          v.progressObj = progressObj;

          return v;
        });

        let filteredResources = filterResources(resourcesWithRisks, filters);

        // pass all resources through suppression helper
        let suppressedResources = addSuppressedFlags(filteredResources, path, '');

        // toatl resource count with given filters
        let totalResources = suppressedResources.length;

        // toatl risks count with given filters
        // let totalRisks = suppressedResources.reduce((a, b) => a + b.risks.length, 0);
        let totalRisks = suppressedResources.reduce((a, b) => {
          return a + b.risks.length;
          // for only fail count
          // return a + b.risks.filter(v => v.status?.toLowerCase() === 'fail').length;
        }, 0);

        // toatl suppressed risks count with given filters
        let totalSuppressedRisks = suppressedResources.reduce((acc, val) => {
          let risksLength = val.risks.filter(v => v.suppress === true).length;
          return acc + risksLength;
        }, 0);

        // if showSuppress is false (default is false) then filter out resources which are false
        if (!showSuppress) {
          suppressedResources = suppressedResources.filter(v => v.suppress === false);
        }
        // filter failed resources
        suppressedResources = suppressedResources.sort((a, b) =>
          a.risks.filter(val => val.status?.toLowerCase() === 'fail').length >
          b.risks.filter(val => val.status?.toLowerCase() === 'fail').length
            ? -1
            : 1
        );

        let newSuppressed = suppressedResources;
        // .slice(limit, limit + 10);
        // newSuppressed.map(v =>

        // Save modified data to universalDrawer model

        yield put({
          type: 'save',
          payload: {
            loading: false,
            filteredResources: newSuppressed,
            counts: {
              totalResources: totalResources,
              totalRisks: totalRisks,
              totalSuppressedRisks: totalSuppressedRisks,
            },
          },
        });
      } catch (error) {
        console.log('Error in getFiltered Resources', error);
        yield put({
          type: 'save',
          payload: {
            loading: false,
            filteredResources: null,
          },
        });
      }
    },

    // resource drawer
    *getSingleResourceRisks({ payload }, { put, call, select }) {
      // get single resource risks

      try {
        // clear filteredRisks here
        // yield put({
        //   type: 'clearSingleResource',
        // });
        yield put({
          type: 'save',
          payload: {
            //filteredRisks: [], // ye kholne ke baad nhi chal raha @todo
            loading: true,
          },
        });

        // get current data from other models
        const organisation = yield select(state => state.organisation.current);
        const orgId = organisation.id;
        let signaturesMetadata = yield select(state => state.signatureMetaData.metadata);
        const path = yield select(state => state.organisation.spRisks); // Get suppresion path
        let filters = yield select(state => state.universalDrawer.appliedFilters);
        let cloudAccounts = filters.find(v => v.type === 'cloudAccounts');
        let resourceFromFilter = filters.find(v => v.type === 'resourceId');
        let signaturesFromFilter = filters.find(v => v.type === 'signatures');
        let resourceId = resourceFromFilter.value[0];
        let cloudAccountId = cloudAccounts.value[0];
        let resourcesReport = yield* getResourcesData(filters, orgId, call, select);
        let singleResult = yield call(read, {
          module: 'resources',
          stream: false,
          params: {
            orgId,
            cloudAccountId,
            id: hash
              .createHash('md5')
              .update(resourceId)
              .digest('hex'),
          },
        });
        const rsID = singleResult.id;
        const cveData = resourcesReport.filter(c => c.id === rsID)[0]?.cloudXRayRisks;
        // TODO Merge Filtered to CVE Data
        let singleResource = [singleResult];
        // convert osVulnerabilities & malwares data to array of objects
        let osVulnerabilities = [];
        let malwares = [];
        let cveInstanceDetailsObj = {};
        if (cveData) {
          // * saving cveInstanceDetailsObj for cveInstanceDetails about the failed cve
          cveInstanceDetailsObj = {
            failureReason: `This resource was not scanned for Malware & OS Vulnerabilities. Error: ${cveData.failureReason}`,
            scanStatus: cveData.scanStatus,
            isFailed:
              cveData.scanStatus === 'fail' || cveData.scanStatus === 'failed' ? true : false,
          };
          osVulnerabilities = Object.keys(cveData.CVEs).map(key => {
            const newObj = {
              ...cveData.CVEs[key],
              ...cveData.CVEsSummary,
              type: 'OS Vulnerabilities',
            };
            return newObj;
          });
          malwares = Object.keys(cveData.malwares).map(key => {
            const newObj = {
              ...cveData.malwares[key],
              ...cveData.malwaresSummary,
              type: 'Malware',
            };
            return newObj;
          });
        }
        // singleResource = { ...singleResource[0], ...cloudxray };
        let risksReport = yield select(state => state.risks.report); // get risks from all selected cloud accounts
        if (singleResource && singleResource[0]) {
          singleResource[0].risks = []; // resourcesWithRisks;
        }
        // pass all resources through suppression helper
        let suppressedResources = addSuppressedFlags(singleResource, path, '');
        // Save modified data to universalDrawer model
        payload = {
          cloudAccounts: singleResource[0].cloudAccountId, // 'iqYEgPmriw5ntrCFbknx',
          historyType: Date.now(), //1608490799999, //
          orgId: orgId, // 'owais_micxw',
          userId: singleResource[0].userId, // 'xKTygJ89CNeMuGzAAvFGOQMcO3g1',
          resourceId: singleResource[0].id, // '3.139.192.158',
        };
        // call your service here
        let historyTimeFromFilter = filters.find(v => v.type === 'history');
        let historyTime = historyTimeFromFilter?.value[0] || Date.now(); //1608490799999, //;
        let finalRisks;

        let cspmRisks = [];
        let thirdPartyRisks = [];

        // ~~~~~~~~ (CSPM) Logic starts ~~~~~~~~
        let riskParams = {
          module: 'risks',
          orderBy: ['createdAt', 'desc'],
          conditions: [['createdAt', '<=', new Date(historyTime)]],
          stream: false, // one time request
          params: {
            orgId: payload.orgId,
            limit: 1,
            cloudAccountId: payload.cloudAccounts,
            resourceId: hash
              .createHash('md5')
              .update(payload.resourceId)
              .digest('hex'),
          },
        };
        const cspmRisksRes = yield call(list, riskParams);
        // TODO: Remove this and use the lodash logic here
        let customSignaturesMeta = yield select(state => state.customSignatures.list);
        if (customSignaturesMeta) {
          signaturesMetadata = signaturesMetadata.concat(customSignaturesMeta);
        }

        // for (let i in riskRes.docs) {
        //   let sig = riskRes.docs[i].signatures;
        //   Object.keys(sig).map(signatureId => {
        //     let metaData = signaturesMetadata.find(e => e.name === signatureId);
        //     if (!metaData) {
        //       return;
        //     }
        //     metaData.signatureName = metaData.name;
        //     let obj = {
        //       ...sig[signatureId],
        //       ...metaData,
        //       cloudAccountId: singleResource[0].cloudAccountId,
        //       resourceName: singleResource[0].name,
        //       name: singleResource[0].name,
        //       resourceId: singleResource[0].id,
        //       suppress: false,
        //     };
        //     finalRisks.push(obj);
        //   });
        // }

        // ::::::::::::;

        // * MISCONFIGURATION RISKS
        cspmRisks = _.flatMap(cspmRisksRes.docs, doc => {
          const signatures = doc.signatures;

          return Object.keys(signatures)
            .map(signatureId => {
              const metaData = signaturesMetadata.find(e => e.name === signatureId);
              if (!metaData) {
                return null;
              }

              const resource = singleResource[0];
              const enhancedMetaData = {
                ...metaData,
                signatureName: metaData.name,
              };

              return {
                ...signatures[signatureId],
                ...enhancedMetaData,
                cloudAccountId: resource.cloudAccountId,
                resourceName: resource.name,
                name: resource.name,
                resourceId: resource.id,
                suppress: false,
              };
            })
            .filter(item => item !== null); // Filter out null values
        });

        // ~~~~~~~~ CSPM Logic ends ~~~~~~~~

        // ::::::::::::;
        // ~~~~~~~~ (TPR) Logic starts ~~~~~~~~
        let thirdPartyRisksParams = {
          module: 'thirdPartyRisks',
          stream: false, // one time request
          params: {
            orgId: payload.orgId,
            cloudAccountId: payload.cloudAccounts,
            resourceId: hash
              .createHash('md5')
              .update(payload.resourceId)
              .digest('hex'),
          },
        };
        const thirdPartyRisksRes = yield call(list, thirdPartyRisksParams);
        // for (let i in res.docs) {
        //   let riskDoc = res.docs[i];
        //   let subParams = {
        //     module: 'thirdPartyRisksSubCollections',
        //     orderBy: ['createdAt', 'desc'],
        //     conditions: [['createdAt', '<=', new Date(historyTime)]],
        //     stream: false, // one time request
        //     params: {
        //       limit: 1,
        //       orgId: payload.orgId,
        //       cloudAccountId: payload.cloudAccounts,
        //       resourceId: hash
        //         .createHash('md5')
        //         .update(payload.resourceId)
        //         .digest('hex'),
        //       subId: riskDoc.id,
        //     },
        //   };
        //   const subRes = yield call(list, subParams);
        //   const finalRisks = _.flatMap(subRes.docs, doc => {
        //     const signatures = Object.values(doc.signatures);
        //     return signatures.map(signature => ({
        //       ...signature,
        //       signatureName: signature.signature,
        //       name: signature.signature,
        //       category: 'thirdPartyRisks',
        //       // suppress: false // Uncomment if needed
        //     }));
        //   });

        //   console.log('finalRisks :>> ', finalRisks);
        // }

        // ::::::::::::::::

        // Loop through each response and extract the risk from nested collection
        for (const riskDoc of thirdPartyRisksRes.docs) {
          const resourceIdHashed = hash
            .createHash('md5')
            .update(payload.resourceId)
            .digest('hex');

          // Nested collection params
          const subParams = {
            module: 'thirdPartyRisksSubCollections',
            orderBy: ['createdAt', 'desc'],
            conditions: [['createdAt', '<=', new Date(historyTime)]],
            stream: false, // one-time request
            params: {
              limit: 1,
              orgId: payload.orgId,
              cloudAccountId: payload.cloudAccounts,
              resourceId: resourceIdHashed,
              subId: riskDoc.id,
            },
          };
          const subRes = yield call(list, subParams);

          thirdPartyRisks = _.flatMap(subRes.docs, doc => {
            return Object.values(doc.signatures).map(signature => ({
              ...signature,
              signatureName: signature.signature,
              name: signature.signature,
              category: 'thirdPartyRisks',
              // suppress: false // Uncomment if needed
            }));
          });
        }
        // ~~~~~~~~ TPR Logic end ~~~~~~~~

        // ::::::::::::::::
        suppressedResources[0].risks = suppressedResources[0].risk = [
          ...cspmRisks,
          ...thirdPartyRisks,
        ];
        suppressedResources = addSuppressedFlags(suppressedResources, path, '');
        if (suppressedResources[0].risks.length > 0) {
          suppressedResources[0].malwares = malwares ? malwares : null;
          suppressedResources[0].osVulnerabilities = osVulnerabilities ? osVulnerabilities : null;
        }
        yield put({
          type: 'save',
          payload: {
            filteredRisks: suppressedResources, // finalRisks, //   [res.data], //
            cveInstanceDetails: cveInstanceDetailsObj,
            loading: false,
          },
        });
      } catch (error) {
        console.log('error in getSingleResourceRisks :>> ', error);
        yield put({
          type: 'save',
          payload: {
            filteredRisks: [],
            loading: false,
            dataMalformed: true,
          },
        });
      }
    },

    //Weekwise Signature
    *clearWeekRisk({ payload }, { put, call, select }) {
      console.log('clearWeekRisk called from universal');
      yield put({
        type: 'save',
        payload: {
          riskByDate: [],
          loading: false,
        },
      });
    },

    *getResourceRisksByWeek(_, { put, call, select }) {
      yield put({
        type: 'save',
        payload: {
          riskByDate: [],
          healthLoading: true,
        },
      });

      const organisation = yield select(state => state.organisation.current);
      const filters = yield select(state => state.universalDrawer.appliedFilters);
      const cloudAccounts = filters.find(v => v.type === 'cloudAccounts');
      const resourceFromFilter = filters.find(v => v.type === 'resourceId');
      const signaturesFromFilter = filters.find(v => v.type === 'signatures');
      const resourceId = resourceFromFilter.value[0];
      const cloudAccountId = cloudAccounts.value[0];

      // create days for last week
      const daysdata = DateOfDays(moment(), '7');
      const weeklyData = [];

      try {
        // * get single resource risks
        const singleResource = yield call(read, {
          module: 'resources',
          stream: false,
          params: {
            orgId: organisation.id,
            cloudAccountId,
            id: hash
              .createHash('md5')
              .update(resourceId)
              .digest('hex'),
          },
        });
        const payload = {
          cloudAccounts: singleResource.cloudAccountId,
          historyType: Date.now(),
          orgId: organisation.id,
          userId: singleResource.userId,
          resourceId: singleResource.id,
        };
        // ? Loop over every day of last week and get risk count
        for (const val of daysdata) {
          if (val) {
            const historyTime = val.date; //1608490799999, //;
            const riskParams = {
              module: 'risks',
              orderBy: ['createdAt', 'desc'],
              conditions: [['createdAt', '<=', new Date(historyTime)]],
              stream: false, // one time request
              params: {
                orgId: payload.orgId,
                limit: 1,
                cloudAccountId: payload.cloudAccounts,
                resourceId: hash
                  .createHash('md5')
                  .update(payload.resourceId)
                  .digest('hex'),
              },
            };
            const riskRes = yield call(list, riskParams);
            //  ? Get CVE and Malware Data
            let cveRisksCount = 0;
            let malwareRisksCount = 0;
            let countRisk = 0;
            const cxrRiskParams = {
              module: 'cloudXRayRisks',
              orderBy: ['createdAt', 'desc'],
              conditions: [['createdAt', '<=', new Date(historyTime)]],
              stream: false, // one time request
              params: {
                orgId: payload.orgId,
                limit: 1,
                cloudAccountId: payload.cloudAccounts,
                resourceId: hash
                  .createHash('md5')
                  .update(payload.resourceId)
                  .digest('hex'),
              },
            };
            const cxrRiskRes = yield call(list, cxrRiskParams);
            // * Calculating cloud xray risk, (all cves and malware all meant to be fail )
            if (cxrRiskRes.docs.length > 0) {
              const cveData = cxrRiskRes.docs[0];
              cveRisksCount = cveData.CVEs ? Object.keys(cveData.CVEs).length : 0;
              malwareRisksCount = cveData.malwares ? Object.keys(cveData.malwares).length : 0;
            }
            // * Calculating Risk Count only for fail status
            if (riskRes.docs.length > 0) {
              const currentRiskSignatures = riskRes.docs[0].signatures;
              countRisk = currentRiskSignatures
                ? Object.keys(currentRiskSignatures).filter(
                    val => currentRiskSignatures[val].status?.toLowerCase() === 'fail'
                  ).length
                : 0;
            }
            // ~~ Check Final Risk And Filtered the Risk Count
            const totalRiskFoundByWeek = cveRisksCount + malwareRisksCount + countRisk;
            weeklyData.push({
              date: historyTime,
              risk: totalRiskFoundByWeek,
            });
          }
        }
      } catch (error) {
        console.log('error in resource week :>> ', error);
        yield put({
          type: 'save',
          payload: {
            riskByDate: [],
            healthLoading: false,
            dataMalformed: true,
          },
        });
      }

      // check if risk for everyday is 0 then we need to save empty array in state
      const isEveryDayRiskZero = weeklyData.every(val => val.risk === 0);

      // * Save weekly data to universalDrawer model
      yield put({
        type: 'save',
        payload: {
          riskByDate: isEveryDayRiskZero ? [] : weeklyData,
          healthLoading: false,
        },
      });
    },

    // signature drawer
    *getResourcesOfSingleRisk({ payload }, { call, put, select, takeEvery, take }) {
      try {
        yield put({
          type: 'save',
          payload: {
            loading: true,
          },
        });

        const filters = yield select(state => state.universalDrawer.appliedFilters);
        const orgId = yield select(state => state.organisation.current.id);
        // const cloudID = payload.cloudAccountId;

        const filterTypeIndex =
          filters.length && filters.findIndex(each => each.type === 'signatureName');

        let sigName;

        if (filters[filterTypeIndex]?.type === 'signatureName') {
          sigName = filters[filterTypeIndex].value[0];
          sigName = sigName.replace(' ', '+');

          // TODO remove this condition later when done working with thirdPartyRisks
          const byPassConditionForNow = true;

          // Check if the signature is from the following cloud services
          if (CNS_DEFINED_SIG.some(i => sigName.includes(i)) || byPassConditionForNow) {
            // CNS Signatures List
            const { signatures: signaturesMetaData } = yield select(
              state => state.signatureMetaData
            );
            // Custom Signatures List
            const listsOfCustomSigs = yield select(state => state.customSignatures.list) || null;
            const customSignaturesMetaData = _.keyBy(listsOfCustomSigs, 'id');

            //?  Merge signatrues objects, preferring the signaturesMetaData if the customSignaturesMetaData is null or empty
            const mergedSignaturesObject = _.isEmpty(customSignaturesMetaData)
              ? _.merge({}, signaturesMetaData)
              : _.merge(signaturesMetaData, customSignaturesMetaData);

            // * Signature Meta Data for CNS related signatures
            let currentSigMetaData = mergedSignaturesObject[sigName];

            const path = yield select(state => state.organisation.spRisks); // Get suppression path

            let resourcesReport = yield* getResourcesData(filters, orgId, call, select);

            const currentRisk = yield select(state => state.universalDrawer.currentRiskData);
            function getResourcesOfCurrentSignature(allResources) {
              // Create a deep copy of resourcesReport to avoid reference issues
              const updatedResourcesReport = _.cloneDeep(allResources);

              _.forEach(updatedResourcesReport, (resource, index) => {
                // * 1. Check if resource has signatures and it is not empty and the current signature lies in cns dfined sig provider names
                if (
                  _.has(resource, 'signatures') &&
                  CNS_DEFINED_SIG.some(i => sigName.includes(i))
                ) {
                  let processObj = {
                    high: 0,
                    low: 0,
                    medium: 0,
                    passed: 0,
                    total: _.size(resource.signatures),
                  };
                  const updatedRisksObject = _.mapValues(resource.signatures, (value, key) => {
                    switch (!_.isEmpty(key) && mergedSignaturesObject?.[key]?.risk?.toLowerCase()) {
                      case 'high':
                        processObj.high++;
                        break;
                      case 'low':
                        processObj.low++;
                        break;
                      case 'medium':
                        processObj.medium++;
                        break;
                      default:
                        // Handle other risk levels if necessary
                        break;
                    }
                    value.status?.toLowerCase() === 'pass' && processObj.passed++;
                    return {
                      ...value,
                      ...mergedSignaturesObject?.[key],
                      resourceId: resource.id,
                      resourceName: resource.name,
                      signatureName: key,
                      suppress: false,
                    };
                  });

                  if (!_.isEmpty(updatedRisksObject[sigName])) {
                    // Add resource status
                    resource.status = updatedRisksObject?.[sigName]?.status;

                    // Update resource with risks
                    resource.risks = [updatedRisksObject[sigName]];

                    // Update processObj with current risks statuses
                    resource.processObj = processObj;
                  }
                }

                // * 2. Check if resource has cloudXRayRisks.CVEs and it is not empty
                if (
                  _.has(resource, 'cloudXRayRisks') &&
                  !_.isEmpty(resource.cloudXRayRisks.CVEs) &&
                  CNS_CATEGORIES.CVE?.toLowerCase() === currentRisk.category?.toLowerCase()
                ) {
                  const cvesData = resource.cloudXRayRisks.CVEs;

                  const cveRiskMetaData = {
                    ...currentRisk,
                    RemediationSteps: currentRisk.title || currentRisk.messageFail,
                    cloud: currentRisk.cloud || currentRisk.provider,
                    description:
                      (currentRisk.messageFail || currentRisk.title.length) > 100
                        ? truncateString(currentRisk.messageFail || currentRisk.title.length, 100)
                        : currentRisk.messageFail || currentRisk.title.length,
                    group: currentRisk.service,
                    name: currentRisk.name,
                    pageDetail: currentRisk?.description,
                    risk: currentRisk.risk || currentRisk.cvss3Severity,
                    signatureName: currentRisk.cveID,
                    riskType: 'osVulnerability',
                    status: 'fail',
                    _cveId: currentRisk.name,
                  };

                  let processObj = {
                    high: 0,
                    low: 0,
                    medium: 0,
                    passed: 0,
                    critical: 0,
                    total: _.size(cvesData),
                  };

                  const updatedRisksObject = _.mapValues(cvesData, (value, key) => {
                    if (key === currentRisk.name || key === currentRisk.cveID) {
                      switch (value.cvss3Severity?.toLowerCase()) {
                        case 'critical':
                          processObj.critical++;
                          break;
                        case 'high':
                          processObj.high++;
                          break;
                        case 'low':
                          processObj.low++;
                          break;
                        case 'medium':
                          processObj.medium++;
                          break;
                        default:
                          // Handle other risk levels if necessary
                          break;
                      }
                    }
                  });

                  const updatedResource = {
                    ...resource,
                    subscriptionId: resource.properties?.IamInstanceProfile?.Arn,
                    userId:
                      resource.provider?.toLowerCase() === 'azure'
                        ? resource?.properties?.networkProfile.networkInterfaces[0].id
                        : resource?.properties?.NetworkInterfaces?.[0]?.OwnerId,
                    risks: [],
                    suppress: false,
                    processObj,
                    resourceId: resource.id,
                    resourceName: resource.name,
                    signatureName: sigName,
                    status: 'fail',
                    statusMessage: '',
                    resourceStatus: 'fail',
                  };

                  // Update currentSigMetaData of cveRiskMetaData same as risk details
                  currentSigMetaData = cveRiskMetaData;

                  // Update the resource object at the current index
                  updatedResourcesReport[index] = updatedResource;
                }

                // * 3. Check if resource has cloudXRayRisks.malwares and it is not empty
                if (
                  _.has(resource, 'cloudXRayRisks') &&
                  !_.isEmpty(resource.cloudXRayRisks.malwares) &&
                  CNS_CATEGORIES.MALWARE?.toLowerCase() === currentRisk.category?.toLowerCase() &&
                  resource.cloudXRayRisks.malwares.hasOwnProperty(currentRisk.name)
                ) {
                  const malwaresData = resource.cloudXRayRisks.malwares;

                  const malwareRiskMetaData = {
                    ...currentRisk,
                    RemediationSteps: currentRisk.title,
                    cloud: currentRisk.cloud || currentRisk.provider,
                    description:
                      currentRisk?.title?.length || currentRisk?.messageFail?.length > 100
                        ? truncateString(currentRisk?.title || currentRisk?.messageFail, 100)
                        : currentRisk.title || currentRisk?.messageFail,
                    group: currentRisk.service,
                    name: currentRisk.malwareId,
                    pageDetail: currentRisk.title,
                    risk: currentRisk.risk || currentRisk.severity,
                    signatureName: currentRisk.malwareId,
                    riskType: 'malware',
                    status: 'fail',
                    function: '',
                    messagePass: '',
                  };

                  let processObj = {
                    high: 0,
                    low: 0,
                    medium: 0,
                    passed: 0,
                    critical: 0,
                    total: _.size(malwaresData),
                  };

                  const updatedRisksObject = _.mapValues(malwaresData, (value, key) => {
                    if (key === currentRisk.name || key === currentRisk.malwareId) {
                      switch (value.severity?.toLowerCase()) {
                        case 'critical':
                          processObj.critical++;
                          break;
                        case 'high':
                          processObj.high++;
                          break;
                        case 'low':
                          processObj.low++;
                          break;
                        case 'medium':
                          processObj.medium++;
                          break;
                        default:
                          // Handle other risk levels if necessary
                          break;
                      }
                    }
                  });

                  const updatedResource = {
                    ...resource,
                    subscriptionId: resource.properties?.IamInstanceProfile?.Arn,
                    userId:
                      resource.provider?.toLowerCase() === 'azure'
                        ? resource?.properties?.networkProfile.networkInterfaces[0].id
                        : resource?.properties?.NetworkInterfaces?.[0]?.OwnerId,
                    risks: [],
                    suppress: false,
                    processObj,
                    resourceId: resource.id,
                    resourceName: resource.name,
                    signatureName: sigName,
                    status: 'fail',
                    statusMessage: '',
                    resourceStatus: 'fail',
                  };

                  // Update currentSigMetaData of cveRiskMetaData same as risk details
                  currentSigMetaData = malwareRiskMetaData;

                  // Update the resource object at the current index
                  updatedResourcesReport[index] = updatedResource;
                }

                // * 4. Check if resource has thirdPartyRisks and it is not empty
                if (
                  _.has(resource, 'thirdPartyRisks') &&
                  CNS_CATEGORIES.THIRD_PARTY_RISK?.toLowerCase() ===
                    currentRisk.category?.toLowerCase()
                ) {
                  const thirdPartyRisksData = resource.thirdPartyRisks;
                  let processObj = {
                    high: 0,
                    low: 0,
                    medium: 0,
                    passed: 0,
                    total: _.size(thirdPartyRisksData),
                  };
                  const updatedRisksObject = _.mapValues(thirdPartyRisksData, (value, key) => {
                    value.status?.toLowerCase() === 'pass' && processObj.passed++;
                    switch (value.risk?.toLowerCase()) {
                      case 'high':
                        processObj.high++;
                        break;
                      case 'low':
                        processObj.low++;
                        break;
                      case 'medium':
                        processObj.medium++;
                        break;
                      default:
                        // Handle other risk levels if necessary
                        break;
                    }
                    return {
                      ...value,
                      cloudAccountId: resource.cloudAccountId,
                      resourceId: resource.id,
                      resourceName: resource.name,
                      riskType: 'thirdPartyRisks',
                      signatureName: key,
                      name: key,
                      suppress: false,
                    };
                  });

                  // Add resource status statsriskType : "third"
                  resource.status = updatedRisksObject?.[sigName]?.status;
                  resource.statusMessage = updatedRisksObject?.[sigName]?.statusMessage;

                  // Update resource with risks
                  resource.risks = [updatedRisksObject[sigName]];

                  // Update processObj with current risks statuses
                  resource.processObj = processObj;

                  // Update currentSigMetaData of thirdPartyRisks same as risk details
                  if (updatedRisksObject[sigName]) {
                    currentSigMetaData = updatedRisksObject[sigName];
                  }
                }
              });

              return updatedResourcesReport;
            }

            // Call the function to get the updated resourcesReport
            const updatedResourcesReport = yield getResourcesOfCurrentSignature(resourcesReport);

            yield put({
              type: 'saveSigName',
              payload: {
                currentSigMetaData,
              },
            });

            let updatedResourceOfRisks;

            // * Filter risks for CNS signatures and ThirdPartyRisks
            if (
              CNS_DEFINED_SIG.some(i => sigName.includes(i)) ||
              CNS_CATEGORIES.THIRD_PARTY_RISK?.toLowerCase() === currentRisk.category?.toLowerCase()
            ) {
              updatedResourceOfRisks = _.filter(updatedResourcesReport, val => {
                return _.some(val.risks, { name: sigName });
              });
            }

            // * Filter resources for CVES and Malwares
            if (
              CNS_CATEGORIES.CVE?.toLowerCase() === currentRisk.category?.toLowerCase() ||
              CNS_CATEGORIES.MALWARE?.toLowerCase() === currentRisk.category?.toLowerCase()
            ) {
              updatedResourceOfRisks = _.filter(updatedResourcesReport, { signatureName: sigName });
            }

            // attach signature metadata to all risks inside resource
            let thirdPartyMetadata = [];
            try {
              // Pass all resources through suppression helper
              const suppressedResources = addSuppressedFlags(updatedResourceOfRisks, path, '');

              const updatedResourceWithSuppression = filterResources(suppressedResources, filters);

              // Function to get the last seen timestamp from a resource
              const getResourceLastSeen = updatedResourceWithSuppression =>
                _.get(
                  updatedResourceWithSuppression,
                  'risks.find(risk => risk?.signatureName === sigName)?.lastSeen._seconds',
                  null
                );

              // Sort resources by last seen using lodash
              const resourcesOfSingleRisk = _.sortBy(
                updatedResourceWithSuppression,
                resource => -getResourceLastSeen(resource)
              );

              yield put({
                type: 'save',
                payload: {
                  filteredResourcesOfSingleRisk: resourcesOfSingleRisk,
                  loading: false,
                },
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            // // Pattern for All affected resources
            // // we can use the risk data save while select on riskCard,
            // //? following risk data has been save when use click on riskCard so we can use that instead of taking all the risk
            // //? better optimization
            // const currentRisk = yield select(state => state.universalDrawer.currentRiskData);
            // if (!currentRisk) return;
            // const service = yield call(list, {
            //   module: 'resourcesReport',
            //   params: {
            //     orgId,
            //     cloudAccountId: cloudID,
            //   },
            // });
            // let affectedResources = [];
            // // eslint-disable-next-line no-inner-declarations
            // function* push(response) {
            //   try {
            //     if (response) {
            //       const allResources = response.reduce((acc, current) => {
            //         return [...acc, ...current.resources];
            //       }, []);
            //       allResources.map(eachResponse => {
            //         // checks if there's an object named cloudXrayRisks in the document
            //         if (
            //           eachResponse.hasOwnProperty('cloudXRayRisks') &&
            //           (Object.keys(eachResponse.cloudXRayRisks.CVEs).includes(
            //             currentRisk.name || currentRisk.cveID
            //           ) ||
            //             Object.keys(eachResponse.cloudXRayRisks.malwares).includes(
            //               currentRisk.name || currentRisk.malwareId
            //             ))
            //         ) {
            //           affectedResources.push(eachResponse);
            //         }
            //       });
            //     }
            //     let objCurrentRisk;
            //     let objFilteredResources;
            //     const failResourceLength = affectedResources.length;
            //     if (sigName.includes('CVE')) {
            //       objCurrentRisk = {
            //         RemediationSteps: currentRisk.title,
            //         category: currentRisk.category,
            //         cloud: currentRisk.cloud || currentRisk.provider,
            //         description:
            //           currentRisk.title.length > 100
            //             ? truncateString(currentRisk.title, 100)
            //             : currentRisk.title,
            //         group: currentRisk.service,
            //         messageFail: currentRisk.messageFail,
            //         name: currentRisk.cveID,
            //         pageDetail: currentRisk?.description,
            //         risk: currentRisk.risk || currentRisk.cvss3Severity,
            //         service: currentRisk.service,
            //         AffectedPackages: currentRisk.AffectedPackages,
            //         cvssVector: currentRisk.cvssVector,
            //         cvssScore: currentRisk.cvssScore,
            //         cvss3Severity: currentRisk.cvss3Severity,
            //         signatureName: currentRisk.cveID,
            //         cvss2Score: currentRisk.cvss2Score,
            //         riskType: 'osVulnerability',
            //         status: 'fail',
            //       };
            //       objFilteredResources = affectedResources.map(val => {
            //         return {
            //           cloudAccountId: val.cloudAccountId,
            //           cloudXRayRisks: val.cloudXRayRisks,
            //           createdAt: val.createdAt,
            //           creationOfRisk: val.creationOfRisk,
            //           id: val.id,
            //           instenceState: val.instenceState,
            //           name: val.name,
            //           ownerId: val.ownerId,
            //           provider: val.provider,
            //           region: val.region,
            //           resourceCreateInCloudnosys: val.resourceCreateInCloudnosys,
            //           resourceCreatedAt: val.resourceCreatedAt,
            //           resourceId: val.resourceId,
            //           service: val.service,
            //           signatures: val.signatures,
            //           subscriptionId: val.properties?.IamInstanceProfile?.Arn,
            //           tags: val.tags,
            //           userId:
            //             val.provider?.toLowerCase() === 'azure'
            //               ? val?.properties?.networkProfile.networkInterfaces[0].id
            //               : val?.properties?.NetworkInterfaces[0]?.OwnerId,
            //           vpcId: val.vpcId,
            //           risks: [],
            //           suppress: false,
            //           progressObj: {
            //             high: failResourceLength,
            //             low: 0,
            //             medium: 0,
            //             passed: 0,
            //             total: failResourceLength,
            //           },
            //           status: 'fail',
            //           statusMessage: '',
            //           resourceStatus: 'fail',
            //         };
            //       });
            //     } else {
            //       objCurrentRisk = {
            //         RemediationSteps: currentRisk.title,
            //         category: currentRisk.category,
            //         cloud: currentRisk.cloud || currentRisk.provider,
            //         description: currentRisk.title,
            //         function: '',
            //         group: currentRisk.service,
            //         messageFail: currentRisk.messageFail,
            //         messagePass: '',
            //         name: currentRisk.malwareId,
            //         pageDetail: currentRisk.title,
            //         risk: currentRisk.risk || currentRisk.severity,
            //         service: currentRisk.service,
            //         signatureName: currentRisk.malwareId,
            //         riskType: 'malware',
            //         status: 'fail',
            //       };
            //       objFilteredResources = affectedResources.map(val => {
            //         return {
            //           cloudAccountId: val.cloudAccountId,
            //           cloudXRayRisks: val.cloudXRayRisks,
            //           createdAt: val.createdAt,
            //           creationOfRisk: val.creationOfRisk,
            //           id: val.id,
            //           instenceState: val.instenceState,
            //           name: val.name,
            //           ownerId: val.ownerId,
            //           provider: val.provider,
            //           region: val.region,
            //           resourceCreateInCloudnosys: val.resourceCreateInCloudnosys,
            //           resourceCreatedAt: val.resourceCreatedAt,
            //           resourceId: val.resourceId,
            //           service: val.service,
            //           signatures: val.signatures,
            //           subscriptionId: val.properties?.IamInstanceProfile?.Arn
            //             ? val.properties.IamInstanceProfile.Arn
            //             : '',
            //           tags: val.tags,
            //           userId: val.properties.NetworkInterfaces[0].OwnerId,
            //           vpcId: val.vpcId,
            //           risks: [],
            //           suppress: false,
            //           progressObj: {
            //             high: failResourceLength,
            //             low: 0,
            //             medium: 0,
            //             passed: 0,
            //             total: failResourceLength,
            //           },
            //           status: 'fail',
            //           statusMessage: '',
            //           resourceStatus: 'fail',
            //         };
            //       });
            //     }
            //     yield put({
            //       type: 'saveSigName',
            //       payload: {
            //         currentSigMetaData: [objCurrentRisk],
            //       },
            //     });
            //     const prevFilteredResources = yield select(
            //       state => state.universalDrawer.prevFilteredResources
            //     );
            //     const selectedCloudAccounts = yield select(
            //       state => state.cloudAccount.selectedCloudAccounts
            //     );
            //     let cloudAccountIds = selectedCloudAccounts.reduce((acc, item) => {
            //       return [...acc, item.id];
            //     }, []);
            //     const newResources = {
            //       ...prevFilteredResources,
            //       [payload.cloudAccountId]: objFilteredResources,
            //     };
            //     Object.keys(newResources).forEach(eachKey => {
            //       if (!cloudAccountIds.includes(eachKey)) {
            //         delete newResources[eachKey];
            //       }
            //     });
            //     objFilteredResources = [].concat(...Object.values(newResources));
            //     yield put({
            //       type: 'save',
            //       payload: {
            //         filteredResourcesOfSingleRisk: objFilteredResources,
            //         prevFilteredResources: newResources,
            //         loading: false,
            //       },
            //     });
            //   } catch (error) {
            //     console.log('Error from inner function:', error);
            //     throw error;
            //   }
            // }
            // yield takeEvery(service, push);
          }
        }
      } catch (error) {
        console.log('Error from getResourcesOfSingleRisk ~ ', error);
        yield put({
          type: 'save',
          payload: {
            filteredResourcesOfSingleRisk: [],
            prevFilteredResources: [],
            appliedFilters: [],
            loading: false,
          },
        });
      }
    },

    *closeDrawer({ _ }, { select, put }) {
      // const drawerType = yield select(state => state.universalDrawer);
      // console.log('drawerType :>> ', drawerType);

      yield put({
        type: 'toggleDrawer',
        payload: false,
        appliedFilters: [],
        prevFilteredResources: [],
        currentSigMetaData: [],
        filteredResourcesOfSingleRisk: [],
        currentSignatureMetaData: sampleSignaruteMetaData,
        dataMalformed: false,
      });
    },

    *saveDrawer({ payload }, { put }) {
      yield put({
        type: 'saveDrawerType',
        payload: { ...payload },
      });
    },

    *openDrawer({ _ }, { put }) {
      yield put({
        type: 'toggleDrawer',
        payload: true,
      });
    },
  },

  reducers: {
    toggleDrawer(state, { payload }) {
      return {
        ...state,
        drawerVisible: payload,
        filteredRisks: [],
        filteredResourcesOfSingleRisk: [],
        dataMalformed: false,
        currentSignatureMetaData: sampleSignaruteMetaData,
        // currentRiskData: payload == false ? {} : state.currentRiskData,
      };
    },
    saveDrawerType(state, { payload }) {
      return {
        ...state,
        drawerType: payload.drawerType,
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveSigName(state, { payload }) {
      return {
        ...state,
        currentSignatureMetaData: payload.currentSigMetaData,
      };
    },
    clearFilteredResources(state, { _ }) {
      return {
        ...state,
        filteredResources: [],
        filteredResourcesOfSingleRisk: [],
        currentSignatureMetaData: sampleSignaruteMetaData,
      };
    },
    saveSelectedDate(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear(state, { _ }) {
      // console.log('clear called');
      return {
        ...state,
        enableDelay: false,
        // drawerVisible: false,
        // drawerType: null, // remove this out of here, make other arrangements for sig and res drawer
        loading: false,
        selectedDate: null,
        currentSignatureMetaData: sampleSignaruteMetaData,
        // appliedFilters: [],
        filteredResources: [], // for resources explorer
        filteredRisks: [], // for single resource drawer,
        filteredResourcesOfSingleRisk: [], // resources for signature drawer
        counts: {
          totalResources: 0,
          totalRisks: 0,
          totalSuppressedRisks: 0,
        },
        alertCloudAcc: [],
        notificationResourcesData: {},
        currentRiskData: {},
      };
    },
  },
};

// helper for getting resource from db || callAble function
function* getResourcesData(filters, orgId, call, select) {
  try {
    let resourcesReport = [];
    let isHistoryExist = filters.find(v => v.type === 'history');
    if (isHistoryExist) {
      let filterDate = isHistoryExist.value[0];
      filterDate = moment(new Date(filterDate)).format('YYYY-MM-DD');
      let userId = yield select(state => state.user.currentUser?.uid);

      let todayDate = moment(new Date()).format('YYYY-MM-DD');

      if (todayDate === filterDate) {
        // *  Fetching today reports data only so we are taking from state
        resourcesReport = yield select(state => state.resourcesReport.report);
      } else {
        let historyParams = {
          cloudAccounts: filters.find(v => v.type === 'cloudAccounts').value,
          // historyType: new Date().getTime(),
          userId,
          historyType: isHistoryExist.value[0],
          orgId,
        };

        let historyRes = yield call(getExplorerHistory, historyParams);

        resourcesReport = historyRes.data;
      }
    } else {
      // *  Fetching today reports data only so we are taking from state
      resourcesReport = yield select(state => state.resourcesReport.report);
    }

    return resourcesReport;
  } catch (error) {
    console.log(error);
  }
}
