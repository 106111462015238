export default {
  'menu.Monitoring': 'Monitoring',
  'menu.organisation': 'Organisation',
  'menu.Security & Compliance.Custom Policy & Regulation': 'Custom Policy & Regulation',
  'menu.invite': 'Invite',
  'app.settings.menuMap.access': 'Access Control',
  'app.settings.menuMap.integration': 'Integration',
  'app.settings.menuMap.billing': 'Billing',
  'app.settings.menuMap.roles': 'Roles',
  'app.settings.menuMap.risk': 'Suppression Rules',
  'menu.Monitoring.health': 'Health Dashboard',
  'menu.Monitoring.compliance': 'Compliance Dashboard',
  'menu.Monitoring.Inventory': 'Inventory',
  'menu.Cloud Accounts': 'Cloud Accounts',
  'menu.Cloud Accounts.Add Cloud Account': 'Add Cloud Account',
  'menu.Cloud Accounts.Edit Cloud Account': 'Edit Cloud Account',
  'menu.Security & Compliance.Add': 'Add Custom Policy',
  'menu.Security & Compliance.Edit': 'Edit Custom Policy',
  'menu.Custom Signatures.Add CustomSignature': 'Add CustomSignature',
  'menu.Custom Signatures.Edit CustomSignature': 'Edit CustomSignature',
  'menu.Login with SAML': 'Login with SAML',
  // '':'',
  'menu.Security & Compliance': 'Security & Compliance',
  'menu.Security & Compliance.Security': 'Security',
  'menu.Security & Compliance.Compliance': 'Compliance',
  'menu.Security & Compliance.Custom Policies': 'Custom Policies',
  'menu.Signatures': 'Signatures',
  'menu.Settings': 'Settings',
  'menu.Custom Signatures': 'Custom Signatures',
  'menu.Playbooks': 'Playbooks',
  'menu.Monitoring.Inventory': 'Inventory',
  'menu.Settings.Integration': 'Integration',
  'menu.forgetpassword': 'forgetpassword',
  'menu.changePassword': 'changePassword',
  'menu.home': 'Home',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register.result': 'Register Result',
  'menu.Monitoring.dashboard': 'Security Dashboard',
  'menu.Monitoring.Security Dashboard': 'Security Dashboard',
  'menu.Monitoring.Compliance Dashboard': 'Compliance Dashboard',
  'menu.Monitoring.Health Dashboard': 'Health Dashboard',
  'menu.Devlogin': 'Devlogin',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.form': 'Form',
  'menu.form.basicform': 'Basic Form',
  'menu.form.stepform': 'Step Form',
  'menu.form.stepform.info': 'Step Form(write transfer information)',
  'menu.form.stepform.confirm': 'Step Form(confirm transfer information)',
  'menu.form.stepform.result': 'Step Form(finished)',
  'menu.form.advancedform': 'Advanced Form',
  'menu.list': 'List',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.cloudiq': 'CloudIQ',
};
