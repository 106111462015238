import { list } from '@/services/firestore';
import { getHistoricalDate } from '../utils/getHistoricalDate';

const namespace = 'risks';
export default {
  namespace,
  state: {
    loadingReport: false,
    report: [],
  },
  effects: {
    // payload must have { cloudAccountId, resourceId, time } if no time, then get latest report
    *getReport({ payload }, { call, put, select }) {
      try {
        const { cloudAccountId, orgId, resourceId } = payload;

        const filters = yield select(state => state.universalDrawer.appliedFilters);

        let isHistoryExist = filters.find(v => v.type === 'history');

        let params = {};
        if (isHistoryExist) {
          let time = isHistoryExist.value[0];

          let timeRes = getHistoricalDate(new Date(time));
          let { endTime } = timeRes;
          endTime = new Date(endTime);
          // startTime = new Date(startTime);

          params = {
            module: namespace,
            orderBy: ['createdAt', 'desc'],
            stream: false, // one time request
            // where conditions [['key', '==', 'value'],...]
            conditions: [['createdAt', '<=', endTime]],
            params: {
              orgId,
              limit: 1,
              cloudAccountId,
              resourceId,
            },
          };
        } else {
          params = {
            module: namespace,
            orderBy: ['createdAt', 'desc'],
            stream: false, // one time request
            params: {
              orgId,
              limit: 1,
              cloudAccountId,
              resourceId,
            },
          };
        }
        // clear old before new request
        yield put({
          type: 'clearReport',
          //  payload: { loadingReport: true },
        });
        let combinedRisks = [];

        // make db call here via services
        const report = yield call(list, params);

        if (report && !report.empty) {
          combinedRisks.push(...report.docs);
        }
        yield put({
          type: 'saveReport',
          payload: combinedRisks,
        });

        // attach metadata risks here?

        // yield put({
        //   type: 'saveReport',
        //   payload: report && report.length > 0 ? report[0] : null,
        // });
      } catch (error) {
        console.log(error);
      }
    },
    *clear(_, { put }) {
      yield put({
        type: 'clearReport',
        // payload: { loadingReport: true },
      });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveReport(state, { payload }) {
      return {
        ...state,
        report: payload,
        loadingReport: false,
      };
    },
    clearReport(state) {
      return {
        ...state,
        report: [],
        loadingReport: true,
      };
    },
  },
};
