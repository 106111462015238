import React, { Component, Fragment } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { connect } from 'dva';
import { Card, Table, Spin, Tag, Select, Switch, Modal, Tooltip, notification } from 'antd';
import { userCan } from '@/utils/authority';
import InviteForm from './InviteForm';
import MultiAccountSelector from './MultiAccountSelector';

const { confirm } = Modal;
const { Option } = Select;

export const AC_STATUS = {
  LOGIN_PENDING: 'login pending',
  INVITE_PENDING: 'response pending',
  INVITE_ACCEPTED: 'invite accepted',
  INVITE_DECLINED: 'invite declined',
  USER_DELETED: 'user deleted',
};

export const AC_STATUS_COLOR = {
  [AC_STATUS.LOGIN_PENDING]: 'orange',
  [AC_STATUS.INVITE_PENDING]: 'purple',
  'invite pending': 'purple',
  [AC_STATUS.INVITE_ACCEPTED]: 'green',
  [AC_STATUS.INVITE_DECLINED]: 'red',
};

@connect(({ user, organisation, accessControl, cloudAccount }) => ({
  organisation: organisation.current,
  currentUser: user.currentUser,
  allCloudAccountsList: cloudAccount.allCloudAccountsList,
  authority: user.authority,
  packageExpiry: organisation.packageExpiry,
  accessControlList: accessControl.list,
  loadingList: accessControl.loadingList,
  loadingCreate: accessControl.loadingCreate,
}))
class Organisation extends Component {
  componentDidMount() {
    this.fetchInitial();
  }

  componentDidUpdate(prevProps) {
    if (this.props.organisation !== prevProps.organisation) {
      this.fetchInitial();
    }
  }

  // componentWillUnmount() {
  //   const { dispatch } = this.props;

  //   dispatch({
  //     type: 'clear:accessControl/list',
  //   });
  // }

  onChange(id, checked) {
    const { dispatch, organisation } = this.props;
    dispatch({
      type: 'accessControl/update',
      payload: {
        params: {
          orgId: organisation.id,
          id,
        },
        data: {
          isActive: checked,
        },
      },
    });
  }

  handleAddInvite = (email, host) => {
    const { dispatch, organisation, currentUser } = this.props;

    // check if email already exists in the access control list
    const exists = this.props.accessControlList.some(item => item.email === email);

    if (exists) {
      return notification.error({
        message: 'This user is already present in the list shown.',
      });
    }

    dispatch({
      type: 'accessControl/create',
      payload: {
        data: {
          orgId: organisation.id,
          orgName: organisation.name,
          email,
          inviter: currentUser.name ? currentUser.name : 'Someone',
          inviterId: currentUser.id,
          host,
        },
      },
    });
  };

  handleRoleChange = (id, role, module = 'accessControl') => {
    const { dispatch, organisation } = this.props;

    dispatch({
      type: `${module}/update`,
      payload: {
        params: {
          orgId: organisation.id,
          id,
        },
        data: { role },
      },
    });
  };

  isMe = role => {
    let currentUserEmail = this.props.currentUser.email;
    if (currentUserEmail === role.email) {
      return true;
    } else {
      return false;
    }
  };

  disableOwner = role => {
    const { authority } = this.props;

    if (role === 'Owner') {
      if (authority === 'Owner') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  fetchInitial() {
    const { dispatch, organisation } = this.props;

    if (organisation) {
      // change to one found in url if not same as current state
      dispatch({
        type: 'organisation/setById',
        payload: organisation.id,
      });

      dispatch({
        type: 'accessControl/list',
        payload: {
          params: {
            orgId: organisation.id,
          },
        },
      });
    }
  }

  showDeleteConfirm(userRecord, b) {
    const { dispatch, organisation } = this.props;
    confirm({
      centered: true,
      title: (
        <div>
          Are you sure you want to delete <b>{userRecord?.email}</b> from the organization{' '}
          <b>{organisation?.name}</b> ?
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await dispatch({
          type: `accessControl/remove`,
          payload: {
            orgId: organisation.id,
            id: userRecord?.id,
            email: userRecord?.email,
            status: AC_STATUS.USER_DELETED,
          },
        });
      },
    });
  }

  renderUserTag = (ownerId, record) => {
    if (record.id === ownerId) {
      // * owner
      return (
        <span>
          &nbsp;
          <Tag color="blue">owner</Tag>
        </span>
      );
    }

    if (typeof record.status === 'string' && record.status !== AC_STATUS.INVITE_ACCEPTED) {
      return (
        <span>
          &nbsp;
          <Tag color={AC_STATUS_COLOR[record.status]}>
            {record.status === 'invite pending' ? AC_STATUS.INVITE_PENDING : record.status}
          </Tag>
        </span>
      );
    }
  };

  render() {
    const {
      organisation,
      accessControlList,
      loadingList,
      allCloudAccountsList,
      packageExpiry,
    } = this.props;

    if (loadingList || !organisation) return <Spin />;

    let data = [];
    accessControlList.forEach(item => {
      data.push({
        ...item,
        cloudAccounts: item.hasOwnProperty('cloudAccounts')
          ? item.cloudAccounts
          : allCloudAccountsList.map(cloudAccount => {
              return {
                id: cloudAccount.id,
                accountName: cloudAccount.accountName,
              };
            }),
      });
    });

    data = data.filter(
      v => v.email !== undefined && v.email.toLowerCase() !== 'support@cloudnosys.com'
    );

    const columns = [
      {
        title: 'User',
        dataIndex: 'email',
        key: 'email',
        render: (item, record) => (
          <span>
            {item}
            {this.renderUserTag(organisation.owner, record)}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (item, record) => {
          if (record.type === 'accessInvites') {
            return;
          }
          return (
            <>
              <Tooltip title={item ? 'Active' : 'Inactive'}>
                <Switch
                  defaultChecked={item}
                  disabled={
                    (organisation && record.id === organisation.owner) ||
                    !userCan('update-access-control') ||
                    this.isMe(record)
                  }
                  onChange={e => this.onChange(record.id, e)}
                />
              </Tooltip>
            </>
          );
        },
      },
      {
        title: (
          <span>
            Cloud Account{' '}
            <Tooltip title="Select Cloud Account(s) you want to give permission to OR don't select any Cloud Account to give permission to all.">
              <QuestionCircleOutlined style={{ marginLeft: 2 }} />
            </Tooltip>
          </span>
        ),
        key: 'cloudAccount',
        dataIndex: 'cloudAccount',
        render: (item, record) => {
          if (record.type === 'accessInvites') {
            return;
          }
          return (
            <MultiAccountSelector
              allCloudAccountsList={allCloudAccountsList}
              accessibleAccounts={record.cloudAccounts}
              accessControlRecord={record}
              organizationId={organisation.id}
            />
          );
        },
      },
      {
        title: 'Role',
        key: 'role',
        dataIndex: 'role',
        render: (item, record) => (
          <Select
            value={item}
            style={{ width: 100 }}
            disabled={
              (organisation && record.id === organisation.owner) ||
              userCan('update-access-control') === false ||
              this.isMe(record)
            }
            onChange={value => {
              this.handleRoleChange(record.id, value, record.type);
            }}
          >
            {['Owner', 'Administrator', 'Auditor', 'DevSecOps'].map(v => (
              <Option disabled={this.disableOwner(v)} key={record.id} value={v}>
                {v}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {packageExpiry !== 'expired' &&
              record.id !== organisation.owner &&
              userCan('update-access-control') &&
              !this.isMe(record) && (
                <a
                  href="#"
                  onClick={userCan('update-access-control', () => this.showDeleteConfirm(record))}
                >
                  <img
                    alt="accName"
                    style={{ width: 18, marginLeft: 14 }}
                    src="/icons/delete_icon.svg"
                  />
                </a>
              )}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        {userCan('create-access-control') && (
          <div style={{ marginBottom: 10, border: 'none' }}>
            <InviteForm
              handleAddInvite={this.handleAddInvite}
              loadingCreate={this.props.loadingCreate}
              exists={data}
              packageExpiry={packageExpiry}
            />
          </div>
        )}

        <Card style={{ border: 'none' }} bodyStyle={{ padding: 0 }}>
          <Table
            loading={loadingList || data.length === 0}
            rowKey="id"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </Card>
        {/* {!loadingList && data.length > 0 ? (
          <Card style={{ border: 'none' }} bodyStyle={{ padding: 0 }}>
            <Table rowKey="id" columns={columns} dataSource={data} pagination={false} />
          </Card>
        ) : (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Spin size="medium" />
          </div>
        )} */}
      </Fragment>
    );
  }
}

export default Organisation;
