const addCategoryToRisk = (metadata, globalCategories) => {
  metadata.forEach(eachMeta => {
    if (globalCategories) {
      Object.keys(globalCategories).forEach(cat => {
        if (globalCategories[cat].includes(eachMeta.name)) {
          eachMeta.category = cat;
        }
      });
    }
  });
  return metadata;
};
export default addCategoryToRisk;
