import errorHandler from '@/utils/errorHandler';
import { firebaseApp, firestore, functions } from '@/utils/firebase';
import Axios from 'axios';

/**
 * @description - Callback function for sending nvite - accessControl-handleUserInvitation
 * @param {*} params - user data
 * @returns {Object} - response from the callback
 */
export const handleAccessControl = async params => {
  return await functions.httpsCallable('accessControl-handleUserInvitation')(params);
};
