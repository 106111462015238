import { create, read, update } from '@/services/firestore';
import { notification } from 'antd';
import { createScopeChecksum } from '../helpers/checkSumId';

let namespace = 'scopeReport';

export default {
  namespace,
  state: {},
  effects: {
    *current(_, { call, put, select }) {
      try {
        let orgId = yield select(state => state.organisation.current.id);

        let filterAccountsId = yield select(state =>
          state.cloudAccount.selectedCloudAccounts.map(v => v.id)
        );

        if (filterAccountsId.length === 0) return;

        // * 1- create hash
        let hashedId = createScopeChecksum({ filterAccountsId });

        let scope = {
          cloudAccounts: filterAccountsId,
          status: '',
        };

        let newPayload = {
          params: {
            id: hashedId,
            orgId,
          },
          data: scope,
        };

        //  * create request to db
        const hashRes = yield call(read, {
          module: namespace,
          ...newPayload,
          no404error: true,
          stream: false,
        });

        // * if scope report not exist, create it
        if (!hashRes) {
          yield put({
            type: 'create',
            payload: {
              ...newPayload,
            },
          });
          yield put({
            type: 'update',
            payload: {
              params: {
                id: hashedId,
                orgId,
              },
              data: {
                ...scope,
                status: 'born',
              },
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    *create({ payload }, { call }) {
      try {
        yield call(create, {
          module: namespace,
          params: {
            id: payload.params.id,
            orgId: payload.params.orgId,
          },
          data: payload.data,
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
    *update({ payload }, { call }) {
      try {
        yield call(update, {
          module: namespace,
          ...payload,
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
  },
  reducers: {
    clear(state) {
      return {
        ...state,
      };
    },
  },
};
