export default {
  firebase: {
    apiKey: 'AIzaSyAkw9N4egonAb9QnjSCuwErbM-l-FIVYZc',
    authDomain: 'cloudnosys-dev-playbooks.firebaseapp.com',
    databaseURL: 'https://cloudnosys-dev-playbooks.firebaseio.com',
    projectId: 'cloudnosys-dev-playbooks',
    storageBucket: 'cloudnosys-dev-playbooks.appspot.com',
    messagingSenderId: '917080911911',
    appId: '1:917080911911:web:2c56114fca9a938eadde02',
  },
};
