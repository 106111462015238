/**
 * @description This component takes provider name and renders its logo
 * @param {String} provider - Provider Name
 * @returns {JSX} - image ( logo ) of the provider
 */
export default function CloudProviderIcon({ provider }) {

    const icons = {
        AZURE: { style: { height: 12, marginTop: -6, marginLeft: 5 }, src: '/azure@2x.png' },
        GCP: { style: { width: 40, marginTop: -3 }, src: '/gcplogo.png' },
        AWS: { style: { height: 20, marginTop: -7, marginLeft: 0 }, src: '/awslogo.png' },
    }

    return (
      <img
        style={icons[provider].style}
        src={icons[provider].src}
        alt={provider}
      />
    );

}
