import { message, notification } from 'antd';
// import download from 'downloadjs';
// import { stat } from 'fs';
import {
  addNewAccount,
  fetchCloudAccounts,
  deleteAccount,
  updateCloudAccount,
  queryUpdateScheduling,
  uploadRegulation,
  updateCloudAccountMode,
  // liveMonitoringDone
} from '../services/cloudAccounts';
// saveSelectedCloudAccounts
export default {
  namespace: 'cloudAccounts',
  state: {
    scanData: {
      cloudAccount: null,
      logs: null,
      date: null,
    },
    newAccountData: {},
    data: {
      list: [],
      allSelectedCloudAccounts: [],
      pagination: {},
    },
    cloudAccountResponse: [],
    updatingCloudAccLoading: false,

    single: {},
    addRespose: {
      status: '',
      message: '',
    },
  },

  effects: {
    // uploading regulation
    *upload({ payload }, { call }) {
      yield call(uploadRegulation, payload);
    },
    //add new cloudAccount
    *addNewAccount({ payload }, { call }) {
      yield call(addNewAccount, payload.accountData);
    },

    *saveSelectedCloudAccounts({ payload }, { put }) {
      let { allSelectedAccounts } = payload;
      yield put({
        type: 'saveSelectedAccounts',
        payload: allSelectedAccounts,
      });
    },

    *clearSelectedCloudAccounts(_, { put }) {
      yield put({
        type: 'saveSelectedAccounts',
        payload: [],
      });
    },

    //fetch cloudAccountsList
    *fetchCloudAccounts({ payload }, { call, put, takeEvery }) {
      function* push(response) {
        yield put({
          type: 'save',
          payload: response,
        });
      }

      // create subscription
      const service = yield call(fetchCloudAccounts, payload);
      yield takeEvery(service, push);
    },

    *deleteAccount({ payload }, { call }) {
      const response = yield call(deleteAccount, payload.accountData);
      message[response.status](response.message);
    },

    *updateScheduling({ payload }, { call }) {
      try {
        // update scheduling and monitoring
        yield call(queryUpdateScheduling, payload);
      } catch (err) {
        console.error('Error updating scheduling and monitoring: ', err);
      }
    },

    *updateCloudaccount({ payload }, { call, put }) {
      const fetchCustomer = yield call(updateCloudAccount, payload);

      yield put({
        type: 'customer',
        payload: {
          response: fetchCustomer,
        },
      });
    },

    *updateCloudAccountMode({ payload }, { call, put }) {
      const response = yield call(updateCloudAccountMode, payload);
      yield put({
        type: 'saveState',
        payload: {
          updatingCloudAccLoading: true,
        },
      });

      if (response.status === 'success') {
        notification.success({
          message: 'Updated Successfully',
          description: response.message,
        });
      } else
        notification.error({
          message: 'Error',
          description: response.message,
        });

      yield put({
        type: 'saveState',
        payload: {
          updatingCloudAccLoading: false,
        },
      });
    },

    //  *liveMonitoringDone({payload},{ call, put }) {
    //   const response = yield call(liveMonitoringDone, payload);
    //   const fetchCloudAccount = yield call(queryCloudAccounts);
    //   yield put({
    //     type: 'refetchOnDone',
    //     payload: {
    //       response: fetchCloudAccount,
    //     },
    //   });
    // },

    // *downloadTemplate({ payload }, { call }) {
    //   const response = yield call(downloadTemplate, payload);
    //   const stringifiedJson = JSON.stringify(response);
    //   const filename = `cloudnosys_stackset_template_${payload.cloudAccount}`;
    //   download(stringifiedJson, `${filename}.json`, 'application/json');
    // },

    // *setScanLogUrl({ payload }, { put }) {
    //   const { date } = payload;

    //   const cloudAccount = payload['cloud-account'];

    //   const defaultLogDate = moment(date).format('HH:mm:ss MMMM DD YYYY');

    //   const newParams = {
    //     ...getPageQuery(),
    //     date: defaultLogDate,
    //     'cloud-account': cloudAccount,
    //   };

    //   yield put(routerRedux.replace(`?${stringify(newParams, { encode: true })}`));
    // },
    // *scanLogDateUrl({ payload }, { put }) {
    //   const { date } = payload;

    //   const defaultLogDate = moment(date).format('HH:mm:ss MMMM DD YYYY');

    //   const newParams = {
    //     ...getPageQuery(),
    //     date: defaultLogDate,
    //   };

    //   yield put(routerRedux.push(`?${stringify(newParams, { encode: true })}`));

    //   yield put({
    //     type: 'addToScanData',
    //     payload: {
    //       date,
    //     },
    //   });
    // },
    // *resetSelectedAccount({ put }) {
    //   yield put({
    //     type: 'addToScanData',
    //     payload: {
    //       logs: null,
    //       date: null,
    //       cloudAccount: null,
    //     },
    //   });
    // },

    // *selectedAccount({ payload }, { put }) {

    //   yield put({
    //     type: 'addToScanData',
    //     payload: {
    //       cloudAccount: payload.cloudAccount,
    //     },
    //   });
    // },

    // *fetchScanLogs({ payload }, { call, put, select }) {
    //   const cloudAccountSelected = yield select(state => state.cloudAccounts.scanData.cloudAccount);

    //   const response = yield call(getScanLogs, payload);

    //   const logs = response.payload;

    //   const { date } = payload;

    //   yield put({
    //     type: 'addToScanData',
    //     payload: {
    //       logs,
    //       date: date === null ? (logs[0] ? logs[0].dateEnded : 0) : payload.date,
    //     },
    //   });

    //   yield put({
    //     type: 'setScanLogUrl',
    //     payload: {
    //       date: payload.date === null ? (logs[0] ? logs[0].dateEnded : 0) : payload.date,
    //       'cloud-account': cloudAccountSelected,
    //     },
    //   });
    // },
    // *saveDocument({ payload }, { call, put, select }) {
    //   try {
    //     var response = yield call(queryUpdateCloudAccounts(payload));
    //   } catch (err) {}
    // },
  },

  reducers: {
    saveSelectedAccounts(state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          allSelectedCloudAccounts: action.payload,
        },
      };
    },
    saveState(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    save(state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          list: action.payload,
        },
      };
    },
  },
};
