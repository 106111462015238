import { functions, firestore } from '../utils/firebase';
// import request from '../utils/request';

export const addCustomSignature = async payload => {
  try {
    let ref = await firestore
      .collection('Organizations')
      .doc(payload.params.orgId)
      .collection('customSignatures')
      .doc(payload.params.name);

    await ref.set(payload.data);
  } catch (err) {
    console.error('Error in addCustomSignature: ', err);
  }
};

export function testCustomSignature(data) {
  return functions.httpsCallable('Signatures-testCustomSignature')(data);
  // let api;
  // if (APP_ENV === 'dev') {
  //   api =
  //     'https://us-central1-cloudnosys-1562854966815.cloudfunctions.net/Signatures-testCustomSignature';
  // } else if (APP_ENV === 'prod') {
  // api = 'https://us-central1-cloudnosys-prod.cloudfunctions.net/testCustomSignature';
  // }
  // return request.post(api, { data: payload });
}
