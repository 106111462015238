/**
 * @description - This helpers is used for changing the sort order, by changing order of condition variables A and B
 * @param {Array} sortOrder - Order of the sort
 * @param {any} firstRisk
 * @param {any} secondRisk
 * @returns
 */
export const getSortConditionBySortOrder = (sortOrder, firstRisk, secondRisk) => {
  if (sortOrder === 'dsc') return firstRisk - secondRisk;
  return secondRisk - firstRisk;
};

/**
 * @description - This helpers finds a tag , from the tag list.
 * @param {Array} tagsList - Array/List of tags
 * @param {Object} tagItem - tag element to find
 * @returns
 */
export const findATagFromArray = ({ tagsList, tagItem }) => {
  return tagsList.find(
    arrayItem => arrayItem.tag === tagItem.tag && arrayItem.value === tagItem.value
  );
};

/**
 * @description - This helpers finds the index of a tag , from the tag list.
 * @param {Array} tagsList - Array/List of tags
 * @param {Object} tagItem - tag element to find
 * @param {Boolean} splitTheTags - set this true, if tags are array of strings, i.e in resourcesReport data
 * @returns
 */
export const findIndexOfATagFromArray = ({ tagsList, tagItem, splitTheTags = false }) => {
  if (splitTheTags) {
    return tagsList.findIndex(
      arrayItem =>
        arrayItem.split('=')[0] === tagItem.tag && arrayItem.split('=')[1] === tagItem.value
    );
  }
  return tagsList.findIndex(
    arrayItem => arrayItem.tag === tagItem.tag && arrayItem.value === tagItem.value
  );
};

/**
 * @description - It provides conditional statement for finding a tag from TagList
 * @param {Array} - arrayItem
 * @param {Object} - tagItem
 * @returns
 */
export const findATagFromArrayCondition = ({ arrayItem, tagItem }) => {
  return arrayItem.tag === tagItem.tag && arrayItem.value === tagItem.value;
};
