import { firestore } from '@/utils/firebase';

export const getSingleReportService = async payload => {
  const { reportId, type } = payload;

  try {
    if (type === 'customRegulation') {
      let orgId = localStorage.getItem('orgId');

      let ref = await firestore
        .collection('Organizations')
        .doc(orgId)
        .collection('customRegulations')
        .where('reportId', '==', reportId)
        .get();

      let { docs } = ref;

      let report = docs[0].data();
      return report;
    } else {
      let ref = await firestore
        .collection('Regulations')
        .where('reportId', '==', reportId)
        .limit(1)
        .get();

      let { docs } = ref;

      let report = docs[0].data();
      return report;
    }
  } catch (error) {
    console.error('Error in getSingleReportService: ', error);
    return null;
  }
};

export const getCloudnosysReportService = async () => {
  try {
    let ref = await firestore
      .collection('Regulations')
      .where('type', '==', 'security')
      .limit(1)
      .get();

    let { docs } = ref;

    let report = docs[0].data();
    return report;
  } catch (error) {
    console.error('Error in getCloudnosysReportService: ', error);
    return [];
  }
};

export const getAllReportsService = async () => {
  try {
    let ref = await firestore
      .collection('Regulations')
      .where('type', '==', 'compliance')
      .get();

    let { docs } = ref;

    let arr = docs.map(report => report.data());

    return arr;
  } catch (error) {
    console.error('Error in getAllReportsService: ', error);
    return [];
  }
};

export const getAllRegulationsService = async () => {
  try {
    let ref = await firestore.collection('Regulations').get();

    let { docs } = ref;

    let arr = docs.map(report => report.data());

    return arr;
  } catch (error) {
    console.error('Error in getAllRegulationsService: ', error);
    return [];
  }
};
