// let datresourceReporta = [
//   {
//     cloudAccountId: 'Kg6waZTIZtFC2d6uuP9M',
//     createdAt: 'April 9, 2020 at 2:28:05 PM UTC+5',
//     id: 'ca093eb4-d3ee-4359-8c27-4e7087dc53a0',
//     name: 'testLambdaIoT',
//     ownerId: '',
//     provider: 'AWS',
//     service : IAM
//     region: 'ap-northeast-1',
//     resourceCreatedAt: '',
//     risks: [
//       {
//         status: 'pass',
//         category: 'bab',
//         cloud: 'AWS',
//         function: 'Cryptography',
//         group: 'Encryption in Motion',
//         name: 'AWS:CF-001',
//       },
//       {
//         status: 'fail',
//         category: 'Security',
//         cloud: 'AWS',
//         function: 'Cryptography',
//         group: 'Encryption in Motion',
//         name: 'AWS:CF-002',
//       },
//     ],
//   },
// ];

// filters = [{ type: 'cloudAccounts', value: ['Kg6waZTIZtFC2d6uuP9M'] } , { type: 'name', value: ['testLambdaIoT']},
// { type: 'status', value: ['fail']} ,{ type: 'category', value: ['Security']},{ type: 'group', value: ['Encryption in Motions']},{ type: 'signatureName', value: ['AWS:CF-002']},{  type: 'risk', value: ['low'], }];
/**
 * Takes resources and filters, outputs filtered resources
 * @Params
 * resourcesWithRisks - array of resources objects with full risks inside
 * filters - array of filters e.g [{type: '', value: []}, ...]
 */

///////waseem give
//[{ type: ‘cloudAccounts’, value: [‘Kg6waZTIZtFC2d6uuP9M’] } , { type: ‘name’, value: [‘testLambdaIoT’]},
// { type: ‘status’, value: [‘fail’]} ,{ type: ‘category’, value: [‘Security’]},{ type: ‘group’, value: [‘Encryption in Motions’]},{ type: ‘signatureName’, value: [‘AWS:CF-002’]},{  type: ‘risk’, value: [‘low’], }];
const filterResources = (resourcesWithRisks, filters) => {
  //
  //
  // loop each resource
  let filteredResources = resourcesWithRisks.reduce((acc, eachResource) => {
    //
    //
    let resourceData = Object.keys(eachResource);
    //

    // filter by resource name
    if (resourceData.includes('name')) {
      let resourceName = filters.filter(e => e.type === 'name');
      if (resourceName.length > 0 && !resourceName[0].value.includes(eachResource.name)) return acc;
    }

    // filter by Cloud Account IDs
    if (resourceData.includes('cloudAccountId')) {
      let cloudAccountId = filters.filter(e => e.type === 'cloudAccounts');
      if (
        cloudAccountId.length > 0 &&
        !cloudAccountId[0].value.includes(eachResource.cloudAccountId)
      )
        return acc;
    }

    // filter by region
    if (resourceData.includes('region')) {
      let region = filters.filter(e => e.type === 'region');
      if (region.length > 0 && !region[0].value.includes(eachResource.region)) return acc;
    }

    // filter by search
    if (resourceData.includes('name')) {
      let search = filters.filter(e => e.type === 'search');
      if (search.length > 0 && !eachResource.name.includes(search[0].value[0])) return acc;
    }

    if (resourceData.includes('service')) {
      let service = filters.filter(e => e.type === 'service');
      //
      //
      ////////////////
      let tempService = eachResource.service;
      // let checkColon = eachResource.service.includes(':');
      // if (checkColon) {
      //   let indexOfColon = eachResource.service.indexOf(':');
      //   tempService = eachResource.service.substr(0, indexOfColon);
      // }
      ////////////////
      if (service.length > 0 && !service[0].value.includes(tempService)) return acc;
    }
    // filter risks
    if (resourceData.includes('risks')) {
      // loop each risk

      let statusForEmptyCheck = filters.filter(e => e.type === 'riskStatus');
      let statusForGroupBy = filters.filter(e => e.type === 'g_by');
      if (eachResource.risks.length === 0 && statusForEmptyCheck.length === 0)
        return [...acc, eachResource];
      let checkForEmpty =
        statusForGroupBy.length === 0 &&
        statusForEmptyCheck.length > 0 &&
        statusForEmptyCheck[0].value.includes(lower('pass'));
      if (eachResource.risks.length === 0 && checkForEmpty) return [...acc, eachResource];

      let filteredRisks = eachResource.risks.reduce((acc2, risk) => {
        // filter by risk status
        let status = filters.filter(e => e.type === 'riskStatus');
        if (status.length > 0 && !status[0].value.includes(lower(risk.status))) return acc2;

        // filter by category
        let category = filters.filter(e => e.type === 'category');
        if (category.length > 0 && !category[0].value.includes(risk.category)) return acc2;

        // filter by function
        let func = filters.filter(e => e.type === 'function');
        if (func.length > 0 && !func[0].value.includes(risk.function)) return acc2;

        // filter by group
        let group = filters.filter(e => e.type === 'g_by');
        if (group.length > 0 && !group[0].value.includes(risk.group)) return acc2;

        // filter by signature name
        let sigName = filters.filter(e => e.type === 'signatureName');

        if (sigName.length > 0) {
          sigName[0].value = sigName[0].value.map(eachSig => {
            return eachSig.replace(' ', '+');
          });
        }

        if (sigName.length > 0 && !sigName[0].value.includes(risk.name.replace(' ', '+')))
          return acc2;

        let risksOfMetaData = filters.filter(e => e.type === 'risk_level');
        if (risksOfMetaData.length > 0 && !risksOfMetaData[0].value.includes(lower(risk.risk)))
          return acc2;
        // if (eachResource.id === '3.139.192.158') {
        //   //
        //
        // }
        return [...acc2, risk];
      }, []);
      // if (eachResource.id === '3.139.192.158') {
      //   //
      //
      // }
      if (filteredRisks.length === 0) return acc;
      eachResource.risks = filteredRisks;

      let status = filters.filter(e => e.type === 'riskStatus');

      // if (
      //   filteredRisks.every(v => lower(v.status) === 'pass') &&
      //   status.length > 0 &&
      //   status[0].value.includes('pass')
      // )
      //   return acc;
    }
    return [...acc, eachResource];
  }, []);
  //
  return filteredResources;
};

export default filterResources;

function lower(val) {
  return val.toLowerCase();
}
