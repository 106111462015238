import { message, notification } from 'antd';

const { runScan } = require('../services/scan');

export default {
  namespace: 'scan',
  state: {},

  effects: {
    *runScan({ payload }, { call }) {
      const { currentlyScanningCloudAccountsIds, cloudAccounts, orgId, advancedScanType } = payload;

      const filterOutNotRunning = cloudAccounts.filter(
        item => !currentlyScanningCloudAccountsIds.includes(item.id)
      );

      if (
        filterOutNotRunning.length === 0 &&
        filterOutNotRunning.length !== currentlyScanningCloudAccountsIds.length
      ) {
        message.error(`Scan already in progress`);
        return;
      }

      if (!filterOutNotRunning || !filterOutNotRunning.length) {
        notification.error({
          message: `Scan did not Start`,
          description: `No Cloud Account Selected`,
        });
        return;
      }

      yield call(runScan, {
        orgId: orgId,
        cloudAccount: filterOutNotRunning,
        advancedScanType,
      });
    },
  },
};
