import { notification, message } from 'antd';
import { list, create, read, update, remove } from '@/services/firestore';

const namespace = 'customRegulations';
export default {
  namespace,
  state: {
    loadingList: false,
    loadingCurrent: false,
    loadingRemove: false,
    loadingOperation: false,
    statusOperation: '',
    payloadOperation: null,
    list: null,
    current: null,
  },

  effects: {
    *list({ payload }, { call, put, select, takeEvery, take }) {
      // clear old before new request
      // yield put({
      //   type: `clear:${namespace}/list`,
      // });

      yield put({
        type: 'save',
        payload: { loadingList: true },
      });

      // create subscription
      const service = yield call(list, {
        module: namespace,
        ...payload,
        no404error: true,
      });

      function* push(response) {
        yield put({
          type: 'saveList',
          payload: Array.isArray(response) ? response : [],
        });

        try {
          let allRegs = yield select(state => state.regulations.allRegulations);
          allRegs = allRegs.filter(v => v.type !== 'customRegulation');
          let newRegs = allRegs.concat(response);

          yield put({
            type: 'regulations/save',
            payload: newRegs,
          });
        } catch (error) {
          console.log(error);
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/list`);
      service.close();
      yield put({
        type: 'clearList',
      });
    },

    *current({ payload }, { call, put, takeEvery, take }) {
      // clear old before new request
      yield put({
        type: `clear:${namespace}/current`,
      });

      yield put({
        type: 'save',
        payload: { loadingCurrent: true },
      });

      // create subscription
      const service = yield call(read, {
        module: namespace,
        ...payload,
        no404error: true,
      });

      function* push(response) {
        yield put({
          type: 'saveCurrent',
          payload: response,
        });
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/current`);
      service.close();
      yield put({
        type: 'clearCurrent',
      });
    },

    *create({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
      });

      try {
        const response = yield call(create, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Custom Policy added successfully',
          });

          yield put({
            type: 'save',
            payload: {
              statusOperation: 'success',
              loadingOperation: false,
              payloadOperation: response,
            },
          });
          yield put({
            type: 'list',
            payload: {
              ...payload,
            },
          });
        } else {
          message.error('Missing or insufficient permissions.');
          yield put({
            type: 'save',
            payload: { statusOperation: 'error', loadingOperation: false },
          });
        }
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    *update({ payload }, { call, put }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingOperation: true, statusOperation: '', payloadOperation: null },
        });
        yield call(update, {
          module: namespace,
          ...payload,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'success' },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { loadingOperation: false, statusOperation: 'error' },
        });
      }
    },

    *remove({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });

      try {
        const response = yield call(remove, {
          module: namespace,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'Custom Policy deleted successfully',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
        yield put({
          type: 'list',
          payload: {
            ...payload,
          },
        });
      } catch (error) {
        //
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: `clear:${namespace}/list` });
      yield put({ type: `clear:${namespace}/current` });
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
        loadingList: false,
      };
    },
    clearList(state) {
      return {
        ...state,
        list: null,
        loadingList: false,
      };
    },
    saveCurrent(state, { payload }) {
      return {
        ...state,
        current: payload,
        loadingCurrent: false,
      };
    },
    clearCurrent(state) {
      return {
        ...state,
        current: null,
        loadingCurrent: false,
        payloadOperation: null,
      };
    },
  },
};
