import { fetchNodesConfig, getPlaybookDataFromURL, runPlaybook } from '@/services/editor';
import { create, list, read, update, remove } from '@/services/firestore';
import { notification } from 'antd';
import lzma from 'lz-string';
import router from 'umi/router';
import moment from 'moment';
import {
  getObjectPath,
  getObjectValue,
  modifyPath,
  updateObjValue,
} from '@/pages/Playbooks/utils/objectManupulators';
import {
  cloudnosysToRea,
  generateNodeId,
  splitCondition,
  toggleNodesMetaData,
} from '@/pages/Playbooks/utils/playbookUtils';
import CronParser from 'cron-parser';
import axios from 'axios';
import _ from 'lodash';
import { PATH_DASHBOARD } from '@/utils/routes/path';

const namespace = 'editor';
export default {
  namespace,
  state: {
    playbookTriggerTab: '',
    current: null,
    playbookDetail: {
      name: 'Untitled-1',
      description: '',
      global: null,
      globalConfig: null,
      globalTabType: 'json',
      isActive: false, // active || disabled || not-configured
      id: '',
      // ... more data of current playbook
      // id,
    },
    list: [],
    playbookNodesMeta: null,
    playbookNodesConfig: null,
    playbookRunning: false,
    playbookUpdating: false,
    playbookTemplates: null,
    playbookTemplatesLoading: false,
    listLoading: false,
    playbookResult: {},
    playbookHistory: [],
    historyLoading: false,
    debugMode: false,
    executionId: '',
    templateId: '',
    configModal: false,
    selectedTrigger: '',
    prefilledData: null,
    // NEW
    fetchingExecutions: false,
    executionDetails: {},
  },
  effects: {
    *run({ payload }, { call, put, select }) {
      try {
        let triggerPayload;
        yield put({
          type: 'save',
          payload: {
            playbookResult: {},
            playbookRunning: true,
          },
        });

        notification.success({
          message: 'Playbook started.',
        });
        let stateRef = yield select(state => state);
        const playbookTriggerTab = yield select(state => state.editor.playbookTriggerTab);
        const playbookDetail = yield select(state => state.editor.playbookDetail);
        const organisation = yield select(state => state.organisation.current);

        if (!_.isEmpty(payload)) {
          triggerPayload = {
            ...payload,
            organizationId: organisation.id,
            organizationName: organisation.name,
          };
        }

        // const triggerPayload = getTriggerPayload(
        //   playbookDetail.global,
        //   playbookDetail.triggerInfo.alert,
        //   organisation.id,
        //   organisation.name
        // );

        stateRef.editor.current['trigger-1'].actionInputs.payload = triggerPayload;
        stateRef.editor.current['trigger-1'].actionInputs.tabType = playbookTriggerTab
          ? playbookTriggerTab
          : stateRef.editor.current['trigger-1'].actionInputs?.tabType;

        let pb = {
          ...playbookDetail,
          orgId: organisation.id,
          userId: stateRef.user.currentUser.id,
          view: stateRef.editor.current,
          callAction: 'runPlaybook',
        };

        if (payload?.runTill) {
          pb.runTill = payload?.runTill;
        }

        const response = yield call(runPlaybook, { playbook: pb });
        let editor = yield select(state => state.editor);
        if (response.data.id !== editor?.playbookDetail?.id) {
          yield put({
            type: 'save',
            payload: {
              playbookResult: {},
              playbookRunning: false,
            },
          });
          return;
        }
        // if current id and response id dont match, then return/ignore/stop
        notification.success({
          message: 'Playbook run successfully.',
        });
        console.log('response.data :>> ', response.data);

        yield put({
          type: 'save',
          payload: {
            playbookResult: response.data.history,
            playbookRunning: false,
          },
        });
      } catch (error) {
        console.log('error :>> ', error);
        notification.error({
          message: error.message || 'Something went wrong',
        });
        yield put({
          type: 'save',
          payload: {
            playbookResult: {},
            playbookRunning: false,
          },
        });
      }
    },
    *getTemplateList(_, { call, put, takeEvery, take }) {
      try {
        yield put({
          type: 'save',
          payload: { playbookTemplates: null, playbookTemplatesLoading: true },
        });
        // create subscription
        const service = yield call(list, {
          module: 'playbookTemplate',
          orderBy: ['createdAt', 'desc'],

          no404error: true,
        });
        function* push(response) {
          if (response) {
            yield put({
              type: 'save',
              payload: {
                playbookTemplates: response,
                playbookTemplatesLoading: false,
              },
            });
          } else {
            yield put({
              type: 'save',
              payload: {
                playbookTemplates: [],
                playbookTemplatesLoading: false,
              },
            });
          }
        }
        yield takeEvery(service, push);
        yield take(`clear:${namespace}/list`);
        // unsubscribe & clear when this action fired
        service.close();
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { listLoading: false, list: [] },
        });
      }
    },
    *list(_, { call, put, takeEvery, take, select }) {
      try {
        // clear old before new request
        yield put({
          type: `clear:${namespace}/list`,
        });
        yield put({
          type: 'save',
          payload: { listLoading: true },
        });
        let organisation = yield select(state => state.organisation.current);

        // create subscription
        const service = yield call(list, {
          module: namespace,
          orderBy: ['createdAt', 'desc'],
          params: {
            orgId: organisation.id,
          },
          no404error: true,
        });
        function* push(response) {
          yield put({
            type: 'save',
            payload: {
              listLoading: false,
              list: response,
            },
          });
        }
        yield takeEvery(service, push);
        yield take(`clear:${namespace}/list`);
        // unsubscribe & clear when this action fired
        service.close();
        yield put({
          type: 'save',
          payload: {
            listLoading: false,
            list: [],
          },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: { listLoading: false, list: [] },
        });
      }
    },
    *currentHistory({ payload }, { call, put, takeEvery, take, select }) {
      try {
        // clear old before new request
        yield put({
          type: 'save',
          payload: {
            playbookHistory: [],
            historyLoading: true,
          },
        });

        // create subscription
        const service = yield call(list, {
          module: 'playbooksHistory',
          orderBy: ['createdAt', 'desc'],
          limit: 20,
          params: {
            ...payload.params,
          },
        });
        function* push(response) {
          const finalRes = [];
          for (const historyObj of response) {
            if (historyObj.processess.isCompressed) {
              // TODO this compression should be cater from execution history component as well
              const compressedData = handleDataDecompression(historyObj.processess.data);
              historyObj.processess = compressedData;
              finalRes.push(historyObj);
            } else finalRes.push(historyObj);
          }
          yield put({
            type: 'save',
            payload: {
              playbookHistory: response,
              historyLoading: false,
            },
          });
          if (payload.fn) {
            if (response.length > 0) {
              payload.fn(response[0].id);
            }
          }
        }
        yield takeEvery(service, push);
        yield take(`clear:${namespace}/list`);
        // unsubscribe & clear when this action fired
        service.close();
      } catch (error) {
        console.error('Error in currentHistory', error);
        yield put({
          type: 'save',
          payload: {
            playbookHistory: [],
            historyLoading: false,
          },
        });
      }
    },
    *getExecutionDetails({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'save',
          payload: {
            fetchingExecutions: true,
          },
        });
        const { historyId } = payload;

        const userId = yield select(state => state.user.currentUser.id);
        const orgId = yield select(state => state.organisation.current.id);

        const { data } = yield call(getPlaybookDataFromURL, {
          historyId,
          userId,
          orgId,
        });

        const executionDetails = yield select(state => state.editor.executionDetails);

        yield put({
          type: 'save',
          payload: {
            fetchingExecutions: false,
            executionDetails: {
              ...executionDetails,
              [historyId]: data,
            },
          },
        });
      } catch (error) {
        yield put({
          type: 'save',
          payload: {
            fetchingExecutions: false,
          },
        });
      }
    },
    *current({ payload }, { call, put, select }) {
      try {
        let { playbookId } = payload;

        let editorState = yield select(state => state.editor);
        let templates = editorState.playbookTemplates;
        if (!editorState.templateId && !playbookId && !templates) {
          router.push(PATH_DASHBOARD.playbooks.add);
          return;
        }
        let savePlaybookType;
        if (editorState.templateId && editorState.templateId !== 'default') {
          savePlaybookType = 'saveTemplate';
          let defaultTrigger = yield select(state => state.editor.selectedTrigger);
          let selectedTemplate = templates.find(template => template.id === editorState.templateId);
          yield put({
            type: 'save',
            payload: {
              selectedTrigger:
                defaultTrigger.length === 0 ? selectedTemplate.triggerInfo.type : defaultTrigger,
            },
          });
        }
        if (playbookId) {
          // fetch playbook from db
          let organisation = yield select(state => state.organisation.current);
          let moduleName = savePlaybookType ? 'playbookTemplate' : namespace;
          // create subscription
          const response = yield call(read, {
            module: moduleName,
            stream: false,
            params: {
              orgId: organisation.id,
              id: playbookId,
            },
          });
          let defaultTrigger = yield select(state => state.editor.selectedTrigger);
          yield put({
            type: 'save',
            payload: {
              current: response.view,
              selectedTrigger:
                defaultTrigger.length === 0 ? response.triggerInfo.type : defaultTrigger,
              playbookTriggerTab: response.triggerInfo.type,
            },
          });
          delete response.view;
          yield put({
            type: 'save',
            payload: {
              playbookDetail: response,
            },
          });
        } else {
          // Get views by templateId
          if (editorState.templateId && editorState.templateId === 'default') {
            // Get default Trigger template
            let initialTriggerTemplate = {
              ...editorState.playbookNodesMeta.find(eachNode => eachNode.name === 'Trigger'),
            };

            // Check if preffilled Data is availabe , from signature details drawer
            if (editorState.prefilledData) {
              // Assign prefilled fields
              initialTriggerTemplate.actionInputs = editorState.prefilledData;
            }

            console.log('initialTriggerTemplate :>> ', initialTriggerTemplate);

            let view = JSON.parse(
              JSON.stringify({
                'trigger-1': initialTriggerTemplate,
              })
            );

            let defaultTrigger = yield select(state => state.editor.selectedTrigger);

            yield put({
              type: 'save',
              payload: {
                current: view,
              },
            });
            yield put({
              type: 'save',
              payload: {
                playbookDetail: {
                  id: moment().format('YYYY-MM-DD h:m:s:SSS'),
                  saved: false,
                  name: 'Untitled-1',
                  description: '',
                  global: null,
                  globalConfig: null,
                  isActive: false,
                },
              },
            });
          } else {
            let template = templates.find(
              templateItem => templateItem.id === editorState.templateId
            );
            yield put({
              type: 'save',
              payload: {
                current: template.view,
              },
            });
            let keysToDelete = ['view', 'updatedAt', 'createdAt', 'userId', 'orgId', 'id'];
            keysToDelete.forEach(k => delete template[k]);
            template.isActive = false;
            template.id = moment().format('YYYY-MM-DD h:m:s:SSS');
            template.saved = false;
            yield put({
              type: 'save',
              payload: {
                playbookDetail: template,
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    *remove({ payload }, { call, put }) {
      let { savePlaybookType } = payload.params;
      yield put({
        type: 'save',
        payload: { loadingRemove: true },
      });
      try {
        let moduleName = savePlaybookType ? 'playbookTemplate' : namespace;
        const response = yield call(remove, {
          module: moduleName,
          ...payload,
        });
        if (response) {
          notification.success({
            message: 'playbook deleted',
          });
        }
        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });

        yield put({
          type: 'save',
          payload: { loadingRemove: false },
        });
      }
    },
    *update({ payload }, { call, put, select }) {
      const { savePlaybookType } = payload;
      try {
        yield put({
          type: 'save',
          payload: {
            playbookUpdating: true,
          },
        });

        const state = yield select(modelState => modelState);
        const playbookTriggerTab = yield select(state => state.editor.playbookTriggerTab);

        let pb = {
          ...state.editor.playbookDetail,
          orgId: state.organisation.current.id,
          userId: state.user.currentUser.id,
          view: state.editor.current,
        };

        // * Validating required field before saving
        validateBeforeSaving(pb.name);

        pb.triggerInfo = {
          type: playbookTriggerTab || state.editor.selectedTrigger, // manual || scheduled || alert
        };

        pb.globalTabType = pb.globalTabType || 'json';

        const trigger = state.editor.current['trigger-1'];
        const tabType = pb.triggerInfo?.type;

        tabType && tabType === 'schedule' && (pb = handleSchedulePB(trigger, pb));
        tabType && tabType === 'newRisk' && (pb = handleNewRiskPB(trigger, pb));
        tabType && tabType === 'manual' && (pb = handleManualPB(trigger, pb));

        const savePlaybookTemplate = savePlaybookType === 'saveTemplate';
        const moduleName = savePlaybookTemplate ? 'playbookTemplate' : namespace;

        // ? If the playbook has been saved previously then update PB
        // ? Else save it as new PB
        if (pb.saved) {
          // * UPDATING EXISTING PB
          delete pb.updatedAt;
          const updateResponse = yield call(update, {
            module: moduleName,
            params: {
              orgId: pb.orgId,
              id: pb.id,
            },
            data: pb,
          });
          if (updateResponse) {
            notification.success({
              message:
                savePlaybookType === 'saveTemplate'
                  ? 'Playbook Template updated'
                  : 'Playbook updated.',
            });
          }
        } else {
          // * CREATING NEW PB
          pb.saved = true;
          const createResponse = yield call(create, {
            module: moduleName,
            params: {
              orgId: pb.orgId,
              id: pb.id,
            },
            data: pb,
          });
          if (createResponse) {
            notification.success({
              message:
                savePlaybookType === 'saveTemplate'
                  ? 'Playbook Template created'
                  : 'Playbook created.',
            });
            router.push(`${PATH_DASHBOARD.playbooks.editor}/${createResponse}`);
          }
        }
        yield put({
          type: 'save',
          payload: {
            playbookUpdating: false,
            templateId: savePlaybookType === 'saveTemplate' ? 'saveTemplate' : 'default',
            playbookDetail: pb,
          },
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
        yield put({
          type: 'save',
          payload: {
            playbookUpdating: false,
          },
        });
      }
    },
    *togglePlaybookStatus({ payload }, { call }) {
      try {
        const { playbook } = payload;

        yield call(update, {
          module: namespace,
          params: {
            orgId: playbook.orgId,
            id: playbook.id,
          },
          data: playbook,
        });

        notification.success({
          message: 'Playbook status updated.',
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
    *getPlaybooksNodesConfig(_, { call, put, select }) {
      try {
        const currentUser = yield select(state => state.user);
        let response = yield call(fetchNodesConfig, {
          orgId: localStorage.getItem('orgId'),
          userId: currentUser.currentUser.id,
        });
        if (response) {
          let { DSC, Puppet, ...destructResponse } = response.data;
          response.data = destructResponse;
          let nodesConfig = response.data;
          let reaNodes = generateReaNodes(nodesConfig);
          reaNodes = toggleNodesMetaData(reaNodes);
          yield put({
            type: 'save',
            payload: {
              playbookNodesMeta: reaNodes,
              playbookNodesConfig: nodesConfig,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    *updateGlobalConfig({ payload }, { put, select }) {
      try {
        let setGlobalObj = null;
        let playbookDetail = yield select(state => state.editor.playbookDetail);
        let activeTab = playbookDetail.globalTabType || 'json';
        playbookDetail.globalConfig = payload.globalConfig;
        let globalObj = playbookDetail.globalConfig[activeTab];
        if (activeTab === 'fields') {
          if (Object.keys(globalObj).length === 0) {
            playbookDetail.global = {};
          } else {
            Object.keys(globalObj).forEach(eachKey => {
              let eachObj = {
                [eachKey]: globalObj[eachKey].default,
              };
              setGlobalObj = { ...setGlobalObj, ...eachObj };
              playbookDetail.global = setGlobalObj;
            });
          }
        } else {
          playbookDetail.global = globalObj;
        }
        yield put({
          type: 'save',
          payload: {
            playbookDetail,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    *updateGlobal(_, { put, select }) {
      try {
        let setGlobalObj = null;
        let playbookDetail = yield select(state => state.editor.playbookDetail);
        let activeTab = playbookDetail.globalTabType || 'json';
        playbookDetail.globalConfig = playbookDetail.globalConfig;
        let globalObj = playbookDetail.globalConfig[activeTab];
        if (activeTab === 'fields') {
          Object.keys(globalObj).forEach(eachKey => {
            let eachObj = {
              [eachKey]: globalObj[eachKey].default,
            };
            setGlobalObj = { ...setGlobalObj, ...eachObj };
            playbookDetail.global = setGlobalObj;
          });
        } else {
          playbookDetail.global = globalObj;
        }
        yield put({
          type: 'save',
          payload: {
            playbookDetail,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    *updateActionInputs({ payload }, { put, select }) {
      try {
        let { actionInputs, nodeId } = payload;

        let copyCurrent = yield select(state => state.editor.current);
        let current = { ...copyCurrent };
        let nodePath = getObjectPath(current, 'id', nodeId);
        nodePath = modifyPath(nodePath);

        let currentNode = getObjectValue(current, nodePath);
        let newActionInputs = {};
        for (const key in actionInputs) {
          newActionInputs[key] = actionInputs[key];
        }

        currentNode.actionInputs = { ...currentNode.actionInputs, ...newActionInputs };

        updateObjValue(current, currentNode, nodePath);

        yield put({
          type: 'save',
          payload: {
            current: current,
          },
        });
      } catch (error) {
        notification.error({
          message: error.message || 'Something went wrong',
        });
      }
    },
    *updateNodeDetail({ payload }, { put, select }) {
      try {
        let { data, nodeId } = payload;

        let copyCurrent = yield select(state => state.editor.current);
        let current = { ...copyCurrent };
        let nodePath = getObjectPath(current, 'id', nodeId);

        nodePath = modifyPath(nodePath);

        let currentNode = getObjectValue(current, nodePath);

        currentNode = { ...currentNode, ...data };

        updateObjValue(current, currentNode, nodePath);

        yield put({
          type: 'save',
          payload: {
            current: current,
          },
        });
      } catch (error) {
        notification.error({
          message: error.message || 'Something went wrong',
        });
      }
    },
    *addAction({ payload }, { put, select }) {
      const { nodeToAdd, onWhichNode, onWhichPort } = payload;

      let editor = yield select(state => state.editor);

      let reaNodes = cloudnosysToRea(editor.current, editor.playbookNodesConfig).nodes;

      // e.g payload: { newActionStructure, whichParent, whichPort }
      // get current playbook from state
      // add new action to current playbook
      // find whichParent in current playbook
      // add newAction to its output
      try {
        if (nodeToAdd) {
          let dragNode = JSON.parse(JSON.stringify(nodeToAdd));
          let reaDropOverNode;
          let reaDropOverExitFirstPort;
          let droppedOnPort;
          let acceptChild = false;
          if (onWhichNode) {
            // 1- find exit 1st port
            // find reaDropOverExitFirstPort and set it to reaDropOverExitFirstPort
            reaDropOverNode = { ...onWhichNode };
            reaDropOverExitFirstPort = reaDropOverNode.ports.find(p => p.side === 'SOUTH');

            let childSupportedNodes = ['Loop'];
            acceptChild = childSupportedNodes.includes(reaDropOverNode.name);
            droppedOnPort = false;
          }
          if (onWhichPort) {
            // 1- find node by portId
            // find reaDropOverNode and set it to reaDropOverNode
            reaDropOverExitFirstPort = { ...onWhichPort };
            reaNodes.forEach(n => {
              n.ports.forEach(p => {
                if (p.id === reaDropOverExitFirstPort.id) {
                  reaDropOverNode = n;
                }
              });
            });
            droppedOnPort = true;
          }
          if (!reaDropOverNode) throw new Error('dropped on canvas');
          // 1- check if if dropOverNode support child nodes
          let cloneData = { ...editor.current };
          let { newId } = generateNodeId(dragNode, cloneData);
          dragNode.id = newId;
          let reteDropOverNode;
          let reteNodePath = getObjectPath(cloneData, 'id', reaDropOverNode.id);
          reteNodePath = modifyPath(reteNodePath);

          reteDropOverNode = getObjectValue(cloneData, reteNodePath);
          if (!reteDropOverNode) throw new Error('not dropped on any node!');
          if (acceptChild) {
            if (Object.keys(reteDropOverNode.children).length === 0) {
              reteDropOverNode.children[dragNode.id] = dragNode;
              updateObjValue(cloneData, reteDropOverNode, reteNodePath);
              yield* addNewNode(put, cloneData);
            }
            // add children in accepted child node
          } else {
            if (reaDropOverNode.name === 'Condition') {
              let portToConnect;

              if (droppedOnPort) {
                portToConnect = onWhichPort;
              } else {
                // find true node in reaDropOverNode
                portToConnect = reaDropOverNode.ports.find(v => splitCondition(v.id) === 'true');
              }
              // connect on true or false
              let whichPort = splitCondition(portToConnect.id) === 'true' ? 'true' : 'false';
              // get edge from the existing node
              // then use the below function
              // addNodeInBetween(edgeToUse, newNodeToAdd); // use this function in 2 places, when node is dropped on plus sign of edge OR when node is dropped on another existing node.
              if (reteDropOverNode.connections[whichPort]) {
                // here handle condition node which have two exits ports- both in loop and outside
                throw new Error(
                  'already attached - dropped on condition node - in both loop and outside!'
                );
              }
              if (reaDropOverNode.parent) {
                reteDropOverNode.connections[whichPort] = dragNode.id;
                dragNode.parent = reteDropOverNode.id;
                reteNodePath = reteNodePath.slice(0, reteNodePath.lastIndexOf('.'));
                let childs = getObjectValue(cloneData, reteNodePath);
                childs[dragNode.id] = dragNode;
                childs[reteDropOverNode.id] = reteDropOverNode;
                updateObjValue(cloneData, childs, reteNodePath);
                yield* addNewNode(put, cloneData);
              } else {
                reteDropOverNode.connections[whichPort] = dragNode.id;
                dragNode.parent = reteDropOverNode.id;
                updateObjValue(cloneData, reteDropOverNode, reteNodePath);
                cloneData[dragNode.id] = dragNode;
                yield* addNewNode(put, cloneData);
              }
            } else {
              if (reteDropOverNode?.connections?.success) {
                // here handle all the single exit nodes both in loop and outside
                if (dragNode.name === 'Condition' && reteDropOverNode.connections.success) {
                  return;
                }
                if (reteDropOverNode.pId) {
                  dragNode.parent = reteDropOverNode.id;
                  let droppedOverExitId = reteDropOverNode.connections.success;
                  reteDropOverNode.connections.success = dragNode.id;
                  dragNode.connections.success = droppedOverExitId;
                  // 1 - update reteDropOverNode exit with draggingNodeId
                  // 2- update reteDropOverNode
                  let retePathNode = getObjectPath(cloneData, 'id', reteDropOverNode.id);
                  retePathNode = modifyPath(retePathNode);
                  retePathNode = retePathNode.slice(0, retePathNode.lastIndexOf('.'));
                  let childs = getObjectValue(cloneData, retePathNode);
                  childs[dragNode.id] = dragNode;
                  childs[reteDropOverNode.id] = reteDropOverNode;

                  childs[droppedOverExitId].parent = dragNode.id;

                  updateObjValue(cloneData, childs, retePathNode);

                  // updateObjValue(cloneData, reteDropOverNode, retePathNode);
                } else {
                  let oldPath = reteDropOverNode.connections.success;
                  reteDropOverNode.connections.success = dragNode.id;
                  updateObjValue(cloneData, reteDropOverNode, reteDropOverNode.id);
                  let nextNode = getObjectValue(cloneData, oldPath);
                  // nextNode.connections.success = dragNode.id;
                  nextNode.parent = dragNode.id;
                  updateObjValue(cloneData, nextNode, nextNode.id);
                  dragNode.connections.success = nextNode.id;
                  dragNode.parent = reteDropOverNode.id;

                  cloneData[dragNode.id] = dragNode;
                }

                // updateObjValue(cloneData, reteDropOverNode, reteDropOverNode.id);

                // 1 - create new id of draggingNode
                // 2 - find exitId of droppedOverNode
                // 3 - find node by exitId and get parent of this node
                // 4 - attach parent to draggingNode = droppedOverNodeId
                // 5 - attach exit to draggingNode = exitId
                // 6 - update obj and set
                // 7 - if droppedOver is in loop then draggingNode is also in loop

                // throw new Error(
                //   'already attached - dropped on single node(any) - in both loop and outside'
                // );
              } else {
                //1- find prevNodeId and nextNodeId
                if (reaDropOverNode.parent) {
                  reteDropOverNode.connections.success = dragNode.id;
                  dragNode.parent = reteDropOverNode.id;
                  reteNodePath = reteNodePath.slice(0, reteNodePath.lastIndexOf('.'));
                  let childs = getObjectValue(cloneData, reteNodePath);
                  childs[dragNode.id] = dragNode;
                  childs[reteDropOverNode.id] = reteDropOverNode;
                  updateObjValue(cloneData, childs, reteNodePath);
                } else {
                  reteDropOverNode.connections.success = dragNode.id;
                  dragNode.parent = reteDropOverNode.id;

                  updateObjValue(cloneData, reteDropOverNode, reteNodePath);

                  cloneData[dragNode.id] = dragNode;
                }
              }

              yield* addNewNode(put, cloneData);
            }
          }

          // handle single node
          payload.fn && payload.fn(dragNode);
        }
      } catch (error) {
        console.log(error);
      }
    },
    *unsubscribe(_, { put }) {
      yield put({ type: `clear:${namespace}` });
    },
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

const handleDataDecompression = data => {
  // Decompress the compressed data
  const decompressedData = lzma.decompressFromBase64(data);

  // Parse the decompressed JSON string back to an object
  const restoredObject = JSON.parse(decompressedData);

  return restoredObject;
};

const handleNewRiskPB = (trigger, pb) => {
  let updatedPB = { ...pb };

  try {
    let alert = {
      riskStatus: trigger?.actionInputs?.riskStatus || '',
      cloudAccounts: trigger?.actionInputs?.cloudAccount || [],
      signatures: trigger?.actionInputs?.signatures || [],
      resources: trigger?.actionInputs?.resources || [],
      services:
        typeof trigger?.actionInputs?.service === 'string'
          ? [trigger?.actionInputs?.service]
          : typeof trigger?.actionInputs?.service === 'undefined'
          ? ''
          : trigger?.actionInputs?.service,
    };
    updatedPB.triggerInfo.alert = alert;
    updatedPB.view[trigger.id].actionInputs.tabType = 'newRisk';
    delete updatedPB.triggerInfo.schedule;
    return updatedPB;
  } catch (error) {
    throw new Error(`Failed while saving New Risk Playbook.`);
  }
};
const handleManualPB = (trigger, updatedPB) => {
  try {
    // TODO:  Save dummy data here
    updatedPB.view[trigger.id].actionInputs.tabType = 'manual';
    return updatedPB;
  } catch (error) {
    throw new Error(`Failed while saving Manual Playbook.`);
  }
};

const handleSchedulePB = (trigger, pb) => {
  let updatedPB = { ...pb };
  try {
    let cronsyntax = '';
    const howOften = trigger.actionInputs?.[`how often should this run?`];
    if (howOften === 'Cron') {
      cronsyntax = trigger.actionInputs['CRON Syntax'];
    } else {
      let interval = trigger?.actionInputs?.['Select Interval'] || 'Every Hour';
      cronsyntax = getRecurrentCronSyntax(interval);
    }
    if (!cronsyntax) {
      throw new Error(`Please enter CRON Syntax`);
    }
    if (cronsyntax[0] === '*') {
      throw new Error(`Cron syntax should be greater than or equal to one hour.`);
    }
    let nextPlayTime = parseCronSyntax(cronsyntax);
    updatedPB.triggerInfo.schedulePlay = false;
    updatedPB.triggerInfo.cronsyntax = cronsyntax;
    updatedPB.triggerInfo.nextPlayTime = nextPlayTime;
    updatedPB.view[trigger.id].actionInputs.tabType = 'schedule';
    delete updatedPB.triggerInfo.alert;

    return updatedPB;
  } catch (error) {
    throw new Error(`Failed to parse cron syntax.`);
  }
};

const validateBeforeSaving = name => {
  // Use lodash _.isEmpty to check if the string is empty
  if (_.isEmpty(name)) {
    throw new Error('Playbook name is required');
  }

  // Use a regular expression to check if the string consists of only spaces
  if (/^ *$/.test(name)) {
    throw new Error('Playbook name contains only spaces');
  }

  // If neither condition is met, the string is not empty and does not contain only spaces
  return;
};

function generateReaNodes(nodesConfig) {
  let cloudnosysNodes = {};
  for (const key in nodesConfig) {
    const node = nodesConfig[key];
    if (node.friendlyName === 'Condition') {
      cloudnosysNodes[node.friendlyName] = {
        id: `${node.friendlyName.replace(/\s+/g, '-').toLowerCase()}-1`,
        name: node.friendlyName,
        description: node.description,
        actionInputs: {},
        action: node.action,
        actionPack: node.actionPack,
        parent: '',
        connections: {
          true: '',
          false: '',
        },
      };
    } else {
      cloudnosysNodes[node.friendlyName] = {
        id: `${node.friendlyName.replace(/\s+/g, '-').toLowerCase()}-1`,
        name: node.friendlyName,
        description: node.description,
        action: node.action,
        actionPack: node.actionPack,
        actionInputs: {},
        parent: '',
        children: {},
        connections: {
          success: '',
          error: '',
        },
      };
    }
  }
  return cloudnosysNodes;
}

function* addNewNode(put, cloudnosysData) {
  yield put({
    type: 'save',
    payload: {
      current: cloudnosysData,
    },
  });
}

function getRecurrentCronSyntax(key) {
  let recurrentMeta = {
    'Every Hour': '0 * * * *',
    'Every Day': '0 0 * * *',
    'Every Week': '0 0 * * 0',
  };

  let cronSyntax = recurrentMeta[key];

  return cronSyntax || key;
}

function parseCronSyntax(cronSyntax) {
  let interval = CronParser.parseExpression(cronSyntax, { utc: true });
  let next = interval.next().toDate();
  return next;
}

const getTriggerPayload = (globalData, resourceDetail, orgId, orgName) => {
  return {
    signature: resourceDetail?.signatures[0],
    service: resourceDetail?.services[0],
    status: resourceDetail?.riskStatus || 'fail',
    organizationId: orgId,
    organizationName: orgName,
    region: resourceDetail?.resources?.[0]?.region || 'us-east-1',
    cloudAccountId: globalData?.cloudAccount || resourceDetail?.resources[0]?.cloudAccountId,
    resourceId: resourceDetail?.resources?.[0]?.id || '',
    resourceName: resourceDetail?.resources?.[0]?.name || '',
    projectId: resourceDetail?.resources?.[0]?.projectId || '',
  };
};
