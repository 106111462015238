import moment from 'moment';


export const DateOfDays = (date, numOfDays) =>
    [
        {
            date: date.subtract(numOfDays - 1, 'days').locale('en-gb').format('DD MMM YYYY'),
        },
        {
            date: date.add(1, 'days').locale('en-gb').format('DD MMM YYYY'),
        },
        {
            date: date.add(1, 'days').locale('en-gb').format('DD MMM YYYY'),
        },
        {
            date: date.add(1, 'days').locale('en-gb').format('DD MMM YYYY'),
        },
        {
            date: date.add(1, 'days').locale('en-gb').format('DD MMM YYYY'),
        },
        {
            date: date.add(1, 'days').locale('en-gb').format('DD MMM YYYY'),
        },
        {
            date: date.add(1, 'days').locale('en-gb').format('DD MMM YYYY'),
        },
    ];