import stringToRegExp from 'string-to-regexp';

const addSuppressedFlagsForRisks = (data, path, regId, selectedCloudAccIds) => {
  if (path.length > 0) {
    let eachRegulationData = data.reduce((acc0, eachRisk) => {
      eachRisk.rulesApplied = [];
      for (let i in path) {
        let eachPath = path[i].path;
        let accIndex = selectedCloudAccIds.indexOf(eachPath?.cloudAccountId.replace(/\//g, ''));
        if (
          checkPathByRegix(selectedCloudAccIds[accIndex], eachPath.cloudAccountId) &&
          checkPathByRegix(regId, eachPath.regId) &&
          checkPathByRegix(eachRisk.name, eachPath.signatureId)
        ) {
          eachRisk.suppress = true;
          eachRisk.rulesApplied = eachRisk.rulesApplied
            ? [...eachRisk.rulesApplied, eachPath]
            : [...eachPath];
        }
      }
      let result = eachRisk.suppress ? true : false;
      return [...acc0, { ...eachRisk, suppress: result }];
    }, []);

    return eachRegulationData;
  } else {
    return data.map(eachRisk => {
      return {
        ...eachRisk,
        suppress: false,
      };
    });
  }
};

const checkPathByRegix = (currentObjVal, pathValue) => {
  if (!pathValue) {
    return true;
  } else {
    return stringToRegExp(pathValue).test(currentObjVal);
  }
};

export default addSuppressedFlagsForRisks;
