import { cloudViewCall, getCloudViewQuery } from '@/services/cloudView';
import { SCAN_STATUS } from '@/utils/constant';
import { notification } from 'antd';
import { decryptAES } from '@/utils/utils';
import { deleteCloudViewPreset, saveCloudViewPreset } from '@/services/cloudViewPreset';

export default {
  namespace: 'cloudviz',
  state: {
    loading: false,
    selectedProvider: 'AWS',
    activeButton: '',
    query: '', // query can be typed manually and will be returned from the backend as well
    disable: true, // use this flag for disabling buttons and  throttling
    cloudAccountData: {},
    filteredAccounts: [], // wrt to selected provider
    data: { nodes: [], edges: [], context: '' },
    presetModalLoading: false,
    presetModalState: false,
  },

  effects: {
    *handleCloudProviderSelection({ payload }, { put, select }) {
      try {
        let { providerName } = payload;

        if (!providerName) providerName = 'AWS';

        // saves provider name
        yield put({
          type: 'save',
          payload: {
            selectedProvider: providerName,
          },
        });

        // take all cloud accounts
        const { selectedCloudAccounts } = yield select(state => state.cloudAccount);
        const filteredCloudAccounts = selectedCloudAccounts;

        if (filteredCloudAccounts && filteredCloudAccounts.length) {
          yield put({
            type: 'save',
            payload: {
              filteredAccounts: filteredCloudAccounts,
              cloudAccountData: filteredCloudAccounts.filter(
                e => e.provider && e.provider.toLowerCase() !== 'azure'
              )[0], // skip this logic once cloud view for azure is done
              activeButton: '',
              query: '',
              disable: true,
              data: { nodes: [], edges: [], context: '' },
            },
          });
        }
      } catch (error) {
        console.log('Error from handleCloudProviderSelection: ', error.message);
      }
    },
    *handleCloudAccountSelection({ payload }, { put, select }) {
      try {
        const { account } = payload;

        const { query, activeButton } = yield select(state => state.cloudviz);
        let [queryCopy, activeButtonCopy] = [query, activeButton];

        yield put({
          type: 'save',
          payload: {
            cloudAccountData: account,
          },
        });

        if (
          account?.status &&
          account.status === SCAN_STATUS.COMPLETED.status &&
          account.cloudViewConfig &&
          account.cloudViewConfig.status === SCAN_STATUS.COMPLETED.status
        ) {
          yield put({
            type: 'cloudviz/getQuery',
            payload: {
              type: 'internet-facing-instances',
              disable: false,
            },
          });
        } else {
          yield put({
            type: 'save',
            payload: {
              query: 'Error in cloud account status',
              activeButton: '',
              disable: true,
              data: { nodes: [], edges: [], context: '' },
            },
          });
        }

        yield put({
          type: 'save',
          payload: {
            query: queryCopy,
            activeButton: activeButtonCopy,
          },
        });
      } catch (error) {
        console.log('Error from handleCloudAccountSelection: ', error.message);
      }
    },

    *getQuery({ payload }, { put, select, call }) {
      const { type } = payload;

      const { cloudAccountData } = yield select(state => state.cloudviz);

      if (cloudAccountData && Object.keys(cloudAccountData)?.length) {
        // Use 'length' to ensure it's an array and has items
        yield put({
          type: 'save',
          payload: {
            loading: true,
            activeButton: type,
            disable: payload.disable,
            query: 'loading...',
          },
        });

        const cloudViewSelectedAccount = cloudAccountData; // Directly use from state
        const cloudAccounts = cloudAccountCredentials([cloudViewSelectedAccount]); // Ensure this function is defined or imported
        const URI = getURI(cloudAccounts); // Ensure this function is defined or imported

        const response = yield call(getCloudViewQuery, {
          cloudAccounts,
          URI,
          type,
        });

        if (response?.status === 200) {
          yield put({
            type: 'save',
            payload: {
              query: response.query,
            },
          });
          yield put({
            type: 'runQuery',
            payload: {
              type,
              URI,
            },
          });
        } else {
          notification.error({ message: 'Something went wrong!' });
          yield put({
            type: 'save',
            payload: {
              query: '',
              loading: false,
            },
          });
        }
      } else {
        notification.error({ message: 'No selected cloud accounts available.' });
        yield put({
          type: 'save',
          payload: {
            query: 'No data',
            loading: false,
          },
        });
      }
    },

    *runQuery({ payload }, { select, call, put }) {
      const { query, cloudAccountData } = yield select(state => state.cloudviz);
      if (!cloudAccountData || Object.keys(cloudAccountData).length === 0) {
        notification.error({ message: 'No selected cloud accounts available.' });
        yield put({
          type: 'save',
          payload: {
            query: 'No data',
            loading: false,
          },
        });
        return;
      }
      const { rawQuery, type } = payload;
      yield put({
        type: 'save',
        payload: {
          loading: true,
          activeButton: type,
        },
      });
      const cloudAccounts = cloudAccountCredentials([cloudAccountData]);

      let URI = 'run-query'; // by default URI
      if (!rawQuery) {
        URI = `${getURI(cloudAccounts)}/data`;
      }

      const response = yield call(cloudViewCall, {
        rawQuery,
        query,
        cloudAccounts,
        URI,
        type,
        orgId: cloudAccountData.orgId,
        cloudAccountDocId: cloudAccountData.id,
      });
      if (response?.status && response.status === 200) {
        yield put({
          type: 'save',
          payload: {
            data: response,
          },
        });
      } else {
        notification.error({ message: `Something went wrong!` });
      }
      yield put({
        type: 'save',
        payload: {
          loading: false,
        },
      });
    },
    *saveCloudViewPreset({ payload }, { put, select, call }) {
      try {
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: true,
          },
        });
        let { uid, orgId, presetName } = payload;
        let query = yield select(state => state.cloudviz.query);

        const response = yield call(saveCloudViewPreset, {
          uid,
          orgId,
          cloudViewPreset: { name: presetName, query, createdAt: new Date() },
        });
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: false,
            presetModalState: false,
          },
        });
        if (!response) {
          notification.error({
            message: 'Something went wrong',
          });
        }
      } catch (error) {
        console.error('Error from Save Filter Preset: ', error);
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: false,
            presetModalState: false,
          },
        });
      }
    },
    *deleteFilterPreset({ payload }, { put, call }) {
      try {
        yield put({
          type: 'update',
          payload: {
            presetModalLoading: true,
          },
        });
        const { name, uid, preset } = payload;

        const response = yield call(deleteCloudViewPreset, { name, uid, preset });
      } catch (error) {
        console.log('Error from Delete Filter Preset: ', error);
        notification.error({
          message: 'Error while deleting preset',
        });
      } finally {
        yield put({
          type: 'update',
          payload: {
            presetModalLoading: false,
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

const cloudAccountCredentials = selectedCloudAccounts => {
  let credentials = [];
  selectedCloudAccounts.map(each => {
    if (each.provider.toLowerCase() === 'aws') {
      credentials.push({ cloudType: each.provider.toLowerCase(), id: each.awsAccountId });
    } else if (each.provider.toLowerCase() === 'azure' && each.userkeys) {
      const userData = JSON.parse(decryptAES(each.userkeys));
      credentials.push({ cloudType: each.provider.toLowerCase(), id: userData.clientId });
    } else if (each.provider.toLowerCase() === 'gcp' && each.userkeys) {
      const userData = JSON.parse(decryptAES(each.userkeys));
      credentials.push({ cloudType: each.provider.toLowerCase(), id: userData.projectId });
    }
  });
  return credentials;
};

const getURI = cloudAccountsArray => {
  if (cloudAccountsArray.length === 1) return 'posture';
  else if (cloudAccountsArray.length > 1) return 'posture-multiple';
  else return 'run-query';
};
