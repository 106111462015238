import { firestore } from '../utils/firebase';

export async function updateNotificationState(payload) {
  try {
    await firestore
      .collection('Users')
      .doc(payload.userId)
      .collection('alerts')
      .get();
  } catch (err) {
    console.error('Error in updateNotificationState: ', err);
  }
}
