import { list } from '@/services/firestore';
import _ from 'lodash';

const namespace = 'resourcesReport';
export default {
  namespace,
  state: {
    loadingReport: false,
    report: [],
    cloudAccountsReports: null,
  },

  effects: {
    *getReport({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'save',
          payload: { loadingReport: true },
        });

        const previousCloudAccountReports = yield select(
          state => state.resourcesReport.cloudAccountsReports
        );

        let combinedResources = [];
        let cloudAccountsReportsData = { ...previousCloudAccountReports };

        for (let cloudAccountId of payload.cloudAccounts) {
          // Check if report for this account already exists
          if (!cloudAccountsReportsData[cloudAccountId]) {
            // Report not present, fetch it
            const resourcesReports = yield call(list, {
              module: namespace,
              params: {
                orgId: payload.orgId,
                cloudAccountId: cloudAccountId,
              },
              stream: false,
            });

            if (resourcesReports && !resourcesReports.empty) {
              let allResources = _.flatMap(resourcesReports.docs, _.property('resources'));
              combinedResources = [...combinedResources, ...allResources];
              cloudAccountsReportsData[cloudAccountId] = allResources;
            }
          } else {
            // Report already exists, use it
            combinedResources = [...combinedResources, ...cloudAccountsReportsData[cloudAccountId]];
          }
        }

        yield put({
          type: 'save',
          payload: {
            report: combinedResources,
            cloudAccountsReports: cloudAccountsReportsData,
            loadingReport: false,
          },
        });

        // TODO: Additional actions if required
      } catch (error) {
        console.error('error in getReport', error);
      }
    },

    // ******* The Effect to monitor changing or selecting/Deselecting cloud account from drawer, so that we can fetch the reports if its not fetch for any cloudaccount previously
    *checkSubscriptionUpdation({ payload }, { select, push, takeEvery, take, call, put }) {
      const cloudAccountSelection = 'cloudAccount/addSelected';
      const cloudAccountRemoval = 'cloudAccount/removeSelected';
      const serviceTBWatched = ['cloudAccount/addSelected', 'cloudAccount/removeSelected'];
      // create subscription
      function* push(response) {
        if (response.type === cloudAccountSelection) {
          const currentReport = yield select(state => state[namespace].report);
          const isCloudAccountIdExistsInReport = _.find(currentReport, {
            id: response.payload.id,
          });
          if (!isCloudAccountIdExistsInReport) {
            // yield put({
            //   type: 'getReport',
            //   payload: {
            //     orgId: response.payload.orgId,
            //     cloudAccounts: [response.payload.id],
            //   },
            // });
          }
        } else if (response.type === cloudAccountRemoval) {
        }
      }
      // on every callback from service
      yield takeEvery(serviceTBWatched, push);
      // unsubscribe & clear when this action fired
      yield take(`clear:${namespace}/checkSubscriptionUpdation`);

      serviceTBWatched.close();
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveReport(state, { payload }) {
      return {
        ...state,
        report: payload,
        loadingReport: false,
      };
    },
    clearReport(state) {
      return {
        ...state,
        loadingReport: false,
      };
    },
  },
};
