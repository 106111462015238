export default {
  firebase: {
    apiKey: 'AIzaSyCHSe-8UoH9glcfQC8N4vaE8zEiZATkvW4',
    authDomain: 'cloudnosys-pre-dev.firebaseapp.com',
    projectId: 'cloudnosys-pre-dev',
    databaseURL: 'cloudnosys-pre-dev.firebaseio.com',
    storageBucket: 'cloudnosys-pre-dev.appspot.com',
    messagingSenderId: '496077582325',
    appId: '1:496077582325:web:3a49eedb9dc6c43bba3abc',
  },
  api: {
    playbooks: 'https://cloudnosys-pre-dev.web.app/runPlaybook',
  },
  masterOrg: 'master-cloudnosys_5aj5e',
};
