import { eventChannel } from 'dva/saga';
import moment from 'moment';
import errorHandler from '../utils/errorHandler';
import { firestore } from '../utils/firebase';
import { generateId } from '../utils/generateId';

// import request from 'umi-request';
const moduleGetDB = (module, params) => {
  return getModuleConfig(module, params).db;
};

const moduleGenerateId = (module, params, data) => {
  return getModuleConfig(module, params).idGenerator(data);
};

const getModuleConfig = (module, params) => {
  // let idGenerator = () => generateId(`${module.substr(0, 2)}`);
  let idGenerator = () => moment().format('YYYY-MM-DD h:m:s:SSS');

  let db;

  switch (module) {
    case 'organisation':
      idGenerator = ({ name }) => generateId('', { slug: name }); // should generate slug from data.name
      db = firestore.collection('Organizations');
      break;

    case 'scanProgress':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.id)
        .collection('scanProgress');
      break;

    case 'user':
      db = firestore.collection('Users');
      break;

    case 'Signatures2':
      db = firestore.collection('Signatures2');
      break;

    case 'Signatures':
      db = firestore.collection('Signatures');
      break;

    // case 'cveData':
    //   db = firestore
    //     .collection('Organizations')
    //     .doc(params.orgId)
    //     .collection('cloudAccounts')
    //     .doc(params.cloudAccountId)
    //     .collection('resourcesReport')
    //   // .doc(params.rsId)
    //   // .collection('cloudXRayRisks')
    //   break;

    case 'accessControl':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('accessControl');
      break;

    case 'accessInvites':
      idGenerator = () => generateId(`${params.orgId.substr(0, 2)}-${module.substr(0, 1)}`);
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('accessInvites');
      break;

    case 'customSignatures':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('customSignatures');
      break;

    case 'playbooks':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('playbooks');
      break;

    case 'editor':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('editor');
      break;

    case 'playbooksHistory':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('editor')
        .doc(params.playbookId)
        .collection('history');
      break;

    case 'reports':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('regulations')
        .doc(params.reportId)
        .collection('reports');
      break;

    case 'scopeReport':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('scopeReport');
      break;

    case 'widget':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('widget')
        .doc(params.widgetId)
        .collection('reports');
      break;

    case 'reportsHistory':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('regulations')
        .doc(params.reportId)
        .collection('reports')
        .doc(params.hashedObj)
        .collection('history');
      break;

    case 'dashboardsReports':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('dashboard')
        .doc(params.dashboardId)
        .collection('reports');
      break;

    case 'notifications':
      db = firestore
        .collection('Users')
        .doc(params.userId)
        .collection('alerts');
      break;

    case 'cloudAccount':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts');
      break;

    case 'billingDashboard':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('settings');
      break;
    case 'integrations':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('settings');
      break;

    case 'private':
      db = firestore
        .collection('Users')
        .doc(params.userId)
        .collection('private');
      break;

    case 'Regulations':
      db = firestore.collection('Regulations');

      break;

    case 'customRegulations':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('customRegulations');
      break;

    case 'resourcesReport':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('resourcesReport');
      break;
    case 'resourcesTags':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('reports');
      break;
    case 'resourcesRisks':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('reports');
      break;
    case 'resources':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('resources');

      break;
    case 'Global':
      db = firestore.collection('Global');

      break;

    case 'risks':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('resources')
        .doc(params.resourceId)
        .collection('risks');

      break;
    case 'cloudXRayRisks':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('resources')
        .doc(params.resourceId)
        .collection('cloudXRayRisks');

      break;
    case 'playbookTemplate':
      db = firestore
        .collection('Global')
        .doc('playbook')
        .collection('templates');
      break;
    case 'thirdPartyRisks':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('resources')
        .doc(params.resourceId)
        .collection('third_party_risks');
      break;

    case 'thirdPartyRisksSubCollections':
      db = firestore
        .collection('Organizations')
        .doc(params.orgId)
        .collection('cloudAccounts')
        .doc(params.cloudAccountId)
        .collection('resources')
        .doc(params.resourceId)
        .collection('third_party_risks')
        .doc(params.subId)
        .collection('risks');

      break;

    default:
      break;
  }

  return { db, idGenerator };
};

/**
 * Get list of documents
 */
export const list = async ({ module, params, orderBy, conditions, limit, stream = true }) => {
  // console.log('List function called, stream is', stream, ' | ', module);
  try {
    let ref = moduleGetDB(module, params);
    // where conditions [['key', '==', 'value'],...]
    if (conditions) {
      conditions.forEach(condition => {
        if (condition.length < 3) {
          return;
        }
        ref = ref.where(condition[0], condition[1], condition[2]);
      });
    }
    // order by
    if (orderBy) ref = ref.orderBy(orderBy[0], orderBy[1]);
    if (params && params.limit) ref = ref.limit(params.limit);
    if (params && params.lastVisible) ref = ref.startAfter(params.lastVisible);
    if (params && params.severity) {
      if (params.severity === 'passed') {
        ref = ref.where('risk.status', '==', 'pass');
      } else if (
        params.severity === 'high' ||
        params.severity === 'medium' ||
        params.severity === 'low'
      ) {
        ref = ref.where('risk.severity', '==', params.severity).where('risk.status', '==', 'fail');
      } else {
        ref = ref.where('risk.severity', '==', params.severity);
      }
    }
    if (params && params.type) ref = ref.where('type', '==', params.type);

    // limit
    if (limit) ref = ref.limit(limit);

    return stream ? streamList(ref, { params }) : await queryList(ref);
  } catch (error) {
    console.log('Error From Firestore', error);
  }
};
/**
 * Get single document with provided id in params
 */
export const read = async ({ module, params, no404error = false, stream = true }) => {
  // console.log('Read function called, stream is', stream, ' | ', module);
  const ref = moduleGetDB(module, params).doc(params.id);

  return stream ? streamRead(ref, { params, no404error }) : queryRead(ref, { params, no404error });
};

export const create = async ({ module, params, data, useFirestoreId = false }) => {
  const id = params.id ? params.id : moduleGenerateId(module, params, data);

  data.id = id;

  try {
    let ref = moduleGetDB(module, params);

    const inputData = {
      createdAt: new Date(),
      ...data,
    };

    if (useFirestoreId) {
      const snapShot = await ref.add(inputData);
      return snapShot.id;
    } else {
      await ref.doc(id).set(inputData);
      return id;
    }
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

export const update = async ({ module, params, data }) => {
  try {
    const ref = moduleGetDB(module, params);
    await ref.doc(params.id).update({
      updatedAt: new Date(),
      ...data,
    });
    return true;
  } catch (err) {
    console.log('err :>> ', err);
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

// cant name it 'delete' as its reserved in JS
export const remove = async ({ module, params }) => {
  try {
    const ref = moduleGetDB(module, params);
    await ref.doc(params.id).delete();
    return true;
  } catch (err) {
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

// Get list of documents
const queryList = async ref => {
  let docs = [];

  try {
    let getData = await ref.get();
    let lastVisible = getData.docs[getData.docs.length - 1];


    getData.forEach(doc => {
      if (doc.exists) {
        docs.push({
          id: doc.id,
          ...doc.data(),
        });
      }
    });

    return {
      docs,
      lastVisible,
    };
  } catch (err) {
    console.log('err in queryList:>> ', err);
    errorHandler({
      status: err.code,
      statusText: err.message,
      statusRaw: err,
    });
    return false;
  }
};

// Get list of documents and listen for changes
const streamList = async ref => {
  // console.log('streamList function called 🆗');
  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      async querySnapshot => {
        let docs = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            docs.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });

        const readSize = querySnapshot.size; // Number of documents read

        emitter(docs);
        docs = [];
      },
      err => {
        errorHandler({
          status: err.code,
          statusText: err.message,
          statusRaw: err,
        });
        emitter([]);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
};

// Get a document
const queryRead = async ref => {
  try {
    const doc = await ref.get();

    if (doc.exists) {
      return {
        id: doc.id,
        ...doc.data(),
      };
    } else return false;
  } catch (err) {
    console.error('Error in queryRead', err);
    return false;
  }
};

// Get a document and listen to changes
const streamRead = async (ref, { no404error = false }) => {
  return eventChannel(emitter => {
    const unsubscribe = ref.onSnapshot(
      async querySnapshot => {
        let singleDoc = false;
        if (querySnapshot.exists) {
          singleDoc = {
            id: querySnapshot.id,
            ...querySnapshot.data(),
          };
        } else {
          if (!no404error) {
            // errorHandler({
            //   status: 404,
            //   statusText: module + ' doesnt exist with id' + params.id,
            // });
          }
        }
        emitter(singleDoc);

        singleDoc = {};
      },
      err => {
        console.error('Error in streamRead', err);
        emitter(false);
      }
    );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
};
