export default {
  firebase: {
    apiKey: 'AIzaSyBCnmwRI_toBWQuaPNPS-iVrOYzhCryIlo',
    authDomain: 'self-hosted-cloudnosys.firebaseapp.com',
    projectId: 'self-hosted-cloudnosys',
    storageBucket: 'self-hosted-cloudnosys.appspot.com',
    messagingSenderId: '553516357412',
    appId: '1:553516357412:web:51197b64726c86f85c05b1',
    databaseURL: 'https://self-hosted-cloudnosys.firebaseio.com',
  },
  masterOrg: 'cloudnosys-master_s0f8f',
};
