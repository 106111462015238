import { eventChannel } from 'dva/saga';
import { firebase, firestore } from '../utils/firebase';

//Add Integration
export async function addIntegration(payload) {
  let orgId = localStorage.getItem('orgId');
  try {
    let res = await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc('integrations')
      .get();
    if (
      res.data() === undefined ||
      Object.keys(res.data()).length < 1 ||
      (res.data() !== undefined && res.data().integrations.length < 1)
    ) {
      try {
        payload.integrations[0].id = '1';
        await firestore
          .collection('Organizations')
          .doc(orgId)
          .collection('settings')
          .doc('integrations')
          .set({
            integrations: payload.integrations,
          });
      } catch (error) {
        console.log(error);
      }
    } else if (res.data() !== undefined && res.data().integrations.length > 0) {
      let resDoc = await firestore
        .collection('Organizations')
        .doc(orgId)
        .collection('settings')
        .doc('integrations')
        .get();
      const updatedIntegration = resDoc.data().integrations;

      let userSelected = payload.integrations[0];

      let beforeConcat = await updatedIntegration.reduce(async (acc, data) => {
        let waitAcc = await acc;
        if (data.name !== userSelected.name) {
          return [...waitAcc, data, userSelected];
        } else {
          return waitAcc;
        }
      }, Promise.resolve([]));

      let finalResp = await beforeConcat.reduce(async (acc, data) => {
        let waitAcc = await acc;
        let obj = {};
        if (data.name === userSelected.name) {
          obj = data;
          obj.id = '1';
        } else if (data.name !== userSelected.name) {
          obj = data;
          obj.id = '0';
        } else {
          obj = data;
          obj.id = '1';
        }

        return [...waitAcc, obj];
      }, Promise.resolve([]));

      try {
        await firestore
          .collection('Organizations')
          .doc(orgId)
          .collection('settings')
          .doc('integrations')
          .set({
            integrations: finalResp,
          });
      } catch (error) {
        console.log(error);
      }
    }
  } catch (err) {
    console.error('Error in addIntegration: ', err);
  }
}

export async function authUserWithSaml(payload) {
  /**
   * @payload payload contains provider id and user Id
   * @description This method will first check for UserId and if the user exists or not, if the user does exist then we will check for SAML auth with provider Id
   */

  try {
    const { userId } = payload;

    let userIdFromFs = (await firestore
      .collection('Users')
      .doc(userId)
      .get()).data();

    if (userIdFromFs) {
      // User successfully found
      // Make Auth calls for SAML
      const samlAuthProvider = firebase.auth.SAMLAuthProvider('saml.cloudnosys');

      firebase
        .auth()
        .signInWithPopup(samlAuthProvider)
        .then(() => {
          //Now redirect the user towards the security dashboard.
        });
    }
  } catch (err) {
    throw err;
  }
}

//fetch Integration
export async function fetchIntegration() {
  let orgId = localStorage.getItem('orgId');
  return eventChannel(emitter => {
    const unsubscribe = firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc('integrations')
      .onSnapshot(
        async querySnapshot => {
          let docs = [];
          const integrationsData = querySnapshot.data()?.integrations;
          if (integrationsData) {
            integrationsData.forEach(doc => {
              if (doc) {
                docs.push(doc);
              }
            });
          }
          emitter(docs);
          docs = [];
        },
        err => {
          console.error('Error fetching documents: ', err);
          emitter([]);
        }
      );
    // The subscriber must return an unsubscribe function
    return () => unsubscribe();
  });
}

//update Integration
export async function updateIntegration(payload) {
  let orgId = localStorage.getItem('orgId');

  try {
    let res = await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc('integrations')
      .get();

    try {
      const arr = res.data().integrations;
      let updatedArray = await arr.reduce(async (acc, val) => {
        let waitAcc = await acc;
        let risk = {};
        if (val.name === payload.name) {
          switch (payload.name) {
            case 'slack':
              risk = val;
              risk.options = payload.obj;
              break;
            case 'webhook':
              risk = val;
              risk = payload;
              break;
            default:
          }
        } else {
          //remaining keys remain same
          risk = val;
        }
        return [...waitAcc, risk];
      }, Promise.resolve([]));
      try {
        await firestore
          .collection('Organizations')
          .doc(orgId)
          .collection('settings')
          .doc('integrations')
          .set({
            integrations: updatedArray,
          });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
}

//delete Integration
export async function deleteIntegration(payload) {
  let orgId = localStorage.getItem('orgId');
  try {
    let res = await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc('integrations')
      .get();

    const remainingIntegrations = res.data().integrations.filter(int => int.name !== payload.name);
    await firestore
      .collection('Organizations')
      .doc(orgId)
      .collection('settings')
      .doc('integrations')
      .set({
        integrations: remainingIntegrations,
      });
    return remainingIntegrations;
  } catch (error) {
    return null;
  }
}
