import router from 'umi/router';

export const pushParamsToUrl = (drawerParams, drawerType, filtersParam = 'filters') => {
  // get orgId
  const orgId = localStorage.getItem('orgId');

  // filter empty filters
  let queryDrawerParams = drawerParams.filter(v => v.type && v.type.length > 0);

  // stringify it
  queryDrawerParams = JSON.stringify(queryDrawerParams);

  // prepare url params
  const urlQuery = `?org=${orgId}&${filtersParam}=${queryDrawerParams}&dType=${drawerType}`;

  // push in url
  router.push(urlQuery);
};
