let portDefaultDimensions = {
  width: 10,
  height: 10,
};

// cloudnosys to reaFlow format helper
export const cloudnosysToRea = (
  nodesObj,
  playbookNodesConfig,
  extraNodes = [],
  extraEdges = []
) => {
  let edges = [];
  let nodes = [];

  nodes = nodes.concat(extraNodes);
  edges = edges.concat(extraEdges);

  for (const id in nodesObj) {
    let childNodes;
    const node = nodesObj[id];
    childNodes = node.children && Object.keys(node.children).length === 0 ? false : node.children;

    if (childNodes) {
      // parent = id;
      for (const childKey in childNodes) {
        // pId
        childNodes[childKey].pId = id;
      }
    }
    // validate inputs here
    let inputValidated = validateInputs(node, playbookNodesConfig);

    let nObj = {
      id: node.id,
      name: node.name,
      description: node.description,
      customName: node.customName || '',
      customDescription: node.customDescription || '',
      actionInputs: node.actionInputs,
      inputsValidated: inputValidated,
      parent: node.pId || '',
      ports: generatePorts(node),
    };

    if (nObj.name === 'Loop') {
      nObj.height = 200;
      nObj.width = 300;
    } else {
      nObj.height = 70;
      nObj.width = 350;
    }

    nodes.push(nObj);
    if (childNodes) {
      let r = cloudnosysToRea(childNodes, playbookNodesConfig, nodes, edges);
      const { nodes: newNodes, edges: newEdges } = r;
      nodes = newNodes;
      edges = newEdges;
    }
  }

  edges = edges.concat(generateEdges(nodesObj));

  return {
    nodes,
    edges,
  };
};

function validateInputs(node, playbookNodesConfig) {
  let required = [];
  let isValidate = false;
  let nodeConfigInputs = playbookNodesConfig[node.name].inputs;

  let { actionInputs } = node;

  if (node.name === 'Trigger') {
    if (
      actionInputs.hasOwnProperty('how often should this run?') &&
      actionInputs['how often should this run?'] === 'Cron' &&
      !actionInputs['CRON Syntax']
    ) {
      isValidate = false;
    } else {
      isValidate = true;
    }
  } else if (node.name === 'Condition') {
    if (
      Object.keys(actionInputs).length > 0 &&
      actionInputs?.conditions[0]?.first.length > 0 &&
      actionInputs?.conditions[0]?.last.length > 0
    ) {
      isValidate = true;
    } else {
      isValidate = false;
    }
  } else {
    for (const actions in nodeConfigInputs) {
      if (nodeConfigInputs[actions].hasOwnProperty('required')) {
        required.push(actions);
      }
    }
    let checkValidate =
      required.length > 0 &&
      required.every(
        val =>
          actionInputs &&
          actionInputs.hasOwnProperty(val) &&
          actionInputs[val] &&
          actionInputs[val].length > 0
      );
    isValidate = checkValidate;
  }

  return isValidate;
}

function generateEdges(nodes) {
  let edges = [];

  for (const id in nodes) {
    const element = nodes[id];

    if (element.name === 'Condition') {
      if (element.connections.true) {
        let from = element.id;
        let to = element.connections.true;

        let edgeId = `${from}-${to}-edge-true`;
        let edge = {
          id: edgeId,
          from,
          to,
          text: 'true',
          parent: element.pId || '',
          fromPort: `${from}-bottom-true`,
          toPort: `${to}-top-0`,
        };

        if (from && to) {
          edges.push(edge);
        }
      }

      if (element.connections.false) {
        let from = element.id;
        let to = element.connections.false;

        let edgeId = `${from}-${to}-edge-false`;
        let edge = {
          id: edgeId,
          from,
          text: 'false',
          to,
          parent: element.pId || '',
          fromPort: `${from}-bottom-false`,
          toPort: `${to}-top-0`,
        };

        if (from && to) {
          edges.push(edge);
        }
      }
    } else {
      if (element.connections.success) {
        let from = element.id;
        let to = element.connections.success;

        let edgeId = `${from}-${to}-edge-success`;
        let edge = {
          id: edgeId,
          from,
          to,
          parent: element.pId || '',
          fromPort: `${from}-bottom-0`,
          toPort: `${to}-top-0`,
        };

        if (from && to) {
          edges.push(edge);
        }
      }

      if (element.connections.error) {
        let from = element.id;
        let to = element.connections.success;

        let edgeId = `${from}-${to}-edge-error`;
        let edge = {
          id: edgeId,
          from,
          to,
          parent: element.pId || '',
          fromPort: `${from}-bottom-0`,
          toPort: `${to}-top-0`,
        };
        if (from && to) {
          edges.push(edge);
        }
      }
    }
  }

  return edges;
}

function generatePorts(node) {
  let ports = [];
  if (node.name === 'Condition') {
    // generate two exit port and 1 input
    ports.push({
      id: `${node.id}-top-0`,
      ...portDefaultDimensions,
      side: 'NORTH',
      hidden: true,
    });

    ports.push({
      id: `${node.id}-bottom-true`,
      ...portDefaultDimensions,
      side: 'SOUTH',
      hidden: false,
    });

    ports.push({
      id: `${node.id}-bottom-false`,
      ...portDefaultDimensions,
      side: 'SOUTH',
      hidden: false,
    });
  } else {
    // generate 1 input port and 1 exit
    ports.push({
      id: `${node.id}-top-0`,
      ...portDefaultDimensions,
      side: 'NORTH',
      hidden: true,
    });

    ports.push({
      id: `${node.id}-bottom-0`,
      ...portDefaultDimensions,
      side: 'SOUTH',
      hidden: false,
    });
    if (node.errorEnabled) {
      ports.push({
        id: `${node.id}-bottom-error-0`,
        ...portDefaultDimensions,
        side: 'SOUTH',
        hidden: false,
      });
    }
  }

  return ports;
}

// generate NodeID
export const generateNodeId = (node, nodes, total = 0, arr = []) => {
  let {id} = node;

  for (const key in nodes) {
    const element = nodes[key];
    if (element.name === node.name) {
      total = total + 1;
      arr.push(element.id);
    }

    if (element.children && Object.keys(element.children).length > 0) {
      let r = generateNodeId(node, element.children, total, arr);
      total = r.total;
      arr = r.arr;
    }
  }

  if (total) {
    let arr2 = arr.map(text => {
      let parts = text.split('-');
      let id = parts.pop();
      return parseInt(id);
    });
    let a = Math.max(...arr2);

    id = `${node.name.replace(/\s+/g, '-').toLowerCase()}-${a + 1}`;
  }

  return { newId: id, total, arr };
};

// get condition key - TRUE || FALSE
export const splitCondition = str => {
  str.slice(0, str.lastIndexOf('-') + 1);
  let text = str.slice(str.lastIndexOf('-') + 1);
  return text;
};

// convert nodes array into object or vice versa
export const toggleNodesMetaData = nodes => {
  let newNodes = {};

  if (Array.isArray(nodes)) {
    nodes.forEach(element => {
      newNodes[element.id] = element;
    });
  } else {
    newNodes = Object.keys(nodes).map(k => nodes[k]);
  }

  return newNodes;
};
