import hash from 'object-hash';

/**
 * Convert given object to MD5 checksum string
 * @param {object} scope containing multiple filters with key value pairs
 */
export const createScopeChecksum = scope => {
  let sortedScope = Object.keys(scope).map(filterKey => {
    return typeof scope[filterKey] === 'object'
      ? filterToString(scope[filterKey])
      : scope[filterKey];
  });
  sortedScope.sort();
  return hash(sortedScope);
};

/**
 * Convert given array to sorted and trimmed string
 * @param {object} eachFilter array e.g ['a2', 'a1', 'b2', 'b1']
 */
function filterToString(eachFilter) {
  let trimmedFilter = eachFilter.map(Function.prototype.call, String.prototype.trim);
  trimmedFilter.sort();

  return trimmedFilter.join('/');
}
