export default {
    firebase: {
        apiKey: "AIzaSyCG3f2a-LDLg77n27xivCP9_6Xnp6rh8Hw",
        authDomain: "cloudnosysbeta.firebaseapp.com",
        projectId: "cloudnosysbeta",
        databaseURL: 'https://cloudnosysbeta.firebaseio.com',
        storageBucket: "cloudnosysbeta.appspot.com",
        messagingSenderId: "847900877623",
        appId: "1:847900877623:web:70fbc32e9112e727cd70b2",
        measurementId: "G-JJMW7WEFGS"
    },
    api: {
      explorer: 'https://us-central1-cloudnosysbeta.cloudfunctions.net/explorer',
      playbooks: 'https://beta.cloudnosys.com/runPlaybook',
    },
    masterOrg: 'master-cloudnosys_5aj5e',
    // cloudnosysVersion: '2.2.6',
    // cloudnosysVersion: '2.2.4',
  };
  