/**
 * @Parameters Params => history(today,week,month,yest) , timestamp
 * @Description This function will calculate  start and end time of specific timestamp
 * @Algorithm
 * 1. take time stamp and historyType
 * 2. calculate start/end time
 * 3. return start/end time
 */
import moment from 'moment';

export const getHistoricalDate = historyType => {
  // currentdate = firebase.firestore.Timestamp.fromDate(
  //   new Date() // Date.now() - 86400000
  // );
  const currentdate = new Date();

  let startTime = '';
  let endTime = '';
  if (historyType === 'Current' || historyType === 'Today') {
    startTime = moment(currentdate)
      .startOf('day')
      .valueOf();

    // .format("MMM Do, YYYY hh:mm a");
    endTime = moment(currentdate)
      .endOf('day')
      .valueOf();
    // .format("MMM Do, YYYY hh:mm a");
  } else if (historyType === 'Yesterday') {
    startTime = moment(currentdate)
      .startOf('day')
      .subtract(1, 'day')
      .valueOf();
    // .format("MMM Do, YYYY hh:mm a");
    endTime = moment(currentdate)
      .subtract(1, 'day')
      .endOf('day')
      .valueOf();
    // .format("MMM Do, YYYY hh:mm a");
  } else if (historyType === 'Weekly' || historyType === 'Last Week') {
    startTime = moment(currentdate)
      .subtract(7, 'day')
      .endOf('week')
      .add(1, 'day')
      .startOf('day')
      .valueOf();
    // .format("MMM Do, YYYY hh:mm a");
    endTime = moment(currentdate)
      .subtract(7, 'day')
      .endOf('week')
      .add(1, 'day')
      .endOf('day')
      .valueOf();
    // .format("MMM Do, YYYY hh:mm a");
  } else if (historyType === 'Monthly' || historyType === 'Last Month') {
    startTime = moment(currentdate)
      .subtract(30, 'day')
      .endOf('month')
      .startOf('day')
      .valueOf();
    // .format("MMM Do, YYYY hh:mm a");
    endTime = moment(currentdate)
      .subtract(30, 'day')
      .endOf('month')
      .endOf('day')
      .valueOf();
    // .format("MMM Do, YYYY hh:mm a");
  } else {
    endTime = moment(historyType)
      .endOf('day')
      .valueOf();
  }

  return { endTime, startTime };
};
