import { notification } from 'antd';
import { create, read } from '@/services/firestore';
import moment from 'moment';
import { createScopeChecksum } from '../helpers/checkSumId';
// import getData from '../components/WidgetWrapper/dummyWidgets';
import { getWidgetType } from '../../config/dashboardConfigs';
import { getWidgetHistory } from '../services/widget';
import { getHistoricalDate } from '@/utils/getHistoricalDate';
let namespace = 'widget';
export default {
  namespace,
  state: { loading: false, categoryFilter: '' },
  effects: {
    *current({ payload }, { call, put, takeEvery, take, select }) {
      // console.log('👁 widget effect called', payload);
      // 1 - create params
      let { widgetId, historyType } = payload;

      let orgId = yield select(state => state.organisation.current.id);
      let filterAccountsId = yield select(state =>
        state.cloudAccount.selectedCloudAccounts.map(v => v.id)
      );
      if (filterAccountsId.length === 0) return;
      let historyTime = getHistoricalDate(historyType);
      let scope = {
        cloudAccounts: filterAccountsId,
        filter: getWidgetType(widgetId) === 'trends' ? historyType : '',
        historyType: historyTime,
      };
      let hashedId = createScopeChecksum({ filterAccountsId });

      let newPayload = {
        params: {
          id: hashedId,
          orgId,
          widgetId,
        },
        scope,
      };

      // // create subscription
      const service = yield call(read, {
        module: namespace,
        ...newPayload,
        no404error: true,
      });

      function* push(response) {
        if (!response) {
          yield put({
            type: 'saveLoadingWidget',
            payload: {
              data: response,
              widgetId,
              status: 'running',
            },
          });
        } else {
          response.widgetId = widgetId;

          yield put({
            type: 'saveWidget',
            payload: response,
          });
        }
      }

      // on every callback from service
      yield takeEvery(service, push);

      // unsubscribe when asked by creator
      yield take(`CANCEL_WATCH_${widgetId}`);

      service.close();
    },

    *getWidgetHistory({ payload }, { call, put, select }) {
      try {
        // clear old before new request
        yield put({
          type: 'setLoadingState',
          loading: true,
        });

        yield put({
          type: `clear`,
          payload,
        });

        // 1 - create params
        let { widgetId, historyType } = payload;

        let orgId = yield select(state => state.organisation.current.id);
        let userId = yield select(state => state.user.currentUser.uid);
        let filterAccountsId = yield select(state =>
          state.cloudAccount.selectedCloudAccounts.map(v => v.id)
        );

        if (filterAccountsId.length === 0) return;
        let historyTime = getHistoricalDate(historyType);

        let params = {
          cloudAccounts: filterAccountsId,
          historyType: historyTime.endTime,
          userId,
          status: 'expired',
          createdAt: new Date(),
          filter: getWidgetType(widgetId) === 'trends' ? historyType : '',
          orgId,
          widgetName: widgetId,
        };

        let historyResponse = yield call(getWidgetHistory, params);
        historyResponse.widgetId = widgetId;
        historyResponse.createdAt =
          historyTime.endTime && getWidgetType(widgetId) !== 'trends'
            ? moment(new Date(historyTime.endTime)).fromNow()
            : moment(Date()).fromNow();
        historyResponse.data = historyResponse.data.widgetResponse;
        // TODO: return this from the backend
        historyResponse.status = 'completed';

        yield put({
          type: 'saveWidget',
          payload: historyResponse,
        });
        yield put({
          type: 'setLoadingState',
          loading: false,
        });
      } catch (error) {
        yield put({
          type: 'setLoadingState',
          loading: false,
        });
      }
    },

    *create({ payload }, { call }) {
      try {
        yield call(create, {
          module: namespace,
          ...payload,
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      }
    },
    *unsubscribe({ widgetId }, { put }) {
      yield put({ type: `CANCEL_WATCH_${widgetId}` });
    },
  },

  reducers: {
    clear(state, { payload }) {
      const { widgetId } = payload;
      return {
        ...state,
        [widgetId]: null,
      };
    },
    setLoadingState(state, { loading }) {
      return {
        ...state,
        loading,
      };
    },
    saveWidget(state, { payload }) {
      const { widgetId } = payload;

      return {
        ...state,
        [widgetId]: payload,
      };
    },
    saveFilter(state, { payload }) {
      return {
        ...state,
        categoryFilter: payload,
      };
    },
    saveLoadingWidget(state, { payload }) {
      const { widgetId } = payload;

      return {
        ...state,
        [widgetId]: payload,
      };
    },
  },
};

// payload.data = '';

// if (widgetId === 'Risks by Regions') {
//   let res = getData(widgetId);
//   payload.data = res;
//   payload.status = 'completed';
// }
// let isDummy = false;
// if (isDummy) {
//   let res = getData(widgetId);
//   payload.data = res;
//   payload.status = 'completed';
// }
