import React, { Fragment } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Input, Button, Checkbox, message, Tooltip } from 'antd';
import { formatMessage } from 'umi-plugin-react/locale';

class InviteForm extends React.PureComponent {
  state = {
    isChecked: true,
    disabled: false,
    inviteLoading: false,
  };

  componentDidMount() {
    const { packageExpiry } = this.props;
    if (packageExpiry && packageExpiry === 'expired') {
      this.setState({
        disabled: true,
      });
    }
  }
  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };
  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!this.isValidated) {
        this.setState({
          disabled: false,
        });
        return message.error(`Please enter email address`);
      }

      if (!err) {
        const { handleAddInvite } = this.props;
        handleAddInvite(values.email, window.location.origin);
      }
    });
  };

  checkValidEmail = (rule, value, callback) => {
    // This method will check for emails that are not barred.
    const regex = /^([\w-.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,4})?$/;
    this.isValidated = regex.test(value);

    if (!this.isValidated) {
      this.setState({
        disabled: true,
      });
      callback(`Invalid Email, use your company email`);
    } else {
      this.setState({
        disabled: false,
      });
      callback();
    }
  };

  onChange = () => {
    this.setState(prevState => ({
      isChecked: !prevState.isChecked,
    }));
  };

  render() {
    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;
    const { disabled } = this.state;
    // Only show error after a field is touched.
    const emailError = isFieldTouched('email') && getFieldError('email');
    return (
      <Fragment>
        <h4 className="ant-typography" style={{ marginBottom: '10px', marginLeft: '26px' }}>
          Access Control
        </h4>
        <Card
          title="Add user to organization"
          headStyle={{ border: 'none' }}
          bordered={false}
          bodyStyle={{ paddingTop: '0px', marginTop: '-7px' }}
        >
          <Form layout="inline" onSubmit={this.handleSubmit}>
            <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: formatMessage({ id: 'validation.email.required' }),
                  },

                  {
                    validator: this.checkValidEmail,
                  },
                ],
              })(
                <Input
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email address"
                />
              )}
            </Form.Item>

            <Form.Item>
              <Tooltip
                title={
                  this.props.packageExpiry === 'expired' &&
                  'You can not invite any user because your package has been expired. Contact support'
                }
              >
                <Button
                  loading={this.props.loadingCreate}
                  type="primary"
                  htmlType="submit"
                  disabled={disabled || this.props.packageExpiry === 'expired'}
                >
                  Invite
                </Button>
              </Tooltip>
            </Form.Item>
          </Form>
        </Card>
      </Fragment>
    );
  }
}

export default Form.create({ name: 'InviteForm' })(InviteForm);
